import {HttpMethod, HttpService} from "./HttpService";
import {Transaction} from "../model/user/purchase/Transaction";

export class TransactionService {

    public static getTransactions(): Promise<Transaction[]> {
        return HttpService.sendRequest<Transaction[]>(
            HttpMethod.GET,
            '/api/transaction',
        );
    }
}