import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import BlogPosts from "../../../components/user/blog/BlogPosts";
import Heading from "../../../components/common/header/Heading";
import {Await, defer, useLoaderData} from "react-router-dom";
import {BlogArticles} from "../../../model/user/blog/Blog";
import {BlogService} from "../../../service/BlogService";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import React, {Suspense} from "react";
import {ApiError} from "../../../service/HttpService";
import {isAdmin} from "../../../util/AuthUtil";
import Categories from "../../../components/ui/category/Categories";
import {useCategory} from "../../../hooks/useCategory";
import {blogCategories} from "../../../model/common/blog/BlogCategories";

type LoaderData = {
    articles: Promise<BlogArticles>;
    isAdmin: boolean;
};


export default function BlogPage() {
    const {articles, isAdmin} = useLoaderData() as LoaderData;

    const {selectedCategory, onCategoryChange} = useCategory(blogCategories);

    return <>
        <Meta title="Blog"/>
        <MainDesign containerStyles='container pt-20 pb-24'>
            <Heading customStyles='mb-16 text-center mw-md-4xl mx-auto' title='Opublikowane artykuły' badgeText='blog'
                     subtitle='Znajdziesz tutaj wszystkie opublikowane już artykuły. Możesz wybrać dowolną kategorię lub przeglądać wszystkie.'/>
            <Categories categories={blogCategories} selectedCategory={selectedCategory}
                        onCategoryChange={onCategoryChange}/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={articles} errorElement={<ErrorContent/>}>
                    {(loadedArticles) => <>
                        <BlogPosts isAdmin={isAdmin} blogArticles={loadedArticles} category={selectedCategory.value}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadArticles(category: string): Promise<BlogArticles> {
    return await BlogService.getArticles(undefined, category);
}

export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const category = url.searchParams.get('category') ?? 'all';

    return defer({
        articles: loadArticles(category),
        isAdmin: await isAdmin()
    })
}

export async function deleteAction({request}: { request: Request }) {
    const formData = await request.formData();
    const articleUuid = formData.get('uuid') as string;
    try {
        await BlogService.deleteArticle(articleUuid);
        return {
            status: 200,
            body: 'Pomyślnie usunięto.'
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}