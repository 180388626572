import {OptInProps} from "../../../util/ExperimentUtil";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import Heading from "../../common/header/Heading";
import React, {useEffect} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import AdvantageItem from "../../user/ai/pricing/AdvantageItem";
import ConfirmModal from "../../ui/ConfirmModal";
import OptInEmailAndName from "../../user/offer/OptInEmailAndName";
import {Link, useSearchParams} from "react-router-dom";
import {BubbleNotificationWithPlaceholders} from "../../ui/motion/BubbleNotificationWithPlaceholders";
import ReactPixel from "react-facebook-pixel";
import PossibleUniversities from "../../user/other-logos/PossibleUniversities";
import AnimatedLink from "../../ui/motion/AnimatedLink";

export default function MWOptInC(props: OptInProps) {
    const [searchParams] = useSearchParams();
    const {
        handleButtonClick,
        confirmModalOpen,
        setConfirmModalOpen,
        isInputValid,
        email,
        setEmail,
        name,
        setName
    } = props;
    const {isSubmitting} = useSubmitFormAction(`/mistrzowskie-wsparcie/spotkanie?variation=A${searchParams.get('source') ? `&source=${searchParams.get('source')}` : ''}`, null, () => {
        setConfirmModalOpen(false);
    });

    useEffect(() => {
        ReactPixel.pageView();
    }, []);

    const index = searchParams.get('index');

    return <>
        <div className='d-block d-md-none'>
            <p className='text-white text-center fs-12 mb-5'>Bezpłatne szkolenie dla rodziców <b>LICEALISTÓW</b></p>
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'Jak zostać'}
                     customTitleStyles='font-heading mb-1 fs-2 text-white'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto mt-0'
                     title={'NAJLEPSZYM'}
                     customTitleStyles='font-heading mb-1 fs-2 text-warning'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'uczniem?'}
                     customTitleStyles='font-heading mb-6 fs-2 text-white'
            />
            <p className={'text-warning fs-11 fw-medium text-center mx-1'}>
                Nawet jeżeli Twoje dziecko ma trudności z motywacją, spędza dużo czasu na telefonie lub nie lubi się
                uczyć.
            </p>
        </div>
        <div className='d-none d-md-block'>
            <p className='text-white text-center fs-11'>Bezpłatne szkolenie dla rodziców <b>LICEALISTÓW</b></p>

            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'Jak zostać'}
                     customTitleStyles='font-heading mb-1 fs-3 text-white'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto mt-0'
                     title={'NAJLEPSZYM'}
                     customTitleStyles='font-heading mb-1 fs-3 text-warning'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'uczniem?'}
                     customTitleStyles='font-heading mb-6 fs-3 text-white'
            />
            <p className={'text-warning fs-10 fw-medium text-center'}>
                Nawet jeżeli Twoje dziecko ma trudności z motywacją, <br/> spędza dużo czasu na telefonie lub nie lubi
                się uczyć.
            </p>
        </div>
        <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
            {
                index === "" ? <AnimatedLink
                    className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                    to={`/mistrzowskie-wsparcie/spotkanie?${searchParams.toString()}&buttonVisible=true`}
                >
                    ZOBACZ
                </AnimatedLink> : <AnimatedButton
                    className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                    onClick={() => {
                        handleButtonClick(1, "A")
                    }}
                >
                    ZOBACZ
                </AnimatedButton>
            }

        </div>
        <div className='row container mt-8'>
            <div className='col-md-6 col-12 text-center'>
                <div style={{position: 'relative', width: '100%', paddingTop: '66.67%'}}> {/* 3:2 aspect ratio */}
                    <img
                        data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/opt-in-photo.webp'
                        className='img-fluid lazyload' alt='mistrzowskie wsparcie'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>
            <div className='col-md-6 col-12 mt-2'>
                <div className='container'>
                    <Heading customTitleStyles='font-heading mb-4 fs-6 text-white'
                             customStyles='mb-6 text-md-left mw-md-4xl mx-auto' title='Na szkoleniu pokażę Ci:'/>
                    <AdvantageItem
                        title={`Jak Twoje dziecko może uczyć się mniej i zdobywać najlepsze oceny.`}/>
                    <AdvantageItem
                        title='Jak określić naturalne talenty i predyspozycje Twojego dziecka.'/>
                    <AdvantageItem
                        title={`Jak dostać się na topowe uczelnie ucząc się mniej niż 1.5h dziennie.`}/>
                    <AdvantageItem
                        title='Jak poradzić sobie z uzależnieniem od telefonu.'/>
                    <AdvantageItem
                        title='Sprawdzony proces na wiarę w swoje możliwości oraz redukcję stresu.'/>
                    <AdvantageItem
                        title='Dlaczego korepetycje nie są rozwiązaniem - i jak sprawić, by nie były więcej potrzebne.'/>
                    <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
                        {
                            index === "" ? <AnimatedLink
                                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                                to={`/mistrzowskie-wsparcie/spotkanie?${searchParams.toString()}&buttonVisible=true`}
                            >
                                ZOBACZ
                            </AnimatedLink> : <AnimatedButton
                                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                                onClick={() => {
                                    handleButtonClick(2, "A")
                                }}
                            >
                                ZOBACZ
                            </AnimatedButton>
                        }
                    </div>
                </div>
            </div>
        </div>

        <PossibleUniversities/>

        {/*<Partners/>*/}

        <BubbleNotificationWithPlaceholders
            message="{name} zapisał/a się {time} minut temu"
            delay={5000}      // Show after 5 seconds
            duration={5000}   // Hide after 5 seconds
        />

        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Zobacz bezpłatne szkolenie"
                      customSubtitleStyles={'mt-0'}
                      buttonType='submit'
                      isSubmitting={isSubmitting}
                      submitDisabled={!isInputValid}
                      hideCancelButton={true}
                      hideXButton={true}
                      customConfirmButtonName='Zobacz'
                      customTitleStyles={'fs-6 fw-bold text-white'}
                      children={
                          <OptInEmailAndName name={name} setName={setName} email={email} setEmail={setEmail}
                                             fullWidth={true}
                                             hideFooter={true}
                          />
                      }
                      childrenBelowButtons={
                          <p className='mb-1 fs-14 fw-medium text-secondary-light text-left mt-2'>
                              <span>Przechodząc dalej akceptujesz </span>
                              <Link className='text-success link-success' to='/polityka-prywatnosci'>
                                  politykę prywatności
                              </Link>
                              <span> i zapisujesz się do newslettera MISTRZOWSKIEGO Wsparcia, z którego w każdej chwili możesz się wypisać. </span>
                          </p>
                      }
        />
    </>
}