import SlideInWhenVisible from "../../ui/motion/SlideInWhenVisible";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import React from "react";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

export default function LearningCourseEnroll() {
    return <>
        <div className='row g-16 mt-12'>
            <div className='col-12 col-md-6'>
                <div className='mw-md-xl'>
                    <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                      Start: Przełom 2024/2025
                    </span>
                    <h1
                        className='mb-5 font-heading fs-4 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        Bądź jednym z pierwszych!
                    </h1>
                    <div className='mb-3 fs-9 fw-medium text-secondary-light'>
                        Już teraz pracujemy z <span className=''>małą grupą </span> osób, aby
                        przygotować materiały jak najwyższej
                        jakości. <br/> <span className='text-success'>Zyskaj dostęp</span> do wszystkich zasobów
                        i indywidualne lekcje z twórcą kursu, kupując
                        dostęp w promocyjnej cenie już teraz. <br/> <span
                        className='text-success'>Umów się</span> na bezpłatne konsultacje celem utworzenia
                        spersonalizowanego planu działania.
                    </div>
                    <div className='row g-4'>
                        <div className='col-12 col-md-auto'>
                            <SlideInWhenVisible initial={{opacity: 0, x: 100}} duration={0.2} initialX={100}>
                                <AnimatedLink
                                    hashLink={true}
                                    className='btn btn-lg btn-success w-100 text-success-light shadow'
                                    to='/kurs-efektywnego-dzialania'
                                >
                                    Umawiam się
                                </AnimatedLink>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                    <h2
                        className='mb-3 font-heading fs-5 text-white mt-16'
                        style={{letterSpacing: '-1px'}}
                    >
                        Nauczysz się jak:
                    </h2>
                    <div className='mb-8 fs-9 fw-medium text-secondary-light'>
                        <ul className='mt-1'>
                            <li>
                                Mistrzowsko zarządzać czasem
                            </li>
                            <li>
                                Osiągnąć mindset zwycięzcy
                            </li>
                            <li>
                                Niezawodnie wyznaczać i realizować cele
                            </li>
                            <li>
                                Zapamiętywać nawet o 300% więcej
                            </li>
                            <li className='mb-1'>
                                Bezproblemowo łączyć naukę, pracę, hobby i życie towarzyskie
                            </li>
                            <span className='text-success fw-bold'>W skrócie: </span>
                            Poznasz narzędzia, które pomogą Ci <span className='text-decoration-underline'>osiągnąć wszystko, o czym marzysz. </span>
                        </ul>

                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='row justify-content-center'>
                    <div className='position-relative col-auto'>
                        <img
                            className='position-absolute top-0 start-0 mt-n8 ms-n12'
                            style={{zIndex: 1}}
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/circle-orange.svg'
                            alt=''
                        />
                        <img
                            className='position-relative img-fluid'
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnej-nauki/kurs+efektywnej+nauki1.png'
                            alt=''
                        />
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <div className="row justify-content-center g-4 pb-8 mb-10">
                    <div className="col-12 col-md-auto">
                        <SlideInWhenVisible initial={{opacity: 0, x: 100}} duration={0.2} initialX={100}>
                            <AnimatedLink
                                className="btn btn-lg btn-success w-100 text-success-light shadow"
                                onClick={() => {
                                    TraceService.addTrace(TraceType.BtnClick, 'btn-1 -> Zacznij działać')
                                }}
                                to={'/start'}>
                                Zapisuję się już teraz
                            </AnimatedLink>
                        </SlideInWhenVisible>
                    </div>
                </div>
            </div>

        </div>
    </>
}