import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import {KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1} from "../../../../util/ExperimentUtil";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import React from "react";
import {useSearchParams} from "react-router-dom";

interface ScheduleCallButtonMaturaParentProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    variation: "A" | "B"
    buttonNum: number
    customExperiment?: string
}

export default function ScheduleCallButtonMaturaParent(props: ScheduleCallButtonMaturaParentProps) {
    const {setOpen, variation, buttonNum, customExperiment} = props;
    const [searchParams] = useSearchParams();
    return <AnimatedButton className='btn btn-lg btn-success mt-4' onClick={() => {
        setOpen(true);
        TraceService.addTrace(TraceType.BtnClick, JSON.stringify({
            name: 'Schedule call',
            buttonNum: buttonNum,
            variation: variation
        }))
        TraceService.addTraceWithExperiment(TraceType.BtnClick, customExperiment ?? KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1, variation, searchParams.get('source'))
    }}>
        Umów się na indywidualną konsultację
    </AnimatedButton>
}