import MainDesign from "../../MainDesign";
import {useEffect, useState} from "react";
import CreateOrEditExerciseForm from "../../../components/admin/exercise/CreateOrEditExerciseForm";
import CreateOrEditExerciseLivePreview from "../../../components/admin/exercise/CreateOrEditExerciseLivePreview";
import {ExerciseRequest} from "../../../model/user/exercise/Exercise";
import {ExerciseService} from "../../../service/ExerciseService";
import {redirect, useParams} from "react-router-dom";

interface AdminCreateOrAddExerciseProps {
    actionType: "CREATE" | "EDIT"
}

type PathParams = {
    uuid: string | undefined;
};

export default function AdminCreateOrAddExercise(props: AdminCreateOrAddExerciseProps) {
    const {actionType} = props;
    const {uuid} = useParams<PathParams>();

    if (actionType === "EDIT" && !uuid) {
        throw new Error("Aby edytować, wprowadź UUID!");
    }

    const [publicationDate, setPublicationDate] = useState('');
    const [publicationHour, setPublicationHour] = useState('');
    const [title, setTitle] = useState('');
    const [field, setField] = useState('');
    const [content, setContent] = useState('');
    const [solution, setSolution] = useState('');

    useEffect(() => {
        if (actionType === "EDIT") {
            ExerciseService.getExercise(uuid!!, true).then((exercise) => {
                setPublicationDate(exercise.addedAt.split(' ')[0])
                setPublicationHour(exercise.addedAt.split(' ')[1])
                setTitle(exercise.title)
                setContent(exercise.content)
                setSolution(exercise.solution)
                setField(exercise.field)
            })
        }
    }, []);

    return <MainDesign containerStyles="py-10">
        <div className='row g-16'>
            <div className='col-12 col-md-6'>
                <CreateOrEditExerciseForm publicationDate={publicationDate} setPublicationDate={setPublicationDate}
                                          publicationHour={publicationHour} setPublicationHour={setPublicationHour}
                                          title={title} setTitle={setTitle} field={field} setField={setField}
                                          content={content}
                                          setContent={setContent} solution={solution} setSolution={setSolution}
                                          actionType={actionType} id={uuid}
                />
            </div>
            <div className='col-12 col-md-6'>
                <CreateOrEditExerciseLivePreview publicationDate={publicationDate} publicationHour={publicationHour}
                                                 title={title} field={field} content={content} solution={solution}/>
            </div>
        </div>
    </MainDesign>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const exerciseData: ExerciseRequest = {
        id: formData.get('id') as string | undefined,
        requestType: formData.get("requestType") as "CREATE" | "EDIT",
        publicationDate: formData.get('publicationDate') as string,
        publicationHour: formData.get('publicationHour') as string,
        title: formData.get('title') as string,
        content: formData.get('content') as string,
        solution: formData.get('solution') as string,
        field: formData.get('field') as string,
    };

    try {
        const redirectUrl = await ExerciseService.addOrEditExercise(exerciseData);
        return redirect(`/zadanie/${redirectUrl}`);
    } catch (error) {
        const apiError = error as Error;
        return {
            status: 500,
            body: apiError.message
        }
    }
}