import {ContentType, HttpMethod, HttpService} from "../HttpService";
import {AssemblyAiToken} from "../../model/user/ai/speaking/AssemblyAi";
import {SpeakingSettings, SpeakingSettingsResponse} from "../../model/user/ai/speaking/SpeakingSettings";
import {SpeakingResponse} from "../../model/user/ai/speaking/SpeakingResponse";
import {LoadThreadMessages} from "../../model/user/ai/chat/LoadThreadMessages";
import {DeepgramToken} from "../../model/user/ai/speaking/Deepgram";

export class SpeakingService {

    public static getAssemblyToken(): Promise<AssemblyAiToken> {
        return HttpService.sendRequest<AssemblyAiToken>(
            HttpMethod.GET,
            '/api/get-ai-tutor-token'
        );
    }

    public static generateSpeakingToken(): Promise<DeepgramToken> {
        return HttpService.sendRequest<AssemblyAiToken>(
            HttpMethod.POST,
            '/api/generate-ai-tutor-token'
        );
    }

    public static establishWebsocketConnection(token: string, language: string): WebSocket {
        // https://developers.deepgram.com/docs/model
        return new WebSocket(`wss://api.deepgram.com/v1/listen?language=${language}&model=nova-2-${language === 'en' ? 'conversationalai' : 'general'}&interim_results=true&punctuate=true`, [
            'token',
            token,
        ]);
    }

    public static getThreadMessages(threadId: string): Promise<LoadThreadMessages> {
        return HttpService.sendRequest<LoadThreadMessages>(
            HttpMethod.GET,
            `/api/thread/${threadId}/speaking`,
        );
    }

    public static getSpeakingResponse(transcript: string, threadId: string | null, modelId: string, duration: number): Promise<SpeakingResponse> {
        return HttpService.sendRequest(
            HttpMethod.POST,
            '/api/ai-tutor',
            JSON.stringify({
                transcript: transcript.trim(),
                threadId: threadId,
                modelId: modelId,
                duration: duration
            }),
            ContentType.JSON
        )
    }

    public static updateSpeakingSettings(speakingSettings: SpeakingSettings): Promise<SpeakingSettingsResponse> {
        return HttpService.sendRequest(
            HttpMethod.POST,
            '/api/update-speaking-settings',
            JSON.stringify(speakingSettings),
            ContentType.JSON
        )
    }
}