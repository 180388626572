import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import EffectiveActionCourseMaturaParentThankYou
    from "../../../components/funnel/direct/EffectiveActionCourseMaturaParentThankYou";
import ProdukacjaCopyrightNote from "../../../components/funnel/ProdukacjaCopyrightNote";
import React from "react";

export default function EffectiveActionCourseMaturaParentThankYouPage() {
    return <>
        <>
            <Meta title='Spotkanie zostało umówione!'/>
            <MainDesign containerStyles='container pt-24 pb-10'>
                <Heading badgeText='sukces'
                         title='Spotkanie zostało umówione.'
                         customSubtitleStyles='fs-10 fw-medium text-warning mw-md-3xl mx-auto text-center'
                         subtitle='Wszystkie szczegóły otrzymasz mailem. Do usłyszenia wkrótce!'/>
                <EffectiveActionCourseMaturaParentThankYou/>
                <ProdukacjaCopyrightNote/>
            </MainDesign>
        </>
    </>
}