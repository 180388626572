import {Feedback} from "../../../model/common/Feedback";
import {motion} from "framer-motion";
import {formatDate} from "../../../util/DateUtil";
import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";

interface FeedbackItemProps {
    feedback: Feedback;
    isSubmitting: boolean;
    markFeedbackAsRead: (feedbackId: string) => void;
    openFeedbackSummary: (feedback: Feedback) => void;
}

export default function FeedbackItem(props: FeedbackItemProps) {
    const {feedback, isSubmitting, markFeedbackAsRead, openFeedbackSummary} = props;
    return <>
        <motion.div className='col-12' key={feedback.id} initial={{opacity: 0, scale: 0.5}}
                    animate={{opacity: 1, scale: 1}}>
            <div className='px-10 py-8 bg-light-dark rounded shadow'>
                <div className='row justify-content-between g-4'>
                    <div className='col d-flex flex-column justify-content-center'>
                        <h3 className='mb-1 font-heading fs-8 text-white'>
                            {feedback.category === 'CONTACT' ? `Wiadomość od ${feedback.userName}` : (feedback.category === 'RECOMMENDATION' ? `Sugestia od ${feedback.userName}` : `Feedback od ${feedback.userName}`)}
                        </h3>
                        <h6 className='mb-1 text-white'>
                            {formatDate(feedback.addedAt)}
                        </h6>
                        <p className='mb-0 fw-medium text-secondary-light'
                           style={{fontSize: '16px'}}>
                            {feedback.message ? feedback.message : 'Kliknij aby zobaczyć szczegóły...'}
                        </p>
                        <div>
                            {
                                feedback.unread && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Nowa
                                </span>
                            }
                            {
                                feedback.message && feedback.category === 'CONTACT' && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Kontakt
                                </span>
                            }
                            {
                                feedback.message && feedback.category === 'RECOMMENDATION' && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Rekomendacja
                                </span>
                            }
                            {
                                (feedback.dreamVision || feedback.feedback) && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Otwarta odpowiedź
                                </span>
                            }
                            {
                                feedback.category === 'CHAT' && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Chat
                                </span>
                            }
                            {
                                feedback.category === 'CONVERSATIONS' && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Konwersacje
                                </span>
                            }
                            {
                                feedback.category === 'NEWSLETTER' && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Newsletter
                                </span>
                            }
                            {
                                feedback.category === 'EXERCISE' && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Zadania
                                </span>
                            }
                            {
                                feedback.category === 'KED_EPISODE' && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      KED
                                </span>
                            }
                        </div>
                    </div>
                    <div className='col-12 col-md-auto mt-6'>
                        <AnimatedButton
                            className='btn btn-lg btn-success w-100 text-success-light shadow'
                            onClick={() => openFeedbackSummary(feedback)}
                        >
                            Zobacz szczegóły
                        </AnimatedButton>
                    </div>
                    {
                        feedback.unread && <div className="col-12 col-md-auto mt-6">
                            <AnimatedButton
                                className="btn btn-lg btn-warning w-100 text-success-light shadow"
                                onClick={() => markFeedbackAsRead(feedback.id)}
                                disabled={isSubmitting}
                            >
                                Przeczytane
                            </AnimatedButton>
                        </div>
                    }
                </div>
            </div>
        </motion.div>
    </>
}