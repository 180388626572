import React from "react";
import {LoadThreadsAndInfo} from "../../../../model/user/ai/chat/LoadThreadsAndInfo";
import CustomSelect from "../../../ui/form/CustomSelect";

interface ThreadSelectionProps {
    allThreads: LoadThreadsAndInfo
    selectedThread: string | null
    handleThreadChange: (item: any) => void;
}

export default function ThreadSelection(props: ThreadSelectionProps) {
    const {selectedThread, handleThreadChange, allThreads} = props;
    return <div className='form-group'>
        <label
            className='mb-2 text-white fw-light'
            htmlFor='modalInput2-2'
        >
            Wybierz wątek (opcjonalnie)
        </label>

        <CustomSelect value={selectedThread || undefined} onChange={(event) => handleThreadChange(event.target.value)}>
            <option value={undefined}>Nowy wątek</option>
            {
                allThreads.threads && allThreads.threads.map((threadInfo) => (
                    <option value={threadInfo.id}
                            key={threadInfo.id}>{threadInfo.title}</option>
                ))
            }
        </CustomSelect>
    </div>
}