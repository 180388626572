import Vimeo from "@u-wave/react-vimeo";
import React, {useRef, useState} from "react";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

export interface VideoWithPlayButtonProps {
    onPlay?: () => void;
    vimeoVideoId: string;
    id: string
    customDivWrapperClasses?: string
    trackStatistics?: boolean
    callAfterTime?: CallAfterTime
}

interface CallAfterTime {
    time: number
    action: () => void
}

export default function VideoWithPlayButton(props: VideoWithPlayButtonProps) {
    const {onPlay, vimeoVideoId, id, customDivWrapperClasses, trackStatistics, callAfterTime} = props;
    const [playButtonVisible, setPlayButtonVisible] = useState(true);
    const [overlayVisible, setOverlayVisible] = useState(true);

    const [paused, setPaused] = useState(false);
    const [ended, setEnded] = useState(false);
    const [lastWatchedTime, setLastWatchedTime] = useState<number | null>(null); // New state for last watched time

    const playerRef = useRef<any>(null);
    const intervalRef = useRef<number | null>(null);

    function handlePlayerReady(player: any) {
        player.on("pause", handlePlayerPause);
        player.on("play", handlePlayerPlay);
        player.on("ended", handlePlayerEnd);

        return () => {
            player.off("pause", handlePlayerPause);
            player.off("play", handlePlayerPlay);
            player.off("ended", handlePlayerEnd);
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }

    function handlePlayerEnd() {
        TraceService.addTrace(TraceType.PlayVideo, "Video has ended.");
    }

    function handlePlayerPlay() {
        if (ended) {
            return;
        }
        setPaused(false);
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = window.setInterval(async () => {
            if (playerRef.current?.player) {
                const time = await playerRef.current.player.getCurrentTime();
                const hadEnded = await playerRef.current.player.getEnded();

                if (ended) {
                    return;
                }

                // Only send trace if current time is different from the last watched time
                if (lastWatchedTime !== time) {
                    TraceService.addTrace(TraceType.PlayVideo, `Watched time: ${time}, ended: ${hadEnded}, paused: ${paused}`);
                    setLastWatchedTime(time); // Update last watched time
                }

                if (callAfterTime) {
                    if (callAfterTime.time <= time) {
                        try {
                            callAfterTime.action();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }

                // Safety check if someone left the video on end and 'ended' was not called...
                if (hadEnded && intervalRef.current !== null) {
                    setEnded(true);
                    clearInterval(intervalRef.current);
                    return;
                }
            }
        }, 5000);
    }

    function handlePlayerPause() {
        setPaused(true);
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
        TraceService.addTrace(TraceType.PlayVideo, 'Stopped video.');
    }

    const handlePlayClick = () => {
        setPlayButtonVisible(false);
        playVideo();
    }

    const playVideo = () => {
        const player = document.querySelector('.vimeo-iframe iframe');
        if (player) {
            // @ts-ignore
            player.contentWindow.postMessage({method: 'play'}, '*');
        }
        setOverlayVisible(false);
        setPlayButtonVisible(false);

        if (onPlay) {
            onPlay();
        }
    }

    return <>
        <div className={customDivWrapperClasses ? customDivWrapperClasses : 'mw-md-3xl mx-auto mb-8 position-relative'}
             id={id}>
            <Vimeo
                ref={playerRef}
                className='h-100 vimeo-iframe'
                video={`https://vimeo.com/${vimeoVideoId}?share=copy`}
                responsive={true}
                onReady={(player: any) => handlePlayerReady(player)}
                paused={paused}
                dnt={!trackStatistics}
            />
            {overlayVisible && (
                <div className="overlay position-absolute top-0 start-0 w-100 h-100"
                     onClick={playVideo}
                     style={{background: 'rgba(0,0,0,0.2)'}}>
                </div>
            )}
            {playButtonVisible &&
                <div
                    className='position-absolute top-50 start-50 translate-middle text-success link-success play-button'
                    onClick={handlePlayClick}
                    style={{zIndex: 1}}
                >
                    <svg
                        width={64}
                        height={64}
                        viewBox='0 0 64 64'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <circle cx={32} cy={32} r={32} fill='currentColor'/>
                        <path
                            d='M40.5 31.13L26.5 23.05C26.348 22.9622 26.1755 22.916 26 22.916C25.8245 22.916 25.652 22.9622 25.5 23.05C25.3474 23.1381 25.2208 23.265 25.133 23.4177C25.0452 23.5705 24.9993 23.7438 25 23.92V40.08C24.9993 40.2562 25.0452 40.4295 25.133 40.5823C25.2208 40.735 25.3474 40.8619 25.5 40.95C25.652 41.0378 25.8245 41.084 26 41.084C26.1755 41.084 26.348 41.0378 26.5 40.95L40.5 32.87C40.6539 32.7828 40.7819 32.6563 40.871 32.5035C40.96 32.3506 41.007 32.1769 41.007 32C41.007 31.8231 40.96 31.6494 40.871 31.4965C40.7819 31.3437 40.6539 31.2172 40.5 31.13ZM27 38.35V25.65L38 32L27 38.35Z'
                            fill='white'
                        />
                    </svg>
                </div>
            }
        </div>
    </>
}