import React from "react";

interface ShowOldExercisesButtonProps {
    showOldExercises: boolean,
    setShowOldExercises: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ShowOldExercisesButton(props: ShowOldExercisesButtonProps) {
    const {showOldExercises, setShowOldExercises} = props;

    function toggleShowOldExercises() {
        setShowOldExercises(!showOldExercises);
    }

    return <div className='text-center mw-xs mx-auto mb-8'>
        <button
            className='btn btn-lg btn-warning text-lg-center w-75 py-3 px-4 h-100 shadow'
            type="button"
            onClick={toggleShowOldExercises}
        >
            {
                showOldExercises ? "Ukryj zadania" : "Pokaż zadania"
            }
        </button>
    </div>
}