import React from "react";

export default function PlannedExercisesDescription() {
    return <div className='mb-6 text-center mw-md-4xl mx-auto'>
                    <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                      zadania
                    </span>
        <h2
            className='font-heading mb-4 fs-5 text-white'
            style={{letterSpacing: '-1px'}}
        >
            Zaplanowane zadania
        </h2>
        <p className='mb-0 fs-10 fw-medium text-secondary-light'>
            Znajdziesz tutaj wszystkie zadania i w razie potrzeby, z łatwością je zedytujesz.
        </p>
    </div>
}