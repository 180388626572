export interface Transaction {
    id: string,
    createdAt: string,
    price: number,
    status: string,
    key: string,
    item: any,
    description: string
}

export function parseTransactionStatus(status: string): string {
    if (status === 'COMPLETED') {
        return 'Zrealizowana'
    } else if (status === 'PAID') {
        return 'Opłacona'
    } else if (status === 'STARTED') {
        return 'Rozpoczęta'
    }

    return 'Nieznana'
}