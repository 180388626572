import React from 'react';
import Spinner from "../../components/common/Spinner";

export default function LoadingModal({isOpen}: { isOpen: boolean }) {
    return (
        <section
            className={`position-fixed bottom-0 start-0 h-100 w-100 d-flex justify-content-center align-items-center ${isOpen ? '' : 'd-none'}`}
            style={{zIndex: 50, backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
        >
            <div className='text-center'>
                <Spinner/>
            </div>
        </section>
    );
}