import React from "react";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import {renderContentWithPlaceholders} from "../../../util/Util";

interface ExerciseContentProps {
    divStyles: string
    content: string
}

export default function ExerciseContent(props: ExerciseContentProps) {
    const {content, divStyles} = props
    const contentWithPlaceholders = renderContentWithPlaceholders(content);
    return <div className={divStyles}>
        <div
            className='font-heading mb-4 fs-8 text-white'
            style={{letterSpacing: '-1px'}}
            key={content}
        >
            <MathJaxContext>
                <MathJax>
                    {contentWithPlaceholders}
                </MathJax>
            </MathJaxContext>
        </div>
    </div>
}