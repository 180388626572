import React, {useEffect, useRef, useState} from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {
    CompleteEpisodeRequest,
    CourseEpisode,
    LearningCourseEpisode
} from "../../../model/user/learning-course/LearningCourse";
import {CourseService} from "../../../service/CourseService";
import {useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import Vimeo from "@u-wave/react-vimeo";
import WyswigEditor from "../../ui/editor/WyswigEditor";
import DOMPurify from "dompurify";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import FeedbackModal from "../feedback/FeedbackModal";

interface LearningCourseEpisodeProps {
    week: number;
    episodeObj: CourseEpisode
    episodeNumber: number;
    episode: LearningCourseEpisode;
}

export default function LearningCourseEpisodeComponent(props: LearningCourseEpisodeProps) {
    const {episode, episodeNumber, week, episodeObj} = props;

    const [paused, setPaused] = useState(false);
    const [ended, setEnded] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [completed, setCompleted] = useState(episodeObj.completed);

    const {isSubmitting} = useSubmitFormAction('', null, () => setCompleted((prev) => !prev), false);

    const playerRef = useRef<any>(null); // Type for Vimeo player ref
    const intervalRef = useRef<number | null>(null); // Type for interval ID ref

    const submit = useSubmit();

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    useEffect(() => {
        setCompleted(episodeObj.completed)
    }, [episodeObj]);

    function updateEpisodeNotes(notes: string) {
        CourseService.updateEpisodeNotes({
            week: week,
            episode: episodeNumber,
            notes: notes
        });
    }

    function submitCompleteEpisode(complete?: boolean) {
        submit({
            completed: complete ? complete : !completed
        }, {
            method: "POST"
        });
    }

    function handlePlayerPause() {
        setPaused(true);
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
    }

    function handlePlayerPlay() {
        if (ended) {
            return;
        }
        setPaused(false);

        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = window.setInterval(async () => {
            if (playerRef.current?.player) {
                const time = await playerRef.current.player.getCurrentTime();
                const hadEnded = await playerRef.current.player.getEnded();
                CourseService.updateCourseWatchTime({
                    episode: episodeNumber,
                    week: week,
                    currentWatchTime: time,
                    increaseWatchTime: 10
                })
                // Safety check if someone left video on end and 'ended' was not called...
                if (hadEnded && intervalRef.current !== null) {
                    setEnded(true)
                    clearInterval(intervalRef.current);
                    return;
                }
                setCurrentTime(time);
            }
        }, 10000);
    }

    // https://developer.vimeo.com/player/sdk/reference
    function handlePlayerReady(player: any) {
        player.on("pause", handlePlayerPause);
        player.on("play", handlePlayerPlay);

        // TODO: Extreact it to separate function
        player.on("ended", () => {
            submitCompleteEpisode(true);
            CourseService.updateCourseWatchTime({
                episode: episodeNumber,
                week: week,
                currentWatchTime: 0,
                increaseWatchTime: 0
            })
            setEnded(true);
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        });

        return () => {
            player.off("pause", handlePlayerPause);
            player.off("play", handlePlayerPlay);
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }

    const renderHtmlFromString = (message: string) => {
        return {__html: DOMPurify.sanitize(message)};
    };

    useEffect(() => {
        // Clean up the interval when the component unmounts
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return <>
        <p className="text-white">
            <AnimatedLink
                className="text-white text-decoration-underline"
                to="/produkt/kurs-efektywnego-dzialania"
            >
                Kurs Efektywnego Działania
            </AnimatedLink>{" "}
            / Tydzień {week}
        </p>
        <div className="row">
            <div className="col-12 col-md-8">
                <h1>{episodeNumber}. {episode.title}</h1>
            </div>
            <div className="col-12 col-md-4">
                <AnimatedButton
                    className={`btn btn-lg ${completed ? "btn-warning" : "border-success"} border-5 w-100 text-success-light shadow`}
                    onClick={() => submitCompleteEpisode()}
                    disabled={isSubmitting}
                >
                    {completed ? "Oznacz jako nieukończoną" : "Oznacz jako ukończoną"}
                </AnimatedButton>
            </div>
        </div>
        {
            episode.vimeoUrl && <Vimeo
                ref={playerRef}
                className="h-100 mt-6"
                dnt={true}
                video={episode.vimeoUrl}
                responsive={true}
                start={episodeObj.currentWatchTime}
                paused={paused}
                onReady={(player: any) => handlePlayerReady(player)}
            />
        }

        <div className="row pb-16">
            {
                episode.vimeoUrl && <div className="col-12">
                    <h2 className="mt-10 fs-7">Twoje własne notatki</h2>
                    <WyswigEditor saveEditorContent={updateEpisodeNotes} rawEditorState={episodeObj.notes}/>
                </div>
            }

            <div className="col-12 col-md-8">
                <h2 className="mt-10 fs-7 mb-5 fw-bold text-decoration-underline">Opis lekcji</h2>
                <p className="text-white fw-medium"
                   dangerouslySetInnerHTML={renderHtmlFromString(episode.description)}/>
            </div>
            <div className="col-12 col-md-4">
                <AnimatedButton
                    className="mt-10 btn btn-sm btn-warning shadow mb-4 d-flex justify-content-between align-items-center fs-11"
                    onClick={() => {
                        setShowFeedbackModal(true);
                        TraceService.addTrace(TraceType.BtnClick, `Prześlij feedback -> ${episode.title}`)
                    }}
                >
                    <span className="text-success-light text-start pe-3">Prześlij feedback, zgłoś błąd</span>
                    <img
                        style={{width: "24px"}}
                        src="https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/feedback.png"
                        alt="Pobierz teraz"
                    />
                </AnimatedButton>
                {episode.resources &&
                    <>
                        <h2 className="mt-6 fs-7 mb-4">Materiały</h2>
                        {episode.resources.map((resource) => <AnimatedLink
                                key={resource.link}
                                className="btn btn-lg btn-success w-100 shadow mb-4 d-flex justify-content-between align-items-center fs-11 px-5"
                                to={resource.link}
                                openLinkInNewTab={true}
                                onClick={() => {
                                    TraceService.addTrace(TraceType.BtnClick, `Materiały -> ${resource.title}`)
                                }}
                            >
                                <span className="text-success-light text-start pe-3">{resource.title}</span>
                                <img
                                    style={{width: "20px"}}
                                    src="https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/download-now-2.png"
                                    alt="Pobierz teraz"
                                />
                            </AnimatedLink>
                        )}
                    </>
                }
            </div>

        </div>
        <FeedbackModal isOpen={showFeedbackModal} setOpen={setShowFeedbackModal} feedbackSource='KED_EPISODE'
                       additionalInfo={`W: ${week}, E: ${episodeNumber}`} hideRating={true} hideDreamVision={true}/>
    </>
}

export async function action({request}: { request: Request }) {
    const url = new URL(request.url);
    const week = url.searchParams.get('w');
    const episode = url.searchParams.get('e');
    const formData = await request.formData();

    const completeEpisode = {
        completed: formData.get('completed') as unknown as boolean,
        week: Number(week),
        episode: Number(episode)
    } as CompleteEpisodeRequest;

    try {
        await CourseService.completeEpisode(completeEpisode);
        return null;
    } catch (error) {
        console.log(error)
        return {
            status: 500,
            body: 'Wystąpił błąd. Spróbuj ponownie później.'
        }
    }
}