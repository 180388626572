import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {BlogArticles, BlogItem, BlogRequest, BlogWithRandomArticles} from "../model/user/blog/Blog";
import {constructUrl} from "../util/Util";
import {increaseItemViews} from "../util/ViewsUtil";

export class BlogService {

    public static async getArticle(uuid: string, toEdit: boolean): Promise<BlogWithRandomArticles> {
        const {uniqueView, repeatedView} = increaseItemViews('blogs', uuid, toEdit);

        return HttpService.sendRequest<BlogWithRandomArticles>(
            HttpMethod.GET,
            `/api/blog/${uuid}?uniqueView=${uniqueView}&repeatedView=${repeatedView}`,
        );
    }

    public static async getArticles(nextPageKey: string | undefined, category: string | null): Promise<BlogArticles> {
        const url = constructUrl('/api/blog', {nextPageKey: nextPageKey, category: category});
        return HttpService.sendRequest<BlogArticles>(
            HttpMethod.GET,
            url,
        );
    }

    public static async getRandomArticles(numberOfArticles: number): Promise<BlogItem[]> {
        const url = constructUrl('/api/blog/random', {numberOfArticles});
        return HttpService.sendRequest<BlogItem[]>(
            HttpMethod.GET,
            url,
        );
    }

    public static createOrEditArticle(requestBody: BlogRequest): Promise<string> {
        return HttpService.sendRequest<string>(
            HttpMethod.POST,
            `/api/blog`,
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static deleteArticle(uuid: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.DELETE,
            `/api/blog/${uuid}`,
        );
    }
}