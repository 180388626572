import React, {useState} from "react";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import CalendlyModalLazy from "../../user/calendly/CalendlyModalLazy";
import ScheduleCallButtonMaturaParent from "./ui/ScheduleCallButtonMaturaParent";
import {MW_STUDENT_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";
import {useSearchParams} from "react-router-dom";
import usePageTracking from "../../../hooks/usePageTracking";
import ExtraordinarySupportStudentExplanation from "./ExtraordinarySupportStudentExplanation";

export default function ExtraordinarySupportStudent() {
    const [searchParams] = useSearchParams();
    const [open, setOpen] = useState(false);

    usePageTracking();

    return <div className='mw-md-3xl mx-auto text-center text-white mb-20'>
        <h1 className='text-white fs-6 mt-12'>Jak podążać <u>własną drogą</u> i osiągnąć wszystko, czego pragniesz?
        </h1>
        <p className='fs-11 fw-medium text-danger'>(bez ciągłego
            poczucia niepewności i obawy o zdanie innych)</p>

        <p className='mt-8 fs-10 fw-medium'>Jeżeli masz duże ambicje i chcesz skutecznie zrealizować wszystkie swoje
            cele,
            jednak nie wiesz od czego zacząć... Zastanawiasz się, co pomyślą inni... Boisz się, że nikt Cię nie
            zrozumie...
        </p>

        <h1 className='text-center fs-7 mt-8'>W takim przypadku, mogę pomóc.</h1>

        <p className='mt-8 fs-10 fw-medium'>Wyobraź sobie, że udało Ci się właśnie zrealizować swoje najbardziej ambitne
            plany. </p>
        <p className='mt-8 fs-10 fw-medium'>Może dostałeś się na najlepsze studia? Może otworzyłeś własną działalność,
            która przynosi Ci świetne zyski i satysfakcję? Może poznałeś partnera marzeń? Może udowodniłeś tym, co w
            ciebie nie wierzyli, jak bardzo się mylili...</p>

        <h1 className='text-center fs-7 mt-8'>Jak wtedy byś się czuł?</h1>

        <p className='mt-8 fs-10 fw-medium'>Gdybym dosłownie mógł poprowadzić Cię za rękę, nauczyć Cię działać
            efektywnie i pomóc zrealizować Twoje największe ambicje... </p>

        <p className='mt-8 fs-10 fw-medium'>Jak to by wpłynęło na Twoją pewność siebie?</p>
        <p className='mt-8 fs-10 fw-medium'>Nie mówiąc już o sukcesach w przyszłości...</p>

        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sukces-dziecka.png'
            className='lazyload img-fluid' alt='sukces'/>

        <h1 className='text-center fs-7 mt-8'>Aby pokazać Ci w jaki sposób działam:</h1>
        <p className='mt-8 fs-10 fw-medium'>Te strategie rozwiązują dosłownie wszystkie problemy nastolatków. To coś
            ponad szkołę średnią, maturę, czy studia, o których tyle się mówi...
        </p>
        <p className='mt-8 fs-10 fw-medium'>
            Koniec z zamartwianiem się o wyniki egzaminów. Koniec ze
            stresem.
            Koniec z poczuciem przytłoczenia. Koniec z bezmyślnym gapieniem się w telefon. Koniec z narzekaniem na świat
            zewnętrzny. Koniec z byciem nierozumianym.</p>
        <p className='mt-8 fs-10 fw-medium'>
            A to dopiero początek...
        </p>

        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Ci być pewnym siebie w każdej
            sytuacji.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Ci w pełni wykorzystać swój
            potencjał.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Ci osiągać znacznie więcej od
            innych.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Ci dostać się na dowolne studia.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Ci, zrealizować każdy swój cel.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Ci <b>być sobą</b>.</p>

        <h1 className='text-center fs-7 mt-8'>Wyobraź sobie, jak to wszystko dzieje się na Twoich oczach.</h1>
        <p className='mt-8 fs-10 fw-medium'>Wiem, co sobie właśnie myślisz: <i>"Przecież to niemożliwe..."</i>. I nie
            winię Cię za to. Jak
            się jednak zaraz przekonasz, niemożliwe może stać się możliwe...</p>

        <p className='mt-8 fs-10 fw-medium'>Poczuj, jak spada z Ciebie całe brzemię niepewności, wątpliwości,
            stresu.</p>
        <p className='mt-8 fs-10 fw-medium'>Pracuję z Tobą indywidualnie.</p>
        <p className='mt-8 fs-10 fw-medium'>Uczę Cię Działać Efektywnie.</p>
        <p className='mt-8 fs-10 fw-medium'>Dostosowuję cały materiał pod Twoje <b>indywidualne</b> potrzeby.</p>
        <p className='mt-8 fs-10 fw-medium'>Organizuję Ci w razie potrzeby pomoc od sprawdzonych ekspertów.</p>
        <p className='mt-8 fs-10 fw-medium'>Pomagam Ci pokonać każdą trudność.</p>
        <p className='mt-8 fs-10 fw-medium'>W skrócie, zapewniam Ci MISTRZOWSKIE wsparcie.</p>

        <h1 className='text-center fs-7 mt-8'>Czy miałeś kiedyś MISTRZOWSKIE wsparcie?</h1>
        <p className='mt-8 fs-10 fw-medium'>Co to właściwie oznacza? Już tłumaczę...</p>

        <ExtraordinarySupportStudentExplanation/>

        <h1 className='text-center fs-7 mt-8'>Czy myślisz, że to mogłoby Ci pomóc?</h1>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={1}
                                        customExperiment={MW_STUDENT_EXPERIMENT_ID_1}/>

        <h1 className='text-center fs-7 mt-8'>Czy wspominałem już o indywidualności?</h1>
        <p className='mt-8 fs-10 fw-medium'>Na samym początku współpracy, organizuję każdej osobie <b>Test
            Gallupa</b> oraz <b>Test na Chronotypy Snu</b>.</p>
        <p className='mt-8 fs-10 fw-medium'>Oznacza to, że już od samego początku biorę pod uwagę
            Twoje preferencje. Odkrywam Twoje mocne strony i wyznaczam Twój naturalny cykl dobowy. </p>
        <p className='mt-8 fs-10 fw-medium'>Cała nasza współpraca opiera się na indywidualnym podejściu.</p>
        <p className='mt-8 fs-10 fw-medium'>Wiem, że każda osoba jest inna, dlatego jest to niezbędny element.</p>

        <h1 className='text-center fs-7 mt-8'>Kim ja w ogóle jestem, żeby uczyć takich rzeczy?</h1>
        <p className='mt-8 fs-10 fw-medium'>Pewnie zastanawiasz się, kim właściwie jestem, żeby obiecywać takie
            rezultaty?</p>
        <p className='mt-8 fs-10 fw-medium'>Jeszcze parę lat temu byłem tam gdzie Ty... Wielkie ambicje i marzenia.
            Niepewność. Poczucie niezrozumienia. Zagubienia. Brak wsparcia...</p>
        <p className='mt-8 fs-10 fw-medium'>Od ponad 6 lat optymalizowałem swój tryb życia,
            aby działać efektywniej i zrealizować postawione przez siebie cele.</p>
        <p className='mt-8 fs-10 fw-medium'>Pokonałem uzależnienie od telefonu i komputera.</p>
        <p className='mt-8 fs-10 fw-medium'>Ukończyłem jeden z najlepszych kierunków informatycznych w Polsce.</p>
        <p className='mt-8 fs-10 fw-medium'>Już podczas studiów, pracowałem w swojej branży.</p>
        <p className='mt-8 fs-10 fw-medium'>Równolegle, rozwijałem też swoją pierwszą działalność.</p>
        <p className='mt-8 fs-10 fw-medium'>A to wszystko bez zaniedbywania zdrowia, snu czy relacji międzyludzkich.</p>
        <p className='mt-8 fs-10 fw-medium'>Zbudowałem w tym czasie świetny związek i pobiłem swój rekord na
            półmaratonie. </p>
        <p className='mt-8 fs-10 fw-medium'>Więc jak widzisz, wszystko jest możliwe. Wystarczy mieć do tego odpowiednie
            narzędzia. </p>
        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/opt-in-photo2.png'
            className='img-fluid lazyload' alt='Mikołaj'/>
        <p className='mt-8 fs-10 fw-medium'>Od ponad 4 lat, pracuję indywidualnie z licealistami, pomagając im
            skutecznie realizować swoje cele. </p>
        <p className='mt-8 fs-10 fw-medium'>Dzięki mojej pomocy, już dziesiątki osób dostały się na wymarzone
            studia, do świetnej pracy, lub wybrały własną ścieżką, nie przejmując się opinią innych. </p>
        <p className='mt-8 fs-10 fw-medium'>A najważniejsze jest to, że realizują siebie i są szczęśliwe.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={2}
                                        customExperiment={MW_STUDENT_EXPERIMENT_ID_1}/>

        <h1 className='text-center fs-7 mt-8'>Ale jeżeli wolisz nic nie zmieniać...</h1>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli wolisz dalej zastanawiać się, czy uda Ci się cokolwiek
            osiągnąć... </p>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli wolisz pozwolić innym, aby zajęli Ci miejsce na Twoich
            wymarzonych studiach, pracy lub w biznesie... </p>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli wolisz mieć poczucie, że można by było zrobić coś więcej, aby plany
            nie pozostały tylko na papierze... </p>
        <p className='mt-8 fs-10 fw-medium'>Proszę bardzo. Nie mogę podjąć za Ciebie decyzji.</p>
        <p className='mt-8 fs-10 fw-medium'>Mogę jednak całkowicie wyeliminować ryzyko...</p>

        <h1 className='text-center fs-7 mt-8'>Gwarancja sukcesu</h1>
        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/gwarancja.png'
            className='img-fluid lazyload' alt='Gwarancja' style={{maxWidth: '300px'}}/>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli w ciągu pierwszych dwóch tygodni nie spodoba Ci się
            współpraca ze mną, zwrócę Ci 100% pieniędzy. </p>
        <p className='mt-8 fs-10 fw-medium'>Jest to wystarczający czas, aby sprawdzić, czy się dogadujemy i czy dobrze
            nam się ze sobą współpracuje. </p>
        <p className='mt-8 fs-10 fw-medium'>Co więcej, nie podpisujemy żadnych zobowiązujących dokumentów i współpracę
            możesz zakończyć w dowolnym momencie.</p>
        <p className='mt-8 fs-10 fw-medium'>Tym bardziej, że po kilku miesiącach nie będziesz potrzebować już niczyjej
            pomocy...</p>
        <p className='mt-8 fs-10 fw-medium'>Zgadza się, dobrze przeczytałeś.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={3}
                                        customExperiment={MW_STUDENT_EXPERIMENT_ID_1}/>

        <h1 className='text-center fs-7 mt-8'>Jednak mój czas też jest ograniczony...</h1>
        <p className='mt-8 fs-10 fw-medium'>Każdy z nas, ja, Ty, Twoi bliscy, mamy do dyspozycji 24 godziny każdego
            dnia. </p>
        <p className='mt-8 fs-10 fw-medium'>Dlatego, mogę współpracować tylko z ograniczoną liczbą osób. </p>
        <p className='mt-8 fs-10 fw-medium'>Z tego powodu, ta okazja nie potrwa wiecznie.</p>
        <p className='mt-8 fs-10 fw-medium'>Mogę przyjąć tylko <b>10 osób</b> do tak kompleksowej, indywidualnej
            współpracy.</p>
        <p className='mt-8 fs-10 fw-medium'>Być może jutro okaże się, że już nie ma miejsc...</p>
        <p className='mt-8 fs-10 fw-medium'>Robię wszystko, aby zapewnić moim podopiecznym najlepszą pomoc, jaką tylko
            mogliby sobie wymarzyć...</p>
        <p className='mt-8 fs-10 fw-medium'>Nie pozwól, żeby inni Cię wyprzedzili...</p>
        <p className='mt-8 fs-10 fw-medium'>Nie czekaj i zajmij swoje miejsce już teraz.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={4}
                                        customExperiment={MW_STUDENT_EXPERIMENT_ID_1}/>

        <p className='mt-8 fs-10 fw-medium'>Efektywne Działanie to coś ponad szkołę średnią czy
            studia.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności to skuteczna realizacja każdego swojego celu.</p>
        <p className='mt-8 fs-10 fw-medium'>To nieograniczone możliwości.</p>
        <p className='mt-8 fs-10 fw-medium'>Pracodawcy płacą setki tysięcy złotych za szkolenia swoich pracowników,
            które tego właśnie mają ich nauczyć...</p>
        <p className='mt-8 fs-10 fw-medium'>Ludzie sukcesu mówią, że zawdzięczają swoje osiągnięcia przede wszystkim
            dyscyplinie, rutynie, umiejętności planowania i odpowiedniemu nastawieniu.</p>
        <p className='mt-8 fs-10 fw-medium'>A to tylko część tego, co nazywam "Efektywnym Działaniem"...</p>
        <p className='mt-8 fs-10 fw-medium'>Dzięki najnowszym odkryciom i technologiom, te umiejętności może z
            łatwością wykształcić w sobie każda osoba.</p>
        <p className='mt-8 fs-10 fw-medium'>Skoro inni mogą, to dlaczego Ty miałbyś nie dać rady?</p>
        <p className='mt-8 fs-10 fw-medium'>Osobiście zadbam, żebyś się ich nauczył i stosował je w swoim
            życiu.</p>
        <p className='mt-8 fs-10 fw-medium'><b>Jedyne co musisz zrobić, to umówić się ze mną na bezpłatne spotkanie</b>,
            na którym porozmawiamy o Twoich potrzebach i problemach, a następnie wdrażać w życie nowe umiejętności.</p>
        <p className='mt-8 fs-10 fw-medium'>Ja zrobię całą resztę.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={5}
                                        customExperiment={MW_STUDENT_EXPERIMENT_ID_1}/>

        <p className='mt-8 fs-10 fw-medium'>Postaw pierwszy krok w kierunku lepszej przyszłości.</p>
        <p className='mt-8 fs-10 fw-medium'>Obiecuję Ci, że podziękujesz sobie za to.</p>
        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ukonczenie+studiow.png'
            className='img-fluid lazyload' alt='Ukończenie studiów'/>
        <p className='mt-8 fs-10 fw-medium'>Dlatego już teraz umów się na bezpłatną konsultację.</p>
        <p className='mt-8 fs-10 fw-medium'>W najgorszym wypadku, stracisz 45 minut swojego czasu.</p>
        <p className='mt-8 fs-10 fw-medium'>A w najlepszym?</p>
        <p className='mt-8 fs-10 fw-medium'>W pełni wykorzystasz swój potencjał i będziesz skutecznie realizować swoje
            nawet największe ambicje, nie przejmując się zdaniem innych. </p>
        <p className='mt-8 fs-10 fw-medium'>Będziesz nie do zatrzymania.</p>
        <p className='mt-8 fs-10 fw-medium'>A przede wszystkim?</p>
        <p className='mt-8 fs-9 fw-medium'><b>Będziesz szczęśliwy</b>.</p>
        <p className='mt-8 fs-9 fw-medium'><b>Będziesz sobą</b>.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={6}
                                        customExperiment={MW_STUDENT_EXPERIMENT_ID_1}/>

        {open && (
            <CalendlyModalLazy open={open} setOpen={setOpen}
                               calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania'
                               onEventScheduled={() => {
                                   TraceService.addTraceWithExperiment(TraceType.CalendlyEventScheduled, MW_STUDENT_EXPERIMENT_ID_1, 'A', searchParams.get('source'))
                               }}/>
        )}
    </div>
}