import React from 'react';
import AnimatedButton from "../../ui/motion/AnimatedButton";

type QuestionProps = {
    question: string;
    options: string[];
    onAnswer: (answerIndex: number) => void;
};

const Question: React.FC<QuestionProps> = ({question, options, onAnswer}) => {
    return (
        <div>
            <h2 className='text-center fs-8 fw-medium mb-4'>"{question}"</h2>
            <div className="row justify-content-center g-4">

                {options.map((option, index) => (
                        <>
                            <div className="col-auto">
                                <AnimatedButton
                                    className={`btn btn-lg ${option === 'Prawda' ? 'btn-success' : (option === 'Fałsz' ? 'btn-warning' : 'btn-dark-light')}  text-success-light shadow`}
                                    key={index} onClick={() => onAnswer(index)}
                                >
                                    {option}
                                </AnimatedButton>
                            </div>
                        </>
                    )
                )}
            </div>

        </div>
    )
        ;
};

export default Question;