import React, {Suspense} from 'react';
import Meta from "../components/common/Meta";
import MainDesign from "./MainDesign";
import AnimatedLink from "../components/ui/motion/AnimatedLink";
import SlideInWhenVisible from "../components/ui/motion/SlideInWhenVisible";
import Heading from "../components/common/header/Heading";
import {TraceService} from "../service/TraceService";
import {TraceType} from "../model/user/general/Trace";
import {motion} from "framer-motion";
import {offerVariants} from "./user/offer/OfferLandingPage";
import ProductPageOption from "../components/user/ai/pricing/ProductPageOption";
import StaggerChildrenWhenVisible from '../components/ui/motion/StaggerChildrenWhenVisible';
import {Await, defer, useLoaderData} from "react-router-dom";
import {BlogService} from "../service/BlogService";
import LoadingPage from "./common/LoadingPage";
import {BlogItem} from "../model/user/blog/Blog";
import BlogPostItem from "../components/user/blog/BlogPostItem";

type LoaderData = {
    articles: Promise<BlogItem[]>;
};
export default function Index() {
    const {articles} = useLoaderData() as LoaderData;
    return <>
        <Meta title='Produkacja - Nowoczesna platforma do nauki online'
              description='Nowoczesna platforma do nauki online. Kurs Efektywnego Działania, rozwiązania AI dla uczniów oraz blog.'
              imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/img/logo-500-500-title.png'/>
        <MainDesign containerStyles="pb-28">
            <div className=''>
                <div
                    style={{
                        backgroundImage: 'url(https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/bg/4.png)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100vh', // adjust height as needed
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: 'white',
                        padding: '20px',
                    }}>
                    <div className='d-none d-lg-block'>
                        <Heading
                            title="Pomagamy licealistom w pełni wykorzystać swój potencjał."
                            customStyles='text-center mt-48 container mb-6 text-center mw-md-7xl mx-auto'
                            customTitleStyles='mb-6 font-heading fs-3 text-white'/>
                    </div>
                    <div className='d-lg-none'>
                        <Heading
                            title="Pomagamy licealistom w pełni wykorzystać swój potencjał."
                            customStyles='text-center mt-24 container'
                            customTitleStyles='mb-6 font-heading fs-4 text-white'/>
                    </div>
                    <div className="row justify-content-center g-4 pb-8 pt-48 mb-10">
                        <div className="col-12 col-md-auto">
                            <AnimatedLink
                                className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                onClick={() => {
                                    TraceService.addTrace(TraceType.BtnClick, 'btn-1 -> Zacznij działać')
                                }}
                                to={'/start'}>
                                Zacznij działać
                            </AnimatedLink>
                        </div>
                    </div>
                </div>
                <div>
                    <hr className='text-white' style={{marginTop: "-1px"}}/>
                    <Heading title='Czym jest Produkacja?'
                             customStyles='mb-md-6 text-center mw-md-4xl mx-auto pt-3 pb-1 mt-10'
                             customTitleStyles='font-heading mb-4 fs-4 text-white'/>
                </div>
                <div className='container'>
                    <p className='text-center text-secondary-light fs-6 fw-medium d-none d-lg-block'>Uczymy nie tylko
                        jak efektywnie przyswajać wiedzę, ale również jak mistrzowsko zarządzać
                        czasem, uwierzyć w swoje możliwości i skutecznie realizować swoje cele.
                        <br/>
                        <br/>

                        Pokazujemy, jak wykorzystując odpowiednie narzędzia, możesz osiągnąć wszystko, czego
                        zapragniesz. </p>
                    <p className='text-center text-secondary-light fs-8 fw-medium d-lg-none'>Uczymy nie tylko
                        jak efektywnie przyswajać wiedzę, ale również jak mistrzowsko zarządzać
                        czasem, uwierzyć w swoje możliwości i skutecznie realizować swoje cele.
                        <br/>
                        <br/>

                        Pokazujemy, jak wykorzystując odpowiednie narzędzia, możesz osiągnąć wszystko, czego
                        zapragniesz. </p>
                </div>
                <div className='mw-md-5xl mx-auto text-center '>
                    <img className='img-fluid mb-10'
                         loading='lazy'
                         src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wspolne1.jpg'
                         alt='wspólne zdjęcie'/>
                </div>
                <hr className='text-white' style={{marginTop: "-1px"}}/>
                <div className='container'>
                    <Heading title='MISTRZOWSKIE wsparcie'
                             customStyles='text-center mw-md-4xl mx-auto pt-3 pb-1 mt-10'
                             customTitleStyles='font-heading mb-4 fs-4 text-white'/>
                    <div className='row g-8 mt-2 mt-md-10 mb-10 mb-md-20'>
                        <StaggerChildrenWhenVisible>
                            <motion.div
                                variants={{
                                    hidden: {opacity: 0, scale: 0.5},
                                    visible: {opacity: 1, scale: 1}
                                }}
                                exit={{opacity: 1, scale: 1}}
                                transition={{type: 'spring'}}
                                className='col-12 col-sm-6 col-md-3'
                            >
                                <div className='mw-md-sm'>
                                    <div
                                        className='mb-4 d-flex justify-content-center text-center align-items-center fs-9 text-secondary-dark fw-semibold rounded-pill'
                                        style={{width: 192, height: 192}}
                                    >
                                        <img className='text-center'
                                             src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/ikony-ked/baza.png'
                                             style={{width: 192, height: 192}}
                                             alt='baza'/>
                                    </div>
                                    <h3 className='mb-2 font-heading fs-9 text-white'>
                                        Baza
                                    </h3>
                                    <p className='mb-0 text-secondary-light fw-medium'>
                                        Poznasz wszystkie niezbędne narzędzia konieczne do efektywnego działania.
                                        Dowiesz
                                        się, jak skutecznie planować, zdobywać wiedzę oraz zarządzać czasem.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div
                                variants={{
                                    hidden: {opacity: 0, scale: 0.5},
                                    visible: {opacity: 1, scale: 1}
                                }}
                                exit={{opacity: 1, scale: 1}}
                                transition={{type: 'spring'}}
                                className='col-12 col-sm-6 col-md-3'
                            >
                                <div className='mw-md-sm'>
                                    <div
                                        className='mb-4 d-flex justify-content-center text-center align-items-center fs-9 text-secondary-dark fw-semibold rounded-pill'
                                        style={{width: 192, height: 192}}
                                    >
                                        <img className='text-center'
                                             src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/ikony-ked/nastawienie.png'
                                             style={{width: 192, height: 192}}
                                             alt='baza'/>
                                    </div>
                                    <h3 className='mb-2 font-heading fs-9 text-white'>
                                        Nastawienie
                                    </h3>
                                    <p className='mb-0 text-secondary-light fw-medium'>
                                        Zdasz sobie sprawę z wagi odpowiedniego nastawienia. Osiągniesz mindset
                                        zwycięzcy i
                                        zauważysz, jakie cechy i zachowania blokują Twój rozwój.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div
                                variants={{
                                    hidden: {opacity: 0, scale: 0.5},
                                    visible: {opacity: 1, scale: 1}
                                }}
                                exit={{opacity: 1, scale: 1}}
                                transition={{type: 'spring'}}
                                className='col-12 col-sm-6 col-md-3'
                            >
                                <div className='mw-md-sm'>
                                    <div
                                        className='mb-4 d-flex justify-content-center text-center align-items-center fs-9 text-secondary-dark fw-semibold rounded-pill'
                                        style={{width: 192, height: 192}}
                                    >
                                        <img className='text-center'
                                             src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/ikony-ked/proces.png'
                                             style={{width: 192, height: 192}}
                                             alt='baza'/>
                                    </div>
                                    <h3 className='mb-2 font-heading fs-9 text-white'>
                                        Proces
                                    </h3>
                                    <p className='mb-0 text-secondary-light fw-medium'>
                                        Proces zakłada stopniowe zdobywanie wiedzy i zagłębianie się w tematykę
                                        efektywnego działania. Stosując się do zaleceń i wykonując ćwiczenia szybko
                                        zauważysz zdumiewające rezultaty.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div
                                variants={{
                                    hidden: {opacity: 0, scale: 0.5},
                                    visible: {opacity: 1, scale: 1}
                                }}
                                exit={{opacity: 1, scale: 1}}
                                transition={{type: 'spring'}}
                                className='col-12 col-sm-6 col-md-3'
                            >
                                <div className='mw-md-sm'>
                                    <div
                                        className='mb-4 d-flex justify-content-center text-center align-items-center fs-9 text-secondary-dark fw-semibold rounded-pill'
                                        style={{width: 192, height: 192}}
                                    >
                                        <img className='text-center'
                                             src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/ikony-ked/mentoring.png'
                                             style={{width: 192, height: 192}}
                                             alt='baza'/>
                                    </div>
                                    <h3 className='mb-2 font-heading fs-9 text-white'>
                                        Mentoring
                                    </h3>
                                    <p className='mb-0 text-secondary-light fw-medium'>
                                        Regularnie i konsekwentnie pracując na cotygodniowych spotkaniach ze swoim
                                        mentorem, a także utrzymując codzienny kontakt przez WhatsAppa,
                                        zrealizujesz każdy swój cel.
                                    </p>
                                </div>
                            </motion.div>
                        </StaggerChildrenWhenVisible>
                    </div>
                    <div className="row justify-content-center g-4 pb-8">
                        <div className="col-12 col-md-auto">
                            <AnimatedLink
                                className="btn btn-lg btn-success w-100 text-success-light shadow"
                                onClick={() => {
                                    TraceService.addTrace(TraceType.BtnClick, 'btn-2 -> Zapisz się teraz')
                                }}
                                to={'/start'}>
                                Zapisz się teraz
                            </AnimatedLink>
                        </div>
                    </div>
                </div>
                <hr className='text-white mt-6 mt-md-16' style={{marginTop: "-1px"}}/>
                <Heading title='Co jest dla nas ważne?'
                         customStyles='mb-6 text-center mw-md-4xl mx-auto pt-3 pb-1 mt-10'
                         customTitleStyles='font-heading mb-4 fs-4 text-white'/>
                <div className='row align-items-center g-8'>
                    <div className='col-12 col-lg-4'>
                        <div className='row g-20'>
                            <div className='col-12'>
                                <div className='mw-sm mx-auto text-center'>
                                    <SlideInWhenVisible initialX={-100}>
                                        <div
                                            className='mb-4 d-flex justify-content-center align-items-center mx-auto text-secondary-dark bg-success rounded'
                                            style={{width: 64, height: 64}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/values/dostepnosc.png'
                                                alt='dostepnosc' style={{width: 40, height: 40}}/>
                                        </div>
                                        <h3 className='font-heading fs-8 mb-4 text-white'>
                                            Dostępność
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary-light'>
                                            Uważamy, że dostęp do wysokiej jakości edukacji powinien być zapewniony dla
                                            wszystkich, zawsze i wszędzie. Dlatego, by korzystać z Produkacji,
                                            potrzebujesz jedynie Internetu.
                                        </p>
                                    </SlideInWhenVisible>
                                </div>
                            </div>
                            <div className='col-12 mt-10'>
                                <div className='mw-sm mx-auto text-center'>
                                    <SlideInWhenVisible initialX={-100}>
                                        <div
                                            className='mb-4 d-flex justify-content-center align-items-center mx-auto text-secondary-dark bg-success rounded'
                                            style={{width: 64, height: 64}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/values/innowacyjnosc.png'
                                                alt='innowacyjność' style={{width: 44, height: 44}}/>
                                        </div>
                                        <h3 className='font-heading fs-8 mb-4 text-white'>
                                            Innowacyjność
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary-light'>
                                            Wykorzystujemy najnowsze technologie, aby stworzyć najbardziej kompleksowe
                                            rozwiązania.
                                        </p>
                                    </SlideInWhenVisible>
                                </div>
                            </div>
                            <div className='col-12 mt-10'>
                                <div className='mw-sm mx-auto text-center'>
                                    <SlideInWhenVisible initialX={-100}>
                                        <div
                                            className='mb-4 d-flex justify-content-center align-items-center mx-auto text-secondary-dark bg-success rounded'
                                            style={{width: 64, height: 64}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/values/latwosc+korzystania.png'
                                                alt='łatwość korzystania' style={{width: 44, height: 44}}/>
                                        </div>
                                        <h3 className='font-heading fs-8 mb-4 text-white'>
                                            Łatwość korzystania
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary-light'>
                                            Tworzymy platformę tak, by była intuicyjna dla każdego. Chcemy by
                                            korzystanie z
                                            niej
                                            było łatwe i przyjemne.
                                        </p>
                                    </SlideInWhenVisible>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-4'>
                        <div className='row justify-content-center'>
                            <div className='col-auto'>
                                <img
                                    className='img-fluid rounded-3'
                                    style={{maxHeight: "700px"}}
                                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/our-values.jpg'
                                    alt=''
                                    loading='lazy'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-4'>
                        <div className='row g-20'>
                            <div className='col-12 col-lg-12'>
                                <div className='mw-sm mx-auto text-center'>
                                    <SlideInWhenVisible initialX={100}>
                                        <div
                                            className='mb-4 d-flex justify-content-center align-items-center mx-auto text-secondary-dark bg-success rounded'
                                            style={{width: 64, height: 64}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/values/profesjonalizm.png'
                                                alt='profesjonalizm' style={{width: 44, height: 44}}/>
                                        </div>
                                        <h3 className='font-heading fs-8 mb-4 text-white'>
                                            Profesjonalizm
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary-light'>
                                            Dbamy, aby wszelkie materiały były na najwyższym poziomie, a wiedza była
                                            przekazywana w przystępny i przejrzysty sposób.
                                        </p>
                                    </SlideInWhenVisible>
                                </div>
                            </div>
                            <div className='col-12 mt-10 col-lg-12'>
                                <div className='mw-sm mx-auto text-center'>
                                    <SlideInWhenVisible initialX={100}>
                                        <div
                                            className='mb-4 d-flex justify-content-center align-items-center mx-auto text-secondary-dark bg-success rounded'
                                            style={{width: 64, height: 64}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/values/inkluzywnosc.png'
                                                alt='inkluzywność' style={{width: 48, height: 48}}/>
                                        </div>
                                        <h3 className='font-heading fs-8 mb-4 text-white'>
                                            Inkluzywność
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary-light'>
                                            Produkacja skierowana jest do wszystkich uczniów. Zapewniamy bezpieczną
                                            przestrzeń, która zapewni odpowiednie warunki do rozwoju i nauki.
                                        </p>
                                    </SlideInWhenVisible>
                                </div>
                            </div>
                            <div className='col-12 mt-10'>
                                <div className='mw-sm mx-auto text-center'>
                                    <SlideInWhenVisible initialX={100}>
                                        <div
                                            className='mb-4 d-flex justify-content-center align-items-center mx-auto text-secondary-dark bg-success rounded'
                                            style={{width: 64, height: 64}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/main-page/values/dialog.png'
                                                alt='dialog' style={{width: 48, height: 48}}/>
                                        </div>
                                        <h3 className='font-heading fs-8 mb-4 text-white'>
                                            Dialog
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary-light'>
                                            Wasza opinia jest dla nas najważniejsza, dlatego słuchamy Waszych uwag,
                                            komentarzy i sugestii.
                                        </p>
                                    </SlideInWhenVisible>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='text-white mt-28' style={{marginTop: "-1px"}}/>
                <Heading title='Narzędzia Produkacji'
                         customStyles='text-center mw-md-4xl mx-auto pt-3 pb-1 mt-10'
                         customTitleStyles='font-heading mb-4 fs-4 text-white'/>
                <div className='container'>
                    <div className='row mw-6xl mx-auto'>
                        <motion.div
                            custom={0} // Custom prop for delay calculation
                            variants={offerVariants}
                            initial="hidden"
                            animate="visible"
                            className="option-wrapper col-12 col-md-6 mt-5"
                        >
                            <ProductPageOption
                                imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/chat.png'
                                redirectTo='/oferta/chat' title='Chat'
                                subtitle='Poznaj możliwości Edukacyjnego Chatu AI, który sprawnie może zastąpić Twojego fizycznego korepetytora.'/>
                        </motion.div>
                        <motion.div
                            custom={1} // Increasing the custom value for sequential delay
                            variants={offerVariants}
                            initial="hidden"
                            animate="visible"
                            className="option-wrapper col-12 col-md-6 mt-5"
                        >
                            <ProductPageOption
                                imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/konwersacje.png'
                                redirectTo='/oferta/konwersacje' title='Konwersacje' subtitle='Sprawdź modele Konwersacji z AI, gdzie
                                bez ograniczeń i z łatwością osiągniesz swobodę językową. (beta)'/>
                        </motion.div>
                    </div>
                </div>
                <hr className='text-white mt-16' style={{marginTop: "-1px"}}/>
                <div className='mx-auto mw-md-5xl container'>
                    <Heading title='Skorzystaj z darmowej wiedzy'
                             subtitle='Na naszym blogu oraz mediach społecznościowych znajdziesz wiele materiałów,
                             w których dzielimy się wiedzą i doświadczeniami.'
                             customStyles='text-center mw-md-4xl mx-auto pt-3 pb-1 mt-10'
                             customTitleStyles='font-heading mb-4 fs-4 text-white'/>
                    <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                        <Await resolve={articles} errorElement={<></>}>
                            {(loaderArticles: BlogItem[]) => <>
                                <div className='mb-20 row g-8'>
                                    {
                                        loaderArticles.map((item) => (
                                            <BlogPostItem key={item.id} blogItem={item} isAdmin={false}
                                                          setArticleToDelete={() => {
                                                          }}
                                                          setConfirmDeleteModalOpen={() => {
                                                          }}/>
                                        ))
                                    }
                                </div>
                            </>}
                        </Await>
                    </Suspense>
                    <div className="row justify-content-center g-4 pb-8">
                        <div className="col-12 col-md-auto">
                            <AnimatedLink
                                className="btn btn-lg btn-success w-100 text-success-light shadow"
                                onClick={() => {
                                    TraceService.addTrace(TraceType.BtnClick, 'btn3 -> zobacz więcej blog')
                                }}
                                to={'/blog'}>
                                Zobacz więcej
                            </AnimatedLink>
                        </div>
                    </div>
                </div>
            </div>
        </MainDesign>
    </>
}

export async function loader() {
    return defer({
        articles: BlogService.getRandomArticles(2)
    });
}

