import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import CoachingSession from "../../../components/user/coaching-session/CoachingSession";
import {PurchaseService} from "../../../service/PurchaseService";
import {PurchaseOption} from "../../../model/user/purchase/PurchaseOption";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {redirect} from "react-router-dom";
import {ApiError} from "../../../service/HttpService";

export default function CoachingSessionPage() {
    return <>
        <Meta title="Indywidualna Konsultacja"
              description='Na konsultacji omówimy wynik Twojego testu Gallupa oraz testu na chronotypy snu, przeprowadzimy wywiad, a następnie opracujemy indywidualny dla Ciebie plan działania.'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Indywidualna Konsultacja'
                     subtitle="Umów się na indywidualną konsultację z założycielem Produkacji i twórcą MISTRZOWSKIEGO wsparcia. Odkryj swoje naturalne talenty i mocne strony, wraz ze spersonalizowanym planem działania do zrealizowania Twoich celów."
                     badgeText='Konsultacje'/>
            <CoachingSession/>
        </MainDesign>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const purchaseData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
    };

    try {
        const redirectUrl = await PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COACHING_SESSION.toString(),
            name: purchaseData.name,
            email: purchaseData.email,
            source: localStorage.getItem('firstJoinedFrom') ?? '',
            successRedirectPath: "/zakup/konsultacje/sukces",
            errorRedirectPath: "/zakup/konsultacje/blad",
        });
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${redirectUrl}, purchaseOptionName: ${PurchaseOption.COURSE_KED_BASIC_ACCESS.toString()}`);
        return redirect(redirectUrl);
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}