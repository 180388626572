import MainDesign from "../../MainDesign";
import React from "react";

export default function RegulationsMWPage() {
    return <MainDesign containerStyles="container py-32">
        <div className='mb-4 text-center mw-md-2xl mx-auto'>
            <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
          Regulamin
            </span>
            <h1
                className='font-heading mb-4 fs-5 text-white'
                style={{letterSpacing: '-1px'}}
            >
                Regulamin usługi MW serwisu PRODUKACJA.PL
            </h1>
        </div>
        <div className='mw-md-2xl mx-auto py-3'>
            <p className='fs-9 text-white mb-4'>
                Treść regulaminu usługi MW (MISTRZOWSKIEGO Wsparcia) znajduje się pod <a
                className='link-success text-decoration-underline'
                href='https://produkacja.s3.eu-central-1.amazonaws.com/regulamin/Regulamin+Us%C5%82ugi+MW+serwisu+Produkacja+17.10.24.pdf'>tym
                linkiem</a>.
            </p>
        </div>

        <div className='mt-8 text-center mw-md-2xl mx-auto'>
                <span className='text-success fw-medium'>
                    Ostatnia aktualizacja: 17.10.2024
                </span>
        </div>
    </MainDesign>
}