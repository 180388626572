import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {
    CompleteEpisodeRequest,
    CourseEpisode,
    CourseStatistics,
    CourseWeekAndEpisodes,
    UpdateCourseWatchTimeRequest,
    UpdateEpisodeNotes
} from "../model/user/learning-course/LearningCourse";
import {UserCourse} from "../model/admin/UserCourse";

export class CourseService {

    public static async getCourseStatistics(): Promise<CourseStatistics> {
        return HttpService.sendRequest<CourseStatistics>(
            HttpMethod.GET,
            '/api/get_course_statistics',
        );
    }

    public static async updateCourseWatchTime(updateCourseWatchTimeRequest: UpdateCourseWatchTimeRequest): Promise<void> {
        try {
            return HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/update_watch_time',
                JSON.stringify(updateCourseWatchTimeRequest),
                ContentType.JSON
            );
        } catch (error) {
            console.log('Error updating watch time:', error);
        }
    }

    public static async updateEpisodeNotes(updateEpisodeNotes: UpdateEpisodeNotes): Promise<void> {
        try {
            return HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/update_episode_notes',
                JSON.stringify(updateEpisodeNotes),
                ContentType.JSON
            );
        } catch (error) {
            console.log('Error updating watch time:', error);
        }
    }

    public static async completeEpisode(completeEpisodeRequest: CompleteEpisodeRequest): Promise<void> {
        try {
            return HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/complete_episode',
                JSON.stringify(completeEpisodeRequest),
                ContentType.JSON
            );
        } catch (error) {
            console.log('Error updating watch time:', error);
        }
    }

    public static async getCourseWeekAndEpisodes(weekNum: number, episodesNum: number, silent: boolean = false): Promise<CourseWeekAndEpisodes> {
        return HttpService.sendRequest<CourseWeekAndEpisodes>(
            HttpMethod.GET,
            `/api/get_week_and_episodes?weekNum=${weekNum}&episodesNum=${episodesNum}&silent=${silent}`,
        );
    }

    public static async getCourseEpisode(weekNum: number, episodeNum: number): Promise<CourseEpisode> {
        return HttpService.sendRequest<CourseEpisode>(
            HttpMethod.GET,
            `/api/get_episode?weekNum=${weekNum}&episodeNum=${episodeNum}`,
        );
    }

    public static async generateIndividualSessionUrl(): Promise<{ url: string }> {
        return HttpService.sendRequest<{ url: string }>(
            HttpMethod.POST,
            '/api/generate_individual_session_url',
            null,
            ContentType.JSON
        );
    }

    public static async getKedUsers(): Promise<UserCourse[]> {
        return HttpService.sendRequest<UserCourse[]>(
            HttpMethod.GET,
            `/api/get_ked_users`,
        );
    }

    public static async unlockNextWeek(userId: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/unlock_next_week',
            JSON.stringify({userId}),
            ContentType.JSON
        );
    }
}