import LoadingPage from "../../common/LoadingPage";
import MainDesign from "../../MainDesign";
import {useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import TiktokPixel from "tiktok-pixel";
import {PurchaseOption} from "../../../model/user/purchase/PurchaseOption";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

// TODO: If needed -> Adjust return_url and its params - the part after & is not added.
// https://secure.tpay.com?id=139170&amount=2000&description=MISTRZOWSKIE%20Wsparcie&md5sum=385c9763cd36bd07eda8a6a52113958e&crc=XcliPhmXyP7fY0HusZr5gwA2IcI&return_url=https://produkacja.pl/dziekuje?redirectUrl=https://produkacja.pl/zakup/mistrzowskie-wsparcie/sukces&purchaseOptionName=EXTRAORDINARY_MONTHLY_SUPPORT&return_error_url=https://produkacja.pl/zakup/mistrzowskie-wsparcie/blad, purchaseOptionName: EXTRAORDINARY_MONTHLY_SUPPORT
// https://produkacja.pl/dziekuje?redirectUrl=https://produkacja.pl/zakup/mistrzowskie-wsparcie/sukces
// purchaseOptionName is cut.
export default function ConfirmTransactionPage() {
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirectUrl');
    const purchaseOptionName = searchParams.get('purchaseOptionName');
    const option = PurchaseOption[purchaseOptionName as keyof typeof PurchaseOption];

    useEffect(() => {
        if (option && option.price) {
            TiktokPixel.track("CompletePayment", {
                "contents": [
                    {
                        "content_id": purchaseOptionName, // string. ID of the product. Example: "1077218".
                        "content_type": "product", // string. Either product or product_group.
                        "content_name": "Tokens" // string. The name of the page or product. Example: "shirt".
                    }
                ],
                price: option.price,
                "currency": "PLN"
            }, {debug: true});
        }

        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${redirectUrl}, purchaseOptionName: ${purchaseOptionName}`);
        window.location.href = redirectUrl ?? '/';
    }, [option, purchaseOptionName, redirectUrl]);

    return <>
        <MainDesign containerStyles='py-28 container'>
            <LoadingPage/>
        </MainDesign>
    </>
}

