import Meta from "../../components/common/Meta";
import MainDesign from "../MainDesign";
import AboutUsPhotos from "../../components/user/about-us/AboutUsPhotos";
import WhoAreWe from "../../components/user/about-us/WhoAreWe";
import WhoAreWeMission from "../../components/user/about-us/WhoAreWeMission";
import WhoAreWeVision from "../../components/user/about-us/WhoAreWeVision";
import WhoAreWeSharedPhoto from "../../components/user/about-us/WhoAreWeSharedPhoto";
import WhoAreWeValuesHeading from "../../components/user/about-us/WhoAreWeValuesHeading";
import WhoAreWeValues from "../../components/user/about-us/WhoAreWeValues";
import WhoAreWeExistence from "../../components/user/about-us/WhoAreWeExistence";
import Heading from "../../components/common/header/Heading";

export default function AboutUsPage() {
    return <>
        <Meta title='O nas' description='Zobacz kim jesteśmy i co jest dla nas ważne'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Dlaczego powstała Produkacja?' badgeText='O nas'
                     customSubtitleStyles='fs-9 fw-medium text-success'
                     subtitle='Tworzymy edukację przyszłości. Zobacz kim jesteśmy, co robimy i poznaj nasza misję.'/>
            <WhoAreWeExistence/>
            <WhoAreWe/>
            <WhoAreWeMission/>
            <AboutUsPhotos/>
            <WhoAreWeValuesHeading/>
            <WhoAreWeValues/>
            <WhoAreWeVision/>
            <WhoAreWeSharedPhoto/>
        </MainDesign>
    </>
}