import {format, fromUnixTime, isValid, parseISO} from "date-fns";

export function formatDate(rawDate: string) {
    return rawDate && isValid(parseISO(rawDate))
        ? format(parseISO(rawDate), 'dd.MM.yyyy HH:mm')
        : ''
}

export function formatUnixTimestamp(timestamp: number) {
    const date = fromUnixTime(timestamp / 1000); // Convert milliseconds to seconds
    return isValid(date)
        ? format(date, 'dd.MM.yyyy')
        : '';
}

export function formatISODateString(dateString: string) {
    const date = parseISO(dateString); // Parse the ISO date string
    return format(date, 'dd.MM.yyyy'); // Format to your desired format
}