import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import VSLChatContent from "../../../components/user/offer/VSLChatContent";
import React from "react";
import {DeferredData} from "@remix-run/router/dist/utils";
import {defer, useLoaderData} from "react-router-dom";
import {isLoggedIn} from "../../../util/AuthUtil";
import {ApiError} from "../../../service/HttpService";
import {NewsletterService} from "../../../service/NewsletterService";
import {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import TiktokPixel from "tiktok-pixel";

type VSLChatLoadingData = {
    isLoggedIn: boolean
}

export default function VSLChatPage() {
    const {isLoggedIn} = useLoaderData() as VSLChatLoadingData;
    return <>
        <Meta title='Twój wirtualny korepetytor'/>
        <MainDesign containerStyles='container py-20'>
            <VSLChatContent isLoggedIn={isLoggedIn}/>
        </MainDesign>
    </>
}

export async function loader(): Promise<DeferredData> {
    return defer({
        isLoggedIn: await isLoggedIn(),
    });
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const purchaseData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
    };

    TiktokPixel.track("SubmitForm", {
        "contents": [
            {
                "content_id": "chat-tutorial-1", // string. ID of the product. Example: "1077218".
                "content_name": "Newsletter" // string. The name of the page or product. Example: "shirt".
            }
        ],
    }, {debug: true});

    try {
        await NewsletterService.subscribeNewsletter({
            name: purchaseData.name,
            type: NewsletterType.ChatTutorial,
            email: purchaseData.email,
            source: localStorage.getItem('firstJoinedFrom') ?? ''
        });
        return {
            status: 200,
            body: "Poradnik został wysłany pomyślnie!"
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}