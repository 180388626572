import FaqItem from "./FaqItem";

interface FaqListProps {
    items: FaqListItem[]
}

interface FaqListItem {
    question: string
    answer: string
}

export default function FaqList(props: FaqListProps) {
    const {items} = props;

    return <div className='mw-md-3xl mx-auto'>
        <div className='row g-6'>
            {items.map((item) => (
                <FaqItem question={item.question} key={item.question}
                         answer={item.answer}/>
            ))}
        </div>
    </div>

}