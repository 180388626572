import React from "react";
import {Link} from "react-router-dom";

export default function ExerciseCreateAccountOffer() {
    return <div className='text-center mw-md-5xl mx-auto mt-16'>
        <p
            className='mb-4 fs-8 text-white mt-3'
            style={{letterSpacing: '-1px'}}
        >
            Chcesz uzyskać dostęp do wszystkich opublikowanych już zadań? Kliknij w poniższy przycisk i utwórz bezpłatne
            konto.
        </p>
        <Link
            className='btn btn-lg btn-warning text-lg-center fs-11 py-3 px-4 h-100 shadow'
            to='/rejestracja'
            onClick={() => {
                localStorage.setItem('redirectUrlAfterRegistration', '/lista-zadan')
            }}
        >
            Zarejestruj się
        </Link>
    </div>
}