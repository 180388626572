import MainDesign from "../MainDesign";
import AdminNumbers from "../../components/admin/AdminNumbers";
import Meta from "../../components/common/Meta";
import {AdminService} from "../../service/AdminService";
import {Await, defer, useLoaderData} from "react-router-dom";
import {DeferredData} from "@remix-run/router/dist/utils";
import {Metrics} from "../../model/admin/Metrics";
import LoadingPage from "../common/LoadingPage";
import ErrorContent from "../../components/common/error/ErrorContent";
import React, {Suspense} from "react";

type LoaderData = {
    metrics: Metrics
}

export default function AdminIndex() {
    const {metrics} = useLoaderData() as LoaderData;
    return <>
        <Meta title="Dashboard"/>
        <MainDesign containerStyles='container pt-24'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={metrics} errorElement={<ErrorContent/>}>
                    {(loadMetrics) => <>
                        <AdminNumbers metrics={loadMetrics}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadMetrics(): Promise<Metrics> {
    return await AdminService.getMetrics();
}

export function loader(): DeferredData {
    return defer({
        metrics: loadMetrics()
    });
}