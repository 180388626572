import Meta from "../../../components/common/Meta";
import React, {Suspense} from "react";
import MainDesign from "../../MainDesign";
import {Await, defer, useLoaderData} from "react-router-dom";
import {AdminService} from "../../../service/AdminService";
import {
    FB_ADS_DIRECT_EXPERIMENT_ID_1,
    FB_ADS_DIRECT_EXPERIMENT_ID_2,
    KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1,
    KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_1,
    KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_2,
    KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_3,
    KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1,
    MW_EXPERIMENT_ID_1,
    MW_FULL_FUNNEL_EXPERIMENT_1,
    MW_FULL_FUNNEL_EXPERIMENT_2,
} from "../../../util/ExperimentUtil";
import {ExperimentMetrics} from "../../../model/admin/Metrics";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import AdminKedExperimentResults from "../../../components/admin/ked/AdminKedExperimentResults";
import Heading from "../../../components/common/header/Heading";
import AdminKedNavbar from "../../../components/admin/ked/AdminKedNavbar";

type LoaderData = {
    experimentMetrics: Promise<ExperimentMetrics[]>[]
}
export default function AdminKedExperimentPage() {
    const {experimentMetrics} = useLoaderData() as LoaderData;

    return <>
        <Meta title='KED - Eksperymenty'/>
        <MainDesign containerStyles='container pt-20'>
            <Heading badgeText='wykresy' title='Wyniki eksperymentów KED'
                     customTitleStyles='font-heading mb-4 fs-5 text-white'/>
            <AdminKedNavbar/>
            <hr className='text-white'/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={experimentMetrics} errorElement={<ErrorContent/>}>
                    {([salesCallExperimentMetrics, optInExperimentMetrics, optInExperimentMetrics2, optInExperimentMetrics3, directSalesPageOrVsl, fbDirectAdsMetrics, mwMetrics, fbDirectAdsMetrics2, fullFunnelAdsMetrics, fullFunnelAdsMetrics2]) => <>
                        <AdminKedExperimentResults salesCallExperimentMetrics={salesCallExperimentMetrics}
                                                   optInExperimentMetrics={optInExperimentMetrics}
                                                   optInExperimentMetrics2={optInExperimentMetrics2}
                                                   optInExperimentMetrics3={optInExperimentMetrics3}
                                                   directSalesPageOrVsl={directSalesPageOrVsl}
                                                   fbDirectAdsMetrics={fbDirectAdsMetrics}
                                                   mwMetrics={mwMetrics}
                                                   fbDirectAdsMetrics2={fbDirectAdsMetrics2}
                                                   fullFunnelAdsMetrics={fullFunnelAdsMetrics}
                                                   fullFunnelAdsMetrics2={fullFunnelAdsMetrics2}
                        />
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

export async function loader() {

    return defer({
        experimentMetrics: Promise.all([
            AdminService.getExperimentResults(KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1),
            AdminService.getExperimentResults(KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_1),
            AdminService.getExperimentResults(KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_2),
            AdminService.getExperimentResults(KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_3),
            AdminService.getExperimentResults(KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1),
            AdminService.getExperimentResults(FB_ADS_DIRECT_EXPERIMENT_ID_1),
            AdminService.getExperimentResults(MW_EXPERIMENT_ID_1),
            AdminService.getExperimentResults(FB_ADS_DIRECT_EXPERIMENT_ID_2),
            AdminService.getExperimentResults(MW_FULL_FUNNEL_EXPERIMENT_1),
            AdminService.getExperimentResults(MW_FULL_FUNNEL_EXPERIMENT_2)
        ])
    })
}