import React from "react";

interface ExerciseDescriptionProps {
    title: string
    subject: string
    level: string
    field: string
}

export default function ExerciseDescription(props: ExerciseDescriptionProps) {
    const {title, subject, level, field} = props
    return <div className='mb-6 text-center mw-md-4xl mx-auto'>
        <span className='badge bg-success text-white text-uppercase shadow'
              style={{marginRight: "22px", marginLeft: "20px", marginBottom: "10px"}}>
                    {subject}
        </span>
        <span className='badge bg-success text-white text-uppercase shadow'
              style={{marginRight: "22px", marginLeft: "-10px", marginBottom: "10px"}}>
                    {level}
        </span>
        <span className='badge bg-success text-white text-uppercase shadow'
              style={{marginRight: "22px", marginLeft: "-10px", marginBottom: "10px"}}>
                    {field}
        </span>
        <h2
            className='font-heading mb-4 fs-5 text-white mt-3'
            style={{letterSpacing: '-1px'}}
        >
            {title}
        </h2>
        <p className='mb-0 fs-10 fw-medium text-secondary-light'>
            Poniżej znajdziesz treść i rozwiązanie zadania
        </p>
    </div>
}