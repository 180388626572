import Meta from "../../../../components/common/Meta";
import MainDesign from "../../../MainDesign";
import ConversationsPricing from "../../../../components/user/ai/pricing/conversations/ConversationsPricing";
import ConversationsHeader from "../../../../components/user/ai/pricing/conversations/ConversationsHeader";
import ConversationsFeatures from "../../../../components/user/ai/pricing/conversations/ConversationsFeatures";
import ConversationsHowItWorks from "../../../../components/user/ai/pricing/conversations/ConversationsHowItWorks";
import ConversationsBenefits from "../../../../components/user/ai/pricing/conversations/ConversationsBenefits";
import ConversationsModels from "../../../../components/user/ai/pricing/conversations/ConversationsModels";
import ConversationsFAQ from "../../../../components/user/ai/pricing/conversations/ConversationsFAQ";
import {DeferredData} from "@remix-run/router/dist/utils";
import {defer, useLoaderData} from "react-router-dom";
import {isLoggedIn} from "../../../../util/AuthUtil";

type LoaderData = {
    isLoggedIn: boolean;
};

export default function ConversationsLandingPage() {
    const {isLoggedIn} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Konwersacje z AI'/>
        <MainDesign containerStyles='container py-28'>
            <ConversationsHeader isLoggedIn={isLoggedIn}/>
            <ConversationsFeatures/>
            <ConversationsHowItWorks isLoggedIn={isLoggedIn}/>
            <ConversationsBenefits isLoggedIn={isLoggedIn}/>
            <ConversationsModels/>
            <ConversationsPricing/>
            <ConversationsFAQ/>
        </MainDesign>
    </>
}

export async function loader(): Promise<DeferredData> {
    return defer({
        isLoggedIn: await isLoggedIn(),
    });
}
