import React from 'react';
import Meta from "../../components/common/Meta";
import {Params} from "react-router-dom";
import {AuthService} from "../../service/AuthService";
import MainDesign from "../MainDesign";
import ChangePasswordText from "../../components/auth/ChangePasswordText";
import ChangePasswordForm from "../../components/auth/ChangePasswordForm";

export default function ChangePassword() {
    return <>
        <Meta title='Zmiana hasła'/>
        <MainDesign containerStyles="py-32 container">
            <ChangePasswordText/>
            <ChangePasswordForm/>
        </MainDesign>
    </>
}

export async function action({request, params}: { request: Request, params: Params<"uuid"> }) {
    const formData = await request.formData();
    const changePasswordData = {
        password: formData.get('password') as string,
        confirmPassword: formData.get('confirmPassword') as string,
        resetPasswordCode: params.uuid!!
    };

    if (changePasswordData.password !== changePasswordData.confirmPassword) {
        return {
            status: 422,
            body: 'Podane hasła różnią się!'
        }
    }

    try {
        await AuthService.changePassword({
            newPassword: changePasswordData.password,
            resetPasswordCode: changePasswordData.resetPasswordCode
        });
    } catch (error) {
        const apiError = error as Error
        return {
            status: 500,
            body: 'Wystąpił błąd: ' + apiError.message
        }
    }

    return {
        status: 200,
        body: 'Hasło zostało pomyślnie zmienione. Zaloguj się do swojego konta przy użyciu nowego hasła.'
    }
}
