import {Form, Link} from "react-router-dom";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import AnimatedButton from "../ui/motion/AnimatedButton";
import AnimatedLink from "../ui/motion/AnimatedLink";
import {AuthService} from "../../service/AuthService";

export default function RegisterForm() {
    const {isSubmitting} = useSubmitFormAction('/rejestracja-udana');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isInputValid = isEmailValid && name.length >= 2;

    return <Form method="POST" className='mw-sm mx-auto p-1'>
        <div className='mb-4 row g-6'>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signUpInput3-1'
                    >
                        Imię
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signUpInput2-1'
                        type='text'
                        aria-describedby='nameHelp'
                        placeholder='Wprowadź swoje imię'
                        name='name'
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>
            </div>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signUpInput3-2'
                    >
                        Email
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signUpInput2-2'
                        type='email'
                        aria-describedby='emailHelp'
                        placeholder='Podaj adres email'
                        name='email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
            </div>
        </div>
        <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
            <span>Stworzenie konta wiąże się z akceptacją </span>
            <Link className='text-success link-success' to='/polityka-prywatnosci'>
                polityki prywatności
            </Link>
            <span> oraz </span>
            <Link className='text-success link-success' to='/regulamin'>
                regulaminu
            </Link>
            .
        </p>
        <div className='mb-3 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    type='submit'
                    disabled={isSubmitting || !isInputValid}
                >
                    {isSubmitting ? "Trwa rejestracja..." : "Zarejestruj się"}
                </AnimatedButton>
            </div>
        </div>
        <div className='mb-6 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-outline-light d-flex flex-wrap align-items-center justify-content-center fs-11 py-3 w-100 text-white shadow'
                    onClick={() => AuthService.facebookLogin()}
                    bounceEffect
                    customScale={1.05}
                    disabled={isSubmitting}
                >

                    <img
                        className='me-2'
                        style={{width: "24px"}}
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/social/social-fb.png'
                        alt='logo facebook'
                    />
                    <span>Kontynuuj z Facebookiem</span>
                </AnimatedButton>
            </div>
        </div>
        <p className='mb-2 fs-13 fw-medium text-white text-center'>
            <span>Masz już konto? </span>
            <AnimatedLink className='text-success link-success' to='/logowanie'>
                Zaloguj się
            </AnimatedLink>
        </p>
        <div className='mb-0 fs-13 fw-medium text-white text-center'>
            <AnimatedLink
                className='fs-13 fw-medium text-success link-success d-inline-block'
                to='/reset-hasla'
            >
                Zapomniałeś hasła?
            </AnimatedLink>
        </div>
    </Form>
}