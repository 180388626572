import React from "react";
import {generateFormDataForPresignedUrl, getFileMD5} from "./Util";
import {GeneralService} from "../service/GeneralService";

export async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>,
                                       onSuccessfulUpload: (file: File, imgUrl: string) => void,
                                       setImageUrl: React.Dispatch<React.SetStateAction<string | undefined>> | React.Dispatch<React.SetStateAction<string>>,
                                       blog: boolean,
                                       setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>) {
    if (event.target.files && event.target.files.length > 0) {
        if (setIsUploading) {
            setIsUploading(true);
        }
        const file = event.target.files[0];

        const md5result = await getFileMD5(file);

        const {data} = await GeneralService.generatePresignedUrl(md5result, blog);
        const imgUrl = `${data.url}${data.fields.key}`;

        const formData = generateFormDataForPresignedUrl(file, data.fields)

        try {
            const response = await fetch(data.url, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                setImageUrl(imgUrl);
                onSuccessfulUpload(file, imgUrl);
            } else {
                console.error('Upload failed', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            if (setIsUploading) {
                setIsUploading(false);
            }
        }
    }
}