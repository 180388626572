import {motion} from "framer-motion";
import SlideInWhenVisible from "../../../../ui/motion/SlideInWhenVisible";

export default function ConversationsFeatures() {
    return <div className='mb-24 container' id='potrzeba'>
        <div className='row mb-20 align-items-center'>
            <div className='col-12 col-md-6'>
                <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                  Potrzeba
                </span>
                <h2
                    className='font-heading mb-4 fs-6 text-white'
                    style={{letterSpacing: '-1px'}}
                >
                    Czy Ty też?
                </h2>
                <div className='mb-0 fs-9 fw-medium text-secondary-light'>
                    <ul>
                        <li>
                            Boisz się wypowiadać w języku obcym?
                        </li>
                        <li>
                            Przeraża cię szybkie tworzenie zdań i wyrażanie swoich myśli, tak by konwersacja była
                            płynna?
                        </li>
                        <li>
                            Obawiasz się konsekwencji popełnionych błędów? Zła ocena ze strony rozmówcy?
                        </li>
                        <li>
                            A może chcesz po prostu poćwiczyć płynność wypowiedzi?
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='row justify-content-center justify-content-md-end'>
                    <div className='col-auto'>
                        <div className='position-relative'>
                            <div
                                className='overflow-hidden rounded-5'
                                style={{maxWidth: 554, height: 312}}
                            >
                                <motion.img className='img-fluid'
                                            initial={{opacity: 0, scale: 0.5}}
                                            animate={{opacity: 1, scale: 1}}
                                            alt='anxious person'
                                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/conversations-offer/anxious-person.png'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row align-items-center g-16'>
            <div className='col-12 col-md-6'>
                <div className='row justify-content-center justify-content-md-start'>
                    <div className='col-auto'>
                        <div className='position-relative'>
                            <img
                                style={{maxHeight: '800px'}}
                                className='img-fluid'
                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/conversations-offer/transformation.png'
                                alt='transformation'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='row g-10'>
                    <div className='col-12'>
                        <div className='mw-xl'>
                            <SlideInWhenVisible initial={{y: -100, opacity: 0, scale: 0.5}} delay={0.2} initialX={0}
                                                duration={0.7}>
                                <div className='row g-6'>
                                    <div className='col-auto'>
                                        <div
                                            className='mb-6 d-flex justify-content-center align-items-center mx-auto text-white bg-success rounded'
                                            style={{width: 56, height: 56}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/9.png'
                                                alt='bariera'/>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h3 className='font-heading fs-8 mb-4 mt-3 text-white'>
                                            Bariera językowa
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary-light'>
                                            Sprawia, że język zamiast otwierać drzwi, staje się murem, który trzyma
                                            Cię z dala od wszechświata możliwości.
                                        </p>
                                    </div>
                                </div>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mw-xl'>
                            <SlideInWhenVisible initial={{y: 0, opacity: 0, scale: 0.5}} delay={0.2} initialX={100}
                                                duration={0.7}>
                                <div className='row g-6'>
                                    <div className='col-auto'>
                                        <div
                                            className='mb-6 d-flex justify-content-center align-items-center mx-auto text-white bg-success rounded'
                                            style={{width: 56, height: 56}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/11.png'
                                                alt='swiadomosc'/>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h3 className='font-heading fs-8 mb-4 mt-3 text-white'>
                                            Świadomość problemu
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary text-secondary-light'>
                                            Rozumiemy jak ważna jest swoboda komunikacji. Brak łatwości i pewności w
                                            wyrażaniu swoich myśli i uczuć jest nie tylko frustrująca, ale i
                                            demotywująca.
                                            Często to nie sama znajomość języka i słownictwa stanowi przeszkodę, lecz
                                            niepewność i strach przed popełnieniem błędu
                                        </p>
                                    </div>
                                </div>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mw-xl'>
                            <SlideInWhenVisible initial={{y: 100, opacity: 0, scale: 0.5}} delay={0.2} initialX={0}
                                                duration={0.7}>
                                <div className='row g-6'>
                                    <div className='col-auto'>
                                        <div
                                            className='mb-6 d-flex justify-content-center align-items-center mx-auto text-white bg-success rounded'
                                            style={{width: 56, height: 56}}
                                        >
                                            <img
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/10.png'
                                                alt='rozwiazanie'/>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h3 className='font-heading fs-8 mb-4 mt-3 text-white'>
                                            Rozwiązanie
                                        </h3>
                                        <p className='mb-0 fw-medium text-secondary text-secondary-light'>
                                            I tu na białym koniu (kocie) wkraczają nasze konwersacje z AI!
                                            Zapewniają bezpieczną i przyjazną przestrzeń, gdzie możesz ćwiczyć swoje
                                            umiejętności wypowiadania się bez strachu przed oceną.
                                            Nasze algorytmy są zaprogramowane tak, by wspierać Cię i motywować, by każda
                                            rozmowa stawała się krokiem do pewnej siebie komunikacji.
                                        </p>
                                    </div>
                                </div>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}