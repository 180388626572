import React, {useState} from "react";
import Heading from "../../common/header/Heading";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";
import CalendlyModalLazy from "../../user/calendly/CalendlyModalLazy";
import ScheduleCallButtonMaturaParent from "./ui/ScheduleCallButtonMaturaParent";
import {useSearchParams} from "react-router-dom";

export default function EffectiveActionCourseMaturaParentDirectA() {
    const [searchParams] = useSearchParams();
    const [open, setOpen] = useState(false);

    return <>
        <Heading customStyles='mb-0 text-center mw-md-3xl mx-auto'
                 customTitleStyles='font-heading mb-4 fs-4 text-white'
                 title='MISTRZOWSKIE wsparcie dla Twojego maturzysty'
                 badgeText='MISTRZOWSKIE WSPARCIE'
        />
        <div className='mw-md-5xl mx-auto'>
            <div className='mw-md-3xl mx-auto mb-8 position-relative'>
                <VideoWithPlayButton vimeoVideoId='1002231906' id='vsl-ked-matura-parent'
                                     onPlay={() => TraceService.addTrace(TraceType.PlayVideo, `vsl-mw`)}/>
            </div>
            <div className='text-center'>
                <p className='text-white fw-medium fs-10 mw-md-3xl mx-auto'>
                    Na spotkaniu przygotujemy <b>indywidualny plan działania</b> dopasowany
                    do <b>potrzeb Twojego dziecka</b>.
                </p>
                <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={1}/>
                {open && (
                    <CalendlyModalLazy open={open} setOpen={setOpen}
                                       calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania-dla-twojego-maturzysty'
                                       onEventScheduled={() => {
                                           TraceService.addTraceWithExperiment(TraceType.ScheduledSalesCall, KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1, "A", searchParams.get('source'))
                                       }}/>
                )}
            </div>
        </div>
    </>
}