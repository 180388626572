import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import CoachingSessionSuccessfulPurchase
    from "../../../components/user/coaching-session/CoachingSessionSuccessfulPurchase";

export default function CoachingSessionSuccessfulPurchasePage() {
    return <>
        <Meta title="Indywidualne Konsultacje - sukces"/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Świetna decyzja!'
                     subtitle="Płatność zakończona sukcesem. Umów się na spotkanie poprzez link wysłany na maila. W razie jakichkolwiek pytań, skontaktuj się z nami pisząc na adres kontakt@produkacja.pl"
                     badgeText='Zakup udany'/>
            <CoachingSessionSuccessfulPurchase/>
        </MainDesign>
    </>
}