import React, {ChangeEvent} from "react";

interface RadioTwoOptionsProps {
    handleRadioChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    label: string;
    firstText: string;
    firstValue: string;
    secondText: string;
    secondValue: string;
    name?: string;
}

export default function RadioTwoOptions(props: RadioTwoOptionsProps) {
    const {
        handleRadioChange, checked, label, firstText, firstValue,
        secondText, secondValue, name
    } = props;
    return <div className='form-group'>
        <label
            className='mb-2 text-white fw-bold'
            htmlFor='modalInput2-1'
        >
            {label}
        </label>
        <div>
            <div className="form-check form-check-inline text-white">
                <input className="form-check-input" type="radio" name={name} value={firstValue}
                       onChange={handleRadioChange} checked={checked}/>
                <label className="form-check-label fs-10" htmlFor="">{firstText}</label>
            </div>
            <div className="form-check form-check-inline text-white">
                <input className="form-check-input" type="radio" name={name} value={secondValue}
                       onChange={handleRadioChange} checked={!checked}/>
                <label className="form-check-label fs-10" htmlFor="">{secondText}</label>
            </div>
        </div>
    </div>
}