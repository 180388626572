import ConfirmModal from "../../../ui/ConfirmModal";
import React, {useState} from "react";
import {PurchaseOption} from "../../../../model/user/purchase/PurchaseOption";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import PurchaseEmailAndName from "./PurchaseEmailAndName";
import {Link} from "react-router-dom";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";

export interface PurchaseOptionsProps {
    title: string
    price: number
    description: string
    chatPurchaseOption: PurchaseOption
    isLoggedIn: boolean;
    tokensNum: number;
}

export default function PurchaseOptions(props: PurchaseOptionsProps) {
    const {title, price, description, chatPurchaseOption, isLoggedIn, tokensNum} = props;

    const {isSubmitting} = useSubmitFormAction();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isInputValid, setIsInputValid] = useState(isLoggedIn);
    const [haveRabatCode, setHaveRabatCode] = useState(false);

    function toggleHaveRabatCode() {
        setHaveRabatCode(!haveRabatCode);
    }

    return <div className='col-12 col-md-4'>
        <div className='bg-light-dark rounded shadow'>
            <div className=' border-light'>
                <div className='p-8'>
                    <p className='mb-3 fs-8 fw-medium text-white'>{title}</p>
                    <div className='d-flex text-white align-items-end'>
                        <div className='fs-4 fw-semibold text-warning'>
                            <span>{tokensNum}</span>
                        </div>
                        <div className='fs-8 mb-4 ps-1 fw-lighter'>TOKENÓW</div>
                    </div>
                    <p className='mb-6 fw-medium text-secondary-light'>{description}</p>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <div className='d-flex text-white align-items-end justify-content-left'>
                                <div className='fs-5 fw-semibold'>
                                    <span>{price}</span>
                                </div>
                                <div className='fs-8 fw-bold mb-4 ps-1'>zł</div>
                            </div>

                        </div>
                        <div className='col-12'>
                            <AnimatedButton
                                className='btn btn-lg btn-success fs-10 w-100 text-success-light shadow'
                                onClick={() => {
                                    setConfirmModalOpen(true)
                                    TraceService.addTrace(TraceType.BtnClick, `btn-6 -> Wybieram: ${chatPurchaseOption.description}`)
                                }}
                            >
                                Wybieram
                            </AnimatedButton>
                        </div>

                    </div>
                </div>
            </div>
            <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Sfinalizuj zakup"
                          subtitle={isLoggedIn ? "Po kliknięciu 'Potwierdź' zostaniesz przekierowany do serwisu płatności." : "Podaj swoje imię i adres email. Jeżeli email nie jest jeszcze zarejestrowany, stworzymy dla Ciebie nowe konto."}
                          customTextTop={<div className='col-11 mt-3'>
                              <span
                                  className='fs-9 text-light'>Kupujesz <span className='fw-bold text-success'>
                                  {chatPurchaseOption.item.tokens}
                              </span> tokenów za <span
                                  className='fw-bold text-success'> {chatPurchaseOption.price}zł</span></span>
                          </div>}
                          buttonType='submit'
                          isSubmitting={isSubmitting}
                          submitDisabled={!isInputValid}
                          children={
                              <React.Fragment>
                                  {
                                      isLoggedIn ?
                                          <>
                                              <input name='purchaseOption' value={chatPurchaseOption.toString()} hidden
                                                     readOnly/>
                                              <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
                                                  <span>Przechodząc dalej akceptujesz </span>
                                                  <Link className='text-success link-success'
                                                        to='/polityka-prywatnosci'>
                                                      politykę prywatności
                                                  </Link>
                                                  <span> oraz </span>
                                                  <Link className='text-success link-success' to='/regulamin'>
                                                      regulamin
                                                  </Link>
                                                  <span>.</span>
                                              </p>
                                          </>
                                          :
                                          <PurchaseEmailAndName purchaseOption={chatPurchaseOption.toString()}
                                                                setIsInputValid={setIsInputValid}
                                                                isLoggedIn={isLoggedIn}/>
                                  }
                                  {/*<div>*/}
                                  {/*    <AnimatedDiv onClick={toggleHaveRabatCode}*/}
                                  {/*                 className='d-inline-block bg-transparent align-items-center text-success link-success me-2 fw-semibold fs-10'*/}
                                  {/*    >*/}
                                  {/*        Mam kod rabatowy*/}
                                  {/*    </AnimatedDiv>*/}
                                  {/*</div>*/}
                                  {/*{*/}
                                  {/*    haveRabatCode ? <div>Rabat code form...</div> : <></>*/}
                                  {/*}*/}

                              </React.Fragment>
                          }
            />
        </div>
    </div>
}