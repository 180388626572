export default function WhoAreWeExistence() {
    return <div className='mt-4 text-center'>
        <img data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/hope.png'
             className='img-fluid lazyload text-center mb-4' alt='Nadzieja'/>
        <p className='text-white fw-medium text-center fs-10 mx-auto mw-md-4xl'>Boli nas to, że młodzież marnuje swój
            potencjał i nie realizuje swoich celów. Podcina się im skrzydła i zachęca do podążania za tłumem, zamiast
            odkrywania i realizowania siebie...</p>
        <p className='text-white fw-medium text-center fs-10 mx-auto mw-md-4xl'>Licealistom brakuje narzędzi do
            efektywnego działania. Nic dziwnego, skoro publiczne szkoły kształtują bezmyślne jednostki, a rodzice tylko
            to podsycają, wysyłając je na korepetycje...</p>
        <p className='text-white fw-medium text-center fs-8 mx-auto mw-md-3xl'><i>Nie chcemy, żeby uczniowie marnowali
            swój potencjał i żyli przeciętnym życiem, nie wierząc w swoje możliwości.</i></p>
        <p className='text-white fw-medium text-center fs-8 mx-auto mw-md-3xl'><i>Nie chcemy, żeby uczniowie ślepo
            podążali
            za tłumem, byli nieszczęśliwi i robili to, co robi 99% społeczeństwa.</i></p>
        <p className='text-white fw-medium text-center fs-8 mx-auto mw-md-3xl'><i>Wierzymy w wyjątkowość każdej
            osoby. Wierzymy, że każdy może więcej, niż mu sie wydaje. Wierzymy w Ciebie.</i></p>


    </div>
}