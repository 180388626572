import {useEffect, useRef, useState} from 'react';

export default function usePagination<T, P extends any[]>(fetchDataFunction: (pageKey: string | undefined, ...params: P) => Promise<{
                                                              nextPageKey?: string,
                                                              items: T[]
                                                          }>,
                                                          initialItems: T[] | undefined,
                                                          initialPageKey: string | undefined,
                                                          autoLoad: boolean = true,
                                                          ...additionalParams: P) {
    const [items, setItems] = useState<T[]>(initialItems ?? []);
    const [nextPageKey, setNextPageKey] = useState<string | undefined>(initialPageKey);
    const sentinelRef = useRef<HTMLDivElement | null>(null);
    const isAutoLoadEnabled = autoLoad && 'IntersectionObserver' in window;

    async function loadMore() {
        if (!nextPageKey) return;
        const data = await fetchDataFunction(nextPageKey, ...additionalParams);
        setNextPageKey(data.nextPageKey);
        setItems((prevItems) => [...prevItems, ...data.items]);
    }

    useEffect(() => {
        if (!isAutoLoadEnabled) return;

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && nextPageKey) {
                loadMore();
            }
        }, {threshold: 0.5});

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        return () => {
            if (sentinelRef.current) {
                observer.unobserve(sentinelRef.current);
            }
        };
    }, [nextPageKey, isAutoLoadEnabled, ...additionalParams]);

    return {items, setItems, loadMore, nextPageKey, setNextPageKey, sentinelRef, isAutoLoadEnabled};
};
