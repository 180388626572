import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";

export default function AddBlogButton() {
    return <div className='text-center mw-xs mx-auto mb-8'>
        <AnimatedLink
            className='btn btn-lg btn-success text-lg-center w-75 py-3 px-4 h-100 shadow'
            to="dodaj"
        >
            Dodaj artykuł
        </AnimatedLink>
    </div>
}