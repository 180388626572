import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import AiPricing from "../../../components/user/ai/pricing/AiPricing";
import AiFAQ from "../../../components/user/ai/pricing/AiFAQ";

export default function AiPricingPage() {
    return <>
        <Meta title='Cennik tokenów'/>
        <MainDesign containerStyles='container py-28'>
            <AiPricing/>
            <AiFAQ/>
        </MainDesign>
    </>
}
