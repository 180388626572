import Meta from "../../components/common/Meta";
import ContactContent from "../../components/user/ContactContent";
import {GeneralService} from "../../service/GeneralService";
import {ApiError} from "../../service/HttpService";

export default function Contact() {

    return <>
        <Meta title='Kontakt' description='Skontaktuj się z nami'/>
        <ContactContent/>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const contactData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
        content: formData.get('content') as string,
        captchaToken: formData.get('captchaToken') as string,
    };

    try {
        await GeneralService.sendContactMessage(contactData);
        return {
            status: 200,
            body: 'Wiadomość została wysłana pomyślnie.'
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}
