import {useEffect, useRef} from 'react';
import {TraceService} from "../service/TraceService";
import {TraceType} from "../model/user/general/Trace";

const usePageTracking = () => {
    const scrollPercentageRef = useRef<number>(0);

    // Track time spent on page and log scrolling activity
    useEffect(() => {
        TraceService.addTrace(TraceType.PageLoad);

        let timeSpent = 0;
        const intervalId = setInterval(() => {
            timeSpent += 4;
            if (timeSpent <= 60) {
                TraceService.addTrace(TraceType.PageLoad, `Spent ${timeSpent}s, scrolled ${scrollPercentageRef.current}%`);
            } else {
                clearInterval(intervalId);
            }
        }, 4000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    // Track scroll percentage
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const docHeight = document.documentElement.scrollHeight;

            const totalDocScrollLength = docHeight - windowHeight;
            scrollPercentageRef.current = Math.floor((scrollTop / totalDocScrollLength) * 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollPercentageRef;
};

export default usePageTracking;