import React, {useState} from "react";
import ConfirmModal from "../../ui/ConfirmModal";
import {formatDate} from "../../../util/DateUtil";
import {differenceInDays, isValid, parseISO} from "date-fns";
import {Form, useNavigate, useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {ExerciseRequest} from "../../../model/user/exercise/Exercise";
import {useHandleDirtyForm} from "../../../hooks/useHandleDirtyForm";
import CustomSelect from "../../ui/form/CustomSelect";
import {exerciseCategories} from "../../../model/common/exercise/ExerciseCategories";

interface CreateOrEditExerciseProps {
    publicationDate: string;
    setPublicationDate: React.Dispatch<React.SetStateAction<string>>;
    publicationHour: string;
    setPublicationHour: React.Dispatch<React.SetStateAction<string>>;
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    field: string;
    setField: React.Dispatch<React.SetStateAction<string>>;
    content: string;
    setContent: React.Dispatch<React.SetStateAction<string>>;
    solution: string;
    setSolution: React.Dispatch<React.SetStateAction<string>>;
    actionType: "CREATE" | "EDIT";
    id?: string
}

export default function CreateOrEditExerciseForm(props: CreateOrEditExerciseProps) {
    const {isSubmitting} = useSubmitFormAction();
    const submit = useSubmit();
    const navigate = useNavigate();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const {
        publicationDate,
        setPublicationDate,
        publicationHour,
        setPublicationHour,
        title,
        setTitle,
        field,
        setField,
        content,
        setContent,
        solution,
        setSolution,
        actionType,
        id
    } = props;

    const [_, setIsFormDirty] = useHandleDirtyForm();

    function isBefore(): boolean {
        return differenceInDays(publicationDate, new Date()) < 0;
    }

    function isFormValid() {
        const hourPattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
        return isValid(parseISO(publicationDate)) &&
            hourPattern.test(publicationHour) &&
            title.trim().length > 0 &&
            field.trim().length > 0 &&
            content.trim().length > 10 &&
            solution.trim().length > 50;
    }

    function publishExercise() {
        const target: ExerciseRequest = {
            publicationDate: publicationDate,
            publicationHour: publicationHour,
            title: title,
            field: field,
            content: content,
            solution: solution,
            requestType: actionType
        }
        if (id && actionType === 'EDIT') {
            target['id'] = id
        }

        submit({
            ...target
        }, {
            method: "POST"
        })

        setIsFormDirty(false);
    }

    return <>
        <Form className='p-10 mw-4xl mx-auto' method='POST'>
            <div className='mb-8 row g-8 align-items-center'>
                <div className='col-auto'>
                </div>
                <div className='col-12'>
                <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                    Zadanie
                </span>
                    <h1
                        className='mb-1 font-heading fs-5 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        Dodaj/edytuj zadanie
                    </h1>
                </div>

                <div className='col-12 col-md-6'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-1'
                        >
                            Dzień publikacji
                        </label>
                        <input
                            className='input-lg form-control shadow-sm border-0 bg-light-dark'
                            id='modalInput2-1'
                            type='date'
                            aria-describedby='dateHelp'
                            value={publicationDate}
                            disabled={actionType === "EDIT"}
                            style={publicationDate ? {color: 'white'} : {}}
                            onChange={(event) => setPublicationDate(event.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-2'
                        >
                            Godzina publikacji
                        </label>
                        <input
                            className='input-lg form-control shadow-sm border-0 bg-light-dark'
                            id='modalInput2-1'
                            type='text'
                            placeholder="17:00"
                            pattern="^[0-2][0-9]:[0-5][0-9]$"
                            aria-describedby='hour'
                            title="Czas powinien być postaci HH:MM"
                            value={publicationHour}
                            disabled={actionType === "EDIT"}
                            style={publicationHour ? {color: 'white'} : {}}
                            onChange={(event) => setPublicationHour(event.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-12 mt-4'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-1'
                        >
                            Tytuł {actionType === 'EDIT' && !isBefore() ? 'UWAGA, tytuł maila nie zostanie zmieniony.' : ''}
                        </label>
                        <input
                            className='input-lg form-control shadow-sm border-0 bg-light-dark'
                            id='modalInput2-1'
                            type='text'
                            placeholder="#32 Geometria analityczna, równoległe proste"
                            aria-describedby='title'
                            value={title}
                            style={title ? {color: 'white'} : {}}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-12 mt-4'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-2'
                        >
                            Dział
                        </label>
                        <CustomSelect disabled={actionType === "EDIT"} value={field || undefined}
                                      grayColor={field === ''}
                                      onChange={(event) => setField(event.target.value)}>
                            {
                                exerciseCategories.map((exerciseCategory) => (
                                    <option value={exerciseCategory.value}
                                            key={exerciseCategory.value}>{exerciseCategory.displayName}</option>
                                ))
                            }
                        </CustomSelect>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-7'
                        >
                            Treść zadania
                        </label>
                        <textarea
                            className='input-lg form-control shadow bg-light-dark'
                            id='modalInput2-7'
                            aria-describedby='commentHelp'
                            placeholder='Chciałabym zająć się głównie geometrią analityczną. Mam przygotowane zadania i chciałabym je przerobić.'
                            rows={3}
                            value={content}
                            style={content ? {color: 'white'} : {}}
                            onChange={(event) => setContent(event.target.value)}
                        ></textarea>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-7'
                        >
                            Rozwiązanie
                        </label>
                        <textarea
                            className='input-lg form-control shadow bg-light-dark'
                            id='modalInput2-7'
                            aria-describedby='commentHelp'
                            placeholder='Chciałabym zająć się głównie geometrią analityczną. Mam przygotowane zadania i chciałabym je przerobić.'
                            rows={5}
                            style={solution ? {color: 'white'} : {}}
                            value={solution}
                            onChange={(event) => {
                                setSolution(event.target.value)
                                setIsFormDirty(true);
                            }}
                        ></textarea>
                    </div>
                </div>

            </div>
            <div className='row mt-12'>
                <div className='col-12 col-md-4'>
                    <button
                        className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                        type="button"
                        onClick={() => setCancelModalOpen(true)}
                    >
                        Cofnij
                    </button>
                </div>
                <div className='col-12 col-md-8'>
                    <button
                        className='btn btn-lg btn-success w-100 text-success-light shadow'
                        onClick={() => setConfirmModalOpen(true)}
                        type="button"
                        disabled={!isFormValid()}
                    >
                        Potwierdź
                    </button>
                </div>
            </div>
        </Form>
        <ConfirmModal isOpen={cancelModalOpen} setOpen={setCancelModalOpen} title="Czy na pewno chcesz anulować?"
                      subtitle="Wszystkie zmiany zostaną odrzucone."
                      confirmButtonAction={() => {
                          setIsFormDirty(false);
                          navigate('/admin/zadania')
                      }}/>
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Czy na pewno chcesz dodać zadanie?"
                      subtitle={`Zostanie ono opublikowane ${formatDate(publicationDate + ' ' + publicationHour)}.`}
                      subtitleWarn={isBefore() ? 'UWAGA! Ta data jest z przeszłości.' : undefined}
                      confirmButtonAction={publishExercise}
                      isSubmitting={isSubmitting}
        />
    </>
}