import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import ExtraordinarySupportUnsuccessfulPurchase
    from "../../../components/user/extraordinary-support/ExtraordinarySupportUnsuccessfulPurchase";

export default function ExtraordinarySupportUnsuccessfulPurchasePage() {
    return <>
        <Meta title="Kurs Efektywnego Działania - Produkacja"/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Przykro nam, coś poszło nie tak'
                     subtitle="Płatność nieudana. Jeżeli problem będzie się powtarzał, skontaktuj się z nami."
                     badgeText='Zakup nieudany'/>
            <ExtraordinarySupportUnsuccessfulPurchase/>
        </MainDesign>
    </>
}