import Heading from "../../common/header/Heading";
import React from "react";
import AdvantageItem from "../../user/ai/pricing/AdvantageItem";
import ConfirmModal from "../../ui/ConfirmModal";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import OptInEmailAndName from "../../user/offer/OptInEmailAndName";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {OptInProps} from "../../../util/ExperimentUtil";
import LoadingDots from "../../common/LoadingDots";

export default function EffectiveActionCourseOptInA(props: OptInProps) {
    const {
        handleButtonClick,
        confirmModalOpen,
        setConfirmModalOpen,
        loading,
        isInputValid,
        email,
        setEmail,
        name,
        setName
    } = props;
    const {isSubmitting} = useSubmitFormAction(`/spotkanie`, null, () => {
        setConfirmModalOpen(false);
    });

    return <>
        <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                 title={'Działaj efektywniej i osiągaj więcej!'}
                 customTitleStyles='font-heading mb-4 fs-4 text-white'
                 customSubtitleStyles='text-warning fs-9 fw-medium'
                 subtitle='Innowacyjny Kurs Efektywnego Działania łączy mistrzowskie zarządzanie czasem, wykształcanie
                 zwycięskiego mindsetu, niezawodne planowanie i realizowanie celów oraz efektywną naukę.'/>
        <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
            <AnimatedButton
                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                onClick={() => handleButtonClick(1, "A")}
            >
                Chcę otrzymać szczegóły
            </AnimatedButton>
            {loading &&
                <div className="spinner-container fs-9 text-danger fw-medium">Sprawdzam
                    dostępność <LoadingDots/>
                </div>}
        </div>
        <div className='row container mt-4'>
            <div className='col-md-6 col-12 text-center'>
                <div style={{position: 'relative', width: '100%', paddingTop: '66.67%'}}> {/* 3:2 aspect ratio */}
                    <img
                        data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/opt-in-photo.webp'
                        className='img-fluid lazyload' alt='pokazanie kursu efektywnego działania'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>
            <div className='col-md-6 col-12 mt-2'>
                <div className='container'>
                    <Heading customStyles='mb-6 text-center mw-md-4xl mx-auto' title='Otrzymujesz:'/>
                    <AdvantageItem
                        title={`Indywidualny plan działania dopasowany do Twoich potrzeb.`}/>
                    <AdvantageItem title='Lekcje w postaci filmów online udostępniane na bieżąco.'/>
                    <AdvantageItem title='Cheat-Sheety i materiały do pracy własnej.'/>
                    <AdvantageItem title='5 indywidualnych konsultacji z założycielem i twórcą Produkacji.'/>
                    <AdvantageItem title='Cotygodniowe grupowe spotkania online na Zoomie.'/>
                    <AdvantageItem title='Pełny dostęp do forum do zadawania nieograniczonej liczby pytań.'/>
                    <AdvantageItem title='Gwarancję satysfakcji albo zwrot pieniędzy.'/>
                    <p className='text-danger fw-medium fs-10'>
                        <span className='fw-bold'>Uwaga! </span>
                        Pozostały już <span className='fw-bold'>tylko 2 miejsca</span>. Nie czekaj i dowiedz się więcej
                        już teraz!</p>
                    <div className='text-center mw-md-2xl mx-auto mt-6 mb-4'>
                        <AnimatedButton
                            className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                            onClick={() => handleButtonClick(2, "A")}
                        >
                            Chcę otrzymać szczegóły
                        </AnimatedButton>
                        {loading &&
                            <div className="spinner-container fs-9 text-danger fw-medium">Sprawdzam
                                dostępność <LoadingDots/>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Otrzymaj więcej szczegółów"
                      subtitle={`Na podanego maila wyślemy informacyjny film z możliwością umówienia
                      się na bezpłatne konsultacje w celu przygotowania indywidualnego planu działania dopasowanego do Twoich potrzeb.`}
                      customSubtitleStyles={'mt-0'}
                      customTextTop={<div className='col-11 mt-2'>
                              <span
                                  className='fs-9 text-light'><span className='fw-bold text-warning'>
                                  Uwaga!
                              </span> Zostały jeszcze tylko 2 miejsca!</span>
                      </div>}
                      buttonType='submit'
                      isSubmitting={isSubmitting}
                      submitDisabled={!isInputValid}
                      children={
                          <OptInEmailAndName name={name} setName={setName} email={email} setEmail={setEmail}
                                             customFooterText='newslettera kursu efektywnego działania'/>
                      }
        />
    </>
}
