// Root.tsx
import {Outlet} from "react-router-dom";
import Footer from "../components/common/Footer";
import ScrollToTop from "../util/ScrollToTop";
import React, {useEffect} from "react";
import {useCookies} from "react-cookie";
import CookieBanner from "../components/common/CookieBanner";
import UserHeader from "../components/user/UserHeader";
import {TraceService} from "../service/TraceService";
import {TraceType} from "../model/user/general/Trace";
import TiktokPixel from "tiktok-pixel";
import {DiagnosticService} from "../service/DiagnosticService";
import ReactPixel from 'react-facebook-pixel';

export default function RootLayout() {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);

    useEffect(() => {
        // If cookie consent is already given, load scripts immediately
        if (cookies.cookieConsent) {
            activateTiktokPixel();
            loadGoogleAnalytics();
            activateFbPixel();
        } else {
            ReactPixel.init('1721779321916461', undefined, {debug: false, autoConfig: true});
            ReactPixel.revokeConsent();
        }
    }, [cookies]);

    function addCookieCookiesConsent(consent: boolean) {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        setCookie("cookieConsent", consent, {path: "/", expires: oneYearFromNow});
        TraceService.addTrace(TraceType.CookieConsent, JSON.stringify({consent: consent}));
    }

    function giveCookieConsent() {
        addCookieCookiesConsent(true);

        activateTiktokPixel();
        loadGoogleAnalytics()
    }

    function rejectCookieConsent() {
        addCookieCookiesConsent(false);
    }

    function loadGoogleAnalytics() {
        try {
            // @ts-ignore
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
        } catch (e) {
            DiagnosticService.addDiagnostic({
                functionName: "cookie-consent",
                error: e,
                fullEvent: {},
                additionalInfo: 'error while granting consent for google ads'
            })
        }
    }


    function activateTiktokPixel() {
        TiktokPixel.init('COUH27BC77U1Q2BAILR0');
        TiktokPixel.pageView();
    }

    function activateFbPixel() {
        ReactPixel.grantConsent();
    }

    return (
        <>
            <ScrollToTop/>
            <UserHeader/>
            <Outlet/>
            <Footer/>
            {(cookies.cookieConsent === undefined || cookies.cookieConsent === null) &&
                <CookieBanner giveCookieConsent={giveCookieConsent} rejectCookieConsent={rejectCookieConsent}/>}
        </>
    );
}
