import {motion} from "framer-motion";
import {formatDate} from "../../../util/DateUtil";
import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {Diagnostic} from "../../../model/admin/Diagnostic";

interface DiagnosticItemProps {
    diagnostic: Diagnostic;
    isSubmitting: boolean;
    markDiagnosticAsReviewed: (diagnosticId: string) => void;
    openDiagnosticSummary: (diagnostic: Diagnostic) => void;
}

export default function DiagnosticItem(props: DiagnosticItemProps) {
    const {diagnostic, isSubmitting, markDiagnosticAsReviewed, openDiagnosticSummary} = props;
    return <>
        <motion.div className='col-12' key={diagnostic.id} initial={{opacity: 0, scale: 0.5}}
                    animate={{opacity: 1, scale: 1}}>
            <div className='px-10 py-8 bg-light-dark rounded shadow'>
                <div className='row justify-content-between g-4'>
                    <div className='col d-flex flex-column justify-content-center'>
                        <h3 className='mb-1 font-heading fs-9 text-white'>
                            Diagnostyka odnośnie {diagnostic.source} - {diagnostic.functionName}
                        </h3>
                        <h6 className='mb-1 text-white'>
                            {formatDate(diagnostic.createdAt)}
                        </h6>
                        <p className='mb-0 fw-medium text-secondary-light'
                           style={{fontSize: '16px'}}>
                            Kliknij aby zobaczyć szczegóły...
                        </p>
                        <div>
                            {
                                !diagnostic.reviewed && <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Nowa
                                </span>
                            }

                        </div>
                    </div>
                    <div className='col-12 col-md-auto mt-6'>
                        <AnimatedButton
                            className='btn btn-lg btn-success w-100 text-success-light shadow'
                            onClick={() => openDiagnosticSummary(diagnostic)}
                        >
                            Zobacz szczegóły
                        </AnimatedButton>
                    </div>
                    {
                        !diagnostic.reviewed && <div className="col-12 col-md-auto mt-6">
                            <AnimatedButton
                                className="btn btn-lg btn-warning w-100 text-success-light shadow"
                                onClick={() => markDiagnosticAsReviewed(diagnostic.id)}
                                disabled={isSubmitting}
                            >
                                Przejrzane
                            </AnimatedButton>
                        </div>
                    }
                </div>
            </div>
        </motion.div>
    </>
}