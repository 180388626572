import {useEffect, useRef, useState} from "react";
import {handleDirtyForm} from "../util/Util";

export function useHandleDirtyForm(initialIsDirty: boolean = false) {
    const [isFormDirty, setIsFormDirty] = useState<boolean>(initialIsDirty);
    const isFormDirtyRef = useRef<boolean>(isFormDirty);

    useEffect(() => {
        // Update the ref every time the state changes
        isFormDirtyRef.current = isFormDirty;

        // Call handleDirtyForm and return the cleanup function
        return handleDirtyForm(isFormDirtyRef);
    }, [isFormDirty]);

    return [isFormDirty, setIsFormDirty] as const;
}