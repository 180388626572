import Heading from "../../../../common/header/Heading";
import HowItWorksItem from "./HowItWorksItem";
import StaggerChildrenWhenVisible from "../../../../ui/motion/StaggerChildrenWhenVisible";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";
import React from "react";
import VideoWithPlayButton from "../../../../ui/video/VideoWithPlayButton";
import {TraceService} from "../../../../../service/TraceService";
import {TraceType} from "../../../../../model/user/general/Trace";

interface ConversationsHowItWorksProps {
    isLoggedIn: boolean
}

export default function ConversationsHowItWorks(props: ConversationsHowItWorksProps) {
    const {isLoggedIn} = props;

    const howItWorksItems = [
        {
            title: 'Zaloguj się na Platformę',
            subtitle: 'Stwórz konto w kilka sekund i uzyskaj dostęp do wszystkich funkcjonalności.'
        },
        {
            title: 'Wybierz interesujący Cię model',
            subtitle: 'Na tą chwilę oferujemy 2 różne rodzaje modeli, z których każdy skupi się na czymś innym. Gramatyka, luźne konwersacje, a może coś innego?'
        },
        {
            title: 'Dodatkowe ustawienia',
            subtitle: 'Określ poziom, akcent lektora i temat konwersacji. Ten krok możesz pominąć.'
        },
        {
            title: 'Gotowe',
            subtitle: 'Zacznij korzystać już teraz! Z darmowymi Tokenami przetestujesz za darmo możliwości naszego rozwiązania.'
        }
    ]
    return <>
        <div className='pt-8 mb-12'>
            <div className='mb-16 row align-items-center g-16'>
                <div className='col-12 col-md-6'>
                    <Heading title='Jak to działa?' customStyles='mw-md-xl' badgeText='Jak to działa?'
                             subtitle='Usługa konwersacji z AI została zaprojektowana, aby zapewnić przestrzeń, w której
                         wyzwanie przełamania bariery językowej stanie się łatwiejsze i bardziej osiągalne.'/>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='row justify-content-center justify-content-md-end'>
                        <div className='position-relative'>
                            <div
                                className='overflow-hidden rounded-5'
                                style={{maxWidth: 554, height: 312}}
                            >
                                <VideoWithPlayButton vimeoVideoId='1012847873' id={'conversations-how-it-works-video'}
                                                     onPlay={() => {
                                                         TraceService.addTrace(TraceType.PlayVideo, 'conversations-how-it-works-video')
                                                     }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StaggerChildrenWhenVisible>
                {
                    howItWorksItems.map((item, index) => (
                        <HowItWorksItem
                            key={index}
                            count={index + 1}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                    ))
                }
                {/*</motion.div>*/}
            </StaggerChildrenWhenVisible>
        </div>
        <div className="row justify-content-center g-4 mb-20">
            <div className="col-12 col-md-auto"><AnimatedLink
                className="btn btn-lg btn-success w-100 text-success-light shadow"
                onClick={() => {
                    localStorage.setItem('redirectUrlAfterRegistration', '/konwersacje')
                }}
                to={isLoggedIn ? "/konwersacje" : "/rejestracja"}>Przetestuj za darmo już teraz</AnimatedLink>
            </div>
        </div>
    </>
}