import React, {useEffect, useState} from "react";
import FeedbackModal from "../../feedback/FeedbackModal";
import AnimatedLink from "../../../ui/motion/AnimatedLink";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";

export interface ChatTokensProps {
    initialTokens: number
    tokens: number | undefined
    setTokens: React.Dispatch<React.SetStateAction<number | undefined>>
    promptCost: number;
    feedbackSource: 'CHAT' | 'CONVERSATIONS' | 'CONTACT'
    hasCompletedCustomFeedbackForm?: boolean
}

export default function ChatTokensWithFeedback(props: ChatTokensProps) {
    const {
        initialTokens,
        tokens,
        setTokens,
        promptCost,
        feedbackSource,
        hasCompletedCustomFeedbackForm
    } = props;

    const [feedbackMenuOpen, setFeedbackMenuOpen] = useState(false);


    useEffect(() => {
        if (!tokens) {
            setTokens(initialTokens);
        } else {
            setTokens((prevState) => prevState! - promptCost)
        }
    }, [promptCost])

    return <>
        {
            ((tokens === 0 || (tokens && tokens < 15)) && !hasCompletedCustomFeedbackForm) &&
            <div className="text-left text-white fs-10 pb-2">
                Wypełnij krótką ankietę i otrzymaj 20 tokenów! <a href='https://forms.gle/qfngGeDgoLXuUJVF6'
                                                                  onClick={async () => await TraceService.addTrace(TraceType.BtnClick, "start feedback form")}
                                                                  className='link-success text-decoration-underline fs-10'>Kliknij
                tutaj, aby rozpocząć.</a>
            </div>
        }
        <div className='mb-1 fs-10 text-secondary-light d-flex flex-wrap align-items-center'>
            <div className="col-12 col-md-auto mb-2 mb-md-0">
                <>
                    <span>Pozostało tokenów: <b>{tokens}</b></span> {(tokens === 0 || (tokens && tokens < 20)) ? <>
                    {"\u00A0"}•{"\u00A0"}{"\u00A0"}
                    <AnimatedLink className='d-inline-block' to='/ai/cennik'><span
                        className='text-danger fw-bold'>{' KUP TOKENY'}</span></AnimatedLink> </> : ''}
                </>
            </div>
            <div className="col d-md-flex justify-content-md-end">
                <div style={{cursor: "pointer"}} className='text-decoration-underline'
                     onClick={() => setFeedbackMenuOpen(true)}>Prześlij feedback
                </div>
            </div>
            <FeedbackModal isOpen={feedbackMenuOpen} setOpen={setFeedbackMenuOpen} feedbackSource={feedbackSource}/>
        </div>
    </>

}