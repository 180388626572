import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import ExtraordinarySupportSuccessfulPurchase
    from "../../../components/user/extraordinary-support/ExtraordinarySupportSuccessfulPurchase";

export default function ExtraordinarySupportSuccessfulPurchasePage() {
    return <>
        <Meta title="MISTRZOWSKIE Wsparcie - Produkacja"/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Gratulujemy - Świetna decyzja!'
                     subtitle="MISTRZOWSKIE Wsparcie zostało zakupione."
                     badgeText='Zakup udany'/>
            <ExtraordinarySupportSuccessfulPurchase/>
        </MainDesign>
    </>
}