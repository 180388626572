import React, {useEffect, useState} from "react";
import DiagnosticItem from "./DiagnosticItem";
import usePagination from "../../../hooks/usePagination";
import PaginationLoadMore from "../../ui/pagination/PaginationLoadMore";
import DiagnosticSummaryModal from "./DiagnosticSummaryModal";
import {Diagnostic, DiagnosticItemsModel} from "../../../model/admin/Diagnostic";
import {DiagnosticService} from "../../../service/DiagnosticService";

interface DiagnosticItemProps {
    diagnostics: DiagnosticItemsModel;
    category: string
}

export default function DiagnosticItems(props: DiagnosticItemProps) {
    const {diagnostics, category} = props;
    const {
        items,
        setItems,
        loadMore,
        nextPageKey,
        setNextPageKey,
        sentinelRef,
        isAutoLoadEnabled
    } = usePagination<Diagnostic, any>(DiagnosticService.getDiagnostics, diagnostics.items, diagnostics.nextPageKey, true, category);

    useEffect(() => {
        setNextPageKey(diagnostics.nextPageKey)
    }, [diagnostics.nextPageKey]);

    useEffect(() => {
        setItems(diagnostics.items)
    }, [diagnostics.items]);

    const [isSummaryOpen, setIsSummaryOpen] = useState(false);
    const [diagnosticSummary, setDiagnosticSummary] = useState<Diagnostic | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function openDiagnosticSummary(diagnostic: Diagnostic) {
        setDiagnosticSummary(diagnostic);
        setIsSummaryOpen(true);
    }

    function closeDiagnosticSummary() {
        setIsSummaryOpen(false);
    }

    async function markDiagnosticAsReviewed(diagnosticId: string) {
        setIsSubmitting(true);
        await DiagnosticService.markDiagnosticAsReviewed(diagnosticId);
        const index = items.findIndex(item => item.id === diagnosticId);

        if (index !== -1) {
            const updatedItems = [...items];

            updatedItems[index] = {
                ...updatedItems[index],
                reviewed: true,
            };

            setItems(updatedItems);
        }
        setIsSubmitting(false);
    }

    return <>
        <div className='mb-20 row g-8'>
            {
                items.map((item) => (
                    <DiagnosticItem key={item.id} diagnostic={item} isSubmitting={isSubmitting}
                                    markDiagnosticAsReviewed={markDiagnosticAsReviewed}
                                    openDiagnosticSummary={openDiagnosticSummary}/>
                ))
            }
        </div>
        <PaginationLoadMore isAutoLoadEnabled={isAutoLoadEnabled} nextPageKey={nextPageKey} loadMore={loadMore}
                            sentinelRef={sentinelRef}/>
        <DiagnosticSummaryModal isOpen={isSummaryOpen} isSubmitting={isSubmitting} diagnostic={diagnosticSummary!!}
                                closeDiagnosticSummary={closeDiagnosticSummary}
                                markDiagnosticAsReviewed={markDiagnosticAsReviewed}/>
    </>
}
