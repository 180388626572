import React, {Suspense} from "react";
import Spinner from "../Spinner";

const LazyCKEditor = React.lazy(() => import('./CKEditorLazy'));

interface RichContentEditorProps {
    content: string
    setContent: React.Dispatch<React.SetStateAction<string>>
    onChange?: (data: string) => void;
}

export default function RichContentEditor(props: RichContentEditorProps) {
    const {content, setContent, onChange} = props;
    return <Suspense fallback={<Spinner/>}>
        <LazyCKEditor content={content} setContent={setContent} onChange={onChange}/>
    </Suspense>
}