import {BlogArticles, BlogItem} from "../../../model/user/blog/Blog";
import BlogPostItem from "./BlogPostItem";
import {BlogService} from "../../../service/BlogService";
import React, {useEffect, useState} from "react";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import ConfirmModal from "../../ui/ConfirmModal";
import {useSubmit} from "react-router-dom";
import usePagination from "../../../hooks/usePagination";
import PaginationLoadMore from "../../ui/pagination/PaginationLoadMore";

interface BlogPostsProps {
    blogArticles: BlogArticles;
    isAdmin: boolean;
    category: string;
}

export default function BlogPosts(props: BlogPostsProps) {
    const {blogArticles, isAdmin, category} = props;

    const submit = useSubmit();
    const {
        items: articles,
        setItems,
        loadMore,
        nextPageKey,
        setNextPageKey,
        sentinelRef,
        isAutoLoadEnabled
    } = usePagination<BlogItem, any>(BlogService.getArticles, blogArticles.items, blogArticles.nextPageKey, true, category);

    const {isSubmitting} = useSubmitFormAction(window.location.pathname, null, () => {
        setConfirmDeleteModalOpen(false);
        setItems(prevState => prevState.filter(item => item.id !== articleToDelete!!.id));
        setArticleToDelete(null);
    });

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [articleToDelete, setArticleToDelete] = useState<BlogItem | null>(null);

    useEffect(() => {
        setNextPageKey(blogArticles.nextPageKey)
    }, [blogArticles.nextPageKey]);

    useEffect(() => {
        setItems(blogArticles.items)
    }, [blogArticles.items]);

    function deleteArticle() {
        submit({
            uuid: articleToDelete!!.id
        }, {
            method: "DELETE"
        });
    }

    return <>
        <div className='mb-20 row g-8'>
            {
                articles.map((item) => (
                    <BlogPostItem key={item.id} blogItem={item} isAdmin={isAdmin}
                                  setArticleToDelete={setArticleToDelete}
                                  setConfirmDeleteModalOpen={setConfirmDeleteModalOpen}/>
                ))
            }
        </div>

        <PaginationLoadMore isAutoLoadEnabled={isAutoLoadEnabled} nextPageKey={nextPageKey} loadMore={loadMore}
                            sentinelRef={sentinelRef}/>

        <ConfirmModal isOpen={confirmDeleteModalOpen} setOpen={setConfirmDeleteModalOpen}
                      title={`Czy na pewno chcesz usunąć artykuł '${articleToDelete?.title}'?`}
                      subtitle="Tej czynności nie będzie dało się cofnąć."
                      isSubmitting={isSubmitting}
                      confirmButtonAction={deleteArticle}/>
    </>
}