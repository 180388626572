import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

interface EmailAndNameProps {
    purchaseOption: string
    setIsInputValid: React.Dispatch<React.SetStateAction<boolean>>
    isLoggedIn: boolean
}

export default function PurchaseEmailAndName(props: EmailAndNameProps) {
    const {purchaseOption, setIsInputValid, isLoggedIn} = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    useEffect(() => {
        setIsInputValid(isLoggedIn || (isEmailValid ?? false) && name.length >= 2)
    }, [name, email]);

    return <>
        <div className='col-12 col-md-5'>
            <div className='form-group'>
                <input name='purchaseOption' value={purchaseOption} hidden readOnly/>
                <label
                    className='mb-2 fw-bold text-white'
                    htmlFor='signUpInput3-1'
                >
                    Imię
                </label>
                <input
                    className='input-lg form-control text-secondary-dark shadow'
                    id='signUpInput2-1'
                    type='text'
                    aria-describedby='nameHelp'
                    placeholder='Wprowadź swoje imię'
                    name='name'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </div>
        </div>
        <div className='col-12 col-md-7'>
            <div className='form-group'>
                <label
                    className='mb-2 fw-bold text-white'
                    htmlFor='signUpInput3-2'
                >
                    Email
                </label>
                <input
                    className='input-lg form-control text-secondary-dark shadow'
                    id='signUpInput2-2'
                    type='email'
                    aria-describedby='emailHelp'
                    placeholder='Podaj adres email'
                    name='email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </div>
        </div>
        <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
            <span>Przechodząc dalej akceptujesz </span>
            <Link className='text-success link-success' to='/polityka-prywatnosci'>
                politykę prywatności
            </Link>
            <span> oraz </span>
            <Link className='text-success link-success' to='/regulamin'>
                regulamin
            </Link>
            <span>.</span>
        </p>
    </>
}