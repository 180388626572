import {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Category} from "../components/ui/category/Categories";

export function useCategory(categories: Category[], defaultCategoryValue?: string) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(
        getCategoryByValue(searchParams.get('category') || (defaultCategoryValue ?? 'all'))
    );

    function getCategoryByValue(value: string) {
        return categories.find(category => category.value === value) ?? categories[0];
    }

    function onCategoryChange(category: Category) {
        setSelectedCategory(category);
        navigate(`?category=${category.value}`, {replace: true});
        setSearchParams({category: category.value});
    }

    return {selectedCategory, onCategoryChange};
}
