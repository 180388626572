import React, {ChangeEvent, Suspense, useEffect, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {handleFileUpload} from "../../../util/FileUploadUtil";
import {AnimatePresence, motion} from 'framer-motion';
import LoadingPage from "../../../pages/common/LoadingPage";

const LazyEditor = React.lazy(() => {
    // @ts-ignore
    import('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
    return import('react-draft-wysiwyg').then(module => ({default: module.Editor}));
});

interface WyswigEditorProps {
    rawEditorState?: string;
    saveEditorContent: (newState: string) => void;
}

export default function WyswigEditor(props: WyswigEditorProps) {
    const {rawEditorState, saveEditorContent} = props;
    // we don't want to import the type in order to not load an entire library.
    const [editorState, setEditorState] = useState<any | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [previousContent, setPreviousContent] = useState<string | null>(rawEditorState || null);

    useEffect(() => {
        const loadEditorState = async () => {
            const {EditorState, convertFromRaw, convertToRaw} = await import('draft-js');
            console.log('loaded editor??')
            setEditorState(
                rawEditorState
                    ? EditorState.createWithContent(convertFromRaw(JSON.parse(rawEditorState)))
                    : EditorState.createEmpty()
            );
        };
        loadEditorState();
    }, [rawEditorState]);

    const onEditorStateChange = async (newState: any) => {
        const {convertToRaw} = await import('draft-js');
        setEditorState(newState);
        saveContentDebounced(newState, convertToRaw); // Save content on every change
    };

    const saveContent = async (state: any, convertToRaw: any) => {
        const content = JSON.stringify(convertToRaw(state.getCurrentContent()));
        if (content !== previousContent) {
            saveEditorContent(content);
            setPreviousContent(content);
            setShowSavedMessage(true);
            setTimeout(() => {
                setShowSavedMessage(false);
            }, 2000);
        }
    };

    const saveContentDebounced = useDebouncedCallback(async (state: any, convertToRaw: any) => {
        saveContent(state, convertToRaw);
    }, 5000);

    const onBlurHandler = async () => {
        const {convertToRaw} = await import('draft-js');
        saveContent(editorState, convertToRaw); // Save content when editor loses focus
        setIsFocused(false); // Set focused state to false on blur
    };

    const onFocusHandler = () => {
        setIsFocused(true); // Set focused state to true on focus
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;
        const autoSave = async () => {
            const {convertToRaw} = await import('draft-js');
            saveContent(editorState, convertToRaw); // Save content every 5 seconds
        };
        if (isFocused) {
            interval = setInterval(autoSave, 5000);
        }
        return () => clearInterval(interval); // Clear interval on cleanup
    }, [editorState, isFocused]);

    return (
        <div>
            <div onBlur={onBlurHandler} onFocus={onFocusHandler}>
                <Suspense fallback={<LoadingPage/>}>
                    {editorState && (
                        <LazyEditor
                            editorState={editorState}
                            toolbarClassName="text-dark bg-light-dark"
                            wrapperClassName="text-white border rounded"
                            editorClassName="text-white px-3"
                            onEditorStateChange={onEditorStateChange}
                            editorStyle={{zIndex: 10000}}
                            toolbar={{
                                options: ['inline', 'list', 'colorPicker', 'image'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                    bold: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/bold.png',
                                        className: 'bg-light-dark'
                                    },
                                    italic: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/italicize.png',
                                        className: 'bg-light-dark'
                                    },
                                    underline: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/underline.png',
                                        className: 'bg-light-dark'
                                    },
                                },
                                list: {
                                    options: ['unordered', 'ordered'],
                                    unordered: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/bullet-points.png',
                                        className: 'bg-light-dark'
                                    },
                                    ordered: {
                                        icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/ordered-list.png',
                                        className: 'bg-light-dark'
                                    }
                                },
                                colorPicker: {
                                    icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/color-picker.png',
                                    className: 'bg-light-dark',
                                    component: undefined,
                                    popupClassName: 'bg-light-dark text-white',
                                    colors: ['#ffffff', '#2ad137', '#f59e0c', '#8896ab', '#ef5844', '#3944bc'],
                                },
                                image: {
                                    icon: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/react-wyswig/image-upload.png',
                                    className: 'bg-light-dark',
                                    popupClassName: 'bg-light-dark text-white',
                                    uploadEnabled: true,
                                    urlEnabled: true,
                                    alignmentEnabled: false,
                                    uploadCallback: uploadImage,
                                    previewImage: true,
                                    inputAccept: 'image/*',
                                    alt: {present: false, mandatory: false},
                                },
                            }}
                        />
                    )}
                </Suspense>
                <AnimatePresence>
                    {showSavedMessage && (
                        <motion.div
                            initial={{opacity: 0, y: -10}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -10}}
                            transition={{duration: 0.5}}
                            className="text-left mt-2 text-success"
                        >
                            Pomyślnie zapisano.
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

const uploadImage = async (file: File): Promise<{ data: { link: string } }> => {
    return new Promise((resolve, reject) => {
        handleFileUpload(
            {target: {files: [file]}} as unknown as ChangeEvent<HTMLInputElement>,
            (uploadedFile: File, imgUrl: string) => {
                resolve({data: {link: imgUrl}});
            },
            // @ts-ignore
            (setImageUrl: (url: string) => void) => {
            },
            false // Adjust the blog parameter as needed
        ).catch(error => reject(error));
    });
};