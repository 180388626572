import Heading from "../../common/header/Heading";
import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import TiktokPixel from "tiktok-pixel";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";

interface VSLChatContentProps {
    isLoggedIn: boolean
}

export default function VSLConversationsContentWithShortVideo(props: VSLChatContentProps) {
    const {isLoggedIn} = props;

    return <>
        <Heading customStyles='mb-6 text-center mw-md-4xl mx-auto'
                 title='Pełna płynność w języku obcym?!'
                 customTitleStyles='font-heading mb-4 fs-4 text-white'
                 customSubtitleStyles='text-warning fs-9 fw-medium'
                 subtitle='Twój partner do rozmowy, dostępny zawsze i wszędzie, 24/7!'/>
        <div className='mw-md-3xl mx-auto mb-8 position-relative'>
            <Vimeo
                className='h-100'
                video="https://player.vimeo.com/video/946252539"
                responsive={true}
                autoplay={true}
                dnt={true}
            />
        </div>
        <div className="mb-24 text-center mw-md-3xl mx-auto">
            <div className="row justify-content-center g-4 pt-2">
                <div className="col-12 col-md-auto">
                    <AnimatedLink
                        hashLink={true}
                        className="btn btn-xl fw-bold btn-warning w-100 text-success-light shadow"
                        onClick={() => {
                            TiktokPixel.track("ClickButton", {
                                "contents": [
                                    {
                                        "content_id": "conversations-vsl-cta-button-tutorial", // string. ID of the product. Example: "1077218".
                                        "content_name": "TutorialRedirect" // string. The name of the page or product. Example: "shirt".
                                    }
                                ],
                            }, {debug: true});
                            TraceService.addTrace(TraceType.BtnClick, 'conversations-vsl-cta-button-tutorial');
                        }}
                        to='#tutorial'>
                        Oglądam poradnik
                    </AnimatedLink>
                </div>
                <div className="col-12 col-md-auto">
                    <AnimatedLink
                        className="btn btn-xl fw-bold btn-success w-100 text-success-light shadow"
                        onClick={() => {
                            localStorage.setItem('redirectUrlAfterRegistration', '/konwersacje')
                            TiktokPixel.track("ClickButton", {
                                "contents": [
                                    {
                                        "content_id": "conversations-vsl-cta-button-register-1", // string. ID of the product. Example: "1077218".
                                        "content_name": "RegisterRedirect" // string. The name of the page or product. Example: "shirt".
                                    }
                                ],
                            }, {debug: true});
                            TraceService.addTrace(TraceType.BtnClick, 'conversations-vsl-cta-button-register-1');
                        }}
                        to={isLoggedIn ? '/konwersacje' : '/rejestracja'}>
                        Testuję już teraz
                    </AnimatedLink>
                </div>
            </div>
        </div>

        <div className='pt-24'>
            <p className='fs-8 text-white mb-12 text-center fw-bold'>19.05: Dodaliśmy wsparcie dla niemieckiego,
                hiszpańskiego i francuskiego!</p>
            <VideoWithPlayButton vimeoVideoId="945847110" id='tutorial' onPlay={() => {
                TraceService.addTrace(TraceType.PlayVideo, 'conversations-tutorial');
            }}/>
        </div>
        <div className='text-center mw-md-2xl mx-auto pt-3'>
            <AnimatedLink
                className="mx-auto mw-md-2xl btn btn-xl fw-bold btn-success w-100 text-success-light shadow"
                onClick={() => {
                    localStorage.setItem('redirectUrlAfterRegistration', '/konwersacje')
                    TiktokPixel.track("ClickButton", {
                        "contents": [
                            {
                                "content_id": 'conversations-vsl-cta-button-register-2', // string. ID of the product. Example: "1077218".
                                "content_name": "RegisterRedirect" // string. The name of the page or product. Example: "shirt".
                            }
                        ],
                    }, {debug: true});
                    TraceService.addTrace(TraceType.BtnClick, 'conversations-vsl-cta-button-register-2');
                }}
                to={isLoggedIn ? '/konwersacje' : '/rejestracja'}>
                Zaczynam rozmowę
            </AnimatedLink>
        </div>
    </>
}
