export class PurchaseOption {
    static readonly BASIC_CHAT_TOKENS = new PurchaseOption('BASIC_CHAT_TOKENS', 10.99, {tokens: 100}, "100 Tokenów");
    static readonly REGULAR_CHAT_TOKENS = new PurchaseOption('REGULAR_CHAT_TOKENS', 59.99, {tokens: 600}, "600 Tokenów");
    static readonly ADVANCED_CHAT_TOKENS = new PurchaseOption('ADVANCED_CHAT_TOKENS', 147.99, {tokens: 1500}, "1500 Tokenów");

    static readonly COURSE_KED_BASIC_ACCESS = new PurchaseOption('COURSE_KED_BASIC_ACCESS', 4897.00, {courseAccess: "KED"}, "Dostęp do Kursu Efektywnego Działania");

    static readonly EXTRAORDINARY_MONTHLY_SUPPORT = new PurchaseOption('EXTRAORDINARY_MONTHLY_SUPPORT', 3000.00, {extraordinarySupport: 1}, "MISTRZOWSKIE Wsparcie");

    static readonly COACHING_SESSION = new PurchaseOption('COACHING_SESSION', 397.00, {coachingSessions: 1}, "Indywidualne Konsultacje");

    private constructor(private readonly key: string, public readonly price: number, public readonly item: TransactionItem, public readonly description: string) {
    }

    toString() {
        return this.key
    }
}

type AvailableCourses = "KED"

export interface TransactionItem {
    tokens?: number
    courseAccess?: AvailableCourses
    coachingSessions?: number
    extraordinarySupport?: number
}