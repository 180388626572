import {motion} from "framer-motion";
import {formatDate} from "../../../util/DateUtil";
import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {UserCourse} from "../../../model/admin/UserCourse";
import {learningCourseStructure} from "../../../model/user/learning-course/LearningCourse";
import {
    calculateNumOfDaysFromStart,
    calculatePercentageOfDoneEpisodes,
    formatSecondsToHoursAndMinutes
} from "../../../util/KedUtil";

interface DiagnosticItemProps {
    kedUser: UserCourse;
    isSubmitting: boolean;
    unlockNextWeek: (userId: string) => void;
}

export default function AdminKedUserItem(props: DiagnosticItemProps) {
    const {kedUser, isSubmitting, unlockNextWeek} = props;
    return <>
        <motion.div className='col-12' key={kedUser.userId}
                    initial={{opacity: 0, scale: 0.5}}
                    animate={{opacity: 1, scale: 1}}>
            <div className='px-10 py-8 bg-light-dark rounded shadow'>
                <div className='row justify-content-between g-4'>
                    <div className='col d-flex flex-column justify-content-center'>
                        <h3 className='mb-1 font-heading fs-9 text-white'>
                            {kedUser.name + " - " + kedUser.email}
                        </h3>
                        <h6 className='mb-1 text-white fs-12'>
                            Dołączył/a: {formatDate(kedUser.boughtAt)} ({calculateNumOfDaysFromStart(kedUser.boughtAt)} dni
                            temu)
                        </h6>
                        <p className='mb-0 fw-medium text-secondary-light'
                           style={{fontSize: '16px'}}>
                            Kliknij aby zobaczyć szczegóły...
                        </p>
                        <div>

                                <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Odblokowano: {kedUser.weeksUnlocked}/{learningCourseStructure.length - 1}
                                </span>
                            <span
                                className='badge bg-success text-white text-uppercase shadow mt-1'
                                style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Czas oglądania: {formatSecondsToHoursAndMinutes(kedUser.totalWatchTimeInSeconds)}
                                </span>
                            <span
                                className='badge bg-success text-white text-uppercase shadow mt-1'
                                style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Przerobiono: {calculatePercentageOfDoneEpisodes(kedUser.episodesCompleted)}%
                                </span>

                        </div>
                    </div>
                    <div className='col-12 col-md-auto mt-6'>
                        <AnimatedButton
                            className='btn btn-lg btn-success w-100 text-success-light shadow'
                            onClick={() => unlockNextWeek(kedUser.userId)}
                            disabled={isSubmitting || kedUser.weeksUnlocked >= learningCourseStructure.length - 1}
                        >
                            Odblokuj kolejny tydzień
                        </AnimatedButton>
                    </div>
                </div>
            </div>
        </motion.div>
    </>
}