import Heading from "../../../components/common/header/Heading";
import MainDesign from "../../MainDesign";
import Meta from "../../../components/common/Meta";
import React, {Suspense} from "react";
import Categories, {Category} from "../../../components/ui/category/Categories";
import {Await, defer, useLoaderData} from "react-router-dom";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import {useCategory} from "../../../hooks/useCategory";
import {DiagnosticItemsModel} from "../../../model/admin/Diagnostic";
import {DiagnosticService} from "../../../service/DiagnosticService";
import DiagnosticItems from "../../../components/admin/diagnostic/DiagnosticItems";

type LoaderData = {
    diagnostics: Promise<DiagnosticItemsModel>
}

const categories = [
    {
        displayName: "Do przejrzenia",
        value: "UNREVIEWED"
    },
    {
        displayName: 'Wszystkie',
        value: 'ALL'
    },
    {
        displayName: 'Lambda',
        value: "LAMBDA"
    },
    {
        displayName: 'Frontend',
        value: "FRONTEND"
    },
] as Category[]

export default function AdminDiagnosticPage() {

    const {diagnostics} = useLoaderData() as LoaderData;
    const {selectedCategory, onCategoryChange} = useCategory(categories);

    return <>
        <Meta title='Dane diagnostyczne'/>
        <MainDesign containerStyles='container py-20'>
            <Heading title='Dane diagnostyczne' badgeText='Diagnostyka'
                     subtitle='Zobacz, czy wszystko działa prawidłowo i w razie potrzeby znajdź przyczynę problemu.'/>
            <div className='mw-4xl mx-auto'>
                <Categories
                    categories={categories}
                    selectedCategory={selectedCategory} onCategoryChange={onCategoryChange}/>
                <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                    <Await resolve={diagnostics} errorElement={<ErrorContent/>}>
                        {(loadedDiagnostics) => <>
                            <DiagnosticItems diagnostics={loadedDiagnostics} category={selectedCategory.value}/>
                        </>}
                    </Await>
                </Suspense>
            </div>

        </MainDesign>
    </>
}

async function loadDiagnostics(category: string): Promise<DiagnosticItemsModel> {
    return await DiagnosticService.getDiagnostics(undefined, category)
}

export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const category = url.searchParams.get('category') ?? 'unreviewed';

    return defer({
        diagnostics: loadDiagnostics(category)
    })
}