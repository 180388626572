import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import CalendlyContent from "../../../components/user/offer/CalendlyContent";

export default function CalendlyScheduleKedCallPage() {
    return <>
        <Meta title='Umów się na indywidualne spotkanie'/>
        <MainDesign containerStyles='container py-28'>
            <CalendlyContent
                calendlyUrl='https://calendly.com/produkacja/twoj-indywidualny-plan-dzialania'
                vslVimeoId='976566334'
                isMaturaParent={false}
                headingTitle='Działaj efektywniej i osiągaj więcej.'/>
        </MainDesign>
    </>
}
