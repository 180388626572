import Message from "./Message";
import React, {useEffect, useRef} from "react";
import {Conversation as ConversationEntity} from "../../../../model/user/ai/Conversation";
import {Model} from "../../../../model/common/Model";

interface ConversationProps {
    conversation: ConversationEntity[];
    selectedModel: Model | null;
    userName: string;
    speakingConversation: boolean;
    isRecording?: boolean;
}

export default function Conversation(props: ConversationProps) {
    const {conversation, selectedModel, userName, speakingConversation, isRecording} = props;

    const messagesContainerRef = useRef<HTMLDivElement>(null);

    // Create a dependency that changes whenever the content of the conversation changes
    const conversationContentSignature = conversation.map(msg => msg.content.length).join(',');
    useEffect(() => {
        if (messagesContainerRef.current) {
            const timer = setTimeout(() => {
                messagesContainerRef.current!!.scrollTop = messagesContainerRef.current!!.scrollHeight;
            }, 20); // A delay of 0 ms effectively pushes the execution to the end of the call stack
            return () => clearTimeout(timer); // Cleanup the timeout when the component unmounts or re-renders
        }
    }, [conversation, conversationContentSignature]);

    return <div className="d-flex flex-column align-items-left text-small bg-secondary-dark p-2 rounded-4">
        {/* Messages */}
        <div ref={messagesContainerRef} className='mt-3'
             style={{overflowY: "auto", maxHeight: "800px"}}>
            {conversation.map((msg, index) => (
                <Message key={index} conversation={msg} userName={userName} selectedModel={selectedModel}
                         speakingConversation={speakingConversation} isRecording={isRecording}/>
            ))}
        </div>
    </div>
}
