import Heading from "../../common/header/Heading";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {MW_FULL_FUNNEL_EXPERIMENT_2} from "../../../util/ExperimentUtil";
import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BubbleNotificationWithPlaceholders} from "../../ui/motion/BubbleNotificationWithPlaceholders";
import AnimatedLink from "../../ui/motion/AnimatedLink";


export default function FunnelMWParentVSL() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const variation = searchParams.get('variation');
    const source = searchParams.get('source');

    let isButtonVisible = searchParams.get('buttonVisible') || searchParams.get('email');

    const [buttonVisible, setButtonVisible] = React.useState(!!isButtonVisible);

    return <>
        <Heading customStyles='mb-0 text-center mw-md-3xl mx-auto'
                 customTitleStyles='font-heading mb-4 fs-5 text-white'
                 title='Najłatwiejszy sposób na zostanie NAJLEPSZYM uczniem'
                 badgeText='BEZPŁATNE SZKOLENIE'
        />
        <div className='mw-md-5xl mx-auto'>
            <div className='mw-md-3xl mx-auto mb-8 position-relative'>
                <VideoWithPlayButton vimeoVideoId='1027390523' id='vsl-ked-matura-parent'
                                     trackStatistics={true}
                                     callAfterTime={{
                                         time: 30,
                                         action: () => {
                                             setButtonVisible(true)
                                             const newSearchParams = new URLSearchParams(searchParams);
                                             newSearchParams.set('buttonVisible', 'true');
                                             navigate({
                                                 pathname: window.location.pathname,
                                                 search: `?${newSearchParams.toString()}`
                                             }, {replace: true});
                                         }
                                     }}
                                     onPlay={() => {
                                         TraceService.addTraceWithExperiment(TraceType.PlayVideo, MW_FULL_FUNNEL_EXPERIMENT_2, variation, source)
                                         TraceService.addTrace(TraceType.PlayVideo, `vsl-mw`)
                                     }}/>
            </div>
            <div className='text-center my-16'>
                {
                    buttonVisible && <>
                        <div className='px-3'>
                            <AnimatedLink className='btn btn-lg btn-success'
                                          to={`/mistrzowskie-wsparcie/spotkanie/umow?${searchParams.toString()}`}
                                          onClick={() => {
                                              TraceService.addTrace(TraceType.BtnClick, 'btn-vsl-mw -> Umów się na spotkanie');
                                          }}>
                                Umów się na indywidualną konsultację
                            </AnimatedLink>
                        </div>
                    </>
                }

            </div>
            <BubbleNotificationWithPlaceholders
                message={`{name} umówił/a się {time} minut temu`}
                delay={isButtonVisible ? 3000 : 60000}      // Show after 3 or 60 seconds
                duration={5000}   // Hide after 5 seconds
            />
        </div>
    </>
}