import React from 'react';
import AnimatedButton from "../../../ui/motion/AnimatedButton";

interface GetTranscriptionProps {
    manual: boolean;
    isRecording: boolean;
    isResponsePlaying: boolean;
    isFetchingResponse: boolean;
    isLoading: boolean;
    generateTranscript: () => Promise<void>;
    endTranscription: (event?: React.MouseEvent<HTMLButtonElement>, cancel?: boolean) => Promise<void>;
    stopResponsePlaying: () => void;
    threadId: string | null;
    newThread: () => void;
}

export default function GetTranscription(props: GetTranscriptionProps) {

    const {
        manual,
        isLoading,
        isRecording,
        isResponsePlaying,
        isFetchingResponse,
        generateTranscript,
        endTranscription,
        stopResponsePlaying,
        threadId,
        newThread
    } = props;

    async function cancelTranscription(event?: React.MouseEvent<HTMLButtonElement>) {
        await endTranscription(event, true);
    }

    return (
        <>
            <div className='text-center mw-lg mx-auto'>
                {isRecording ? (
                    <>
                        <AnimatedButton
                            className='btn btn-lg btn-success fs-11 mx-4 py-3 px-5 text-success-light shadow d-inline-block mt-2'
                            type='button' onClick={endTranscription} disabled={!manual}>
                            Zakończ wypowiedź
                        </AnimatedButton>
                        <AnimatedButton
                            className='btn btn-lg btn-danger fs-11 py-3 px-5 text-success-light shadow d-inline-block mt-2'
                            type='button' onClick={cancelTranscription}>
                            Anuluj
                        </AnimatedButton>
                    </>
                ) : (
                    <>
                        <AnimatedButton
                            className='btn btn-lg btn-success fs-11 mx-4 py-3 px-5 text-success-light shadow mt-2'
                            type='button' onClick={generateTranscript}
                            disabled={isResponsePlaying || isLoading}
                        >
                            Rozpocznij konwersację
                        </AnimatedButton>
                        {
                            isResponsePlaying && <AnimatedButton
                                className='btn btn-lg btn-danger fs-11 py-3 px-5 text-success-light shadow d-inline-block mt-2'
                                type='button' onClick={stopResponsePlaying} disabled={isLoading || isFetchingResponse}>
                                Anuluj
                            </AnimatedButton>
                        }
                        {
                            !isResponsePlaying && threadId && <AnimatedButton
                                className='btn btn-lg btn-warning fs-11 py-3 px-5 text-success-light shadow d-inline-block mt-2'
                                type='button' onClick={newThread} disabled={isLoading || isFetchingResponse}>
                                Nowy wątek
                            </AnimatedButton>
                        }
                    </>
                )}
            </div>
        </>
    );
}