import Heading from "../../../../common/header/Heading";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";
import ChatHowItWorksItem from "./ChatHowItWorksItem";
import StaggerChildrenWhenVisible from "../../../../ui/motion/StaggerChildrenWhenVisible";
import SlideInWhenVisible from "../../../../ui/motion/SlideInWhenVisible";
import {TraceService} from "../../../../../service/TraceService";
import {TraceType} from "../../../../../model/user/general/Trace";
import VideoWithPlayButton from "../../../../ui/video/VideoWithPlayButton";


interface ChatHowItWorksProps {
    isLoggedIn: boolean
}

export default function ChatHowItWorks(props: ChatHowItWorksProps) {
    const {isLoggedIn} = props
    return <>
        <div className='pt-8 mb-20'>
            <div className='mb-16 row align-items-center g-16'>
                <div className='col-12 col-md-6'>
                    <div className='row justify-content-left'>
                        <div className='position-relative'>
                            <div
                                className='overflow-hidden rounded-5'
                                style={{maxWidth: 554, height: 312}}
                            >
                                <VideoWithPlayButton vimeoVideoId='1014049306' id='chat-how-it-works-video'
                                                     onPlay={() => {
                                                         TraceService.addTrace(TraceType.PlayVideo, 'chat-how-it-works-video')
                                                     }}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <Heading title='Jak to działa?' customStyles='mw-md-xl' badgeText='Działanie'
                             subtitle='Usługa chatu w zależności od wybranego modelu, zapewni Ci dostęp do wiedzy,
                             pomoc w nauce oraz ułatwi pisanie tekstów anglojęzycznych.'/>
                    <div className="row justify-content-left mt-4 g-4">
                        <div className="col-12 col-md-auto">
                            <SlideInWhenVisible initialX={100} duration={0.4}>
                                <AnimatedLink hashLink={true}
                                              onClick={() => {
                                                  TraceService.addTrace(TraceType.BtnClick, 'btn-3 -> zobacz dostępne modele')
                                              }}
                                              className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                              to="#modele">Zobacz dostępne modele</AnimatedLink>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row g-8'>
                <StaggerChildrenWhenVisible>
                    <ChatHowItWorksItem count={1} title='Zaloguj się na Platformę'
                                        subtitle='Stwórz konto w kilka sekund i uzyskaj dostęp do wszystkich funkcjonalności.'/>
                    <ChatHowItWorksItem count={2} title='Wybierz interesujący Cię model'
                                        subtitle='Na tą chwilę oferujemy 3 różne rodzaje modeli, z których każdy skupi się na czymś innym.
                            Korepetytor, baza wiedzy, a może edytor tekstów?'/>
                    <ChatHowItWorksItem count={3} title='Gotowe' subtitle='Zacznij korzystać już teraz! Z darmowymi Tokenami testuj możliwości naszego
                            rozwiązania.'/>
                </StaggerChildrenWhenVisible>
            </div>
            <div className="row justify-content-center g-4 pt-12 pb-8">
                <div className="col-12 col-md-auto">
                    <SlideInWhenVisible initial={{y: 100, opacity: 0, scale: 0.5}} initialX={0} duration={0.5}>
                        <AnimatedLink
                            className="btn btn-lg btn-success w-100 text-success-light shadow"
                            onClick={() => {
                                localStorage.setItem('redirectUrlAfterRegistration', '/chat')
                                TraceService.addTrace(TraceType.BtnClick, 'btn-4 -> przetestuj za darmo już teraz')
                            }}
                            to={isLoggedIn ? '/chat' : '/rejestracja'}>
                            Przetestuj za darmo już teraz
                        </AnimatedLink>
                    </SlideInWhenVisible>
                </div>
            </div>
        </div>
    </>
}