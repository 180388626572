import {parseTransactionStatus, Transaction} from "../../../model/user/purchase/Transaction";
import {formatISODateString} from "../../../util/DateUtil";

interface TransactionListProps {
    transactions: Transaction[]
}

export default function TransactionList(props: TransactionListProps) {
    const {transactions} = props;
    return <div style={{overflowX: "auto"}} className='text-center'>
        <table className="table bg-light-dark text-white px-1 text-center mw-6xl mx-auto fs-10"
               style={{boxSizing: "unset", overflowX: "visible"}}>
            <thead>
            <tr>
                <th scope="col">Data</th>
                <th scope="col">Nazwa</th>
                <th scope="col">Cena</th>
                <th scope="col">Status</th>
            </tr>
            </thead>
            <tbody>
            {
                transactions.map((transaction) => (
                    <tr key={transaction.id}>
                        <th scope="row">{formatISODateString(transaction.createdAt)}</th>
                        <td>{transaction.description}</td>
                        <td>{transaction.price}zł</td>
                        <td>{parseTransactionStatus(transaction.status)}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    </div>
}