import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import React, {Suspense} from "react";
import {Await, defer, useLoaderData} from "react-router-dom";
import {CourseService} from "../../../service/CourseService";
import {UserCourse} from "../../../model/admin/UserCourse";
import AdminKedUsers from "../../../components/admin/ked/AdminKedUsers";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import Heading from "../../../components/common/header/Heading";
import AdminKedNavbar from "../../../components/admin/ked/AdminKedNavbar";

interface LoaderData {
    kedUsers: Promise<UserCourse[]>
}

export default function AdminKedUsersPage() {
    const {kedUsers} = useLoaderData() as LoaderData;

    return <>
        <Meta title='KED - Oferta'/>
        <MainDesign containerStyles='container pt-20'>
            <Heading title='Zapisani do KED' badgeText='kursanci'/>
            <AdminKedNavbar/>
            <hr className='text-white'/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={kedUsers} errorElement={<ErrorContent/>}>
                    {(loadedKedUsers) => <>
                        <AdminKedUsers kedUsers={loadedKedUsers}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadKedUsers(): Promise<UserCourse[]> {
    return await CourseService.getKedUsers();
}

export async function loader() {

    return defer({
        kedUsers: loadKedUsers()
    })
}