import React from "react";
import Heading from "../../common/header/Heading";

export default function CoachingSessionSuccessfulPurchase() {
    return <div className='mw-md-4xl mx-auto text-center mt-16'>
        <Heading title='Działaj efektywniej i osiągaj więcej.'
                 customTitleStyles='fs-6 fw-bold text-success'
        />
        <img className='img-fluid mb-10'
             src='https://produkacja.s3.eu-central-1.amazonaws.com/web/konsultacje/konsultacje-thank-you.jpg'
             alt='dziękuję za umówienie się'/>

    </div>
}