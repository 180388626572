import {MathJax, MathJaxContext} from "better-react-mathjax";
import {AnimatePresence, motion} from "framer-motion";
import React, {ReactNode} from "react";
import {renderContentWithPlaceholders} from "../../../util/Util";
import AnimatedButton from "../../ui/motion/AnimatedButton";

interface ExerciseSolutionProps {
    showSolution: boolean;
    setShowSolution: React.Dispatch<React.SetStateAction<boolean>>
    headerTitle?: string;
    solution?: string | undefined;
    jsxSolution?: ReactNode | undefined;
}

export default function ExerciseSolution(props: ExerciseSolutionProps) {
    const {showSolution, setShowSolution, headerTitle, solution, jsxSolution} = props;

    const solutionWithPlaceholders = renderContentWithPlaceholders(solution);

    function toggleShowSolution() {
        setShowSolution(!showSolution);
    }

    return <>
        <div className='text-center mw-sm mx-auto'>
            <AnimatedButton
                className='btn btn-lg btn-success text-lg-center fs-11 w-100 py-3 px-4 h-100 shadow'
                type="button"
                onClick={toggleShowSolution}
            >
                {showSolution ? "Ukryj rozwiązanie" : "Pokaż rozwiązanie"}
            </AnimatedButton>
        </div>
        <AnimatePresence>
            {
                showSolution &&
                <motion.div className='col-12 mt-4' initial={{opacity: 0, y: 100}} animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: 100}}>
                    <div className='p-1 mw-5xl mx-lg-auto'>
                        {
                            headerTitle && <h2
                                className='font-heading mb-2 mt-12 fs-4 text-white text-center'
                                style={{letterSpacing: '-1px'}}
                            >
                                {headerTitle}
                            </h2>
                        }
                        <div
                            className='font-heading mb-4 fs-8 text-white p-1'
                            style={{letterSpacing: '-1px'}}
                            key={solution}
                        >
                            <MathJaxContext>
                                {
                                    jsxSolution && (<>{jsxSolution}</>) || (
                                        <MathJax>
                                            {solutionWithPlaceholders}
                                        </MathJax>)
                                }
                            </MathJaxContext>
                        </div>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    </>
}