import {NavLink} from "react-router-dom";
import React from "react";
import {motion} from "framer-motion";

interface NavLinkItemProps {
    mainPage?: boolean,
    liStyles?: string,
    burgerMenu?: boolean
    title: string,
    to: string,
    onClick?: () => void;
}

const linkInactive = 'nav-link p-0 link-secondary-light';
const linkActive = 'nav-link p-0 link-light-light';

const linkActiveBurger = 'nav-link link-light-light bg-light-dark';
const linkInactiveBurger = 'nav-link link-secondary-light';

export default function NavLinkItem(props: NavLinkItemProps) {
    const {mainPage, liStyles, burgerMenu, title, to, onClick} = props;
    return <motion.li className={`nav-item ${liStyles ? liStyles : ''}`} whileHover={{scale: 1.1}}>
        <NavLink
            className={({isActive}) => (isActive ? (burgerMenu ? linkActiveBurger : linkActive) : (burgerMenu ? linkInactiveBurger : linkInactive))}
            onClick={onClick}
            to={to}
            end={mainPage}>
            {title}
        </NavLink>
    </motion.li>
}