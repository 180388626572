import Meta from "../../../../components/common/Meta";
import MainDesign from "../../../MainDesign";
import ChatPricing from "../../../../components/user/ai/pricing/chat/ChatPricing";
import ChatFeatures from "../../../../components/user/ai/pricing/chat/ChatFeatures";
import ChatHowItWorks from "../../../../components/user/ai/pricing/chat/ChatHowItWorks";
import ChatHeader from "../../../../components/user/ai/pricing/chat/ChatHeader";
import ChatBenefits from "../../../../components/user/ai/pricing/chat/ChatBenefits";
import ChatModels from "../../../../components/user/ai/pricing/chat/ChatModels";
import ChatFAQ from "../../../../components/user/ai/pricing/chat/ChatFAQ";
import {DeferredData} from "@remix-run/router/dist/utils";
import {defer, useLoaderData} from "react-router-dom";
import {isLoggedIn} from "../../../../util/AuthUtil";

type LoaderData = {
    isLoggedIn: boolean;
};

export default function ChatLandingPage() {
    const {isLoggedIn} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Sztuczna inteligencja'/>
        <MainDesign containerStyles='container py-28'>
            <ChatHeader isLoggedIn={isLoggedIn}/>
            <ChatFeatures/>
            <ChatHowItWorks isLoggedIn={isLoggedIn}/>
            <ChatBenefits isLoggedIn={isLoggedIn}/>
            <ChatModels/>
            <ChatPricing/>
            <ChatFAQ/>
        </MainDesign>
    </>
}

export async function loader(): Promise<DeferredData> {
    return defer({
        isLoggedIn: await isLoggedIn(),
    });
}
