import React from "react";

interface ImageWithDotsAndCircleProps {
    src: string
}

export default function ImageWithDotsAndCircle(props: ImageWithDotsAndCircleProps) {
    const {src} = props;
    return <div className='d-flex justify-content-end ps-4 ps-md-0'>
        <div className='position-relative'>
            <img
                className='position-absolute end-0 top-0'
                style={{marginTop: "-5.9rem"}}
                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/dots-horizontal-green.svg'
                alt=''
            />
            <img
                className='position-absolute end-0 bottom-0'
                style={{marginBottom: "-5.9rem"}}
                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/dots-horizontal-green.svg'
                alt=''
            />
            <img
                className='position-absolute top-50 translate-middle-y'
                style={{width: '41%', left: '-75px'}}
                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/circle-xxl-orange.svg'
                alt=''
            />
            <img
                className='position-relative img-fluid rounded'
                style={{zIndex: 1, maxHeight: "600px"}}
                src={src}
                alt=''
            />
        </div>
    </div>
}