import React, {useState} from 'react';
import Meta from "../../../components/common/Meta";
import NewsletterForm, {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import MainDesign from "../../MainDesign";
import ExerciseContent from "../../../components/user/exercise/ExerciseContent";
import ExerciseSolution from "../../../components/user/exercise/ExerciseSolution";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import SlideInWhenVisible from "../../../components/ui/motion/SlideInWhenVisible";
import ImageWithDotsAndCircle from "../../../components/ui/img/ImageWithDotsAndCircle";

export default function DailyMathExercises() {
    const [showSolution, setShowSolution] = useState(false);

    return <>
        <Meta title='Zadania'/>
        <MainDesign containerStyles="py-20 py-md-44">
            <div className='row align-items-center g-16'>
                <div className='col-12 col-md-7'>
                    <div className='p-2 mw-xl-xl mx-xl-auto'>
                        <h1
                            className='font-heading mb-4 fs-5 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Codzienna praktyka
                        </h1>
                        <p className='mb-8 fs-8 fw-medium text-secondary-light'>
                            Podaj swój adres email i otrzymuj codzienne zadania z matematyki
                        </p>
                        <NewsletterForm type={NewsletterType.DailyMathExercises}/>
                        <h2
                            className='font-heading mb-2 mt-12 fs-6 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Jak to działa?
                        </h2>
                        <p className='mb-2 fs-10 fw-medium text-secondary-light'>
                            Codziennie o godzinie 17:00 otrzymasz nowe zadanie. Będzie ono miało pod spodem
                            rozwiązanie, aby można było zweryfikować swoją odpowiedź. <br/> Zadania są na poziomie
                            matury podstawowej. Zobacz przykład poniżej.
                        </p>
                        <div className='mt-0 mb-0 row g-6'>
                            <div className='col-auto'>
                                <div
                                    className='bg-success h-100 rounded-pill'
                                    style={{width: 2}}
                                />
                            </div>
                            <div className='col'>
                                <div className='py-7'>
                                    <p className='mb-4 fs-8 text-light fw-medium'>
                                        Success is the sum of small efforts, repeated day in and day out.
                                    </p>
                                    <p className='mb-0 fs-10 text-secondary-light fw-medium'>
                                        — Robert Collier, amerykański autor
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-5'>
                    <ImageWithDotsAndCircle
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/newsletter/happy-student.webp'/>
                </div>
                <SlideInWhenVisible initialX={100} duration={0.5}>
                    <div className='col-12'>
                        <div className='p-1 mw-4xl mx-lg-auto'>
                            <h2
                                className='font-heading mb-2 mt-12 fs-4 text-white  text-center'
                                style={{letterSpacing: '-1px'}}
                            >
                                Przykładowe zadanie
                            </h2>
                            <ExerciseContent
                                content={"Rozwiąż nierówność wymierną \\(\\frac{2}{x+1} \\ge 12\\). Pamiętaj o wyznaczeniu dziedziny."}
                                divStyles=""/>
                        </div>
                    </div>

                </SlideInWhenVisible>

                <ExerciseSolution showSolution={showSolution} setShowSolution={setShowSolution}
                                  headerTitle="Rozwiązanie" jsxSolution={
                    <>
                        <MathJaxContext>
                            <MathJax>
                                {"Rozwiązaniem nierówności \\(\\frac{2}{x+1} \\ge 12\\) jest \\(x \\in (-1, -\\frac{5}{6}]\\). Zobacz, jak do tego dojść krok po kroku."}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"1. Wyznaczamy dziedzinę: \\(x+1 \\neq 0\\). Po przekształceniu otrzymujemy \\(x \\neq -1\\)"}
                            </MathJax>
                            <MathJax className="mt-3 ">
                                {"2. Mamy teraz dwie możliwości. Albo sprowadzamy wszystko do wspólnego mianownika \\(x+1\\), albo mnożymy stronami przez \\((x+1)^2\\). Ja skorzystam z drugiej opcji."}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"Mnożymy przez \\((x+1)^2\\) żeby mieć pewność, że mnożymy przez coś nieujemnego. Przy nierównościach trzeba na to uważać, ponieważ w przypadku mnożenia przez coś ujemnego, zmienia się nam znak nierówności."}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"3. Otrzymujemy: \\[2(x+1) \\ge 12(x+1)^2 \\] " +
                                    "\\[2x+2 \\ge 12(x^2+2x+1) \\]" +
                                    "\\[2x+2 \\ge 12x^2+24x+12 \\]" +
                                    "\\[-12x^2 -22x -10 \\ge 0 \\]"}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"Pomnóżmy teraz stronami przez \\(-\\frac{1}{2}\\) pamiętając o zmianie znaku nierówności."}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"Otrzymujemy: \\[6x^2 + 11x + 5 \\le 0 \\] "}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"4. Ponownie, mamy kilka sposobów na rozwiązanie nierówności kwadratowej. Najbardziej popularną opcją będzie po prostu policzenie delty. " +
                                    "Ja jednak skorzystam z innej opcji i zastosuję technikę grupowania."}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"Odpowiednio przekształcając, otrzymujemy: \\[6x^2 + 6x + 5x + 5 \\le 0 \\] " +
                                    "\\[6x(x + 1) + 5(x + 1) \\le 0 \\] " +
                                    "\\[(6x + 5)(x + 1) \\le 0 \\]"}
                            </MathJax>
                            <MathJax className="mt-3">
                                {"Miejscami zerowymi są \\(x = -\\frac{5}{6}\\) oraz \\(x = -1\\) Ramiona funkcji kwadratowej są skierowane do góry, " +
                                    "oraz szukamy rozwiązań mniejszych lub równych zero. Biorąc pod uwagę, że \\(-1 \\notin D\\)  nasze rozwiązanie jest następujące:" +
                                    "\\[x \\in (-1, -\\frac{5}{6}]\\]"}
                            </MathJax>
                        </MathJaxContext>
                    </>
                }/>
            </div>
        </MainDesign>
    </>
}



