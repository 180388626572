import React, {useEffect, useState} from "react";
import {NewsletterType} from "../../user/newsletter/NewsletterForm";
import {NewsletterEntity} from "../../../model/user/general/Newsletter";
import {NewsletterService} from "../../../service/NewsletterService";
import CustomSelect from "../../ui/form/CustomSelect";

export default function MailingListTable() {
    const [selectedMailingList, setSelectedMailingList] = useState<NewsletterType>(NewsletterType.MWOptInParent);
    const [mailingList, setMailingList] = useState<NewsletterEntity[]>([]);
    const options = Object.keys(NewsletterType)

    useEffect(() => {
        NewsletterService.getMailingList(selectedMailingList).then(setMailingList)
    }, [selectedMailingList]);

    return <>
        <div className=' mw-md-md mx-auto my-10'>
            <p className='text-white fw-bold mb-1'>Wybierz listę mailingową</p>
            <CustomSelect name='mailingList' value={selectedMailingList}
                          onChange={(event) => setSelectedMailingList(event.target.value as NewsletterType)}
                          customStyles={'select-md'}>
                {
                    options.map(model => (
                        <option value={model} key={model}>{model}</option>
                    ))
                }
            </CustomSelect>
        </div>

        <div style={{overflowX: "auto"}} className='text-center'>
            <table className="table bg-light-dark text-white px-1 text-center mw-6xl mx-auto fs-10 rounded"
                   style={{boxSizing: "unset", overflowX: "visible"}}>
                <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Imię</th>
                    <th scope="col">Data dołączenia</th>
                    <th scope="col">Źródło</th>
                    <th scope="col">Wysłano FU (suma)</th>
                    <th scope="col">Ostatnio wysłano FU (data)</th>
                    <th scope="col">Otwarto FU (suma)</th>
                    <th scope="col">Kliknięto FU (suma)</th>
                </tr>
                </thead>
                <tbody>
                {
                    mailingList.map((trace: NewsletterEntity) => (
                        <tr key={trace.email}>
                            <th scope="row">{trace.email}</th>
                            <td>{trace.name ?? 'unknown'}</td>
                            <td>{trace.joinedAt}</td>
                            <td>{trace.source}</td>
                            <td>{trace.lastSentFollowUpEmail}</td>
                            <td>{trace.lastSentFollowUpEmailAt}</td>
                            <td>{trace.openedFollowUpsNum}</td>
                            <td>{trace.clickedFollowUpsNum}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    </>
}