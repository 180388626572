import {ApiError, ContentType, HttpMethod, HttpService} from "./HttpService";
import {autoSignIn, signIn, SignInOutput, signInWithRedirect, signOut, signUp, SignUpOutput} from "aws-amplify/auth";
import {InitRegistrationRequest, LoginRequest} from "../model/auth/InitRegistrationRequest";
import {ActivateAccount} from "../model/auth/ActivateAccount";
import {UserToActivate} from "../model/auth/UserToActivate";

type RegistrationSuccessResponse = {
    statusCode: number;
    message: string;
}

type ChangePasswordRequest = {
    newPassword: string;
    resetPasswordCode: string;
}

type RegistrationResponse = RegistrationSuccessResponse | ApiError;

export class AuthService {

    public static initRegistration(requestBody: InitRegistrationRequest): Promise<RegistrationResponse> {
        return HttpService.sendRequest<RegistrationSuccessResponse>(
            HttpMethod.POST,
            '/api/signup',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static fetchUserDataForRegistration(uuid: string): Promise<UserToActivate> {
        return HttpService.sendRequest<UserToActivate>(
            HttpMethod.GET,
            `/api/activate/${uuid}`,
            null,
            ContentType.JSON
        );
    }

    public static async activateAccount(activateAccountRequest: ActivateAccount): Promise<SignUpOutput> {
        return signUp({
            username: activateAccountRequest.email.toLowerCase(),
            password: activateAccountRequest.password,
            options: {
                userAttributes: {
                    email: activateAccountRequest.email.toLowerCase(),
                    name: activateAccountRequest.name,
                    "custom:promoCode": activateAccountRequest.promoCode ?? '',
                    "custom:confirmationCode": activateAccountRequest.confirmationCode
                },
                autoSignIn: true
            }
        })
    }

    public static async autoSignIn(): Promise<SignInOutput> {
        return autoSignIn();
    }

    public static async login(loginRequest: LoginRequest): Promise<SignInOutput> {
        return signIn({
            username: loginRequest.email.toLowerCase(),
            password: loginRequest.password,
        });
    }


    public static async facebookLogin(): Promise<void> {
        await signInWithRedirect({provider: 'Facebook'})
    }

    public static resetPassword(email: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/reset-password',
            JSON.stringify({email: email}),
            ContentType.JSON
        );
    }

    public static changePassword(changePasswordRequest: ChangePasswordRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/change-password',
            JSON.stringify(changePasswordRequest),
            ContentType.JSON
        );
    }

    public static async signOut(): Promise<void> {
        return signOut();
    }
}