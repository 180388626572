import ExerciseDescription from "./ExerciseDescription";
import ExerciseContent from "./ExerciseContent";
import ExerciseSolution from "./ExerciseSolution";
import React, {useState} from "react";
import {Exercise} from "../../../model/user/exercise/Exercise";
import Meta from "../../common/Meta";
import BackButton from "../../ui/element/BackButton";
import ExerciseCreateAccountOffer from "./ExerciseCreateAccountOffer";
import FeedbackModal from "../feedback/FeedbackModal";
import ExerciseFeedbackButton from "./ExerciseFeedbackButton";

interface FullExerciseProps {
    exercise: Exercise,
    showSolution: boolean,
    setShowSolution: React.Dispatch<React.SetStateAction<boolean>>,
    isLoggedIn: boolean
    showBackButtonToAdminPanel: boolean
    backButtonDisabled: boolean
}

export default function FullExercise(props: FullExerciseProps) {
    const {
        exercise, showSolution, setShowSolution, isLoggedIn,
        showBackButtonToAdminPanel, backButtonDisabled
    } = props;

    const [feedbackMenuOpen, setFeedbackMenuOpen] = useState(false);

    return <>
        <Meta title={exercise.title}/>
        {
            isLoggedIn && <BackButton to='/lista-zadan' text='Powrót do listy zadań'
                                      onClick={backButtonDisabled ? e => e.preventDefault() : undefined}
                                      secondButtonDisplayed={showBackButtonToAdminPanel} secondButtonTo='/admin/zadania'
                                      secondButtonText='Powrót do panelu admina'/>
        }
        <ExerciseDescription title={exercise.title} subject={exercise.subject} level={exercise.level}
                             field={exercise.field}/>
        <ExerciseContent content={exercise.content} divStyles="mt-20 mb-10 text-center mw-md-4xl mx-auto"/>
        <ExerciseSolution showSolution={showSolution} setShowSolution={setShowSolution}
                          solution={exercise.solution}/>

        {
            showSolution && <ExerciseFeedbackButton setOpen={setFeedbackMenuOpen}/>
        }

        {
            showSolution && !isLoggedIn && <ExerciseCreateAccountOffer/>
        }
        <FeedbackModal isOpen={feedbackMenuOpen} setOpen={setFeedbackMenuOpen} feedbackSource='EXERCISE'
                       additionalInfo={"Id zadania: " + exercise.id}/>
    </>
}