import React, {Suspense} from 'react';
import Meta from "../../components/common/Meta";
import {Await, defer, Params, redirect, useLoaderData} from "react-router-dom";
import ActivateAccountForm from "../../components/auth/ActivateAccountForm";
import {AuthService} from "../../service/AuthService";
import {DeferredData} from "@remix-run/router/dist/utils";
import LoadingPage from "../common/LoadingPage";
import {UserToActivate} from "../../model/auth/UserToActivate";
import MainDesign from "../MainDesign";
import ActivateAccountText from "../../components/auth/ActivateAccountText";
import ErrorContent from "../../components/common/error/ErrorContent";
import TiktokPixel from "tiktok-pixel";

type LoaderData = {
    userData: Promise<UserToActivate>;
};

export default function ActivateAccount() {
    const {userData} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Aktywacja konta'/>
        <MainDesign containerStyles="py-32 container">
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={userData} errorElement={<ErrorContent/>}>
                    {(loadedUserData: UserToActivate) => <>
                        <ActivateAccountText name={loadedUserData.name}/>
                        <ActivateAccountForm/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadUserData(uuid: string): Promise<UserToActivate> {
    return await AuthService.fetchUserDataForRegistration(uuid)
}

export function loader({params}: { params: Params<"uuid"> }): DeferredData {
    return defer({
        userData: loadUserData(params.uuid!!)
    });
}

export async function action({request, params}: { request: Request, params: Params<"uuid"> }) {
    const formData = await request.formData();
    const userToActivate = await AuthService.fetchUserDataForRegistration(params.uuid!!);
    const activateAccountRequest = {
        email: userToActivate.email,
        password: formData.get('password') as string,
        confirmPassword: formData.get('confirmPassword') as string,
        name: userToActivate.name,
        promoCode: formData.get('promoCode') as string,
        confirmationCode: params.uuid!!
    };

    if (activateAccountRequest.password !== activateAccountRequest.confirmPassword) {
        return {
            status: 422,
            body: 'Podane hasła różnią się!'
        }
    }

    let signupComplete = false;
    try {
        const {isSignUpComplete} = await AuthService.activateAccount(activateAccountRequest);
        signupComplete = isSignUpComplete;
    } catch (error) {
        const apiError = error as Error
        if (apiError.name === 'InvalidPasswordException') {
            if (apiError.message === 'Password did not conform with policy: Password not long enough') {
                return {
                    status: 422,
                    body: 'Podane hasło jest za krótkie! Minimum to 8 znaków.'
                }
            } else if (apiError.message === 'Password did not conform with policy: Password must have uppercase characters') {
                return {
                    status: 422,
                    body: 'Hasło musi mieć przynajmniej 1 małą, 1 dużą literę, oraz cyfrę.'
                }
            } else if (apiError.message === 'Password did not conform with policy: Password must have lowercase characters') {
                return {
                    status: 422,
                    body: 'Hasło musi mieć przynajmniej 1 małą, 1 dużą literę, oraz cyfrę.'
                }
            } else if (apiError.message === 'Password did not conform with policy: Password must have numeric characters') {
                return {
                    status: 422,
                    body: 'Hasło musi mieć przynajmniej 1 małą, 1 dużą literę, oraz cyfrę.'
                }
            } else if (apiError.message === 'Password did not conform with policy: Password must have symbol characters') {
                return {
                    status: 422,
                    body: 'Hasło musi mieć przynajmniej 1 znak specjalny!'
                }
            } else {
                return {
                    status: 422,
                    body: 'Wprowadzono niepoprawne hasło!'
                }
            }
        } else if (apiError.name === 'UsernameExistsException') {
            return {
                status: 409,
                body: 'Ten adres email jest już zarejestrowany!'
            }
        } else {
            return {
                status: 500,
                body: 'Wystąpił błąd podczas aktywacji konta.'
            }
        }
    }

    if (signupComplete) {
        const {isSignedIn} = await AuthService.autoSignIn();

        if (isSignedIn) {
            const redirectUrlAfterRegistration = localStorage.getItem('redirectUrlAfterRegistration') ?? '/'
            localStorage.removeItem('redirectUrlAfterRegistration');
            TiktokPixel.track("CompleteRegistration", {
                "contents": [
                    {
                        "content_id": "complete-registration", // string. ID of the product. Example: "1077218".
                        "content_name": "Registration" // string. The name of the page or product. Example: "shirt".
                    }
                ],
            }, {debug: true});

            return redirect(redirectUrlAfterRegistration);
        } else {
            return {
                status: 500,
                body: 'Pomyślnie utworzono konto. Aby korzystać z systemu, zaloguj się.'
            }
        }
    } else {
        return {
            status: 500,
            body: 'Wystąpił błąd podczas aktywacji konta.'
        }
    }
}
