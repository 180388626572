import {ContentType, HttpMethod, HttpService} from "../HttpService";
import {LoadThreadsAndInfo} from "../../model/user/ai/chat/LoadThreadsAndInfo";
import {LoadThreadMessages} from "../../model/user/ai/chat/LoadThreadMessages";
import {ChatRequest} from "../../model/user/ai/chat/ChatRequest";
import {ChatResponse} from "../../model/user/ai/chat/ChatResponse";
import {TraceService} from "../TraceService";
import {TraceType} from "../../model/user/general/Trace";

export class ChatService {

    // TODO: Move it to common
    public static getAllUserThreadsAndInfo(type: "chat" | "speaking"): Promise<LoadThreadsAndInfo> {
        return HttpService.sendRequest<LoadThreadsAndInfo>(
            HttpMethod.GET,
            `/api/threads/${type}`,
        );
    }

    public static getThreadMessages(threadId: string): Promise<LoadThreadMessages> {
        return HttpService.sendRequest<any>(
            HttpMethod.GET,
            `/api/thread/${threadId}/chat`,
        );
    }

    public static async askLlm(chatRequest: ChatRequest, callback: (data: ChatResponse) => void) {
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL!!}/llm`, {
            method: 'POST',
            body: JSON.stringify(chatRequest),
        });
        TraceService.addTrace(TraceType.RequestSend, `POST: /llm. ModelId: ${chatRequest.modelId}`);
        // @ts-ignore
        for await (const chunk of this.parseJsonStream(response.body)) {
            callback(chunk)
        }
    }

    public static generateStreamingToken(): Promise<{ token: string }> {
        return HttpService.sendRequest(
            HttpMethod.POST,
            `/api/llm/generate-streaming-token`,
        );
    }

    public static updateSelectedModel(modelId: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            `/api/update-selected-chat-model`,
            JSON.stringify({modelId: modelId}),
            ContentType.JSON
        );
    }

    // ------------------- HELPER METHODS -------------------

    public static async* parseJsonStream(readableStream: ReadableStream) {
        for await (const line of this.readLines(readableStream.getReader())) {
            const trimmedLine = line.trim().replace(/,$/, '');

            if (trimmedLine !== '[' && trimmedLine !== ']') {
                yield JSON.parse(trimmedLine);
            }
        }
    }

    public static async* readLines(reader: ReadableStreamDefaultReader) {
        const textDecoder = new TextDecoder();
        let partOfLine = '';
        for await (const chunk of this.readChunks(reader)) {
            const chunkText = textDecoder.decode(chunk);
            const chunkLines = chunkText.split('\n');
            if (chunkLines.length === 1) {
                partOfLine += chunkLines[0];
            } else if (chunkLines.length > 1) {
                yield partOfLine + chunkLines[0];
                for (let i = 1; i < chunkLines.length - 1; i++) {
                    yield chunkLines[i];
                }
                partOfLine = chunkLines[chunkLines.length - 1];
            }
        }
    }

    public static readChunks(reader: ReadableStreamDefaultReader) {
        return {
            async* [Symbol.asyncIterator]() {
                let readResult = await reader.read();
                while (!readResult.done) {
                    yield readResult.value;
                    readResult = await reader.read();
                }
            },
        };
    }
}