import MainDesign from "../../MainDesign";
import BlogPost from "../../../components/user/blog/BlogPost";
import {BlogWithRandomArticles} from "../../../model/user/blog/Blog";
import {Await, defer, Params, useLoaderData} from "react-router-dom";
import {DeferredData} from "@remix-run/router/dist/utils";
import {BlogService} from "../../../service/BlogService";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import React, {Suspense} from "react";
import {isAdmin} from "../../../util/AuthUtil";

type BlogArticleData = {
    blogWithRandomArticles: BlogWithRandomArticles;
    isAdmin: boolean;
};

export default function BlogPostPage() {
    const {blogWithRandomArticles, isAdmin} = useLoaderData() as BlogArticleData;
    return <>
        <MainDesign containerStyles='py-28 container'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={blogWithRandomArticles} errorElement={<ErrorContent/>}>
                    {(loadedBlogWithRandomArticles) => <>
                        <BlogPost blogWithRandomArticles={loadedBlogWithRandomArticles}
                                  showBackButtonToAdminPanel={isAdmin} disableBackLink={false}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadArticle(uuid: string): Promise<BlogWithRandomArticles> {
    return await BlogService.getArticle(uuid, false);
}

export async function loader({params}: { params: Params<"uuid"> }): Promise<DeferredData> {
    return defer({
        blogWithRandomArticles: loadArticle(params.uuid!!),
        isAdmin: await isAdmin()
    });
}