import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import React from "react";
import {useLoaderData} from "react-router-dom";
import VSLConversationsContentWithShortVideo
    from "../../../components/user/offer/VSLConversationsContentWithShortVideo";
import {IsLoggedInLoaderData} from "../../../util/Loader";


export default function VSLConversationsPageWithShortVideo() {
    const {isLoggedIn} = useLoaderData() as IsLoggedInLoaderData;

    return <>
        <Meta title='Zacznij korzystać z Konwersacji z AI już teraz!'/>
        <MainDesign containerStyles='container py-20'>
            <VSLConversationsContentWithShortVideo isLoggedIn={isLoggedIn}/>
        </MainDesign>
    </>
}
