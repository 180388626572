import {Category} from "../../../components/ui/category/Categories";

export const exerciseCategories = [
    {
        displayName: 'Wszystkie działy',
        value: 'all'
    },
    {
        displayName: 'Liczby i działania',
        value: 'Liczby i działania',
    },
    {
        displayName: 'Algebra',
        value: 'Algebra',
    },
    {
        displayName: 'Równania i nierówności',
        value: 'Równania i nierówności',
    },
    {
        displayName: 'Funkcje',
        value: 'Funkcje',
    },
    {
        displayName: 'Ciągi',
        value: 'Ciągi',
    },
    {
        displayName: 'Trygonometria',
        value: 'Trygonometria',
    },
    {
        displayName: 'Planimetria',
        value: 'Planimetria',
    },
    {
        displayName: 'Geometria analityczna',
        value: 'Geometria analityczna',
    },
    {
        displayName: 'Stereometria',
        value: 'Stereometria',
    },
    {
        displayName: 'Statystyka i prawdopodobieństwo',
        value: 'Statystyka i prawdopodobieństwo',
    },

] as Category[];