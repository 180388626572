import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import LearningCourseEnroll from "../../../components/user/learning-course/LearningCourseEnroll";
import React from "react";

export default function LearningCourseEnrollPage() {
    return <>
        <Meta title="Kurs Efektywnego Działania"
              description='Działaj efektywniej i osiągaj więcej! Kurs efektywnej nauki połączony z elementami efektywnego działania.'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Kurs Efektywnego Działania'
                     customStyles='mb-0 text-center mw-md-4xl mx-auto'
                     badgeText='efektywne działanie'/>
            <p className={'fs-10 fw-medium text-secondary-light mb-6 text-center mw-md-4xl mx-auto'}>
                Zapisz się na <span className='text-success'>bezpłatne konsultacje</span>, a stworzymy dla Ciebie
                <span className='text-success'> spersonalizowany plan</span> działania.
            </p>
            <LearningCourseEnroll/>
        </MainDesign>
    </>
}