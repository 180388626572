import {DeferredData} from "@remix-run/router/dist/utils";
import {defer} from "react-router-dom";
import {isLoggedIn} from "./AuthUtil";

export async function isLoggedInLoader(): Promise<DeferredData> {
    return defer({
        isLoggedIn: await isLoggedIn(),
    });
}

export type IsLoggedInLoaderData = {
    isLoggedIn: boolean
}
