import React from "react";

interface TextWithImageAboveProps {
    title: string
    explanation: string
    customElement?: JSX.Element
}

export default function TextWithImageAbove({title, explanation, customElement}: TextWithImageAboveProps) {
    const firstLetter = title.substring(0, 1).toUpperCase();
    return <>
        <div className='text-center d-block d-md-none'>
            <img
                src={`https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/mistrzowskie-wsparcie-ikony/${firstLetter}.png`}
                alt={`litera ${firstLetter}`}/>
            <p className='text-white'>
                {customElement ? customElement : <><b>{title}</b> - {explanation}</>}
            </p>
        </div>
        <div className='text-center d-none d-md-block'>
            <img
                src={`https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/mistrzowskie-wsparcie-ikony/${firstLetter}.png`}
                alt={`litera ${firstLetter}`}/>
            <p className='text-white'>
                <b className='fs-8'>{title}</b><br/>
                {explanation.charAt(0).toUpperCase() + explanation.slice(1)}
            </p>
        </div>
    </>
}