import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import AddOrEditModelModal from "./AddOrEditModelModal";

export default function AddAIModelButton() {
    const [createModalOpen, setCreateModalOpen] = React.useState(false);

    return <>
        <div className='text-center mw-xs mx-auto mb-8'>
            <AnimatedButton
                className='btn btn-lg btn-success text-lg-center w-75 py-3 px-4 h-100 shadow'
                onClick={() => setCreateModalOpen(true)}
            >
                Dodaj model
            </AnimatedButton>
        </div>
        <AddOrEditModelModal isOpen={createModalOpen} setOpen={setCreateModalOpen}/>
    </>
}