// ABTestingComponent.tsx
import React, {ReactNode, useEffect, useState} from "react";
import {TraceService} from "../../service/TraceService";
import {TraceType} from "../../model/user/general/Trace";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

interface ABTestingComponentProps {
    ComponentA: ReactNode;
    ComponentB: ReactNode;
    experimentId: string;
    noCookies?: boolean
}

export default function ABTestingComponent({ComponentA, ComponentB, experimentId, noCookies}: ABTestingComponentProps) {
    const [variation, setVariation] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let storedVariation = localStorage.getItem(`ab-variation-${experimentId}`);

        let firstView = false

        if (noCookies) {
            const urlParams = new URLSearchParams(location.search);
            storedVariation = urlParams.get(`ab-variation-${experimentId}`);

            if (!storedVariation) {
                storedVariation = Math.random() < 0.5 ? "A" : "B";
                urlParams.set(`ab-variation-${experimentId}`, storedVariation);
                navigate({search: urlParams.toString()}, {replace: true});
                firstView = true
            }
        } else {
            if (!storedVariation) {
                storedVariation = Math.random() < 0.5 ? "A" : "B";
                localStorage.setItem(`ab-variation-${experimentId}`, storedVariation);
                firstView = true
            }
        }

        if (firstView) {
            TraceService.addTraceWithExperiment(TraceType.PageLoad, experimentId, storedVariation, searchParams.get('source'));
        }

        setVariation(storedVariation);
        ReactPixel.pageView();
    }, [experimentId, variation, noCookies, location, navigate]);

    if (variation === null) {
        return null; // Or a loading spinner
    }

    return (
        <>
            {variation === "A" ? ComponentA : ComponentB}
        </>
    );
};