import {Link, useSubmit} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Model, modelOptions, modelTypes} from "../../../model/common/Model";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import RadioTwoOptions from "../../ui/form/RadioTwoOptions";
import {useHandleDirtyForm} from "../../../hooks/useHandleDirtyForm";

interface AddOrEditModelModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    model?: Model
}

export default function AddOrEditModelModal(props: AddOrEditModelModalProps) {
    const {isOpen, setOpen, model} = props

    const submit = useSubmit();
    const {isSubmitting} = useSubmitFormAction('/admin/ai', null, () => {
        setOpen(false);
        setModelName("");
        setModelType("");
        setImageUrl("");
        setModelKey("");
        setPrompt("");
        setIsDraft(false);
        setCallCost(0);
        setDescription("");
        setIsFormDirty(false);
    });

    const [modelName, setModelName] = React.useState(model?.name ?? "");
    const [modelType, setModelType] = React.useState(model?.modelType ?? "");
    const [imageUrl, setImageUrl] = React.useState(model?.imageUrl ?? "");
    const [modelKey, setModelKey] = React.useState(model?.modelKey ?? "");
    const [prompt, setPrompt] = React.useState(model?.prompt ?? "");
    const [isDraft, setIsDraft] = React.useState(model?.isDraft === undefined ? false : model?.isDraft);
    const [callCost, setCallCost] = useState<number>(model?.callCost ?? 0);
    const [description, setDescription] = useState<string>(model?.description ?? "");

    const [_, setIsFormDirty] = useHandleDirtyForm();

    useEffect(() => {
        setModelName(model?.name ?? "");
        setModelType(model?.modelType ?? "");
        setImageUrl(model?.imageUrl ?? "");
        setModelKey(model?.modelKey ?? "");
        setPrompt(model?.prompt ?? "");
        setIsDraft(model?.isDraft === undefined ? false : model?.isDraft)
        setCallCost(model?.callCost ?? 0);
        setDescription(model?.description ?? "");
    }, [model])

    const isEditing = !!model;

    function submitForm() {
        submit({
            id: model?.id ?? "",
            name: modelName,
            modelType,
            modelKey,
            imageUrl,
            prompt,
            isDraft,
            callCost,
            description,
            requestType: isEditing ? "EDIT" : "CREATE"
        }, {
            method: "POST",
        });
    }

    return <section
        className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${isOpen ? '' : 'd-none'}`}
        style={{zIndex: 50}}
    >
        <div className='d-flex flex-wrap align-items-center h-100'>
            <div className='container py-10'>
                <form className='p-10 mw-4xl mx-auto bg-light-dark overflow-hidden rounded shadow'>
                    <div className='mb-8 row g-8 align-items-center'>
                        <div className='col-auto'>
                            <Link className='navbar-close text-secondary-light' to='#' onClick={() => {
                                setIsFormDirty(false);
                                setOpen(false)
                            }}>
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M6 18L18 6M6 6L18 18'
                                        stroke='currentColor'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </Link>
                        </div>
                        <div className='col-11'>
                            <span
                                className='fs-7 fw-bold text-white'>{model ? `Edytujesz model ${model.name}` : 'Dodajesz nowy model'}</span>
                        </div>
                        <div className='col-12 col-md-7'>
                            <div className='form-group'>
                                <label
                                    className='mb-2 text-white fw-bold'
                                    htmlFor='modalInput2-1'
                                >
                                    Nazwa modelu
                                </label>
                                <input
                                    className='input-lg form-control shadow-sm border-0'
                                    id='modalInput2-11'
                                    type='text'
                                    aria-describedby='dateHelp'
                                    value={modelName}
                                    onChange={(e) => setModelName(e.target.value)}
                                    placeholder='Podaj nazwę modelu'
                                    style={modelName ? {color: 'black'} : {}}
                                />
                            </div>
                        </div>
                        <div className='col-12 col-md-5'>
                            <div className='form-group'>
                                <label className='mb-2 text-white fw-bold' htmlFor='modalInput2-2'>
                                    Wybierz model pod spodem
                                </label>
                                <select
                                    className='select-lg form-select shadow-sm border-0'
                                    id='modalInput2-3'
                                    aria-describedby='hourHelp'
                                    value={modelKey}
                                    onChange={(e) => setModelKey(e.target.value)}
                                    style={modelKey ? {color: 'black'} : {}}
                                >
                                    <option value=''>Wybierz model...</option>
                                    {Object.entries(modelOptions).map(([value, name]) => (
                                        <option key={value} value={value}>{name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className='col-6 col-md-3'>
                            <div className='form-group'>
                                <label
                                    className='mb-2 text-white fw-bold'
                                    htmlFor='modalInput2-1'
                                >
                                    URL ikony (zdjęcie)
                                </label>
                                <input
                                    className='input-lg form-control shadow-sm border-0'
                                    id='modalInput2-4'
                                    type='text'
                                    aria-describedby='dateHelp'
                                    placeholder='Podaj URL'
                                    value={imageUrl}
                                    onChange={(e) => setImageUrl(e.target.value)}
                                    style={imageUrl ? {color: 'black'} : {}}
                                />
                            </div>
                        </div>
                        <div className='col-6 col-md-3'>
                            <div className='form-group'>
                                <label
                                    className='mb-2 text-white fw-bold'
                                    htmlFor='modalInput2-2'
                                >
                                    Wybierz typ
                                </label>
                                <select
                                    className='select-lg form-select shadow-sm border-0'
                                    id='modalInput2-2'
                                    aria-describedby='hourHelp'
                                    value={modelType}
                                    onChange={(e) => setModelType(e.target.value)}
                                    style={modelType ? {color: 'black'} : {}}
                                    disabled={isEditing}
                                >
                                    <option value=''>Wybierz typ</option>
                                    {Object.entries(modelTypes).map(([value, name]) => (
                                        <option key={value} value={value}>{name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-6 col-md-3'>
                            <div className='form-group'>
                                <label
                                    className='mb-2 text-white fw-bold'
                                    htmlFor='modalInput2-1'
                                >
                                    Koszt zapytania
                                </label>
                                <input
                                    className='input-lg form-control shadow-sm border-0'
                                    id='modalInput2-1'
                                    type='number'
                                    aria-describedby='dateHelp'
                                    placeholder='Podaj koszt'
                                    value={callCost}
                                    onChange={(e) => setCallCost(e.target.value as unknown as number)}
                                    style={callCost ? {color: 'black'} : {}}
                                />
                            </div>
                        </div>
                        <div className='col-6 col-md-3'>
                            <RadioTwoOptions name='showTranscript' handleRadioChange={(event) => {
                                setIsDraft(event.target.value === "yes")
                            }} checked={isDraft} label="Zapisać jako draft?" firstText="Tak" firstValue="yes"
                                             secondText="Nie" secondValue="no"/>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label
                                    className='mb-2 text-white fw-bold'
                                    htmlFor='modalInput2-2'
                                >
                                    Podaj opis modelu (dla użytkownika)
                                </label>
                                <textarea
                                    className='input-lg form-control shadow'
                                    id='modalInput2-6'
                                    aria-describedby='commentHelp'
                                    placeholder='Zacznij pisać...'
                                    rows={3}
                                    value={description}
                                    onChange={(event) => {
                                        setIsFormDirty(true);
                                        setDescription(event.target.value)
                                    }}
                                    style={description ? {color: 'black'} : {}}
                                ></textarea>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label
                                    className='mb-2 text-white fw-bold'
                                    htmlFor='modalInput2-2'
                                >
                                    Podaj początkowy prompt (konfiguracja modelu pod spodem)
                                </label>
                                <textarea
                                    className='input-lg form-control shadow'
                                    id='modalInput2-7'
                                    aria-describedby='commentHelp'
                                    placeholder='Zacznij pisać...'
                                    rows={6}
                                    value={prompt}
                                    onChange={(event) => {
                                        setIsFormDirty(true);
                                        setPrompt(event.target.value)
                                    }}
                                    style={prompt ? {color: 'black'} : {}}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <h1 className='font-heading mb-4 fs-8 text-white' style={{letterSpacing: '-1px'}}>
                        Dostępne placeholdery</h1>
                    <p className='mb-0 fs-10 fw-medium text-secondary-light'>
                        <strong>
                            {"{userName}"}
                        </strong>
                        <span> - imię użytkownika </span>
                    </p>
                    <p className='mb-0 fs-10 fw-medium text-secondary-light'>
                        <strong>
                            {"{conversationSubject}"}
                        </strong>
                        <span> - temat konwersacji (tylko dla konwersacji) </span>
                    </p>
                    <p className='mb-0 fs-10 fw-medium text-secondary-light'>
                        <strong>
                            {"{level}"}
                        </strong>
                        <span> - poziom użytkownika (tylko dla konwersacji) </span>
                    </p>

                    <div className='row mt-12'>
                        <div className='col-12 col-md-4'>
                            <AnimatedLink
                                className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                to='#'
                                onClick={() => {
                                    setIsFormDirty(false);
                                    setOpen(false)
                                }}
                            >
                                Anuluj
                            </AnimatedLink>
                        </div>
                        <div className='col-12 col-md-8'>
                            <AnimatedButton
                                className='btn btn-lg btn-success w-100 text-success-light shadow'
                                onClick={submitForm}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Potwierdź
                            </AnimatedButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
}