import {Feedback} from "../../../model/common/Feedback";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import React from "react";
import {formatDate} from "../../../util/DateUtil";
import {useToast} from "../../ui/toast/ToastContext";
import {addNewLineTag} from "../../../util/Util";

interface FeedbackSummaryModalProps {
    isOpen: boolean;
    isSubmitting: boolean;
    feedback: Feedback | undefined;
    closeFeedbackSummary: () => void;
    markFeedbackAsRead: (feedbackId: string) => void;
}

export default function FeedbackSummaryModal(props: FeedbackSummaryModalProps) {
    const {isOpen, isSubmitting, feedback, closeFeedbackSummary, markFeedbackAsRead} = props;

    const {showMessage} = useToast();

    async function copyToClip() {
        await navigator.clipboard.writeText(feedback?.userEmail ?? '');
        showMessage("Pomyślnie skopiowano.");
    }

    return feedback ?
        <>
            <section
                className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${isOpen ? '' : 'd-none'}`}
                style={{zIndex: 50}}
            >
                <div className='d-flex flex-wrap align-items-center h-100'>
                    <div className='container py-20'>
                        <div className='p-10 mw-4xl mx-auto bg-light-dark overflow-hidden rounded shadow'>
                            <div className='mb-8 row g-8 align-items-center'>
                                <div className='col-auto'>
                                    <div className='navbar-close text-secondary-light'
                                         onClick={() => closeFeedbackSummary()}>
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M6 18L18 6M6 6L18 18'
                                                stroke='currentColor'
                                                strokeWidth={2}
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className='col-11 mb-0'>
                                    <span className='fs-7 fw-bold text-white'>Zobacz podsumowanie</span>
                                    <h6 className='mb-1 text-white'>
                                        {formatDate(feedback.addedAt)}
                                    </h6>
                                </div>
                                <div className='col-12 fs-10 mb-0 text-white'>
                                    Feedback od {feedback.userName} ({feedback.userEmail})
                                </div>
                                {
                                    feedback.generalRating && feedback.generalRating > 0 && feedback.recommendRating && feedback.recommendRating > 0 &&
                                    <div className='col-12 mb-0 text-white'>
                                        Ocena: <span
                                        className='fw-bold text-white'> {feedback.generalRating} / 5 </span>,
                                        Polecę: <span
                                        className='fw-bold text-white'> {feedback.recommendRating} / 5 </span>
                                    </div>
                                }
                                {
                                    feedback.feedback &&
                                    <div className='col-12 mb-1 fs-8 fw-bold text-white'>
                                        Feedback:
                                    </div>
                                }
                                {
                                    feedback.feedback &&
                                    <div className='col-12 mb-0 mt-0 text-white'>
                                        {addNewLineTag(feedback.feedback)}
                                    </div>
                                }
                                {
                                    feedback.dreamVision &&
                                    <div className='col-12 mb-1 fs-8 fw-bold text-white'>
                                        Wizja platformy:
                                    </div>
                                }
                                {
                                    feedback.dreamVision &&
                                    <div className='col-12 mb-0 mt-0 text-white'>
                                        {addNewLineTag(feedback.dreamVision)}
                                    </div>
                                }
                                {
                                    feedback.message &&
                                    <div className='col-12 mb-1 fs-8 fw-bold text-white'>
                                        Wiadomość
                                    </div>
                                }
                                {
                                    feedback.message &&
                                    <div className='col-12 mb-0 mt-0 text-white'>
                                        {addNewLineTag(feedback.message)}
                                    </div>
                                }
                            </div>
                            <div className='row justify-content-center g-2 mt-8'>
                                <div className='col-auto me-2'>
                                    <div className='bg-white rounded'>
                                        <AnimatedButton
                                            className='btn btn-lg btn-outline-light fs-12 px-4 py-2 text-secondary shadow'
                                            onClick={copyToClip}
                                        >
                                            <svg
                                                className='me-1'
                                                width={20}
                                                height={20}
                                                viewBox='0 0 20 20'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    d='M15 15.8333H5C4.33696 15.8333 3.70108 15.5699 3.23224 15.1011C2.76339 14.6323 2.5 13.9964 2.5 13.3333V6.66667C2.5 6.44565 2.41221 6.23369 2.25592 6.07741C2.09964 5.92113 1.88768 5.83333 1.66667 5.83333C1.44566 5.83333 1.23369 5.92113 1.07741 6.07741C0.921133 6.23369 0.833336 6.44565 0.833336 6.66667V13.3333C0.833336 14.4384 1.27232 15.4982 2.05372 16.2796C2.44063 16.6665 2.89996 16.9734 3.40549 17.1828C3.91101 17.3922 4.45283 17.5 5 17.5H15C15.221 17.5 15.433 17.4122 15.5893 17.2559C15.7455 17.0996 15.8333 16.8877 15.8333 16.6667C15.8333 16.4457 15.7455 16.2337 15.5893 16.0774C15.433 15.9211 15.221 15.8333 15 15.8333ZM19.1667 8.28333C19.158 8.20678 19.1412 8.13136 19.1167 8.05833V7.98333C19.0766 7.89765 19.0232 7.81889 18.9583 7.75V7.75L13.9583 2.75C13.8894 2.68518 13.8107 2.63173 13.725 2.59167H13.65L13.3833 2.5H6.66667C6.00363 2.5 5.36774 2.76339 4.8989 3.23223C4.43006 3.70107 4.16667 4.33696 4.16667 5V11.6667C4.16667 12.3297 4.43006 12.9656 4.8989 13.4344C5.36774 13.9033 6.00363 14.1667 6.66667 14.1667H16.6667C17.3297 14.1667 17.9656 13.9033 18.4344 13.4344C18.9033 12.9656 19.1667 12.3297 19.1667 11.6667V8.33333C19.1667 8.33333 19.1667 8.33333 19.1667 8.28333ZM14.1667 5.34167L16.325 7.5H15C14.779 7.5 14.567 7.4122 14.4107 7.25592C14.2545 7.09964 14.1667 6.88768 14.1667 6.66667V5.34167ZM17.5 11.6667C17.5 11.8877 17.4122 12.0996 17.2559 12.2559C17.0996 12.4122 16.8877 12.5 16.6667 12.5H6.66667C6.44565 12.5 6.23369 12.4122 6.07741 12.2559C5.92113 12.0996 5.83334 11.8877 5.83334 11.6667V5C5.83334 4.77899 5.92113 4.56702 6.07741 4.41074C6.23369 4.25446 6.44565 4.16667 6.66667 4.16667H12.5V6.66667C12.5 7.32971 12.7634 7.96559 13.2322 8.43443C13.7011 8.90327 14.337 9.16667 15 9.16667H17.5V11.6667Z'
                                                    fill='#BBC3CF'
                                                />
                                            </svg>
                                            <span>Skopiuj adres email</span>
                                        </AnimatedButton>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-8'>
                                <div className='col-12 col-md-4'>
                                    <AnimatedButton
                                        className="btn btn-lg btn-dark-light w-100 text-success-light shadow mt-2"
                                        type="button"
                                        onClick={() => closeFeedbackSummary()}
                                    >
                                        Zamknij
                                    </AnimatedButton>
                                </div>
                                <div className='col-12 col-md-8'>
                                    <AnimatedButton
                                        className='btn btn-lg btn-success w-100 text-success-light shadow mt-2'
                                        type="button"
                                        onClick={() => {
                                            markFeedbackAsRead(feedback.id);
                                            closeFeedbackSummary();
                                        }}
                                        disabled={!feedback.unread || isSubmitting}
                                    >
                                        Oznacz jako przeczytany
                                    </AnimatedButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </> : <></>
}