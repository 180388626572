import React, {useState} from "react";
import ExercisesList from "../../user/exercise/ExercisesList";
import FullExercise from "../../user/exercise/FullExercise";
import {Exercise} from "../../../model/user/exercise/Exercise";

interface CreateOrEditExerciseLivePreviewProps {
    publicationDate: string;
    publicationHour: string;
    title: string;
    field: string;
    content: string;
    solution: string;
}

export default function CreateOrEditExerciseLivePreview(props: CreateOrEditExerciseLivePreviewProps) {
    const [showSolution, setShowSolution] = useState(false);
    const {publicationDate, publicationHour, title, field, content, solution} = props;

    const exercise: Exercise = {
        id: 'abcd',
        title: title,
        field: field,
        content: content,
        solution: solution,
        addedAt: publicationDate + ' ' + publicationHour,
        subject: 'Matematyka',
        level: 'Podstawa',
        uniqueViews: 0,
        repeatedViews: 0
    }
    return <div className='p-10 mb-8 row g-8 align-items-center'>
        <div className='col-auto'>
        </div>
        <div className='col-12'>
            <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                Podgląd
            </span>
            <h1
                className='mb-1 font-heading fs-5 text-white'
                style={{letterSpacing: '-1px'}}
            >
                Zobacz podgląd
            </h1>
            <div>
                <ExercisesList pageKey={undefined} category='all' initialExercises={[exercise]} readonly={true}
                               displayOnLivePreview={true} isAdmin={true}/>
            </div>
            <div className="mt-6 p-2">
                <FullExercise exercise={exercise} showSolution={showSolution} setShowSolution={setShowSolution}
                              isLoggedIn={true} showBackButtonToAdminPanel={false} backButtonDisabled={true}/>
            </div>
        </div>
    </div>
}