import {Outlet} from "react-router-dom";
import AdminHeader from "../../components/admin/AdminHeader";
import ScrollToTop from "../../util/ScrollToTop";
import React from "react";
import Footer from "../../components/common/Footer";

export default function AdminRootLayout() {
    return <>
        <ScrollToTop/>
        <AdminHeader/>
        <Outlet/>
        <Footer/>
    </>
}