import SlideInWhenVisible from "../../ui/motion/SlideInWhenVisible";
import React, {useState} from "react";
import AdvantageItem from "../ai/pricing/AdvantageItem";
import HowItWorksItem from "../ai/pricing/conversations/HowItWorksItem";
import StaggerChildrenWhenVisible from "../../ui/motion/StaggerChildrenWhenVisible";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import FaqList from "../../ui/faq/FaqList";
import PurchaseEmailAndName from "../ai/pricing/PurchaseEmailAndName";
import ConfirmModal from "../../ui/ConfirmModal";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {PurchaseOption} from "../../../model/user/purchase/PurchaseOption";

export default function CoachingSession() {
    const {isSubmitting} = useSubmitFormAction();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [isInputValid, setIsInputValid] = useState(false);


    return <>
        <div className='row g-16 mt-12'>
            <div className='col-12 col-md-6'>
                <div className='mw-md-xl'>
                    <h2
                        className='mb-5 font-heading fs-4 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        Co zyskujesz?
                    </h2>
                    <div className='mb-3 fs-9 fw-medium text-secondary-light'>
                        <p>
                            Analizę Twojej aktualnej sytuacji i indywidualny plan działania, dopasowany do Twoich
                            potrzeb.<br/>
                            <span className='text-decoration-underline'> Dowiesz się między innymi o:</span>
                        </p>
                        <AdvantageItem title={'Swoich mocnych stronach i naturalnych talentach.'}/>
                        <AdvantageItem
                            title={'Swoich preferencjach biologicznych do wykonywania poszczególnych aktywności.'}/>
                        <AdvantageItem title={'Obszarach nad którymi trzeba popracować, aby zrealizować Twoje cele.'}/>
                        <p>Plan działania będziesz mógł zrealizować samemu, z pomocą kogoś innego, lub ze mną, jeżeli
                            zdecydujemy
                            się na dalszą współpracę. </p>
                    </div>
                    <h2
                        className='mb-5 mt-10 font-heading fs-6 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        Jak wyglądają konsultacje?
                    </h2>
                    <div className='mb-3 fs-9 fw-medium text-secondary-light'>
                        Spotkamy się na zoomie. Zaczniemy od luźnego wywiadu, na którym opowiesz mi o swoich celach,
                        planach i problemach.
                        Następnie przedstawię Ci analizę wyników testu Gallupa i testu na
                        Chronotypy snu.
                        Na końcu zaproponuję Ci indywidualny plan działania, dopasowany do Twoich potrzeb. <br/>
                        <b>Każda osoba jest inna</b>, dlatego dalsze kroki są ustalane indywidualnie.
                    </div>
                    <div className='row g-4'>
                        <div className='col-12 col-md-auto'>
                            <SlideInWhenVisible initial={{opacity: 0, x: 100}} duration={0.2} initialX={100}>
                                <AnimatedButton
                                    className='btn btn-lg btn-success w-100 text-success-light shadow'
                                    onClick={() => {
                                        TraceService.addTrace(TraceType.BtnClick, `btn-1-umawiam-sie`)
                                        setConfirmModalOpen(true);
                                    }}
                                >
                                    Umawiam się już teraz
                                </AnimatedButton>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='row justify-content-center'>
                    <div className='position-relative col-auto'>
                        <img
                            className='position-absolute top-0 start-0 mt-n8 ms-n12'
                            style={{zIndex: 1}}
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/circle-orange.svg'
                            alt=''
                        />
                        <img
                            className='position-relative img-fluid'
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/konsultacje/indywidualne+konsultajce-photo.png'
                            alt=''
                        />
                    </div>
                </div>
            </div>
            <div>
                <h2
                    className='mb-10 mt-10 font-heading fs-5 text-white text-center'
                    style={{letterSpacing: '-1px'}}
                >
                    Jak wygląda proces?
                </h2>
                <StaggerChildrenWhenVisible>
                    <HowItWorksItem count={1} title={'Dostęp'}
                                    subtitle={'Wykup dostęp do indywidualnych konsultacji klikając w poniższy przycisk i podając swoje dane. W ciągu kilku godzin otrzymasz maila z dokładnymi instrukcjami i kodami do testów.'}/>
                    <HowItWorksItem count={2} title={'Testy'}
                                    subtitle={'Wykonaj test Gallupa oraz testu na Chronotypy snu. Wyniki testów wyślij na adres kontakt@produkacja.pl do 24h przed planowanym spotkaniem.'}/>
                    <HowItWorksItem count={3} title={'Dogodny termin'}
                                    subtitle={'Wybierz dogodny dla siebie termin konsultacji. Rano, w ciągu dnia, a może wieczorem? Nie ma problemu.'}/>
                    <HowItWorksItem count={4} title={'Spotkanie online'}
                                    subtitle={'Konsultacja odbędzie się na zoomie. Link do spotkania otrzymasz mailowo po wybraniu dogodnego dla siebie terminu. Na końcu otrzymasz maila z podsumowaniem konsultacji i propozycją planu działania.'}/>
                </StaggerChildrenWhenVisible>
                <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
                    <AnimatedButton
                        className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                        onClick={() => {
                            TraceService.addTrace(TraceType.BtnClick, `btn-2-umawiam-sie`)
                            setConfirmModalOpen(true);
                        }}
                    >
                        Umawiam się już teraz
                    </AnimatedButton>
                </div>

                <h2
                    className='mb-10 mt-24 font-heading fs-6 text-white text-center'
                    style={{letterSpacing: '-1px'}}
                >
                    Najczęściej zadawane pytania
                </h2>
                <div className='mw-3xl mx-auto'>
                    <FaqList items={[
                        {
                            question: 'Dla kogo są indywidualne konsultacje?',
                            answer: 'Konsultacje są przede wszystkim dla licealistów, którzy potrzebują pomocy w wybraniu ścieżki życiowej, okryciu swojego potencjału i możliwości, a także dla tych najbardziej ambitnych.\n Przedstawimy Ci plan działania do zrealizowania DOWOLNEGO postawionego przez siebie celu. '
                        },
                        {
                            question: 'Ile trwają konsultacje?',
                            answer: 'Konsultacje trwą 60 minut.'
                        },
                        {
                            question: 'Ile kosztują konsultacje?',
                            answer: 'Jedna godzina konsultacji kosztuje 397zł.'
                        },
                        {
                            question: 'Czym jest test Gallupa?',
                            answer: 'Test Gallupa pomaga odkryć swoje naturalne talenty i mocne strony.\n Będziemy bazować na wynikach testu \'CliftonStrengths for Students\', specjalnie dopasowanego do uczniów liceum lub studentów.'
                        },
                        {
                            question: 'Jaki test Gallupa oferujecie?',
                            answer: 'Oferujemy test \'CliftonStrengths for Students\', który jest specjalnie dopasowany do uczniów liceum lub studentów.'
                        },
                        {
                            question: 'Czym jest test na Chronotypy snu?',
                            answer: 'Jest to test pozwalający odkryć swoje naturalne preferencje do wykonywania poszczególnych aktywności i preferowania określonych godzin odpoczynku, pracy, snu itp.'
                        },
                        {
                            question: 'Umówiłem/am się na konsultacje, jednak nie dam rady się na nich zjawić. Co teraz?',
                            answer: 'W takiej sytuacji napisz maila na \'kontakt@produkacja.pl\' przynajmniej 24h przed planowaną godziną konsultacji, a znajdziemy inny termin lub zwrócimy Ci pieniądze.\n W przypadku odwołania zajęć mniej niż 24h wcześniej, zwroty lub zmiany terminów rozpatrywane są indywidualnie.'
                        },
                        {
                            question: 'Jakie są dostępne terminy?',
                            answer: 'Jest to zależne od dnia tygodnia i obłożenia grafiku. Z reguły terminy dostępne są między 6:00 a 19:00, z wyłączeniem piątków i niedzieli.'
                        }
                    ]}/>
                </div>
            </div>
        </div>
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Sfinalizuj zakup"
                      subtitle={"Podaj swoje imię i adres email. Po kliknięciu 'Potwierdź' zostaniesz przekierowany do serwisu płatności."}
                      customTextTop={<div className='col-11 mt-3'>
                              <span
                                  className='fs-9 text-light'>Kupujesz <span className='fw-bold text-success'>
                                  60 minut konsultacji
                              </span> za <span
                                  className='fw-bold text-success'> 397zł</span>.</span>
                      </div>}
                      buttonType='submit'
                      isSubmitting={isSubmitting}
                      submitDisabled={!isInputValid}
                      children={
                          <React.Fragment>
                              {
                                  <PurchaseEmailAndName purchaseOption={PurchaseOption.COACHING_SESSION.toString()}
                                                        setIsInputValid={setIsInputValid}
                                                        isLoggedIn={false}/>
                              }
                              <p className='text-white mt-2 fw-medium'>Na maila otrzymasz automatycznie link do
                                  kalendarza w celu wybrania dogodnego dla Ciebie terminu. W ciągu kilku godzin
                                  otrzymasz również instrukcję i kody do wykonania testów.</p>
                          </React.Fragment>
                      }
        />
    </>
}