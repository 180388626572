import ModelSelection from "./ModelSelection";
import ThreadSelection from "./ThreadSelection";
import Conversation from "./Conversation";
import ChatInput from "./ChatInput";
import ChatTokensWithFeedback from "./ChatTokensWithFeedback";
import ModelDescription from "../ModelDescription";
import React, {MouseEvent, useState} from "react";
import {LoadThreadsAndInfo} from "../../../../model/user/ai/chat/LoadThreadsAndInfo";
import {Conversation as ConversationEntity} from "../../../../model/user/ai/Conversation";
import {getModelById, getSelectedChatModelOrDefault} from "../../../../model/common/Model";

interface ChatFormProps {
    loadedThreadsAndInfo: LoadThreadsAndInfo
    modelId: string | null
    threadId: string | null
    conversation: ConversationEntity[]
    errorMessage: string
    message: string
    setMessage: React.Dispatch<React.SetStateAction<string>>
    sendMessage: (event: MouseEvent, imageUrl?: string) => void
    isLoading: boolean
    showEmptyChat: boolean
    handleModelChange: (item: string) => void
    loadThreadMessages: (item: any) => void
    promptCost: number
}

export default function ChatForm(props: ChatFormProps) {
    const {
        loadedThreadsAndInfo,
        modelId,
        threadId,
        conversation,
        errorMessage,
        message,
        setMessage,
        sendMessage,
        isLoading,
        showEmptyChat,
        handleModelChange,
        loadThreadMessages,
        promptCost
    } = props;
    const [tokens, setTokens] = useState<number | undefined>();

    const model = modelId ? (getModelById(loadedThreadsAndInfo.models, modelId) ?? null) :
        getSelectedChatModelOrDefault(loadedThreadsAndInfo);

    return <>
        {
            !showEmptyChat && <div
                className="d-flex w-100 align-items-center justify-content-center border-bottom gap-1 pt-0 pb-6 fs-9 text-secondary-light">
                {model ? `Model: ${model.name}` : "Wybrany model już nie istnieje. Rozpocznij nową konwersację."}
            </div>
        }
        <div className='row d-flex'>
            {showEmptyChat &&
                <div className='col-12 col-md-7 d-flex flex-column p-3'>
                    <ModelSelection
                        selectedModelId={modelId ?? getSelectedChatModelOrDefault(loadedThreadsAndInfo).id}
                        models={loadedThreadsAndInfo.models}
                        handleModelChange={handleModelChange}/>
                </div>
            }
            {showEmptyChat &&
                <div
                    className='col-12 col-md-5 d-flex flex-column justify-content-end p-3'>
                    <ThreadSelection allThreads={loadedThreadsAndInfo}
                                     selectedThread={threadId}
                                     handleThreadChange={loadThreadMessages}/>
                </div>
            }
        </div>
        {/* Conversation area */}
        {!showEmptyChat && conversation.length > 0 && (
            <Conversation conversation={conversation}
                          selectedModel={model}
                          userName={loadedThreadsAndInfo.userName}
                          speakingConversation={false}/>
        )}

        <ChatInput errorMessage={errorMessage} message={message} setMessage={setMessage}
                   sendMessage={sendMessage} isLoading={isLoading}
                   showEmptyChat={showEmptyChat} model={model} disableInput={model === null}/>
        <ChatTokensWithFeedback initialTokens={loadedThreadsAndInfo.tokens}
                                tokens={tokens}
                                promptCost={promptCost}
                                setTokens={setTokens}
                                feedbackSource='CHAT'
                                hasCompletedCustomFeedbackForm={loadedThreadsAndInfo.hasCompletedCustomFeedbackForm}
        />
        {
            showEmptyChat &&
            <div className='row mt-5'>
                <ModelDescription model={model ?? loadedThreadsAndInfo.models[0]}/>

            </div>
        }
    </>
}