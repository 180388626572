import Heading from "../../../../common/header/Heading";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";

interface ConversationsBenefitsProps {
    isLoggedIn: boolean
}

export default function ConversationsBenefits(props: ConversationsBenefitsProps) {
    const {isLoggedIn} = props
    return <>
        <Heading badgeText='Korzyści' title='Kluczowe korzyści'
                 subtitle='Zobacz, co możesz zyskać dzięki naszemu rozwiązaniu.'/>
        <div className='row g-8 mt-10 mb-20'>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Lagodzenie+strachu.svg'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Łagodzenie strachu przed rozmową
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        AI jest nastawiona wspierająco i nie krytykuje, co zmniejsza stres związany z mówieniem i
                        pozwala użytkownikowi skupić się na nauce.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Personalizacja+poziomu+trudnosci.svg'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Personalizacja poziomu trudności
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Poziom rozmowy dopasowujesz do swoich potrzeb, co zapewnia odpowiednie wyzwania bez poczucia
                        przytłoczenia.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Doste%CC%A8pnosc.svg'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Elastyczność i dostępność
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Usługa jest dostępna 24/7 z dowolnego miejsca na świecie. Jedyny warunek: Dostęp do Internetu.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Transkrypcja.svg'
                            alt='stress relief' style={{width: 32, height: 32}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Transkrypcja i podpowiedzi
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Otrzymujesz pełną transkrypcję rozmowy wraz z sugestiami poprawy.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Tematy+rozmow.svg'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Tematyki rozmów
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Rozmawiaj na interesujące Cię tematy, dzięki czemu nauka staje się bardziej angażująca i mniej
                        monotonna.
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='mw-md-sm'>
                    <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{width: 48, height: 48}}
                    >
                        <img
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Slownictwo.svg'
                            alt='stress relief' style={{width: 36, height: 36}}/>
                    </div>
                    <h3 className='mb-2 font-heading fs-9 text-white'>
                        Rozbudowanie słownictwa
                    </h3>
                    <p className='mb-0 text-secondary-light fw-medium'>
                        Ucz się z nowych słów i zwrotów, które naturalnie są wplatane w rozmowę.
                    </p>
                </div>
            </div>
            <div className='row mt-7 mw-3xl mx-auto px-1'>
                <div className='col-12 col-md-6 mb-8'>
                    <div className='mw-md-sm'>
                        <div
                            className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                            style={{width: 48, height: 48}}
                        >
                            <img
                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Nauka+przez+dzialanie.svg'
                                alt='stress relief' style={{width: 36, height: 36}}/>
                        </div>
                        <h3 className='mb-2 font-heading fs-9 text-white'>
                            Nauka poprzez praktykę
                        </h3>
                        <p className='mb-0 text-secondary-light fw-medium'
                           style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>
                            Używaj teoretycznej wiedzy w praktyce, co jest jedną z najskuteczniejszych metod nauki.
                        </p>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='mw-md-sm'>
                        <div
                            className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                            style={{width: 48, height: 48}}
                        >
                            <img
                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/oferta-konwersacje/Pewnosc+siebie.svg'
                                alt='stress relief' style={{width: 36, height: 36}}/>
                        </div>
                        <h3 className='mb-2 font-heading fs-9 text-white'>
                            Budowanie pewności siebie
                        </h3>
                        <p className='mb-0 text-secondary-light fw-medium'>
                            Regularne praktykowanie konwersacji pomoże Ci stopniowo przełamywać barierę językową
                        </p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center g-4 pt-2 mb-10">
                <div className="col-12 col-md-auto"><AnimatedLink
                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                    onClick={() => {
                        localStorage.setItem('redirectUrlAfterRegistration', '/konwersacje')
                    }}
                    to={isLoggedIn ? "/konwersacje" : "/rejestracja"}>Przetestuj za darmo już teraz</AnimatedLink>
                </div>
            </div>
        </div>
    </>
}