import {motion} from "framer-motion";
import {Form} from "react-router-dom";
import {hasAdminGroup, hasEffectiveActionCourseSubscriberGroup} from "../../../util/AuthUtil";
import AnimatedLink from "../motion/AnimatedLink";
import AnimatedButton from "../motion/AnimatedButton";
import React from "react";

interface HeaderDropdownMenuProps {
    dropdownRef: React.RefObject<HTMLDivElement>;
    toggleDropdown: () => void;
    dropdownOpen: boolean;
    groups: string[];
}

export default function HeaderDropdownMenu(props: HeaderDropdownMenuProps) {
    const {dropdownRef, toggleDropdown, dropdownOpen, groups} = props;
    return <>
        <div className='col-auto' ref={dropdownRef}>
            <div className={`dropdown ${dropdownOpen ? 'show' : ''}`}>
                <motion.div whileHover={{scale: 1.2}}>
                    <div role='button' onClick={toggleDropdown}>
                        <img
                            className='mb-0 team-round-image lazyload'
                            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/user.png'
                            alt=''
                            style={{width: '50px', height: '50px'}}
                        />
                    </div>
                </motion.div>
                <div
                    className={`bg-light-dark dropdown-menu position-absolute ${dropdownOpen ? 'show' : ''}`}
                    style={{right: '-10px', top: '54px'}}
                    aria-labelledby="dropdownMenuLink"
                >
                    {
                        hasAdminGroup(groups) &&
                        <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                      to="/admin" onClick={toggleDropdown} customScale={1.05}>Panel
                            admina</AnimatedLink>
                    }
                    <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                  to={hasEffectiveActionCourseSubscriberGroup(groups) ? "/produkt/kurs-efektywnego-dzialania" : "/mistrzowskie-wsparcie"}
                                  onClick={toggleDropdown}
                                  customScale={1.05}> {hasEffectiveActionCourseSubscriberGroup(groups) ? "Kurs Efektywnego Działania" : "MISTRZOWSKIE Wsparcie"}</AnimatedLink>
                    <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                  to="/chat" onClick={toggleDropdown}
                                  customScale={1.05}>Chat</AnimatedLink>
                    <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                  to="/konwersacje" onClick={toggleDropdown}
                                  customScale={1.05}>Konwersacje (beta)</AnimatedLink>
                    <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                  to="/transakcje" onClick={toggleDropdown}
                                  customScale={1.05}>Historia transakcji</AnimatedLink>
                    <Form method="post" action="/logout">
                        <AnimatedButton className="text-white dropdown-item dropdown-item-custom"
                                        customScale={1.05}>
                            Wyloguj się
                        </AnimatedButton>
                    </Form>
                </div>
            </div>
        </div>
    </>
}