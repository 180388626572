import React, {MouseEvent, useEffect, useRef, useState} from "react";
import useAutoResizeTextArea from "../../../../hooks/useAutoResizeTextArea";
import {useToast} from "../../../ui/toast/ToastContext";
import {Model} from "../../../../model/common/Model";
import {handleFileUpload} from "../../../../util/FileUploadUtil";

interface ChatInputProps {
    errorMessage: string;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    sendMessage: (event: MouseEvent, imageUrl?: string) => void;
    isLoading: boolean;
    showEmptyChat: boolean;
    model: Model | null;
    disableInput?: boolean;
}

export default function ChatInput(props: ChatInputProps) {
    const {
        errorMessage, message, setMessage, sendMessage,
        isLoading, showEmptyChat, model, disableInput
    } = props;

    const textAreaRef = useAutoResizeTextArea();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {showMessage} = useToast();

    // For now vision is disabled anyway
    const isUploadAllowed = (model && model.modelKey && model.modelKey.includes('vision')) ?? false;

    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [isUploading, setIsUploading] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());

    // Effect for resizing the text area based on its content
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "24px";
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }

    }, [message, textAreaRef]);

    function clearImgState() {
        setFileName(undefined);
        setImageUrl(undefined);
        setInputKey(Date.now());
    }

    async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        await handleFileUpload(event, (file: File) => {
            setFileName(file.name);
            showMessage("Plik został pomyślnie przesłany.")
        }, setImageUrl, false, setIsUploading)
    }

    // Function to handle key press events
    const handleKeypress = (e: any) => {
        // It triggers by pressing the enter key
        if (e.keyCode === 13 && !e.shiftKey) {
            if (!(isLoading || message?.length === 0 || disableInput || message?.length > 5000)) {
                sendMessage(e, imageUrl);
                clearImgState();
            }
            e.preventDefault();
        }
    };

    return <>
        <form className="pt-2">
            <div className="d-flex flex-column mx-2">
                {
                    showEmptyChat && <p className='mb-1 fs-10 fw-medium text-secondary-light'>
                        Postaraj się szczegółowo opisać swój problem.
                    </p>
                }

                {/* Display error message if any */}
                {errorMessage && (
                    <div className="mb-2 d-flex justify-content-center">
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                )}
                {/* Text area for user input */}

                <div
                    className="position-relative d-flex flex-column flex-grow-1 py-2 rounded shadow-sm bg-secondary-dark">
                    {
                        isUploadAllowed && <>
                            <input
                                type='file'
                                key={inputKey}
                                ref={fileInputRef}
                                style={{display: 'none'}}
                                onChange={handleFileChange}
                                accept=".png,.jpg,.jpeg,.webp,.gif"
                                disabled={isLoading || isUploading}
                            />
                            <div
                                className="position-absolute"
                                style={{left: '0.75rem', top: '1.25rem', cursor: isUploading ? 'default' : 'pointer'}}
                                onClick={() => fileInputRef.current!!.click()}
                            >
                                <img style={{width: '26px'}}
                                     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/attachment+icon.svg'
                                     alt='attachment'/>
                            </div>
                        </>
                    }

                    <textarea
                        ref={textAreaRef}
                        value={message}
                        className='form-control shadow bg-secondary-dark text-white'
                        placeholder='Napisz wiadomość...'
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeypress}
                        style={{
                            minHeight: '48px',
                            outline: 'none',
                            resize: "none",
                            overflow: "hidden",
                            paddingRight: "40px",
                            paddingBottom: "15px",
                            paddingTop: "15px",
                            paddingLeft: isUploadAllowed ? "48px" : "15px"
                        }}
                    />
                    <button
                        disabled={isLoading || message?.length === 0 || disableInput || message?.length > 5000}
                        onClick={(event) => {
                            sendMessage(event, imageUrl);
                            clearImgState();
                        }}
                        className="btn position-absolute p-2 rounded"
                        style={{
                            bottom: '0.5rem',
                            right: '0rem',
                            minWidth: '50px',
                            minHeight: '50px',
                            color: "#2ad167",
                            border: 'none'
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             fill="currentColor"
                             className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </form>
        {
            fileName && <div className="text-secondary-light px-2" style={{display: 'flex', alignItems: 'center'}}>
                {fileName}
                <img style={{width: '16px', cursor: 'pointer'}} className='mx-2' onClick={clearImgState}
                     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/x-icon.svg' alt='Remove file icon'/>
            </div>
        }

        {/* Informational text */}
        <div className="text-center text-muted px-2 pb-2">
            Asystent może popełniać błędy. Pamiętaj o samodzielnym sprawdzeniu istotnych informacji.
        </div>
    </>
}