import React from 'react';
import Meta from "../../components/common/Meta";
import MainDesign from "../MainDesign";
import ResetPasswordText from "../../components/auth/ResetPasswordText";
import ResetPasswordForm from "../../components/auth/ResetPasswordForm";
import {AuthService} from "../../service/AuthService";
import {ApiError} from "../../service/HttpService";


export default function ResetPassword() {
    return (
        <>
            <Meta title='Reset hasła'/>
            <MainDesign containerStyles="py-32">
                <ResetPasswordText/>
                <ResetPasswordForm/>
            </MainDesign>
        </>
    );
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const email = formData.get('email') as string;
    try {
        await AuthService.resetPassword(email);
        return {
            status: 200,
            body: 'Email został wysłany.'
        }
    } catch (error) {
        const apiError = error as ApiError;
        if (apiError.message) {
            return {
                status: 500,
                body: apiError.message
            }
        }
        return {
            status: 500,
            body: 'Wystąpił błąd. Spróbuj ponownie później.'
        }
    }
}

