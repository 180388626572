import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {TraceService} from '../service/TraceService';
import {TraceType} from '../model/user/general/Trace';

export default function useOptInForm(experimentId: string) {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [searchParams] = useSearchParams();

    const isInputValid =
        !!email.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) && name.length >= 2;

    function handleButtonClick(buttonNum: number, variation: 'A' | 'B') {
        setLoading(true);
        TraceService.addTrace(
            TraceType.BtnClick,
            `btn ${buttonNum} -> chcę otrzymać szczegóły. Variation ${variation}`
        );
        TraceService.addTraceWithExperiment(
            TraceType.BtnClick,
            experimentId,
            variation,
            searchParams.get('source')
        );
        setLoading(false);
        setConfirmModalOpen(true);
    }

    return {
        confirmModalOpen,
        setConfirmModalOpen,
        loading,
        email,
        setEmail,
        name,
        setName,
        isInputValid,
        handleButtonClick,
    };
}