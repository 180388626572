import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import ABTestingComponent from "../../../components/ab-testing/ABTestingComponent";
import {KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";
import React from "react";
import ProdukacjaCopyrightNote from "../../../components/funnel/ProdukacjaCopyrightNote";
import EffectiveActionCourseMaturaParentDirectA
    from "../../../components/funnel/direct/EffectiveActionCourseMaturaParentDirectA";
import EffectiveActionCourseMaturaParentDirectB
    from "../../../components/funnel/direct/EffectiveActionCourseMaturaParentDirectB";

interface FunnelMaturaParentDirectPageProps {
    preselectedVariation?: "A" | "B"
}

export default function FunnelMaturaParentDirectPage(props: FunnelMaturaParentDirectPageProps) {
    const {preselectedVariation} = props
    return (
        <>
            <Meta
                title="MISTRZOWSKIE Wsparcie"
                description="Mistrzowskie wsparcie dla maturzystów. Twoje dziecko dostanie się na dowolne studia lub zwrócimy Ci pieniądze."
            />
            <MainDesign containerStyles="container pt-10 pt-md-20 pb-10" minHeight='100vh'>
                {
                    preselectedVariation === 'A' && <EffectiveActionCourseMaturaParentDirectA/>
                }
                {
                    preselectedVariation === 'B' && <EffectiveActionCourseMaturaParentDirectB/>
                }
                {
                    !preselectedVariation && <ABTestingComponent
                        ComponentA={<EffectiveActionCourseMaturaParentDirectA/>}
                        ComponentB={<EffectiveActionCourseMaturaParentDirectB/>}
                        experimentId={KED_MATURA_PARENT_DIRECT_EXPERIMENT_ID_1}
                        noCookies={true}
                    />
                }
                <ProdukacjaCopyrightNote/>
            </MainDesign>
        </>
    );
}