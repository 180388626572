import {Model, modelOptions, modelTypes} from "../../../model/common/Model";
import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";

interface ModelListItemProps {
    model: Model
    setEditModalOpen: (value: boolean) => void
    setModelToEdit: React.Dispatch<React.SetStateAction<Model | null>>
    setConfirmDeleteModalOpen: (value: boolean) => void
    setModelToDelete: React.Dispatch<React.SetStateAction<Model | null>>
}

export default function ModelListItem(props: ModelListItemProps) {
    const {model, setEditModalOpen, setModelToEdit, setConfirmDeleteModalOpen, setModelToDelete} = props;


    return <>
        <div className='col-12' key={model.id}>
            <div className='px-10 py-8 bg-light-dark rounded shadow'>
                <div className='row justify-content-between g-4'>
                    <div className='col-auto'>
                        <img
                            className='mb-0 team-round-image'
                            src={model.imageUrl}
                            alt=''
                            style={{width: '70px', height: '70px'}}
                        />
                        <h3 className='text-center mt-1 mb-1 fs-11 text-white'
                            style={{width: "70px"}}>Użycia</h3>
                        <h3 className='text-center mt-0 fw-medium font-heading text-white fs-10'
                            style={{width: "70px"}}>{model.usages}</h3>
                    </div>
                    <div className='col d-flex flex-column justify-content-center'>
                        <h3 className='mb-1 font-heading fs-8 text-white'>
                            {model.name}
                        </h3>
                        <h6 className='mb-1 text-white'>
                            {modelTypes[model.modelType]} - {modelOptions[model.modelKey]}
                        </h6>
                        <p className='mb-0 fw-medium text-secondary-light' style={{fontSize: '14px'}}>
                            {model.prompt.split(" ").length > 45
                                ? model.prompt.split(" ").slice(0, 45).join(" ") + "..."
                                : model.prompt}
                        </p>
                        <p className='mt-2 mb-0 fw-medium text-white' style={{fontSize: '14px'}}>
                            Koszt zapytania: <span
                            className='fw-bold'>{model.callCost === 1 ? "1 token" : (model.callCost < 5 && model.callCost > 1 ? `${model.callCost} tokeny` : `${model.callCost} tokenów`)}</span>,
                        </p>
                        <p className='mt-1 mb-0 fw-medium text-white' style={{fontSize: '14px'}}>
                            Całkowity koszt AI: <span
                            className='fw-bold'>{(model.totalCostInUsd ?? 0).toFixed(5)}</span>$,
                        </p>
                        {
                            !!model.assemblyAiCostInUsd && !!model.pollyCostInUsd &&
                            <p className='mt-0 mb-0 fw-medium text-white' style={{fontSize: '14px'}}>
                                Całkowity koszt transkrypcji: <span
                                className='fw-bold'>{(model.assemblyAiCostInUsd ?? 0).toFixed(5)}</span>$,
                                Całkowity koszt Polly: <span
                                className='fw-bold'>{(model.pollyCostInUsd ?? 0).toFixed(5)}</span>$,
                            </p>
                        }
                        {
                            !!model.assemblyAiCostInUsd && !!model.pollyCostInUsd &&
                            <p className='mb-0 fw-medium text-white' style={{fontSize: '14px'}}>
                                Całkowity koszt: <span
                                className='fw-bold'>{(model.totalCostInUsd + model.assemblyAiCostInUsd + model.pollyCostInUsd).toFixed(5)}</span>$.
                            </p>
                        }
                        <p className='mb-0 fw-medium text-white' style={{fontSize: '14px'}}>
                            Wykorzystano: <span className='fw-bold'>{model.usedTokens ?? 0}</span> tokenów.
                        </p>

                    </div>
                    <div className="col-12 col-md-auto mt-6">
                        <AnimatedButton
                            className="btn btn-lg btn-warning w-100 text-success-light shadow"
                            onClick={() => {
                                setModelToEdit(model);
                                setEditModalOpen(true);
                            }}>
                            Edytuj
                        </AnimatedButton>
                    </div>
                    <div className="col-12 col-md-auto mt-6">
                        <AnimatedButton
                            className="btn btn-lg btn-danger w-100 text-success-light shadow"
                            onClick={() => {
                                setModelToDelete(model);
                                setConfirmDeleteModalOpen(true);
                            }}
                        >
                            Usuń
                        </AnimatedButton>
                    </div>
                </div>
            </div>
        </div>
    </>
}