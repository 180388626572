import {motion} from "framer-motion";

export const whoAreWeValuesVariants = {
    hidden: (i: number) => ({
        opacity: 0,
        scale: 0.5,
        x: (i % 2 === 0 ? -100 : 100),
        y: 50,
        rotate: -10
    }),
    visible: (i: number) => ({
        opacity: 1,
        scale: 1,
        x: 0,
        y: 0,
        rotate: 0,
        transition: {
            delay: i * 0.5,
            duration: 0.5,
            ease: [0.6, -0.05, 0.01, 0.99],
        },
    }),
};

export default function WhoAreWeValues() {
    return <div className='row mw-6xl mx-auto'>
        <motion.div
            custom={0}
            variants={whoAreWeValuesVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{once: true}}
            className="option-wrapper col-12 col-md-6 mt-5 px-10"
        >
            <div className='text-center'>
                <img
                    className='position-relative img-fluid mb-6'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wartosci/dostepnosc.png'
                    style={{maxHeight: "300px"}}
                    alt=''
                />
            </div>
            <h3 className='font-heading mb-4 fs-5 text-white link-white text-center'>
                Dostępność
            </h3>
            <p className='text-white mb-0'>
                Wiedza i dostęp do jakościowej edukacji to klucz do sukcesu i wykorzystania możliwości oferowanych przez
                świat.
            </p>
            <p className='text-white mb-0'>
                Dlatego usuwamy blokady, by każdy miał dostęp do solidnego źródła informacji, które jest dostępne
                zawsze i wszędzie.
            </p>
            <p className='text-white'>
                Wystarczy urządzenie z połączeniem Internetowym.
            </p>
        </motion.div>
        <motion.div
            custom={1}
            variants={whoAreWeValuesVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{once: true}}

            className="option-wrapper col-12 col-md-6 mt-5 px-10"
        >
            <div className='text-center'>
                <img
                    className='position-relative img-fluid mb-6'
                    style={{maxHeight: "300px"}}
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wartosci/profesjonalizm.png'
                    alt=''
                />
            </div>
            <h3 className='font-heading mb-4 fs-5 text-white link-white text-center'>
                Profesjonalizm
            </h3>
            <p className='text-white mb-1'>
                Dokładamy wszelkich starań, by nasze usługi były na najwyższym poziomie, tak aby spełniały oczekiwania
                nawet najbardziej wymagających i ambitnych osób.
            </p>
            <p className='text-white'>
                Wiedza to nie wszystko, podkreślamy znaczenie nastawienia i przekonań zakorzenionych w umyśle. Pomagamy
                zbudować odwagę do zmiany dotychczasowych nawyków i otwarcie się na nowe doświadczenia.
            </p>
        </motion.div>
        <motion.div
            custom={0}
            variants={whoAreWeValuesVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{once: true}}
            className="option-wrapper col-12 col-md-6 mt-5 px-10"
        >
            <div className='text-center'>
                <img
                    className='position-relative img-fluid mb-6'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wartosci/inkluzywnosc.png'
                    style={{maxHeight: "300px"}}
                    alt=''
                />
            </div>
            <h3 className='font-heading mb-4 fs-5 text-white link-white text-center'>
                Inkluzywność
            </h3>
            <p className='text-white mb-0'>
                Aby rozwój był skuteczny, niezbędne jest odpowiednie środowisko. Produkacja to bezpieczna przestrzeń,
                gdzie każdy może się stawać najlepszą wersją siebie. Zapewniamy najlepsze warunki, dostosowane
                specjalnie do Twoich potrzeb.
            </p>

        </motion.div>
        <motion.div
            custom={1}
            variants={whoAreWeValuesVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{once: true}}

            className="option-wrapper col-12 col-md-6 mt-5 px-10"
        >
            <div className='text-center'>
                <img
                    className='position-relative img-fluid mb-6'
                    style={{maxHeight: "300px"}}
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wartosci/innowacyjnosc.png'
                    alt=''
                />
            </div>
            <h3 className='font-heading mb-4 fs-5 text-white link-white text-center'>
                Innowacyjność
            </h3>
            <p className='text-white'>
                Wykorzystujemy najnowsze technologie i adaptujemy je tak, by zapewniały kompleksowe rozwiązania
                edukacyjne. Cenimy czas, dlatego tworzymy narzędzia umożliwiające jak najlepsze efekty w jak najkrótszym
                czasie.
            </p>
        </motion.div>
        <motion.div
            custom={0}
            variants={whoAreWeValuesVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{once: true}}
            className="option-wrapper col-12 col-md-6 mt-5 px-10"
        >
            <div className='text-center'>
                <img
                    className='position-relative img-fluid mb-6'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wartosci/easy+to+use.png'
                    style={{maxHeight: "300px"}}
                    alt=''
                />
            </div>
            <h3 className='font-heading mb-4 fs-5 text-white link-white text-center'>
                Intuicyjność
            </h3>
            <p className='text-white mb-0'>
                Wiemy, że najlepsze efekty osiągane są poprzez pełną znajomość narzędzi, dlatego nasze rozwiązania są
                intuicyjne w użyciu.
                Zapewniamy również poradniki, gdzie znajdują się
                szczegółowe objaśnienia wszystkich możliwości.
            </p>
        </motion.div>
        <motion.div
            custom={1}
            variants={whoAreWeValuesVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{once: true}}

            className="option-wrapper col-12 col-md-6 mt-5 px-10"
        >
            <div className='text-center'>
                <img
                    className='position-relative img-fluid mb-6'
                    style={{maxHeight: "300px"}}
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wartosci/dialog.png'
                    alt=''
                />
            </div>
            <h3 className='font-heading mb-4 fs-5 text-white link-white text-center'>
                Dialog
            </h3>
            <p className='text-white'>
                Cenimy sobie wasze zdanie. Jesteśmy zdeterminowani, by dostarczać Wam najlepsze rozwiązania, dlatego
                Wasze opinie i sugestie są dla nas niezwykle istotne. Cenimy wszelkie wasz wiadomości, komentarze i
                recenzje, które pozwalają nam stale ulepszać platformę i zapewniać Wam lepszą edukację.
            </p>
        </motion.div>
    </div>
}