import {ContentType, HttpMethod, HttpService} from "../HttpService";
import {Model, ModelRequest} from "../../model/common/Model";

export class AICommonService {

    public static getAllModels(type: "chat" | "speaking" | "all"): Promise<Model[]> {
        return HttpService.sendRequest<Model[]>(
            HttpMethod.GET,
            `/api/models/${type}`,
        );
    }

    public static createOrEditModel(request: ModelRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            `/api/model`,
            JSON.stringify(request),
            ContentType.JSON
        );

    }

    public static deleteModel(id: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.DELETE,
            `/api/model/${id}`,
        );
    }
}