import React, {useEffect, useState} from "react";
import {Exercise} from "../../../model/user/exercise/Exercise";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import {formatDate} from "../../../util/DateUtil";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import {motion} from "framer-motion";
import ConfirmModal from "../../ui/ConfirmModal";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {useSubmit} from "react-router-dom";
import usePagination from "../../../hooks/usePagination";
import {ExerciseService} from "../../../service/ExerciseService";
import PaginationLoadMore from "../../ui/pagination/PaginationLoadMore";

interface ExercisesListProps {
    initialExercises: Exercise[],
    pageKey: string | undefined,
    category: string
    readonly: boolean,
    displayOnLivePreview?: boolean
    isAdmin: boolean
}

export default function ExercisesList(props: ExercisesListProps) {
    const {initialExercises, pageKey, category, readonly, displayOnLivePreview, isAdmin} = props;
    const {isSubmitting} = useSubmitFormAction();
    const submit = useSubmit();

    const {
        items: exercises,
        setItems,
        loadMore,
        nextPageKey,
        setNextPageKey,
        sentinelRef,
        isAutoLoadEnabled
    } = usePagination<Exercise, any>(ExerciseService.getExercises, initialExercises, pageKey, true, category);

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [exerciseToDelete, setExerciseToDelete] = useState<Exercise>();

    useEffect(() => {
        setNextPageKey(pageKey)
    }, [pageKey]);

    useEffect(() => {
        setItems(initialExercises)
    }, [initialExercises]);

    function submitForm() {
        submit({
            uuid: exerciseToDelete!!.id
        }, {
            method: "DELETE"
        });
    }

    return <div className={displayOnLivePreview ? `` : `mw-md-4xl mx-auto`}>
        <motion.div className='row g-8' transition={{staggerChildren: 0.05}}>
            {exercises.map((exercise) => (
                <motion.div className='col-12' key={exercise.id} initial={{opacity: 0, scale: 0.5}}
                            animate={{opacity: 1, scale: 1}}>
                    <div className='px-10 py-8 bg-light-dark rounded shadow'>
                        <div className='row justify-content-between g-4'>
                            <div className='col d-flex flex-column justify-content-center'>
                                <h3 className='mb-1 font-heading fs-8 text-white'>
                                    {exercise.title}
                                </h3>
                                <h6 className='mb-1 text-white'>
                                    {formatDate(exercise.addedAt)}
                                    {
                                        isAdmin && <>
                                            {"\u00A0"} <span className='text-secondary-light'>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         width="21" height="21"
                                         viewBox="0 0 26 26">
                                        <path
                                            fill='currentColor'
                                            d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/>
                                    </svg>
                                            {"\u00A0"}{exercise.uniqueViews + exercise.repeatedViews}
                                </span>
                                        </>
                                    }

                                </h6>
                                <p className='mb-0 fw-medium text-secondary-light'
                                   style={{fontSize: '16px'}}>
                                    <MathJaxContext>
                                        <MathJax>
                                            {exercise.content.length > 60 ? exercise.content.substring(0, 60) + '...' : exercise.content}
                                        </MathJax>
                                    </MathJaxContext>
                                </p>
                                <div>
                                    <span
                                        className='badge bg-success text-white text-uppercase shadow mt-1'
                                        style={{marginRight: "22px", marginLeft: "-10px"}}>
                                          {exercise.subject}
                                    </span>
                                    <span
                                        className='badge bg-success text-white text-uppercase shadow mt-1'
                                        style={{marginRight: "22px", marginLeft: "-10px"}}>
                                          {exercise.level}
                                    </span>
                                    <span
                                        className='badge bg-success text-white text-uppercase shadow mt-1'
                                        style={{marginRight: "22px", marginLeft: "-10px"}}>
                                          {exercise.field}
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-auto mt-6'>
                                {
                                    displayOnLivePreview && <button
                                        className='btn btn-lg btn-success w-100 text-success-light shadow'
                                        disabled
                                    >
                                        Przejdź do zadania
                                    </button> || <AnimatedLink
                                        className='btn btn-lg btn-success w-100 text-success-light shadow'
                                        to={`/zadanie/${exercise.id}`}
                                    >
                                        Przejdź do zadania
                                    </AnimatedLink>
                                }
                            </div>
                            {
                                !readonly && <div className="col-12 col-md-auto mt-6">
                                    <AnimatedLink
                                        className="btn btn-lg btn-warning w-100 text-success-light shadow"
                                        to={`edytuj/${exercise.id}`}>
                                        Edytuj
                                    </AnimatedLink>
                                </div>
                            }
                            {
                                !readonly && <div className="col-12 col-md-auto mt-6">
                                    <AnimatedButton
                                        className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                        onClick={() => {
                                            setExerciseToDelete(exercise)
                                            setConfirmDeleteModalOpen(true)
                                        }}
                                    >
                                        Usuń
                                    </AnimatedButton>
                                </div>
                            }

                        </div>
                    </div>
                </motion.div>
            ))}
        </motion.div>
        {
            !displayOnLivePreview &&
            <PaginationLoadMore isAutoLoadEnabled={isAutoLoadEnabled} nextPageKey={nextPageKey} loadMore={loadMore}
                                sentinelRef={sentinelRef}/>
        }

        <ConfirmModal isOpen={confirmDeleteModalOpen} setOpen={setConfirmDeleteModalOpen}
                      title={`Czy na pewno chcesz usunąć zadanie '${exerciseToDelete?.title}'`}
                      subtitle="Tej czynności nie będzie dało się cofnąć."
                      isSubmitting={isSubmitting}
                      confirmButtonAction={() => submitForm()}/>
    </div>
}