import React from "react";

export default function ExercisesDescription() {
    return <div className='mb-6 text-center mw-md-4xl mx-auto'>
                    <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                      zadania
                    </span>
        <h2
            className='font-heading mb-4 fs-5 text-white'
            style={{letterSpacing: '-1px'}}
        >
            Wszystkie zadania
        </h2>
        <p className='mb-0 fs-10 fw-medium text-secondary-light'>
            Znajdziesz tutaj wszystkie opublikowane już zadania. Możesz dowolny wybrać dział, lub przeglądać wszystkie.
            Działy są zgodne ze specyfikacją maturalną. Na ten moment oferujemy tylko zadania z matury podstawowej z
            matematyki.
        </p>
    </div>
}