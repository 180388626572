import React from "react";
import TextWithImageAbove from "../optin/TextWithImageAbove";

export default function ExtraordinarySupportStudentExplanation() {
    return <>
        <TextWithImageAbove title='Mentoring'
                            explanation='zapewniam nielimitowany kontakt na WhatsAppie i regularne konsultacje.'/>
        <TextWithImageAbove title='Indywidualizm'
                            explanation='personalizuję materiały na podstawie testów osobowości i wywiadu.'/>
        <TextWithImageAbove title='Sukces'
                            explanation='weryfikuję postępy i gwarantuję zrozumienia każdego zagadnienia.'/>
        <TextWithImageAbove title='Transparentność'
                            explanation='regularnie rozmawiamy o Twoich postępach.'/>
        <TextWithImageAbove title='Różnorodność'
                            explanation='zapewniam zróżnicowane treści edukacyjne (filmy, karty pracy, zbiory wiedzy).'/>
        <TextWithImageAbove title='Zaufanie'
                            explanation='umożliwiam przetestowanie i sprawdzenie wartości mojej pomocy.'/>
        <TextWithImageAbove title='Opinia'
                            explanation='zyskasz renomę osoby sukcesu.'/>
        <TextWithImageAbove title='Wsparcie'
                            explanation='pomoc mentora, społeczności, lub w razie potrzeby sprawdzonych ekspertów.'/>
        <TextWithImageAbove title='Systematyczność'
                            explanation='cotygodniowe konsultacje i regularny kontakt.'/>
        <TextWithImageAbove title='Kompleksowość'
                            explanation='gwarantuję pełne wsparcie, jakiego tylko mógłbyś potrzebować.'/>
        <TextWithImageAbove title='Innowacja'
                            explanation='pierwsze takie rozwiązanie na polskim rynku.'/>
        <TextWithImageAbove title='Edukacja'
                            explanation='to nie tylko szkoła. Przekazuję wiedzę, która wyróżni Cię z tłumu na studiach, w pracy, czy w biznesie.'/>
    </>
}