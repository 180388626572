import {Form, Link} from "react-router-dom";
import React from "react";
import AnimatedButton from "../ui/motion/AnimatedButton";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";


export default function ChangePasswordForm() {
    const {isSubmitting} = useSubmitFormAction();

    return <Form method='post' className='mw-sm mx-auto'>
        <div className='mb-6 row g-3'>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-2'
                    >
                        Hasło
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-2'
                        type='password'
                        aria-describedby='passwordHelp'
                        placeholder='Wprowadź hasło'
                        name='password'
                        required
                        autoComplete='new-password'
                    />
                </div>
            </div>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-2'
                    >
                        Powtórz hasło
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-3'
                        type='password'
                        aria-describedby='passwordHelp'
                        placeholder='Powtórz hasło'
                        name='confirmPassword'
                        required
                        autoComplete='new-password'
                    />
                </div>
            </div>
        </div>
        <div className='mb-6 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    type='submit'
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Trwa zmienianie hasła" : "Zmień hasło"}
                </AnimatedButton>
            </div>
        </div>
        <p className='mb-0 fs-13 fw-medium text-white text-center'>
            <span>Pamiętasz hasło? </span>
            <Link className='text-success link-success' to='/logowanie'>
                Zaloguj się
            </Link>
        </p>
    </Form>
}