import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import SlideInWhenVisible from "../../ui/motion/SlideInWhenVisible";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

export default function RedirectAfterSchedulingIndividualKEDSession() {
    return <>
        <div className='mw-md-3xl mx-auto'>
            
            <div className="row justify-content-center g-4 pt-2 mb-5">
                <div className="col-12 col-md-auto">
                    <SlideInWhenVisible initialX={-100} duration={0.4}>
                        <AnimatedLink
                            className="btn btn-lg btn-success w-100 text-success-light shadow"
                            onClick={() => {
                                TraceService.addTrace(TraceType.BtnClick, 'btn-1 -> wypróbuj teraz')
                                localStorage.setItem('redirectUrlAfterRegistration', '/chat')
                            }}
                            to={"/produkt/kurs-efektywnego-dzialania"}>Wróc do kursu</AnimatedLink>
                    </SlideInWhenVisible>

                </div>
                <div className="col-12 col-md-auto">
                    <SlideInWhenVisible initialX={100} duration={0.4} delay={0.4}>
                        <AnimatedLink hashLink={true}
                                      onClick={() => {
                                          TraceService.addTrace(TraceType.BtnClick, 'btn-2 -> czytaj dalej')
                                      }}
                                      className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                      to="/">Przejdź do strony głównej</AnimatedLink>
                    </SlideInWhenVisible>
                </div>
            </div>
            <img src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/do-uslyszenia.png'
                 alt='Dziękujemy za rejestrację' className='img-fluid'/>
            <p className='text-center fs-11 fw-medium text-secondary-light mt-3'>
                Możesz zamknąć tę stronę.</p>
        </div>
    </>
}