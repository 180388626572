import {fetchAuthSession, JWT} from 'aws-amplify/auth';
import {redirect} from "react-router-dom";
import {v4 as uuidv4} from 'uuid';
import {TraceService} from "../service/TraceService";
import {TraceType} from "../model/user/general/Trace";

const ADMIN_GROUP = process.env.REACT_APP_ADMIN_GROUP!!;

const EFFECTIVE_ACTION_COURSE_SUBSCRIBER = "Effective_Action_Course_Subscriber";

async function getRawIdToken(): Promise<JWT | null> {
    try {
        const {idToken} = (await fetchAuthSession()).tokens ?? {};

        return idToken ? idToken : null;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function getRawIdTokenAsString(): Promise<string> {
    try {
        const idToken = await getRawIdToken()

        return idToken ? idToken.toString() : '';
    } catch (err) {
        console.log(err);
        return '';
    }
}

export async function getRawIdTokenWithGroups(): Promise<[string, string[]]> {
    try {
        const idToken = await getRawIdToken();

        if (!idToken) {
            return ['', []];
        }

        const groups = idToken.payload['cognito:groups'];

        if (!groups) {
            return [idToken.toString(), []];
        }

        return [idToken.toString(), groups as string[]];
    } catch (err) {
        console.log(err);
        return ['', []];
    }
}

export async function tokenLoader({request}: { request: Request }): Promise<[string, string[]]> {
    const url = new URL(request.url);
    const source = url.searchParams.get('source') ?? 'direct';
    const firstJoinedFrom = localStorage.getItem('firstJoinedFrom');
    if (!firstJoinedFrom) {
        localStorage.setItem('firstJoinedFrom', source);
    }

    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
        sessionStorage.setItem('sessionId', uuidv4());
    }
    TraceService.addTrace(TraceType.PageLoad, undefined, request.url);
    return await getRawIdTokenWithGroups();
}

export function loadTokenWithGroupsFromLoaderData(loaderData: any): [string, string[]] {
    let idToken: string;
    let groups: string[];
    if (loaderData) {
        [idToken, groups] = loaderData as [string, string[]];
    } else {
        idToken = '';
        groups = [];
    }

    return [idToken, groups];
}

export async function isLoggedIn(): Promise<boolean> {
    return (await getRawIdTokenAsString()) !== '';
}

export async function redirectIfLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (token !== '') {
        return redirect('/');
    }
    return null;
}

export async function redirectIfNotLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (!token) {
        return redirect('/logowanie');
    }
    return null;
}

export async function redirectIfNotAdmin(): Promise<Response | [string, string[]]> {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
        sessionStorage.setItem('sessionId', uuidv4());
    }
    const tokens = (await fetchAuthSession()).tokens;
    if (!tokens) {
        return redirect('/logowanie');
    }

    const rawGroups = tokens.accessToken.payload['cognito:groups'];

    if (!rawGroups) {
        return redirect('/');
    }

    const groups = rawGroups as string[]

    if (!groups.includes(ADMIN_GROUP)) {
        return redirect('/');
    }

    return await getRawIdTokenWithGroups();
}

export async function redirectIfNotEffectiveActionCourseSubscriber(): Promise<Response | null> {
    const [_, groups] = await getRawIdTokenWithGroups();
    if (!hasEffectiveActionCourseSubscriberGroup(groups)) {
        return redirect('/');
    }
    return null;
}

export async function isAdmin(): Promise<boolean> {
    const [_, groups] = await getRawIdTokenWithGroups();
    return hasAdminGroup(groups);
}

export async function isEffectiveActionCourseSubscriber(): Promise<boolean> {
    const [_, groups] = await getRawIdTokenWithGroups();
    return hasEffectiveActionCourseSubscriberGroup(groups) || isAdmin();
}

export function hasAdminGroup(groups: string[]): boolean {
    return groups.includes(ADMIN_GROUP);
}

export function hasEffectiveActionCourseSubscriberGroup(groups: string[]): boolean {
    return groups.includes(EFFECTIVE_ACTION_COURSE_SUBSCRIBER);
}