import {PurchaseRequest} from "../model/user/purchase/PurchaseRequest";
import {PurchaseOption} from "../model/user/purchase/PurchaseOption";
import TiktokPixel from "tiktok-pixel";
import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {
    CreateIndividualKedOfferRequest,
    CreateIndividualKedOfferResponse,
    IndividualKedOffer
} from "../model/user/purchase/IndividualKedOffer";

export class PurchaseService {

    public static initPurchase(purchaseRequest: PurchaseRequest): Promise<string> {
        const option = PurchaseOption[purchaseRequest.purchaseOptionName as keyof typeof PurchaseOption];
        TiktokPixel.track("InitiateCheckout", {
            "contents": [
                {
                    "content_id": purchaseRequest.purchaseOptionName, // string. ID of the product. Example: "1077218".
                    "content_type": "product", // string. Either product or product_group.
                    "content_name": option.item.tokens ? "tokens" : "course_access", // string. The name of the page or product. Example: "shirt".
                }
            ],
            "value": option.price,
            "currency": "PLN"
        }, {debug: true});
        return HttpService.sendRequest<string>(
            HttpMethod.POST,
            `/api/purchase/init`,
            JSON.stringify(purchaseRequest),
            ContentType.JSON
        );
    }

    public static createIndividualKedOffer(createOfferRequest: CreateIndividualKedOfferRequest): Promise<CreateIndividualKedOfferResponse> {
        return HttpService.sendRequest<CreateIndividualKedOfferResponse>(
            HttpMethod.POST,
            `/api/individual-ked-offer`,
            JSON.stringify(createOfferRequest),
            ContentType.JSON
        );
    }

    public static getIndividualKedOffer(id: string): Promise<IndividualKedOffer> {
        return HttpService.sendRequest<IndividualKedOffer>(
            HttpMethod.GET,
            `/api/individual-ked-offer/${id}`,
        );
    }
}