import ArrowUp from "./ArrowUp";
import {useState} from "react";
import ArrowDown from "./ArrowDown";
import {addNewLineTag} from "../../../util/Util";

interface FaqItemProps {
    question: string
    answer: string
}

export default function FaqItem(props: FaqItemProps) {
    const {question, answer} = props;

    const [isOpen, setIsOpen] = useState(false);

    function toggleAnswer() {
        setIsOpen(!isOpen);
    }

    const answerWithNewLine = addNewLineTag(answer);

    return <div className='col-12'>
        <div className='text-secondary link-secondary' onClick={toggleAnswer}
             style={{cursor: "pointer", userSelect: "none"}}>
            <div className='p-9 bg-light-dark rounded shadow'>
                <div className='d-flex justify-content-between align-items-start'>
                    <h3 className={`${isOpen ? 'mb-1' : 'mb-0'} font-heading fs-9 text-white flex-grow-1`}>
                        {question}
                    </h3>
                    <div className='ms-3'>
                        {isOpen ? <ArrowUp/> : <ArrowDown/>}
                    </div>
                </div>
                {isOpen && <p className='mb-0 fw-medium text-secondary-light'>
                    {answerWithNewLine}
                </p>}
            </div>
        </div>
    </div>
}