import React from "react";
import {Link} from "react-router-dom";

export default function AddExerciseButton() {
    return <div className='text-center mw-xs mx-auto mb-8'>
        <Link
            className='btn btn-lg btn-success text-lg-center w-75 py-3 px-4 h-100 shadow'
            type="button"
            to="dodaj"
        >
            Dodaj zadanie
        </Link>
    </div>
}