import {Form, Link} from "react-router-dom";
import React from "react";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import AnimatedButton from "../ui/motion/AnimatedButton";

export default function ActivateAccountForm() {
    const {isSubmitting} = useSubmitFormAction();

    return <Form method='post' className='mw-sm mx-auto'>
        <div className='mb-6 row g-3'>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-2'
                    >
                        Hasło
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-2'
                        type='password'
                        aria-describedby='passwordHelp'
                        placeholder='Wprowadź hasło'
                        name='password'
                        required
                        autoComplete='new-password'
                    />
                </div>
            </div>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-2'
                    >
                        Powtórz hasło
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-3'
                        type='password'
                        aria-describedby='passwordHelp'
                        placeholder='Powtórz hasło'
                        name='confirmPassword'
                        required
                        autoComplete='new-password'
                    />
                </div>
            </div>
            {/*<div className='col-12'>*/}
            {/*    <div className='form-group'>*/}
            {/*        <label*/}
            {/*            className='mb-1 fw-medium text-white'*/}
            {/*            htmlFor='signUpInput3-1'*/}
            {/*        >*/}
            {/*            Kod polecający (opcjonalne)*/}
            {/*        </label>*/}
            {/*        <input*/}
            {/*            className='form-control text-secondary-dark shadow'*/}
            {/*            id='signUpInput2-1'*/}
            {/*            type='text'*/}
            {/*            aria-describedby='nameHelp'*/}
            {/*            placeholder='Wprowadź kod'*/}
            {/*            name='promoCode'*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        <div className='mb-6 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    type='submit'
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Trwa tworzenie konta" : "Utwórz konto"}
                </AnimatedButton>
            </div>
        </div>
        <p className='mb-0 fs-13 fw-medium text-white text-center'>
            <span>Nie masz jeszcze konta? </span>
            <Link className='text-success link-success' to='/rejestracja'>
                Zarejestruj się
            </Link>
        </p>
    </Form>
}