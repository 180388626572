import AnimatedButton from "../../../ui/motion/AnimatedButton";
import React from "react";

interface NewThreadButtonProps {
    newThread: () => void
}

export default function NewThreadButton(props: NewThreadButtonProps) {
    const {newThread} = props;
    return <div className='text-center mw-sm mx-auto'>
        <AnimatedButton
            className='btn btn-lg btn-warning text-lg-center fs-11 w-100 py-3 px-4 h-100 shadow mb-8'
            type="button"
            onClick={newThread}
        >
            Nowy wątek
        </AnimatedButton>
    </div>
}