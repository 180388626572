import LoadingPage from "../../common/LoadingPage";
import MainDesign from "../../MainDesign";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useToast} from "../../../components/ui/toast/ToastContext";
import {ApiError} from "../../../service/HttpService";
import ErrorContent from "../../../components/common/error/ErrorContent";
import {NewsletterService} from "../../../service/NewsletterService";

export default function ConfirmNewsletterPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const redirectUrl = searchParams.get('redirectUrl');
    const {showMessage} = useToast();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (id) {
            NewsletterService.confirmNewsletter({id: id})
                .then(response => {
                    if (redirectUrl) {
                        window.location.href = redirectUrl
                    } else {
                        showMessage(response.message);
                        navigate('/');
                    }
                })
                .catch((error: ApiError) => {
                    if (redirectUrl) {
                        window.location.href = redirectUrl
                    }
                    setErrorMessage(error.message);
                });
        } else {
            showMessage('Wprowadź poprawne id.');
            navigate('/');
        }
    }, [id]);

    return <>
        <MainDesign containerStyles='py-28 container'>
            {
                !errorMessage && <LoadingPage/>
            }
            {
                errorMessage && <ErrorContent messageProps={errorMessage}/>
            }
        </MainDesign>
    </>
}

