import React, {lazy, Suspense} from "react";
import LoadingPage from "../../../pages/common/LoadingPage";

const CalendlyInline = lazy(() => import("./CalendlyInline"));

interface CalendlyInlineLazyProps {
    calendlyUrl: string
    email?: string
    name?: string
    onEventScheduled?: () => void
    onDateAndTimeSelected?: () => void
    onEventTypeViewed?: () => void
}

export default function CalendlyInlineLazy(props: CalendlyInlineLazyProps) {
    const {calendlyUrl, email, name, onEventTypeViewed, onDateAndTimeSelected, onEventScheduled} = props;
    return <Suspense fallback={<LoadingPage/>}>
        <CalendlyInline
            url={calendlyUrl + `${email ? `?email=${email}` : ''}${name ? (email ? `&name=${name}` : `?name=${name}`) : ''}`}
            onEventScheduled={onEventScheduled}
            onDateAndTimeSelected={onDateAndTimeSelected}
            onEventTypeViewed={onEventTypeViewed}
        />
    </Suspense>
}