import AdvantageItem from "../../ai/pricing/AdvantageItem";
import {Form, Link} from "react-router-dom";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import {IndividualKedOffer} from "../../../../model/user/purchase/IndividualKedOffer";

interface LearningCoursePurchaseProps {
    individualKedOffer?: IndividualKedOffer;
}

export default function LearningCoursePurchase(props: LearningCoursePurchaseProps) {
    const {individualKedOffer} = props
    const {isSubmitting} = useSubmitFormAction();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isInputValid = isEmailValid && name.length >= 2;

    return <>
        {
            individualKedOffer &&
            <>
                <hr className='text-white'/>
                <h1 className='mw-md-4xl mx-auto text-center'> Indywidualna oferta
                    dla <span className='fw-bold text-success text-decoration-none'>{individualKedOffer.name}</span>
                </h1>
                <hr className='text-white'/>
            </>

        }
        <div className={`mw-md-xl mx-auto text-white ${individualKedOffer ? 'mt-12' : 'mt-16'}`}>
            <h2 className='mb-4'>Co zyskujesz?</h2>
            {
                individualKedOffer ?
                    individualKedOffer.benefits.map((benefit, index) => (
                        <AdvantageItem key={index} title={benefit}/>
                    )) : <>
                        <AdvantageItem
                            title='Roczny dostęp do kursu efektywnego działania (liczony od października 2024).'/>
                        <AdvantageItem title='Cotygodniowe grupowe spotkania na Zoomie.'/>
                        <AdvantageItem title='5 spotkań indywidualnych, dopasowanych do Twoich potrzeb.'/>
                        <AdvantageItem
                            title='Dostęp do forum, na którym można zadawać nieograniczoną liczbę pytań dotyczących zagadnień z kursu.'/>
                    </>
            }

            {
                individualKedOffer?.additionalComment && <>
                    <h3 className='mt-6'>Dodatkowe ustalenia, komentarze</h3>
                    <p>
                        {individualKedOffer.additionalComment.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                {index < individualKedOffer.additionalComment.split('\n').length - 1 && <br/>}
                            </React.Fragment>
                        ))}
                    </p>
                </>
            }

            <Form method="POST" className='mw-md-xl mx-auto border-light rounded shadow p-1'>
                <p className={`fw-medium fs-10 ${individualKedOffer?.additionalComment ? 'mt-8' : 'mt-10'}`}>Poniżej
                    podaj dane
                    osoby, <span
                        className='fw-bold'>dla której</span> kupujesz dostęp do kursu.</p>
                <div className='mb-4 row g-6'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label
                                className='mb-1 fw-medium text-white'
                                htmlFor='purchase-course-name-input'
                            >
                                Imię
                            </label>
                            <input
                                className='form-control text-secondary-dark shadow'
                                id='purchase-course-name-input'
                                type='text'
                                placeholder='Podaj imię'
                                name='name'
                                autoComplete='name'
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label
                                className='mb-1 fw-medium text-white'
                                htmlFor='purchase-course-email-input'
                            >
                                Email
                            </label>
                            <input
                                className='form-control text-secondary-dark shadow'
                                id='purchase-course-email-input'
                                type='email'
                                placeholder='Podaj adres email'
                                name='email'
                                autoComplete='email'
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
                    <span>Przechodząc dalej akceptujesz </span>
                    <Link className='text-success link-success' to='/polityka-prywatnosci'>
                        politykę prywatności
                    </Link>
                    <span>, </span>
                    <Link className='text-success link-success' to='/regulamin'>
                        regulamin
                    </Link>
                    <span> oraz </span>
                    <Link className='text-success link-success' to='/regulamin-ked'>
                        regulamin KED
                    </Link>
                    <span>. </span>
                    {
                        individualKedOffer && <span>Powyższe ustalenia mają wyższy priorytet niż regulamin KED.</span>
                    }
                </p>
                <div className='mb-3 row g-4'>
                    <div className='col-12'>
                        <AnimatedButton
                            className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                            disabled={isSubmitting || !isInputValid}
                            customScale={1.05}
                        >
                            {isSubmitting ? 'Proszę czekać...' : 'Kontynuuj'}
                        </AnimatedButton>
                    </div>
                </div>
            </Form>
        </div>
    </>
}