import {motion} from "framer-motion";
import {ReactNode} from "react";

interface FadeInWhenVisibleProps {
    children: ReactNode

}

export default function StaggerChildrenWhenVisible(props: FadeInWhenVisibleProps) {
    const {children} = props;

    return (
        <motion.div className='row g-8'
                    whileInView="visible"
                    viewport={{once: true}}
                    variants={{
                        hidden: {opacity: 0},
                        visible: {
                            opacity: 1,
                            transition: {
                                staggerChildren: 0.4
                            }
                        },
                    }}
                    initial="hidden"
        >
            {children}
        </motion.div>
    );
}