import React from 'react';
import Meta from "../../components/common/Meta";
import RegisterForm from "../../components/auth/RegisterForm";
import {AuthService} from "../../service/AuthService";
import {ApiError} from "../../service/HttpService";
import MainDesign from "../MainDesign";
import RegisterText from "../../components/auth/RegisterText";

export default function Register() {
    return <>
        <Meta title='Rejestracja'/>
        <MainDesign containerStyles="py-32">
            <RegisterText/>
            <RegisterForm/>
        </MainDesign>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const source = localStorage.getItem('firstJoinedFrom')

    const requestBody = {
        email: formData.get('email') as string,
        name: formData.get('name') as string,
        source: source ?? ''
    };

    try {
        const responseData = await AuthService.initRegistration(requestBody);

        return {
            status: 200,
            body: responseData.message
        };
    } catch (error) {
        console.log(error);
        const apiError = error as ApiError;
        console.error(apiError);
        return {
            status: apiError.httpsStatus || 500,
            body: apiError.message || "Wystąpił błąd."
        };
    }
}

