import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";

interface ExerciseFeedbackButtonProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ExerciseFeedbackButton(props: ExerciseFeedbackButtonProps) {
    const {setOpen} = props;
    return <div className='text-center mw-md-5xl mx-auto mt-16'>
        <AnimatedButton
            className='btn btn-lg btn-success text-lg-center fs-11 py-3 px-4 h-100 shadow'
            onClick={() => setOpen(true)}
        >
            Prześlij feedback / zgłoś błąd
        </AnimatedButton>
    </div>
}