import React from 'react';
import {redirect} from "react-router-dom";
import Meta from "../../components/common/Meta";
import LoginForm from "../../components/auth/LoginForm";
import {AuthService} from "../../service/AuthService";
import MainDesign from "../MainDesign";
import LoginText from "../../components/auth/LoginText";

export default function Login() {
    return (
        <>
            <Meta title='Logowanie'/>
            <MainDesign containerStyles="py-32">
                <LoginText/>
                <LoginForm/>
            </MainDesign>
        </>
    );
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const loginRequest = {
        email: formData.get('email') as string,
        password: formData.get('password') as string,
    };

    const redirectUrl = formData.get('redirectUrl') as string
    try {
        const {isSignedIn} = await AuthService.login(loginRequest);
        if (isSignedIn) {
            return redirect((!redirectUrl || redirectUrl === '' || redirectUrl === '/logout' || redirectUrl === '/login-error') ? '/' : redirectUrl);
        } else {
            return {
                status: 500,
                body: 'Wystąpił problem z logowaniem.'
            }
        }
    } catch (error) {
        const apiError = error as Error;
        if (apiError.name === 'NotAuthorizedException' || apiError.name === 'UserNotFoundException') {
            return {
                status: 400,
                body: 'Niepoprawny adres email lub hasło.'
            }
        } else if (apiError.name === 'UserAlreadyAuthenticatedException') {
            return {
                status: 500,
                body: 'Jesteś już zalogowany.'
            }

        } else {
            return {
                status: 500,
                body: 'Wystąpił błąd: ' + error
            }
        }
    }
}

