import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import AddAIModelButton from "../../../components/admin/ai/AddAIModelButton";
import ModelList from "../../../components/admin/ai/ModelList";
import {ApiError} from "../../../service/HttpService";
import {AICommonService} from "../../../service/ai/AICommonService";
import {Model, ModelRequest} from "../../../model/common/Model";
import {Await, defer, useLoaderData} from "react-router-dom";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import React, {Suspense} from "react";

type LoaderData = {
    models: Model[];
};

export default function AdminAIPage() {
    const {models} = useLoaderData() as LoaderData;

    return <>
        <Meta title='AI'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Konfiguracja modeli' badgeText='AI'
                     subtitle='Zobaczysz tutaj wszystkie modele i z łatwością utworzysz nowy.'/>
            <AddAIModelButton/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={models} errorElement={<ErrorContent/>}>
                    {(loadedModels) => <>
                        <ModelList models={loadedModels}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadAllModels(): Promise<Model[]> {
    return await AICommonService.getAllModels("all");
}

export async function loader() {
    return defer({
        models: loadAllModels(),
    })
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    try {
        if (request.method === "DELETE") {
            const modelId = formData.get('id') as string;

            await AICommonService.deleteModel(modelId);
            return {
                status: 200,
                body: 'Pomyślnie usunięto.'
            }
        } else {

            const request = {
                id: formData.get('id') as string | undefined,
                requestType: formData.get("requestType") as "CREATE" | "EDIT",
                name: formData.get('name') as string,
                modelType: formData.get('modelType') as string,
                modelKey: formData.get('modelKey') as string,
                imageUrl: formData.get('imageUrl') as string,
                prompt: formData.get('prompt') as string,
                isDraft: formData.get('isDraft') as unknown as boolean,
                callCost: formData.get('callCost') as unknown as number,
                description: formData.get('description') as string
            } as ModelRequest

            await AICommonService.createOrEditModel(request);
            return {
                status: 200,
                body: 'Pomyślnie zapisano.'
            }
        }
    } catch (error) {
        console.log(error)
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }

}