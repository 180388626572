import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {ContactRequest} from "../model/user/general/Contact";
import {S3PresignedUrlResponse} from "../model/common/S3PresignedUrl";

export class GeneralService {

    public static sendContactMessage(requestBody: ContactRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/contact',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static generatePresignedUrl(md5HashOfTheFile: string, blog?: boolean): Promise<S3PresignedUrlResponse> {
        return HttpService.sendRequest(
            HttpMethod.POST,
            '/api/generate-presigned-url',
            JSON.stringify({
                md5HashOfTheFile: md5HashOfTheFile,
                blog: blog
            }),
            ContentType.JSON
        );
    }
}