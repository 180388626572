import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import CalendlyContent from "../../../components/user/offer/CalendlyContent";
import ABTestingComponent from "../../../components/ab-testing/ABTestingComponent";
import {KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";


export default function CalendlyScheduleSalesCallMaturaParentPage() {
    return <>
        <Meta title='Umów się na bezpłatną konsultację'/>
        <MainDesign containerStyles='container py-20'>
            <ABTestingComponent
                ComponentA={<CalendlyContent
                    calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania-dla-twojego-maturzysty'
                    vslVimeoId='988769595'
                    isMaturaParent={true}
                    hideBadge={true}
                    headingTitle='Umów się na bezpłatną konsultację'/>}
                ComponentB={<CalendlyContent
                    calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania-dla-twojego-maturzysty'
                    vslVimeoId='988391458'
                    isMaturaParent={true}
                    hideBadge={true}
                    headingTitle='Umów się na bezpłatną konsultację'/>}
                experimentId={KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1}
            />
        </MainDesign>
    </>
}
