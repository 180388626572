import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import AiLandingPageSelectOption from "../../../components/user/ai/pricing/AiLandingPageSelectOption";
import Heading from "../../../components/common/header/Heading";

export default function OfferLandingPageAi() {
    return <>
        <Meta title='Sztuczna inteligencja'/>
        <MainDesign containerStyles='container py-20'>
            <Heading title='Oferta AI' badgeText='oferta'
                     subtitle='Wybierz chat lub konwersacje i poznaj dokładną ofertę.'
                     customSubtitleStyles='text-warning fs-9 fw-medium'/>
            <AiLandingPageSelectOption/>
            <div className='mt-28'/>
        </MainDesign>
    </>
}
