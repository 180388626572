interface AdminNumbersItemProps {
    metricValue: number | string
    metricTitle: string
    smallerText?: boolean
}

export default function AdminNumbersItem(props: AdminNumbersItemProps) {
    const {metricValue, metricTitle, smallerText} = props;
    return <div className='col-12 col-md-6 col-lg-3'>
        <div className='text-center'>
            <h2
                className={`mb-2 font-heading text-success ${smallerText ? 'fs-6' : 'fs-5'}`}
                style={{letterSpacing: '-1px'}}
            >
                {metricValue}
            </h2>
            <p className='mb-0 fs-9 text-secondary-light fw-medium'>
                {metricTitle}
            </p>
        </div>
    </div>
}