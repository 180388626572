import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import React from "react";
import VSLChatContentWithShortVideo from "../../../components/user/offer/VSLChatContentWithShortVideo";
import {useLoaderData} from "react-router-dom";
import {IsLoggedInLoaderData} from "../../../util/Loader";

export default function VSLChatPageWithShortVideo() {
    const {isLoggedIn} = useLoaderData() as IsLoggedInLoaderData;

    return <>
        <Meta title='Zacznij korzystać z Chatu już teraz!'/>
        <MainDesign containerStyles='container py-20'>
            <VSLChatContentWithShortVideo isLoggedIn={isLoggedIn}/>
        </MainDesign>
    </>
}
