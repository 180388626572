import * as React from 'react';
import {useEffect} from 'react';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import IndexPage, {loader as randomBlogArticlesLoader} from './pages/Index';
import Login, {action as loginAction} from "./pages/auth/Login";
import Register, {action as registerAction} from "./pages/auth/Register";
import Contact, {action as contactAction} from "./pages/user/Contact";
import RootLayout from "./pages/Root";
import ActivateAccount, {
    action as activateAccountAction,
    loader as activateAccountLoader
} from "./pages/auth/ActivateAccount";
import {Amplify} from "aws-amplify";
import Error404 from "./pages/common/error/Error404";
import {redirectIfLoggedIn, redirectIfNotAdmin, redirectIfNotLoggedIn, tokenLoader} from "./util/AuthUtil";
import {logoutAction} from "./components/ui/header/Header";
import DailyMathExercises from "./pages/user/exercise/DailyMathExercises";
import {action as newsletterFormAction} from "./components/user/newsletter/NewsletterForm";
import CancelNewsletter, {
    action as cancelNewsletterFormAction,
    loader as cancelNewsletterLoader
} from "./components/user/newsletter/CancelNewsletter";
import AdminIndex, {loader as adminMetricsLoader} from "./pages/admin/AdminIndex";
import AdminRootLayout from "./pages/admin/AdminRoot";
import AdminNewsletter, {action as adminNewsletterAction} from "./pages/admin/newsletter/AdminNewsletter";
import AdminExercises, {
    deleteAction as adminExerciseDeleteAction,
    loader as adminExercisesLoader
} from "./pages/admin/exercise/AdminExercises";
import Exercises, {loader as exercisesLoader} from "./pages/user/exercise/Exercises";
import ExercisePage, {loader as exerciseLoader} from "./pages/user/exercise/ExercisePage";
import AdminCreateOrAddExercise, {
    action as createOrAddExerciseAction
} from "./pages/admin/exercise/AdminCreateOrAddExercise";
import PrivacyPolicyPage from "./pages/user/regulation/PrivacyPolicyPage";
import Newsletter from "./pages/user/Newsletter";
import ResetPassword, {action as resetPasswordAction} from "./pages/auth/ResetPassword";
import ChangePassword, {action as changePasswordAction} from "./pages/auth/ChangePassword";
import ChatBot from "./pages/user/ai/chat/ChatBot";
import {loader as threadsLoader} from "./components/user/ai/chat/Chat";
import AiPricingPage from "./pages/user/ai/AiPricingPage";
import {action as purchaseTokensAction} from "./components/user/ai/pricing/AiPricing";
import BlogPage, {deleteAction as deleteArticleAction, loader as blogArticlesLoader} from "./pages/user/blog/BlogPage";
import BlogPostPage, {loader as blogArticleLoader} from "./pages/user/blog/BlogPostPage";
import AdminCreateOrEditBlogArticlePage, {
    action as createOrEditBlogArticleAction,
    loader as loadArticleToEdit
} from "./pages/admin/blog/AdminCreateOrEditBlogArticlePage";
import AdminBlogPage, {loader as adminBlogArticlesLoader} from "./pages/admin/blog/AdminBlogPage";
import SpeakingBotPage, {
    action as updateSpeakingBotSettingsAction,
    loader as speakingsThreadsAndInfoLoader
} from "./pages/user/ai/speaking/SpeakingBotPage";
import AdminAIPage, {action as adminAIAction, loader as adminAIModelsLoader} from "./pages/admin/ai/AdminAIPage";
import Planner from "./pages/user/marketing/Planner";
import OfferLandingPage from "./pages/user/offer/OfferLandingPage";
import TransactionsPage, {loader as transactionsLoader} from "./pages/user/profile/TransactionsPage";
import {action as sendFeedbackAction} from "./components/user/feedback/FeedbackModal";
import AdminFeedbackPage, {loader as feedbackLoader} from "./pages/admin/feedback/AdminFeedbackPage";
import {HelmetProvider} from "react-helmet-async";
import AdminDiagnosticPage, {loader as diagnosticsLoader} from "./pages/admin/diagnostic/AdminDiagnosticPage";
import ChatLandingPage from "./pages/user/ai/chat/ChatLandingPage";
import ConversationsLandingPage, {
    loader as conversationsLandingPageLoader
} from "./pages/user/ai/speaking/ConversationsLandingPage";
import LearningCourseEnrollPage from "./pages/user/learning-course/LearningCourseEnrollPage";
import AboutUsPage from "./pages/user/AboutUsPage";
import ConfirmNewsletterPage from "./pages/user/marketing/ConfirmNewsletterPage";
import RegulationsPage from "./pages/user/regulation/RegulationsPage";
import LoginError from "./pages/auth/LoginError";
import {ToastProvider} from "./components/ui/toast/ToastContext";
import VSLChatPage, {action as VSLChatPageAction, loader as VSLChatPageLoader} from "./pages/user/offer/VSLChatPage";
import VSLChatTutorialPage from "./pages/user/offer/VSLChatTutorialPage";
import AdminTracesPage, {loader as adminTracesPageLoader} from "./pages/admin/trace/AdminTracesPage";
import ConfirmTransactionPage from "./pages/user/transaction/ConfirmTransactionPage";
import VSLChatPageWithShortVideo from "./pages/user/offer/VSLChatPageWithShortVideo";
import RedirectAfterRegistrationPage from "./pages/user/thank-you/RedirectAfterRegistrationPage";
import {isLoggedInLoader} from "./util/Loader";
import VSLConversationsPageWithShortVideo from "./pages/user/offer/VSLConversationsPageWithShortVideo";
import ProductsPage, {loader as productsPageLoader} from "./pages/user/products/ProductsPage";
import LearningCourseDashboardPage, {
    loader as courseStatisticsLoader
} from "./pages/user/learning-course/LearningCourseDashboardPage";
import {action as completeEpisodeAction} from "./components/user/learning-course/LearningCourseEpisodeComponent";
import LearningCoursePurchasePage, {
    action as coursePurchaseAction,
    loader as individualKedOfferLoader
} from "./pages/user/learning-course/LearningCoursePurchasePage";
import LearningCourseSuccessfulPurchasePage from "./pages/user/learning-course/LearningCourseSuccessfulPurchasePage";
import LearningCourseUnsuccessfulPurchasePage
    from "./pages/user/learning-course/LearningCourseUnsuccessfulPurchasePage";
import AIProductsPage from "./pages/user/products/AIProductsPage";
import CalendlyScheduleKedCallPage from "./pages/user/offer/CalendlyScheduleKedCallPage";
import RegulationsKEDPage from "./pages/user/regulation/RegulationsKEDPage";
import FunnelAllOptInPage, {action as effectiveActionCourseOptInAction} from "./pages/funnel/optin/FunnelAllOptInPage";
import RedirectAfterSchedulingCallPage from "./pages/user/thank-you/RedirectAfterSchedulingCallPage";
import OfferLandingPageAi from "./pages/user/offer/OfferLandingPageAi";
import AdminKedExperimentPage, {
    loader as adminKedExperimentResultsLoader
} from "./pages/admin/ked/AdminKedExperimentPage";
import CoachingSessionPage, {
    action as scheduleCoachingSessionAction
} from "./pages/user/coaching-session/CoachingSessionPage";
import CoachingSessionSuccessfulPurchasePage from "./pages/user/coaching-session/CoachingSessionSuccessfulPurchasePage";
import CoachingSessionUnsuccessfulPurchasePage
    from "./pages/user/coaching-session/CoachingSessionUnsuccessfulPurchasePage";
import RedirectAfterSchedulingIndividualKEDSessionPage
    from "./pages/user/thank-you/RedirectAfterSchedulingIndividualKEDSessionPage";
import RedirectComponent from "./components/common/RedirectComponent";
import SleepChronotypesTestPage from "./pages/user/SleepChronotypesTestPage";
import CalendlyScheduleSalesCallMaturaParentPage from "./pages/user/offer/CalendlyScheduleSalesCallMaturaParentPage";
import RedirectAfterSchedulingCallMaturaParentPage
    from "./pages/user/thank-you/RedirectAfterSchedulingCallMaturaParentPage";
import FunnelRootLayout from "./pages/funnel/FunnelRoot";
import FunnelMaturaParentOptInPage from "./pages/funnel/optin/FunnelMaturaParentOptInPage";
import {funnelMaturaParentOptInAction, funnelMWParentOptInAction} from "./pages/funnel/optin/action"
import KedShowcasePage from "./pages/user/offer/KedShowcasePage";
import AdminKedOfferPage, {action as adminKedOfferAction} from "./pages/admin/ked/AdminKedOfferPage";
import AdminKedUsersPage, {loader as adminKedUsersLoader} from "./pages/admin/ked/AdminKedUsersPage";
import FunnelMaturaParentDirectPage from "./pages/funnel/direct/FunnelMaturaParentDirectPage";
import EffectiveActionCourseMaturaParentThankYouPage
    from "./pages/funnel/direct/EffectiveActionCourseMaturaParentThankYouPage";
import FBAdsLandingPage from "./pages/funnel/direct/FbAdsLandingPage";
import RegulationsMWPage from "./pages/user/regulation/RegulationsMWPage";
import ExtraordinarySupportPurchasePage, {
    action as extraordinarySupportPurchaseAction,
    loader as extraordinarySupportPurchaseLoader
} from "./pages/user/extraordinary-support/ExtraordinarySupportPurchasePage";
import ExtraordinarySupportSuccessfulPurchasePage
    from "./pages/user/extraordinary-support/ExtraordinarySupportSuccessfulPurchasePage";
import ExtraordinarySupportUnsuccessfulPurchasePage
    from "./pages/user/extraordinary-support/ExtraordinarySupportUnsuccessfulPurchasePage";
import ParentOrStudentSelectPage from "./pages/user/offer/ParentOrStudentSelectPage";
import ExtraordinarySupportParentPage from "./pages/funnel/direct/ExtraordinarySupportParentPage";
import ExtraordinarySupportStudentPage from "./pages/funnel/direct/ExtraordinarySupportStudentPage";
import FunnelMWParentOptInPage from "./pages/funnel/optin/FunnelMWParentOptInPage";
import FunnelMWParentVSLPage from "./pages/funnel/after-optin/FunnelMWParentVSLPage";
import FunnelMWParentCalendlyPage from "./pages/funnel/after-optin/FunnelMWParentCalendlyPage";

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_USER_POOL_ID!!,
            userPoolClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID!!,
            loginWith: {
                oauth: {
                    domain: `${process.env.REACT_APP_ENV}-auth-produkacja.auth.eu-central-1.amazoncognito.com`,
                    scopes: ['email', 'profile', 'openid'],
                    responseType: 'token',
                    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_COMMA_SEPARATED!!.split(','),
                    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_COMMA_SEPARATED!!.split(',')
                }
            }
        }
    }
})

const router = createBrowserRouter([
    {
        path: '/admin',
        element: <AdminRootLayout/>,
        errorElement: <Error404/>,
        loader: redirectIfNotAdmin,
        id: 'adminRoot',
        children: [
            {
                index: true,
                element: <AdminIndex/>,
                loader: adminMetricsLoader
            },
            {
                path: 'ai',
                element: <AdminAIPage/>,
                loader: adminAIModelsLoader,
                action: adminAIAction
            },
            {
                path: 'newsletter',
                element: <AdminNewsletter/>,
                action: adminNewsletterAction
            },
            {
                path: 'zadania',
                element: <AdminExercises/>,
                loader: adminExercisesLoader,
                action: adminExerciseDeleteAction
            },
            {
                path: 'zadania/dodaj',
                element: <AdminCreateOrAddExercise actionType="CREATE"/>,
                action: createOrAddExerciseAction
            },
            {
                path: 'zadania/edytuj/:uuid',
                element: <AdminCreateOrAddExercise actionType="EDIT"/>,
                action: createOrAddExerciseAction
            },
            {
                path: 'blog',
                element: <AdminBlogPage/>,
                loader: adminBlogArticlesLoader,
                action: deleteArticleAction
            },
            {
                path: 'blog/dodaj',
                element: <AdminCreateOrEditBlogArticlePage actionType="CREATE"/>,
                loader: loadArticleToEdit,
                action: createOrEditBlogArticleAction
            },
            {
                path: 'blog/edytuj/:uuid',
                element: <AdminCreateOrEditBlogArticlePage actionType="EDIT"/>,
                loader: loadArticleToEdit,
                action: createOrEditBlogArticleAction
            },
            {
                path: 'feedback',
                element: <AdminFeedbackPage/>,
                loader: feedbackLoader
            },
            {
                path: 'diagnostyka',
                element: <AdminDiagnosticPage/>,
                loader: diagnosticsLoader
            },
            {
                path: 'traces',
                element: <AdminTracesPage/>,
                loader: adminTracesPageLoader
            },
            {
                path: 'ked',
                element: <AdminKedExperimentPage/>,
                loader: adminKedExperimentResultsLoader
            },
            {
                path: 'ked/oferta',
                element: <AdminKedOfferPage/>,
                action: adminKedOfferAction,
            },
            {
                path: 'ked/kursanci',
                element: <AdminKedUsersPage/>,
                loader: adminKedUsersLoader
            }
        ]
    },
    // Without cookies! To avoid banner on landing page
    {
        path: '/kurs-efektywnego-dzialania',
        element: <FunnelRootLayout/>,
        errorElement: <Error404/>,
        id: 'funnelRoot',
        children: [
            {
                index: true,
                element: <FunnelAllOptInPage/>,
                action: effectiveActionCourseOptInAction
            },
            {
                path: 'dla-maturzysty',
                element: <FunnelMaturaParentOptInPage/>,
                action: funnelMaturaParentOptInAction
            }
        ]
    },
    {
        path: 'qr',
        id: 'qrRoot',
        children: [
            {
                index: true,
                element: <RedirectComponent to='/mistrzowskie-wsparcie/spotkanie' from='qr'/>
            }
        ]
    },
    {
        path: '/mistrzowskie-wsparcie',
        element: <FunnelRootLayout/>,
        errorElement: <Error404/>,
        id: 'extraordinarySupportRoot',
        children: [
            {
                index: true,
                element: <FunnelMWParentOptInPage/>,
                action: funnelMWParentOptInAction
            },
            {
                path: 'spotkanie',
                element: <FunnelMWParentVSLPage/>,
            },
            {
                path: 'spotkanie/umow',
                element: <FunnelMWParentCalendlyPage/>,
            },
            {
                path: 'video',
                element: <FunnelMaturaParentDirectPage preselectedVariation='A'/>,
            },
            {
                path: 'oferta',
                element: <FunnelMaturaParentDirectPage preselectedVariation='B'/>,
            },
            {
                path: 'dziekuje',
                element: <EffectiveActionCourseMaturaParentThankYouPage/>,
            },
            {
                path: 'fb',
                element: <FBAdsLandingPage/>
            },
            {
                path: 'rodzic',
                element: <ExtraordinarySupportParentPage/>
            },
            {
                path: 'uczen',
                element: <ExtraordinarySupportStudentPage/>
            }
        ]
    },
    {
        path: '/',
        element: <RootLayout/>,
        errorElement: <Error404/>,
        loader: tokenLoader,
        shouldRevalidate: () => true,
        id: 'root',
        children: [
            // @Everyone
            {
                index: true,
                element: <IndexPage/>,
                loader: randomBlogArticlesLoader
            },
            // {
            //     path: 'korepetycje',
            //     element: <KorepetycjePage/>
            // },
            // {
            //     path: 'korepetycje/:subject',
            //     element: <TeamPage/>
            // },
            // @Everyone (who is not logged in)
            {
                path: 'logowanie',
                element: <Login/>,
                loader: redirectIfLoggedIn,
                action: loginAction
            },
            // @Everyone (who is not logged in)
            {
                path: 'rejestracja',
                element: <Register/>,
                loader: redirectIfLoggedIn,
                action: registerAction,
            },
            // @Everyone
            {
                path: 'logout',
                action: logoutAction,
                element: <RedirectComponent to='/'/>
            },
            // @Everyone (who is not logged in)
            {
                path: 'reset-hasla',
                loader: redirectIfLoggedIn,
                action: resetPasswordAction,
                element: <ResetPassword/>
            },
            // @Everyone (who is not logged in)
            {
                path: 'zmien-haslo/:uuid',
                loader: redirectIfLoggedIn,
                action: changePasswordAction,
                element: <ChangePassword/>
            },
            // @Everyone
            {
                path: 'kontakt',
                element: <Contact/>,
                action: contactAction
            },
            // {
            //     path: 'lekcja/:uuid',
            //     element: <Lesson/>,
            //     loader: redirectIfNotLoggedIn
            // },
            // {
            //     path: '/korepetytor/:uuid',
            //     element: <AboutTutor/>
            // },
            // @Everyone
            {
                path: '/aktywuj/:uuid',
                element: <ActivateAccount/>,
                loader: activateAccountLoader,
                action: activateAccountAction
            },
            {
                path: '/potwierdz-newsletter',
                element: <ConfirmNewsletterPage/>,
            },
            {
                path: '/dziekuje',
                element: <ConfirmTransactionPage/>,
            },
            // @Everyone (who is logged in)
            // {
            //     path: '/zajecia',
            //     element: <LessonList/>,
            //     loader: redirectIfNotLoggedIn
            // },
            // {
            //     path: '/polecaj',
            //     element: <RecommendationSystem/>
            // },
            // @Everyone (who is logged in)
            // {
            //     path: 'profil',
            //     element: <UserProfilePage/>,
            // },
            // @Everyone (who is logged in)
            {
                path: 'transakcje',
                element: <TransactionsPage/>,
                loader: transactionsLoader
            },
            // @Everyone (who is logged in)
            {
                path: 'chat',
                element: <ChatBot/>,
                loader: threadsLoader
            },
            // @Everyone (who is logged in)
            {
                path: 'konwersacje',
                element: <SpeakingBotPage/>,
                loader: speakingsThreadsAndInfoLoader,
                action: updateSpeakingBotSettingsAction
            },
            // @Everyone
            {
                path: 'ai/cennik',
                element: <AiPricingPage/>,
                loader: isLoggedInLoader,
                action: purchaseTokensAction
            },
            // @Everyone
            {
                path: 'oferta/chat',
                element: <ChatLandingPage/>,
                loader: isLoggedInLoader,
                action: purchaseTokensAction
            },
            // @Everyone
            {
                path: 'oferta/konwersacje',
                element: <ConversationsLandingPage/>,
                loader: conversationsLandingPageLoader,
                action: purchaseTokensAction
            },
            // @Everyone
            {
                path: 'oferta/kurs-efektywnego-dzialania',
                element: <LearningCourseEnrollPage/>,
            },
            // @Everyone
            {
                path: 'oferta/kurs-efektywnej-nauki',
                element: <LearningCourseEnrollPage/>
            },
            // @Everyone
            {
                path: 'oferta/konsultacje',
                element: <CoachingSessionPage/>,
                action: scheduleCoachingSessionAction
            },
            // @Everyone, but visible only after payment
            {
                path: 'zakup/konsultacje/sukces',
                element: <CoachingSessionSuccessfulPurchasePage/>,
            },
            // @Everyone, but visible only after payment
            {
                path: 'zakup/konsultacje/blad',
                element: <CoachingSessionUnsuccessfulPurchasePage/>,
            },
            // @Everyone, but not visible on the website before call
            {
                path: 'zakup/kurs-efektywnego-dzialania',
                element: <LearningCoursePurchasePage/>,
                loader: individualKedOfferLoader,
                action: coursePurchaseAction
            },
            // @Everyone, but not visible on the website before call
            {
                path: 'zakup/kurs-efektywnego-dzialania/:ksuid',
                element: <LearningCoursePurchasePage/>,
                loader: individualKedOfferLoader,
                action: coursePurchaseAction
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/kurs-efektywnego-dzialania/sukces',
                element: <LearningCourseSuccessfulPurchasePage/>,
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/kurs-efektywnego-dzialania/blad',
                element: <LearningCourseUnsuccessfulPurchasePage/>,
            },
            // @Everyone, but not visible on the website before call
            {
                path: 'zakup/mistrzowskie-wsparcie',
                element: <ExtraordinarySupportPurchasePage/>,
                loader: extraordinarySupportPurchaseLoader,
                action: extraordinarySupportPurchaseAction
            },
            // @Everyone, but not visible on the website before call
            {
                path: 'zakup/mistrzowskie-wsparcie/:ksuid',
                element: <ExtraordinarySupportPurchasePage/>,
                loader: extraordinarySupportPurchaseLoader,
                action: extraordinarySupportPurchaseAction
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/mistrzowskie-wsparcie/sukces',
                element: <ExtraordinarySupportSuccessfulPurchasePage/>
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/mistrzowskie-wsparcie/blad',
                element: <ExtraordinarySupportUnsuccessfulPurchasePage/>
            },
            // @Everyone
            {
                path: 'oferta',
                element: <OfferLandingPage/>,
            },
            // @Everyone
            {
                path: 'oferta/ai',
                element: <OfferLandingPageAi/>,
            },
            // @Everyone
            {
                path: 'o-nas',
                element: <AboutUsPage/>,
            },
            // @Everyone
            {
                path: 'planer',
                element: <Planner/>,
                action: newsletterFormAction
            },
            // @Everyone
            {
                path: 'newsletter',
                element: <Newsletter/>,
                action: newsletterFormAction
            },
            // @Everyone
            {
                path: 'anuluj-newsletter',
                element: <CancelNewsletter/>,
                loader: cancelNewsletterLoader,
                action: cancelNewsletterFormAction
            },
            // @Everyone
            {
                path: 'zadania',
                element: <DailyMathExercises/>,
                action: newsletterFormAction
            },
            // @Logged users
            {
                path: 'lista-zadan',
                element: <Exercises/>,
                loader: exercisesLoader
            },
            // @Everyone
            {
                path: 'zadanie/:uuid',
                element: <ExercisePage/>,
                loader: exerciseLoader
            },
            // @Everyone
            {
                path: 'polityka-prywatnosc',
                element: <PrivacyPolicyPage/>,
            },
            // @Everyone
            {
                path: 'polityka-prywatnosci',
                element: <PrivacyPolicyPage/>,
            },
            // @Everyone
            {
                path: 'regulamin',
                element: <RegulationsPage/>,
            },
            // @Everyone
            {
                path: 'regulamin-ked',
                element: <RegulationsKEDPage/>,
            },
            {
                path: 'regulamin-mw',
                element: <RegulationsMWPage/>,
            },
            // @Everyone
            {
                path: 'blog',
                element: <BlogPage/>,
                loader: blogArticlesLoader,
                action: deleteArticleAction,
            },
            // @Everyone
            {
                path: 'blog/:uuid',
                element: <BlogPostPage/>,
                loader: blogArticleLoader,
            },
            // @Everyone (who is logged in)
            {
                path: 'feedback',
                action: sendFeedbackAction
            },
            // @Everyone
            {
                path: 'login-error',
                element: <LoginError/>
            },
            // @Everyone
            {
                path: 'produkt/chat',
                element: <VSLChatPage/>,
                loader: VSLChatPageLoader,
                action: VSLChatPageAction
            },
            // @Everyone
            {
                path: 'chat/poradnik',
                element: <VSLChatTutorialPage/>,
                loader: VSLChatPageLoader,
                action: VSLChatPageAction
            },
            // @Everyone
            {
                path: 'video/chat',
                element: <VSLChatPageWithShortVideo/>,
                loader: isLoggedInLoader,
            },
            // @Everyone
            {
                path: 'video/konwersacje',
                element: <VSLConversationsPageWithShortVideo/>,
                loader: isLoggedInLoader,
            },
            // @Everyone
            {
                path: 'rejestracja-udana',
                element: <RedirectAfterRegistrationPage/>,
            },

            // @OnlyLoggedIn
            {
                path: 'produkt',
                element: <ProductsPage/>,
                loader: productsPageLoader
            },
            // @OnlyLoggedIn
            {
                path: 'produkt/ai',
                element: <AIProductsPage/>,
                loader: redirectIfNotLoggedIn
            },
            // @OnlyLoggedIn
            {
                path: 'produkt/kurs-efektywnego-dzialania',
                element: <LearningCourseDashboardPage/>,
                loader: courseStatisticsLoader,
                action: completeEpisodeAction
            },
            {
                path: 'produkt/kurs-efektywnego-dzialania/spotkanie/dziekuje',
                element: <RedirectAfterSchedulingIndividualKEDSessionPage/>,
            },

            // @Everyone
            {
                path: 'spotkanie',
                element: <CalendlyScheduleKedCallPage/>
            },
            // @Everyone
            {
                path: 'spotkanie/dla-maturzysty',
                element: <CalendlyScheduleSalesCallMaturaParentPage/>
            },
            // @Everyone
            {
                path: 'spotkanie/dziekuje',
                element: <RedirectAfterSchedulingCallPage/>
            },
            // @Everyone
            {
                path: 'spotkanie/dla-maturzysty/dziekuje',
                element: <RedirectAfterSchedulingCallMaturaParentPage/>
            },
            {
                path: 'ked/prezentacja',
                element: <KedShowcasePage/>,
            },
            {
                path: 'yt',
                element: <RedirectComponent to={'/oferta'} from='yt'/>
            },
            {
                path: 'tiktok',
                element: <RedirectComponent to={'/oferta'} from='tiktok'/>
            },
            {
                path: 'ig',
                element: <RedirectComponent to={'/oferta'} from='ig'/>
            },
            {
                path: 'chronotypy',
                element: <SleepChronotypesTestPage/>
            },
            {
                path: 'start',
                element: <ParentOrStudentSelectPage/>
            }
        ]
    },
]);

function App() {
    useEffect(() => {
        let error = new URLSearchParams(window.location.hash.substring(1)).get('error_description');
        if (error) {
            if (error.startsWith('PreSignUp failed with error Użytkownik o podanym adresie email istnieje')) {
                window.location.href = '/login-error?error_description=Użytkownik o podanym adresie email istnieje już w bazie danych. Zaloguj się bezpośrednio przy użyciu adresu email.'
            } else {
                window.location.href = '/login-error'
            }
        }
    }, []);

    return <HelmetProvider>
        <ToastProvider>
            <RouterProvider router={router}/>
        </ToastProvider>
    </HelmetProvider>
}

export default App;

export type ActionResponse = {
    body: string
    status: number
}
