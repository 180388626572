import React, {useState} from "react";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import CalendlyModalLazy from "../../user/calendly/CalendlyModalLazy";
import ScheduleCallButtonMaturaParent from "./ui/ScheduleCallButtonMaturaParent";
import {MW_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";
import {useSearchParams} from "react-router-dom";
import ExtraordinarySupportParentExplanation from "./ExtraordinarySupportParentExplanation";
import usePageTracking from "../../../hooks/usePageTracking";

export default function ExtraordinarySupportParent() {
    const [searchParams] = useSearchParams();
    const [open, setOpen] = useState(false);

    usePageTracking();

    return <div className='mw-md-3xl mx-auto text-center text-white mb-20'>
        <h1 className='text-danger fs-7 mt-12'>Jak spać spokojnie, <u>nie martwiąc się</u> o przyszłość swojego dziecka?
        </h1>
        <p className='fs-11 fw-medium text-danger'>(bez inwestowania w zajęcia dodatkowe czy korepetycje i ciągłego
            poczucia niepewności)</p>

        <p className='mt-8 fs-10 fw-medium'>Jeżeli jesteś już zmęczony martwieniem się o swoje dziecko, wydawaniem
            pieniędzy na zajęcia
            dodatkowe, zastanawianiem się, czy robisz wystarczająco... Poczuciem, że być może inni robią więcej...
        </p>

        <h1 className='text-center fs-7 mt-8'>W takim przypadku, mogę pomóc.</h1>

        <p className='mt-8 fs-10 fw-medium'>Wyobraź sobie, że jest dzień matur, a Ty masz spokojną głowę, bo wiesz, że
            Twoje dziecko na pewno sobie poradzi. Że zapewniłeś mu wszystko, czego potrzebowało, by osiągnęło
            sukces. </p>

        <p className='mt-8 fs-10 fw-medium'>Pójdźmy jeszcze trochę dalej w przyszłość. Twoje dziecko właśnie się
            dowiedziało, że dostało się na wymarzone studia. Podchodzi do Ciebie i dziękuje Ci za Twoje zaangażowanie.
            Jest Ci wdzięczne, że mu w tym pomogłeś. Że zapewniłeś mu wszelkie niezbędne narzędzia, dzięki którym z
            łatwością zdało wszystkie egzaminy.</p>

        <h1 className='text-center fs-7 mt-8'>Jak wtedy byś się czuł?</h1>

        <p className='mt-8 fs-10 fw-medium'>Gdybym dosłownie mógł poprowadzić Twoje dziecko za rękę, nauczyć je działać
            efektywnie i sprawić, że będzie w stanie zrealizować każdy swój cel... </p>

        <p className='mt-8 fs-10 fw-medium'>Jak myślisz, jak to by wpłynęło na jego pewność siebie?</p>
        <p className='mt-8 fs-10 fw-medium'>Nie mówiąc już o sukcesach w przyszłości...</p>

        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sukces-dziecka.png'
            className='lazyload img-fluid' alt='Sukces dziecka'/>

        <h1 className='text-center fs-7 mt-8'>Aby pokazać Ci w jaki sposób działam:</h1>
        <p className='mt-8 fs-10 fw-medium'>Te strategie rozwiązują dosłownie wszystkie problemy nastolatków. To coś
            ponad szkołę średnią czy maturę.
        </p>
        <p className='mt-8 fs-10 fw-medium'>
            Koniec z zamartwianiem się o wyniki egzaminów. Koniec ze
            stresem.
            Koniec z poczuciem przytłoczenia. Koniec z bezmyślnym gapieniem się w telefon. Koniec z narzekaniem na świat
            zewnętrzny.</p>
        <p className='mt-8 fs-10 fw-medium'>
            A to dopiero początek...
        </p>

        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Twojemu dziecku, być pewnym siebie w każdej
            sytuacji.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Twojemu dziecku, w pełni wykorzystać swój
            potencjał.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Twojemu dziecku, osiągać znacznie więcej od
            innych.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Twojemu dziecku, dostać się na dowolne studia.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Twojemu dziecku, zrealizować każdy swój cel.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności pomogą Twojemu dziecku, <b>być sobą</b>.</p>

        <h1 className='text-center fs-7 mt-8'>Wyobraź sobie, jak to wszystko dzieje się na Twoich oczach.</h1>
        <p className='mt-8 fs-10 fw-medium'>Wiem, co sobie właśnie myślisz: <i>"Przecież to niemożliwe..."</i>. I nie
            winię Cię za to. Jak
            się jednak zaraz przekonasz, niemożliwe może stać się możliwe...</p>

        <p className='mt-8 fs-10 fw-medium'>Poczuj, jak spada z Ciebie całe brzemię odpowiedzialności.</p>
        <p className='mt-8 fs-10 fw-medium'>Pracuję z Twoim dzieckiem indywidualnie.</p>
        <p className='mt-8 fs-10 fw-medium'>Uczę je Działać Efektywnie.</p>
        <p className='mt-8 fs-10 fw-medium'>Dostosowuję cały materiał pod jego <b>indywidualne</b> potrzeby.</p>
        <p className='mt-8 fs-10 fw-medium'>Organizuję mu w razie potrzeby pomoc od sprawdzonych ekspertów.</p>
        <p className='mt-8 fs-10 fw-medium'>Pomagam mu, pokonać każdą trudność.</p>
        <p className='mt-8 fs-10 fw-medium'>W skrócie, zapewniam Twojemu dziecku MISTRZOWSKIE wsparcie.</p>

        <h1 className='text-center fs-7 mt-8'>Czy Twoje dziecko miało kiedyś MISTRZOWSKIE wsparcie?</h1>
        <p className='mt-8 fs-10 fw-medium'>Co to właściwie oznacza? Już tłumaczę...</p>

        <ExtraordinarySupportParentExplanation/>

        <h1 className='text-center fs-7 mt-8'>Czy myślisz, że to mogłoby pomóc Twojemu dziecku?</h1>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={1}
                                        customExperiment={MW_EXPERIMENT_ID_1}/>

        <h1 className='text-center fs-7 mt-8'>Czy wspominałem już o indywidualności?</h1>
        <p className='mt-8 fs-10 fw-medium'>Na samym początku współpracy, organizuję każdej osobie <b>Test
            Gallupa</b> oraz <b>Test na Chronotypy Snu</b>.</p>
        <p className='mt-8 fs-10 fw-medium'>Oznacza to, że już od samego początku biorę pod uwagę
            preferencje każdej osoby. Odkrywam jej mocne strony i wyznaczam jej naturalny cykl dobowy. </p>
        <p className='mt-8 fs-10 fw-medium'>Cała nasza współpraca opiera się na indywidualnym podejściu.</p>
        <p className='mt-8 fs-10 fw-medium'>Wiem, że każda osoba jest inna, dlatego jest to niezbędny element.</p>

        <h1 className='text-center fs-7 mt-8'>Kim ja w ogóle jestem, żeby uczyć takich rzeczy?</h1>
        <p className='mt-8 fs-10 fw-medium'>Pewnie zastanawiasz się, kim właściwie jestem, żeby obiecywać takie
            rezultaty?</p>
        <p className='mt-8 fs-10 fw-medium'>Od ponad 6 lat optymalizowałem swój tryb życia,
            aby działać efektywniej i zrealizować postawione przez siebie cele.</p>
        <p className='mt-8 fs-10 fw-medium'>Pokonałem uzależnienie od telefonu i komputera.</p>
        <p className='mt-8 fs-10 fw-medium'>Ukończyłem jeden z najlepszych kierunków informatycznych w Polsce.</p>
        <p className='mt-8 fs-10 fw-medium'>Już podczas studiów, pracowałem w swojej branży.</p>
        <p className='mt-8 fs-10 fw-medium'>Równolegle, rozwijałem też swoją pierwszą działalność.</p>
        <p className='mt-8 fs-10 fw-medium'>A to wszystko bez zaniedbywania zdrowia, snu czy relacji międzyludzkich.</p>
        <p className='mt-8 fs-10 fw-medium'>Zbudowałem w tym czasie świetny związek i pobiłem swój rekord na
            półmaratonie. </p>
        <p className='mt-8 fs-10 fw-medium'>Więc jak widzisz, wszystko jest możliwe. Wystarczy mieć do tego odpowiednie
            narzędzia. </p>
        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/opt-in-photo2.png'
            className='img-fluid lazyload' alt='Mikołaj'/>
        <p className='mt-8 fs-10 fw-medium'>Od ponad 4 lat, pracuję indywidualnie z licealistami, pomagając im
            skutecznie realizować swoje cele. </p>
        <p className='mt-8 fs-10 fw-medium'>Dzięki mojej pomocy, już dziesiątki osób dostały się na wymarzone
            studia, do świetnej pracy, lub wybrały własną ścieżką, nie przejmując się opinią innych. </p>
        <p className='mt-8 fs-10 fw-medium'>A najważniejsze jest to, że realizują siebie i są szczęśliwe.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={2}
                                        customExperiment={MW_EXPERIMENT_ID_1}/>

        <h1 className='text-center fs-7 mt-8'>Ale jeżeli wolisz nic nie zmieniać...</h1>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli wolisz dalej się zamartwiać, czy Twoje dziecko poradzi sobie w
            przyszłości... </p>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli wolisz pozwolić innym, aby zajęli Twojemu dziecku miejsce na jego
            wymarzonych studiach lub pracy... </p>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli wolisz mieć poczucie, że mogłeś zrobić coś więcej i pomóc mu w
            osiągnięciu jego celów... </p>
        <p className='mt-8 fs-10 fw-medium'>Proszę bardzo. Nie mogę podjąć za Ciebie decyzji.</p>
        <p className='mt-8 fs-10 fw-medium'>Mogę jednak całkowicie wyeliminować ryzyko...</p>

        <h1 className='text-center fs-7 mt-8'>Gwarancja sukcesu</h1>
        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/gwarancja.png'
            className='img-fluid lazyload' alt='Gwarancja' style={{maxWidth: '300px'}}/>
        <p className='mt-8 fs-10 fw-medium'>Jeżeli w ciągu pierwszych dwóch tygodni, Twojemu dziecku nie spodoba się
            współpraca ze mną, zwrócę Ci 100% pieniędzy. </p>
        <p className='mt-8 fs-10 fw-medium'>Jest to wystarczający czas, aby sprawdzić, czy się dogadujemy i czy dobrze
            nam się ze sobą współpracuje. </p>
        <p className='mt-8 fs-10 fw-medium'>Co więcej, nie podpisujemy żadnych zobowiązujących dokumentów i współpracę
            możesz zakończyć w dowolnym momencie.</p>
        <p className='mt-8 fs-10 fw-medium'>Tym bardziej, że po kilku miesiącach Twoje dziecko nie będzie
            potrzebowało już niczyjej pomocy...</p>
        <p className='mt-8 fs-10 fw-medium'>Zgadza się, dobrze przeczytałeś.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={3}
                                        customExperiment={MW_EXPERIMENT_ID_1}/>

        <h1 className='text-center fs-7 mt-8'>Jednak mój czas też jest ograniczony...</h1>
        <p className='mt-8 fs-10 fw-medium'>Każdy z nas, ja, Ty, Twoje dziecko, mamy do dyspozycji 24 godziny każdego
            dnia. </p>
        <p className='mt-8 fs-10 fw-medium'>Dlatego, mogę współpracować tylko z ograniczoną liczbą osób. </p>
        <p className='mt-8 fs-10 fw-medium'>Z tego powodu, ta okazja nie potrwa wiecznie.</p>
        <p className='mt-8 fs-10 fw-medium'>Mogę przyjąć tylko <b>10 osób</b> do tak kompleksowej, indywidualnej
            współpracy.</p>
        <p className='mt-8 fs-10 fw-medium'>Być może jutro okaże się, że już nie ma miejsc...</p>
        <p className='mt-8 fs-10 fw-medium'>Robię wszystko, aby zapewnić moim podopiecznym najlepszą pomoc, jaką tylko
            mogliby sobie wymarzyć...</p>
        <p className='mt-8 fs-10 fw-medium'>Nie pozwól, żeby inni Cię wyprzedzili...</p>
        <p className='mt-8 fs-10 fw-medium'>Nie czekaj i zajmij miejsce swojemu dziecku już teraz.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={4}
                                        customExperiment={MW_EXPERIMENT_ID_1}/>

        <p className='mt-8 fs-10 fw-medium'>Efektywne Działanie to coś ponad szkołę średnią czy
            studia.</p>
        <p className='mt-8 fs-10 fw-medium'>Te umiejętności to skuteczna realizacja każdego swojego celu.</p>
        <p className='mt-8 fs-10 fw-medium'>To bezpieczna przyszłość.</p>
        <p className='mt-8 fs-10 fw-medium'>Pracodawcy płacą setki tysięcy złotych za szkolenia swoich pracowników,
            które tego właśnie mają ich nauczyć...</p>
        <p className='mt-8 fs-10 fw-medium'>Ludzie sukcesu mówią, że zawdzięczają swoje osiągnięcia przede wszystkim
            dyscyplinie, rutynie, umiejętności planowania i odpowiedniemu nastawieniu.</p>
        <p className='mt-8 fs-10 fw-medium'>A to tylko część tego, co nazywam "Efektywnym Działaniem"...</p>
        <p className='mt-8 fs-10 fw-medium'>Dzięki najnowszym odkryciom i technologiom, te umiejętności może z
            łatwością wykształcić w sobie każda osoba.</p>
        <p className='mt-8 fs-10 fw-medium'>Skoro inni mogą, to dlaczego Twoje dziecko miałoby nie dać rady?</p>
        <p className='mt-8 fs-10 fw-medium'>Osobiście zadbam, żeby Twoje dziecko się ich nauczyło i stosowało je w swoim
            życiu.</p>
        <p className='mt-8 fs-10 fw-medium'><b>Jedyne co musisz zrobić, to umówić się ze mną na bezpłatne spotkanie</b>,
            na którym porozmawiamy o potrzebach Twojego dziecka.</p>
        <p className='mt-8 fs-10 fw-medium'>Ja zrobię całą resztę.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={5}
                                        customExperiment={MW_EXPERIMENT_ID_1}/>

        <p className='mt-8 fs-10 fw-medium'>Postaw pierwszy krok w kierunku lepszej przyszłości.</p>
        <p className='mt-8 fs-10 fw-medium'>Obiecuję Ci, że podziękujesz sobie za to.</p>
        <p className='mt-8 fs-10 fw-medium'>I dokładnie to samo zrobi Twoje dziecko.</p>
        <img
            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ukonczenie+studiow.png'
            className='img-fluid lazyload' alt='Ukończenie studiów'/>
        <p className='mt-8 fs-10 fw-medium'>Dlatego już teraz umów się na bezpłatną konsultację.</p>
        <p className='mt-8 fs-10 fw-medium'>W najgorszym wypadku, stracisz 45 minut swojego czasu.</p>
        <p className='mt-8 fs-10 fw-medium'>A w najlepszym?</p>
        <p className='mt-8 fs-10 fw-medium'>Twoje dziecko w pełni wykorzysta swój potencjał, ukończy świetne studia i
            dostanie się do wymarzonej pracy
            lub założy własny biznes. </p>
        <p className='mt-8 fs-10 fw-medium'>A przede wszystkim?</p>
        <p className='mt-8 fs-9 fw-medium'><b>Będzie szczęśliwe</b>.</p>
        <p className='mt-8 fs-9 fw-medium'><b>Będzie sobą</b>.</p>
        <ScheduleCallButtonMaturaParent setOpen={setOpen} variation='A' buttonNum={6}
                                        customExperiment={MW_EXPERIMENT_ID_1}/>

        {open && (
            <CalendlyModalLazy open={open} setOpen={setOpen}
                               calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania-dla-twojego-dziecka'
                               onEventScheduled={() => {
                                   TraceService.addTraceWithExperiment(TraceType.CalendlyEventScheduled, MW_EXPERIMENT_ID_1, 'A', searchParams.get('source'))
                               }}/>
        )}
    </div>
}