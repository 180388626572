import Heading from "../../common/header/Heading";
import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import TiktokPixel from "tiktok-pixel";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

interface VSLChatContentProps {
    isLoggedIn: boolean
}

export default function VSLChatContentWithShortVideo(props: VSLChatContentProps) {
    const {isLoggedIn} = props;

    return <>
        <Heading customStyles='mb-6 text-center mw-md-4xl mx-auto'
                 title='Rozwiąż i zrozum każde zadanie w kilka sekund'
                 customTitleStyles='font-heading mb-4 fs-4 text-white'
                 customSubtitleStyles='text-warning fs-9 fw-medium'
                 subtitle='Twój wirtualny korepetytor, dostępny zawsze i wszędzie, 24/7!'/>
        <div className='mw-md-3xl mx-auto mb-8 position-relative'>
            <Vimeo
                className='h-100'
                video="https://player.vimeo.com/video/945310014"
                responsive={true}
                autoplay={true}
                dnt={true}
            />
        </div>
        <div className='text-center mw-md-2xl mx-auto mt-16'>
            <AnimatedLink
                className="mx-auto mw-md-2xl btn btn-xl fw-bold btn-success w-100 text-success-light shadow"
                onClick={() => {
                    localStorage.setItem('redirectUrlAfterRegistration', '/chat')
                    TiktokPixel.track("ClickButton", {
                        "contents": [
                            {
                                "content_id": "short-video-cta-button", // string. ID of the product. Example: "1077218".
                                "content_name": "RegisterRedirect" // string. The name of the page or product. Example: "shirt".
                            }
                        ],
                    }, {debug: true});
                    TraceService.addTrace(TraceType.BtnClick, 'short-video-cta-button');
                }}
                to={isLoggedIn ? '/chat' : '/rejestracja'}>
                Testuję już teraz
            </AnimatedLink>
        </div>
    </>
}
