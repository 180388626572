import React, {useState} from "react";
import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import CreateOrEditBlogForm from "../../../components/admin/blog/CreateOrEditBlogForm";
import CreateOrEditBlogPostLivePreview from "../../../components/admin/blog/CreateOrEditBlogPostLivePreview";
import {defer, Params, redirect, useLoaderData, useParams} from "react-router-dom";
import {BlogRequest, BlogWithRandomArticles} from "../../../model/user/blog/Blog";
import {BlogService} from "../../../service/BlogService";
import {DeferredData} from "@remix-run/router/dist/utils";

interface AdminCreateOrEditBlogProps {
    actionType: "CREATE" | "EDIT"
}

type PathParams = {
    uuid: string | undefined;
};

type BlogArticleData = {
    blogWithRandomArticles: BlogWithRandomArticles | null;
};
export default function AdminCreateOrEditBlogArticlePage(props: AdminCreateOrEditBlogProps) {
    const {actionType} = props;
    const {blogWithRandomArticles} = useLoaderData() as BlogArticleData;

    const article = blogWithRandomArticles?.article;

    const [data, setData] = useState(article?.content ?? '');
    const [title, setTitle] = useState(article?.title ?? '');
    const [subtitle, setSubTitle] = useState(article?.subtitle ?? '');
    const [tags, setTags] = useState(article?.tags.join() ?? '');
    const [imgUrl, setImgUrl] = useState(article?.imageUrl ?? '');
    const [isDraft, setIsDraft] = useState(article?.isDraft ?? false);

    const {uuid} = useParams<PathParams>();
    if (actionType === "EDIT" && !uuid) {
        throw new Error("Aby edytować, wprowadź UUID!");
    }

    return <>
        <Meta title='Blog'/>
        <MainDesign containerStyles='py-20'>
            <div className='row g-16'>
                <div className='col-12 col-md-6'>
                    <CreateOrEditBlogForm title={title} setTitle={setTitle} subtitle={subtitle}
                                          setSubTitle={setSubTitle} content={data} setContent={setData} tags={tags}
                                          setTags={setTags} imgUrl={imgUrl}
                                          isDraft={isDraft}
                                          setIsDraft={setIsDraft}
                                          setImgUrl={setImgUrl} actionType={actionType} id={uuid}/>

                </div>
                <div className='col-12 col-md-6'>
                    <CreateOrEditBlogPostLivePreview title={title} subtitle={subtitle} tags={tags}
                                                     imgUrl={imgUrl}
                                                     content={data}/>
                </div>

            </div>
        </MainDesign>
    </>
}

async function loadArticle(uuid: string): Promise<BlogWithRandomArticles> {
    return await BlogService.getArticle(uuid, true);
}

export async function loader({params}: { params: Params<"uuid"> }): Promise<DeferredData | BlogArticleData> {
    if (!params.uuid) {
        return {
            blogWithRandomArticles: null
        };
    }
    return defer({
        // load it immediately since we have to set states correctly
        blogWithRandomArticles: await loadArticle(params.uuid!!)
    });
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const articleData: BlogRequest = {
        id: formData.get('id') as string | undefined,
        requestType: formData.get("requestType") as "CREATE" | "EDIT",
        title: formData.get('title') as string,
        subtitle: formData.get('subtitle') as string,
        tags: formData.get('tags') as string,
        imageUrl: formData.get('imageUrl') as string,
        content: formData.get('content') as string,
        isDraft: formData.get('isDraft') as unknown as boolean
    };

    try {
        const redirectUrl = await BlogService.createOrEditArticle(articleData);
        return redirect(`/blog/${redirectUrl}`);
    } catch (error) {
        const apiError = error as Error;
        return {
            status: 500,
            body: apiError.message
        }
    }
}