import BlogPost from "../../user/blog/BlogPost";
import React from "react";
import {Blog, BlogWithRandomArticles} from "../../../model/user/blog/Blog";
import BlogPostItem from "../../user/blog/BlogPostItem";

interface CreateOrEditBlogPostLivePreviewProps {
    title: string;
    subtitle: string;
    tags: string;
    content: string;
    imgUrl: string;
    addedAt?: string;
}

export default function CreateOrEditBlogPostLivePreview(props: CreateOrEditBlogPostLivePreviewProps) {
    const {title, subtitle, tags, content, imgUrl, addedAt} = props;

    const blog: Blog = {
        id: 'id',
        title: title,
        subtitle: subtitle,
        tags: tags.split(','),
        content: content,
        author: "Twoje imię",
        addedAt: addedAt ?? new Date().toISOString(),
        imageUrl: imgUrl,
        uniqueViews: 0,
        repeatedViews: 0
    }
    const blogWithRandomArticles = {
        article: blog,
        randomArticles: []
    } as BlogWithRandomArticles
    return <div className='p-12'>
        <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
          Podgląd
        </span>
        <h1
            className='mb-6 font-heading fs-5 text-white'
            style={{letterSpacing: '-1px'}}
        >
            Podgląd postu
        </h1>
        <p className='mb-8 fs-9 fw-medium text-secondary-light'>
            Zobacz w czasie rzeczywistym, jak będzie wyglądać Twój post.
        </p>

        <div className='mb-6 fs-10 text-white'>
            <BlogPost blogWithRandomArticles={blogWithRandomArticles} showBackButtonToAdminPanel={false}
                      disableBackLink={true}/>
        </div>
        <hr className='text-white'/>
        <h1
            className='mb-6 font-heading fs-5 text-white'
            style={{letterSpacing: '-1px'}}
        >
            Podgląd na liście
        </h1>
        <p className='mb-8 fs-9 fw-medium text-secondary-light'>
            Zobacz, jak Twój post będzie wyglądać na liście.
        </p>
        {/*In this case it does not matter because we don't delete article*/}
        <BlogPostItem blogItem={blog} isAdmin={true} readonly={true} setArticleToDelete={() => null}
                      setConfirmDeleteModalOpen={() => null}/>
    </div>
}