import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import RedirectAfterSchedulingCallMaturaParent
    from "../../../components/user/thank-you/RedirectAfterSchedulingCallMaturaParent";

export default function RedirectAfterSchedulingCallMaturaParentPage() {

    return (
        <>
            <Meta title='Spotkanie zostało umówione!'/>
            <MainDesign containerStyles='container py-28'>
                <Heading badgeText='sukces'
                         title='WAŻNE: Proszę Cię o zrobienie tych dwóch rzeczy.'
                         customSubtitleStyles='fs-10 fw-medium text-warning mw-md-3xl mx-auto text-center'
                         subtitle='Sukces! Spotkanie zostało umówione. Wykonaj te dwa kroki, aby je potwierdzić. Zajmie Ci to maksymalnie minutę. W przeciwnym razie będziemy zmuszeni odwołać Twoją rozmowę i oddać miejsce komuś innemu.'/>
                <RedirectAfterSchedulingCallMaturaParent/>
            </MainDesign>
        </>
    );
}