import React, {useEffect, useRef, useState} from 'react';
import {Form, Link, NavLink, useActionData, useNavigate, useRouteLoaderData} from "react-router-dom";
import {useToast} from "../toast/ToastContext";
import {AuthService} from "../../../service/AuthService";
import {ActionResponse} from "../../../App";
import MainDesign from "../../../pages/MainDesign";
import {
    hasAdminGroup,
    hasEffectiveActionCourseSubscriberGroup,
    loadTokenWithGroupsFromLoaderData
} from "../../../util/AuthUtil";
import NavLinkItem from "../../common/header/NavLinkItem";
import AnimatedLink from "../motion/AnimatedLink";
import {motion} from 'framer-motion';
import HeaderDropdownMenu from "./HeaderDropdownMenu";
import HeaderButtonOpenPhoneMenu from "./HeaderButtonOpenPhoneMenu";

interface HeaderProps {
    mainPage?: HeaderLinkItem,
    topItems: HeaderLinkItem[]
    adminPanel: boolean;
}

interface HeaderLinkItem {
    title: string
    to: string
}

export default function Header(props: HeaderProps) {
    const {mainPage, topItems, adminPanel} = props;
    const routeLoaderData = useRouteLoaderData(adminPanel ? 'adminRoot' : 'root');
    const [idToken, groups] = loadTokenWithGroupsFromLoaderData(routeLoaderData);
    const actionData = useActionData();

    const {showMessage} = useToast();
    const navigate = useNavigate();

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const logoUrl = 'https://produkacja.s3.eu-central-1.amazonaws.com/web/logo/logo-produkacja.png';

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleClickOutside = (event: MouseEvent): void => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (actionData) {
            const {body, status} = actionData as ActionResponse;
            showMessage(body);
            setDropdownOpen(false);
            if (status === 200) {
                navigate('/');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [actionData]);

    return <div style={{
        position: window.location.pathname.startsWith("/admin") ? undefined : "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: "white",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        overflowX: "visible"
    }}>
        <MainDesign minHeight="0" removeSectionOverflowHidden={true} hideBackgroundImage={true}
                    minHeightChildrenDiv={'80px'} disableAnimation={true}>
            <nav className='navbar navbar-dark navbar-expand-lg py-3 py-lg-1'>
                <div className='container-fluid'>
                    <NavLink className='navbar-brand d-none d-lg-block' to='/'>
                        <motion.img
                            className='img-fluid lazyload'
                            style={{height: "70px", width: '83px', marginTop: "-8px", marginBottom: "-10px"}}
                            data-src={logoUrl}
                            alt='logo'
                            whileHover={{scale: 1.1}}
                        />
                    </NavLink>
                    <NavLink className='navbar-brand d-lg-none' to='/'>
                        <motion.img
                            className='img-fluid lazyload'
                            style={{height: "56px", width: "67px", marginTop: "-8px", marginBottom: "-10px"}}
                            data-src={logoUrl}
                            alt='logo'
                            whileHover={{scale: 1.1}}
                        />
                    </NavLink>
                    <div className='collapse navbar-collapse justify-content-center'>
                        <ul className='navbar-nav'>
                            {
                                mainPage &&
                                <NavLinkItem mainPage={true} liStyles='p-6' title={mainPage.title} to={mainPage.to}/>
                            }
                            {
                                topItems.map((item) => <NavLinkItem liStyles='p-6' key={item.title} title={item.title}
                                                                    to={item.to}/>)
                            }
                        </ul>
                    </div>
                    <div className='d-none d-lg-block'>
                        <div className='row align-items-center g-6'>
                            {!idToken && <>
                                <motion.div className='col-auto' whileHover={{scale: 1.1}}>
                                    <AnimatedLink to='/logowanie' className='fs-11 link-secondary-light fw-medium'
                                                  state={{from: window.location.pathname}}
                                                  bounceEffect>
                                        Zaloguj się
                                    </AnimatedLink>
                                </motion.div>
                                <div className='col-auto'>
                                    <AnimatedLink to='/rejestracja'
                                                  className='btn btn-sm btn-success text-success-light shadow'
                                                  bounceEffect
                                                  onClick={() => {
                                                      localStorage.setItem('redirectUrlAfterRegistration', '/')
                                                  }}
                                    >
                                        Zarejestruj się
                                    </AnimatedLink>
                                </div>
                            </>
                            }
                            {idToken && <>
                                <motion.div className='col-auto me-1' whileHover={{scale: 1.1}}>
                                    <AnimatedLink to='/produkt'
                                                  className='btn btn-sm btn-success text-success-light shadow '
                                    >
                                        Moje produkty
                                    </AnimatedLink>
                                </motion.div>
                                <HeaderDropdownMenu dropdownRef={dropdownRef} toggleDropdown={toggleDropdown}
                                                    dropdownOpen={dropdownOpen} groups={groups}/>
                            </>
                            }
                        </div>
                    </div>
                    <div className='d-lg-none'>
                        {/*TODO: Add also button on phone! + Remove Strona główna from top */}
                        <HeaderButtonOpenPhoneMenu toggleMenu={toggleMenu}/>
                    </div>
                </div>
            </nav>
            <div
                className={`navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs ${!isMenuOpen ? 'd-none' : ''}`}
                style={{zIndex: 9999}}
            >
                <div
                    className='navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark'
                    style={{opacity: '75%'}}
                    onClick={toggleMenu}
                />
                <nav
                    className='position-relative h-100 w-100 d-flex flex-column justify-content-between p-6 bg-secondary-dark overflow-auto'>
                    <div className='d-flex align-items-center'>
                        <Link className='me-auto h4 mb-0 text-decoration-none' to='/'>
                            <img
                                loading='lazy'
                                className='img-fluid'
                                style={{height: "80px"}}
                                src={logoUrl}
                                alt=''
                            />
                        </Link>
                        <Link className='navbar-close text-secondary-light' to='#' onClick={toggleMenu}>
                            <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M6 18L18 6M6 6L18 18'
                                    stroke='currentColor'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        </Link>
                    </div>
                    <div className='py-16'>
                        <ul className='nav nav-pills flex-column'>
                            {
                                mainPage &&
                                <NavLinkItem mainPage={true} burgerMenu={true} title={mainPage.title} to={mainPage.to}
                                             onClick={toggleMenu}/>
                            }
                            {
                                topItems.map((item) => <NavLinkItem burgerMenu={true} key={item.title}
                                                                    title={item.title}
                                                                    to={item.to} onClick={toggleMenu}/>)
                            }
                        </ul>
                    </div>
                    <div className='row align-items-center g-6'>
                        {
                            !idToken && <>
                                <div className='col-12'>
                                    <Link
                                        className='fs-11 d-block w-100 link-secondary-light text-center fw-medium'
                                        to='/logowanie'
                                        state={{from: window.location.pathname}}
                                    >
                                        Zaloguj się
                                    </Link>
                                </div>
                                <div className='col-12'>
                                    <Link
                                        className='btn btn-sm btn-success w-100 text-success-light shadow'
                                        to='/rejestracja'
                                    >
                                        Zarejestruj się
                                    </Link>
                                </div>
                            </>
                        }
                        {
                            idToken && <>
                                {
                                    hasAdminGroup(groups) &&
                                    <Link
                                        className='fs-11 d-block w-100 link-secondary-light text-center fw-medium'
                                        to='/admin'
                                    >
                                        Panel admina
                                    </Link>
                                }
                                <div className='col-12'>
                                    <Link
                                        className='fs-11 d-block w-100 link-secondary-light text-center fw-medium'
                                        to={hasEffectiveActionCourseSubscriberGroup(groups) ? '/produkt/kurs-efektywnego-dzialania' : '/mistrzowskie-wsparcie'}
                                    >
                                        {hasEffectiveActionCourseSubscriberGroup(groups) ? "Kurs Efektywnego Działania" : "MISTRZOWSKIE Wsparcie"}
                                    </Link>
                                </div>
                                <div className='col-12'>
                                    <Link
                                        className='fs-11 d-block w-100 link-secondary-light text-center fw-medium'
                                        to='/chat'
                                    >
                                        Chat
                                    </Link>
                                </div>
                                <div className='col-12'>
                                    <Link
                                        className='fs-11 d-block w-100 link-secondary-light text-center fw-medium'
                                        to='/konwersacje'
                                    >
                                        Konwersacje (beta)
                                    </Link>
                                </div>

                                <div className='col-12'>
                                    <Link
                                        className='fs-11 d-block w-100 link-secondary-light text-center fw-medium'
                                        to='/transakcje'
                                    >
                                        Historia transakcji
                                    </Link>
                                </div>
                                <div className='col-12'>
                                    <Form method="post" action="/logout">
                                        <button className='btn btn-sm btn-warning w-100 text-success-light shadow'>
                                            Wyloguj się
                                        </button>
                                    </Form>
                                </div>
                            </>
                        }
                    </div>
                </nav>
            </div>
        </MainDesign>
    </div>
}

export async function logoutAction() {
    try {
        await AuthService.signOut();
        return {
            status: 200,
            body: "Pomyślnie wylogowano."
        }
    } catch (error) {
        return {
            status: 500,
            body: "Wystąpił błąd. Spróbuj ponownie."
        }
    }
}