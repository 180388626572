import {
    CourseStatistics,
    CourseWeekAndEpisodes,
    learningCourseStructure
} from "../../../../model/user/learning-course/LearningCourse";
import {useState} from "react";
import CalendlyModalLazy from "../../calendly/CalendlyModalLazy";
import {CourseService} from "../../../../service/CourseService";
import LoadingModal from "../../../../pages/common/LoadingModal";
import {Link} from "react-router-dom";
import {
    calculateNumOfDaysFromStart,
    calculatePercentageOfDoneEpisodes,
    formatSecondsToHoursAndMinutes
} from "../../../../util/KedUtil";

interface LearningCourseDashboardDescriptionProps {
    statistics: CourseStatistics
    courseWeekAndEpisodes: CourseWeekAndEpisodes[]
}

export default function LearningCourseDashboardDescription(props: LearningCourseDashboardDescriptionProps) {
    const {statistics, courseWeekAndEpisodes} = props;
    const [open, setOpen] = useState(false);
    const [calendlyUrl, setCalendlyUrl] = useState<string | null>(null);
    const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

    async function openIndividualSessionModal() {
        setIsLoadingModalOpen(true);
        let url = statistics.individualSessionUrl;
        if (!url) {
            const sessionUrlResponse = await CourseService.generateIndividualSessionUrl();
            url = sessionUrlResponse.url;
        }
        setCalendlyUrl(url);
        setOpen(true);
        setIsLoadingModalOpen(false);
    }

    return <>
        <h1>Twoje podsumowanie kursu</h1>
        <div className='row mt-10'>
            <div className='col-12 col-md-3 mb-3'>
                <div className="px-2 py-8 border rounded text-center">
                    <h3 className='text-success fs-6'>{calculateNumOfDaysFromStart(statistics.boughtAt)}</h3>
                    <p className="mb-0 text-white fw-medium">DNI OD POCZĄTKU</p>
                </div>
            </div>
            <div className='col-12 col-md-4 mb-3'>
                <div className="px-2 py-8 border rounded text-center">
                    <h3 className='text-success fs-6'>{formatSecondsToHoursAndMinutes(statistics.totalWatchTimeInSeconds)}</h3>
                    <p className="mb-0 text-white fw-medium">CAŁKOWITY CZAS OGLĄDANIA</p>
                </div>
            </div>
            <div className='col-12 col-md-3 mb-3'>
                <div className="px-2 py-8 border rounded text-center">
                    <h3 className='text-success fs-6'>{calculatePercentageOfDoneEpisodes(statistics.episodesCompleted)}%</h3>
                    <p className="mb-0 text-white fw-medium">PRZEROBIONO KURSU</p>
                </div>
            </div>
        </div>
        <h2 className='mt-10'>O kursie</h2>
        <p className='text-secondary-light fw-medium fs-9 fw-bold'>Witamy na Kursie Efektywnego Działania!</p>
        {
            statistics.individualSessionsScheduledNum === 0 &&
            <p className='text-secondary-light fw-medium'>Umów się na pierwsze spotkanie organizacyjne, <span
                onClick={openIndividualSessionModal} style={{cursor: 'pointer'}}
                className='text-success text-decoration-underline d-inline'>klikając
                tutaj</span>. Dopasujemy tempo kursu i materiały indywidualnie to Twoich potrzeb. Po pierwszym spotkaniu
                uzyskasz dostęp do pierwszych tygodni z kursu.
            </p>
        }
        {
            (statistics.individualSessionsScheduledNum > 0 && statistics.individualSessionsScheduledNum < statistics.maxIndividualSessionsNum) &&
            <p className='text-secondary-light fw-medium'>Umów się na indywidualne konsultacje, <span
                onClick={openIndividualSessionModal} style={{cursor: 'pointer'}}
                className='text-success text-decoration-underline d-inline'>klikając
                tutaj</span>. Wykorzystano
                już {statistics.individualSessionsScheduledNum} z {statistics.maxIndividualSessionsNum} spotkań.
            </p>
        }
        {
            statistics.individualSessionsScheduledNum >= statistics.maxIndividualSessionsNum &&
            <p className='text-secondary-light fw-medium'>Wykorzystano już wszystkie indywidualne konsultacje. Możesz
                wykupić dodatkowe spotkanie <Link to='/oferta/konsultacje'
                                                  className={'text-success text-decoration-underline'}>klikając
                    tutaj</Link>.
            </p>
        }

        <hr className='text-white'/>
        <p className='text-secondary-light fw-medium'>Kurs będzie składać się z 7 tygodni.
            <br/>
            Zaczniemy od porządnych podstaw
            dotyczących efektywnego działania, a następnie przejdziemy przez podstawowe zagadnienia dotyczące efektywnej
            nauki.
            <br/>
            W kolejnej części skupimy się na optymalizacji środowiska pracy, oraz zagłębimy się w bardziej
            zaawansowane aspekty wydajności. Zoptymalizujemy również aktywność fizyczną, jedzenie, odpoczynek i sen,
            by po szóstym tygodniu z łatwością wchodzić w stan flow i osiągać więcej w jeden dzień, niż większość osób
            przez tydzień lub nawet miesiąc!
            <br/>
            Zakończymy rozdziałem poświęconym na wejście poziom wyżej, dzięki któremu
            już nigdy nie wrócisz do swoich starych nawyków i nie zboczysz ze zwycięskiej ścieżki!</p>
        <p className='text-secondary-light fw-medium'>Zalecamy oglądać filmy po kolei, aby niczego nie ominąć. Często
            kolejne lekcje bazują na poprzednich.</p>

        {/*TODO: IF NEEDED*/}
        <h2 className='mt-10 fs-7 mb-5'>Przegląd kursu</h2>
        <hr className='text-white'/>
        {
            learningCourseStructure.map((learningCourseStructureWeek, count) => (
                <div key={count} className='bg-secondary-dark' style={{
                    borderRadius: '10px',
                    padding: '20px',
                    marginBottom: '20px',
                    border: '1px solid #444',
                    color: '#ffffff'
                }}>
                    {
                        count <= statistics.weeksUnlocked && <>
                            <h3 className='fs-8 text-warning mb-15'>
                                Tydzień {count}: {learningCourseStructureWeek.title}
                            </h3>
                            <div>
                                {learningCourseStructureWeek.episodes.map((episode, episodeCount) => {
                                    const isEpisodeCompleted = !!courseWeekAndEpisodes[count]?.episodes[episodeCount]?.completed;
                                    return <div key={episodeCount} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '15px',
                                        marginBottom: '10px',
                                        color: '#ffffff',
                                        fontSize: '16px'
                                    }}>
                                        <span className='text-secondary-light fw-medium' style={{marginRight: '5px'}}>
                                            {episodeCount + 1}.
                                        </span>
                                        <Link
                                            onClick={() => {
                                                window.scrollTo({top: 40, left: 0, behavior: 'smooth'});
                                            }}
                                            to={`?w=${count}&e=${episodeCount + 1}`}
                                            style={{
                                                color: '#ffffff',
                                                textDecoration: isEpisodeCompleted ? 'line-through' : 'underline',
                                                display: 'inline-block',
                                                marginBottom: '1px',
                                                transition: 'color 0.3s',
                                            }}
                                            onMouseOver={(e) => e.currentTarget.style.color = '#f59e0c'}
                                            onMouseOut={(e) => e.currentTarget.style.color = '#ffffff'}
                                        >
                                            {episode.title}
                                        </Link>
                                        {
                                            isEpisodeCompleted && <img
                                                className='me-2'
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/checkbox-green.svg'
                                                alt='checkbox'
                                                style={{marginLeft: '5px'}}
                                            />
                                        }
                                    </div>
                                })}
                            </div>
                        </>
                    }
                    {
                        count > statistics.weeksUnlocked && <>
                            <h3 className='fs-8 text-secondary-light mb-15'>
                                Tydzień {count}: {learningCourseStructureWeek.title} <img
                                style={{width: "32px", marginBottom: '5px'}}
                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/lock.png'
                                alt='blokada'/>
                            </h3>
                        </>
                    }
                </div>
            ))
        }
        {
            open &&
            <CalendlyModalLazy open={open} setOpen={setOpen} calendlyUrl={calendlyUrl ?? ''} name={statistics.name}
                               email={statistics.email}/>
        }
        <LoadingModal isOpen={isLoadingModalOpen}/>
    </>
}