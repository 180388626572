import {motion} from "framer-motion";

export default function ChatFeatures() {
    return <div className='mb-20' id='potrzeba'>
        <div className='row mb-20 align-items-center'>
            <div className='col-12 col-md-6'>
                <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                  Potrzeba
                </span>
                <h2
                    className='font-heading mb-4 fs-6 text-white'
                    style={{letterSpacing: '-1px'}}
                >
                    Czy Ty też?
                </h2>
                <div className='mb-0 fs-9 fw-medium text-secondary-light'>
                    <ul>
                        <li>
                            Chcesz szybko pozyskiwać potrzebne Ci informacje?
                        </li>
                        <li>
                            Szukasz narzędzia bazującego na technikach skutecznej nauki?
                        </li>
                        <li>
                            Potrzebujesz natychmiastowych weryfikacji swoich odpowiedzi, by szybko wprowadzać zmiany?
                        </li>
                    </ul>
                    <span className='fw-bold fs-8'>
                          {"\u00A0"}{"\u00A0"}{"\u00A0"}{"\u00A0"}Idealnie trafiłeś!
                    </span>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='row justify-content-center justify-content-md-end'>
                    <div className='col-auto'>
                        <div className='position-relative'>
                            <div
                                className='overflow-hidden rounded-5'
                                style={{maxWidth: 554, height: 312}}
                            >
                                <motion.img className='img-fluid'
                                            initial={{opacity: 0, scale: 0.5}}
                                            animate={{opacity: 1, scale: 1}}
                                            alt='czy ty tez'
                                            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-chat/czy+ty+tez.png'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}