import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useBubbleNotification} from "../../../hooks/useBubbleNotification";

interface BubbleNotificationProps {
    message: string;
    delay?: number;
    duration?: number;
}

const names = ["Mirosław", "Agnieszka", "Monika", "Marta", "Adam", "Andrzej", "Julia", "Anna"]

const times = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

function getRandomElement(array: string[] | number[]) {
    return array[Math.floor(Math.random() * array.length)];
}

export function BubbleNotificationWithPlaceholders({message, delay, duration}: BubbleNotificationProps) {
    const {showBubble, setShowBubble} = useBubbleNotification({delay, duration});

    const randomName = getRandomElement(names);
    const randomTime = getRandomElement(times);

    const replacedMessage = message.replace("{name}", randomName.toString()).replace("{time}", randomTime.toString());

    return (
        <AnimatePresence>
            {showBubble && (
                <motion.div
                    className="position-fixed bottom-0 start-0 mb-3 ms-3 p-2 bg-dark text-white rounded-pill shadow"
                    style={{zIndex: 1000}}
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 50}}
                    transition={{duration: 0.5}}
                    onClick={() => setShowBubble(false)}
                >
                    {replacedMessage}
                </motion.div>
            )}
        </AnimatePresence>
    );
}