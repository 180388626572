import React, {useEffect, useState} from 'react';
import {MathJax, MathJaxContext} from "better-react-mathjax";
import Markdown from "react-markdown";
import {motion} from 'framer-motion';
import {Conversation} from "../../../../model/user/ai/Conversation";
import {Model} from "../../../../model/common/Model";

interface MessageProps {
    conversation: Conversation;
    selectedModel: Model | null;
    userName: string;
    speakingConversation: boolean;
    isRecording?: boolean;
}

export default function Message(props: MessageProps) {
    const {conversation, selectedModel, userName, speakingConversation, isRecording} = props;
    const {role, content: text, imageUrl} = conversation;

    // State to control the typing animation visibility
    const [showTypingAnimation, setShowTypingAnimation] = useState(true);

    const replacedText = text.replace(/\\\(/g, '\\\\(')
        .replace(/\\\[/g, '\\\\[')
        .replace(/\\\)/g, '\\\\)')
        .replace(/\\]/g, '\\\\]')

    // Effect to control the typing animation based on the message content
    useEffect(() => {
        if (text === "Typing") {
            setShowTypingAnimation(true);
        } else {
            setShowTypingAnimation(false);
        }
    }, [text, replacedText]);


    const dotVariants = {
        animate: {
            opacity: [0, 1, 0],
            transition: {
                duration: 1.2,
                repeat: Infinity,
                ease: "easeInOut",
            },
        },
    };

    // Checking if the message is from the user
    const isUser = role === "user";

    return (
        <div className={`d-flex w-100 mb-2 p-2 rounded-3 ${isUser ? 'bg-secondary-dark' : 'bg-secondary-dark'}`}>
            <div className={`d-flex ${isUser ? 'justify-content-end' : ''}`}>
                {/* User or OpenAI icon */}
                <div className="p-2 rounded-circle text-white"
                    // TODO: Different colors
                     style={{backgroundColor: isUser ? '#2a3342' : '#2a3342', width: '50px', 'height': '50px'}}>
                    {isUser ? <img
                            className='mb-0 team-round-image lazyload'
                            data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/user.png'
                            alt=''
                            style={{width: '36px', height: '36px'}}
                        /> :
                        <img
                            className='mb-0 team-round-image lazyload'
                            data-src={selectedModel ? selectedModel.imageUrl : 'https://produkacja.s3.eu-central-1.amazonaws.com/web/logo/logo-500-500-raw.png'}
                            alt=''
                            style={{width: '36px', height: '36px'}}
                        />
                    }
                </div>
                {/* Message content */}
                <div className={`flex-grow-1 d-flex flex-column ${isUser ? 'align-items-end' : ''}`}>
                    <div className="p-2 rounded text-white">
                        {/* Typing animation or message text */}
                        {showTypingAnimation ? (
                            <div className='pt-3'
                                 style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                {[...Array(3)].map((_, index) => (
                                    <motion.div
                                        key={index}
                                        variants={dotVariants}
                                        animate="animate"
                                        style={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: "50%",
                                            backgroundColor: "white",
                                            marginLeft: "5px",
                                            // Delay each dot's animation to simulate typing
                                            animationDelay: `${index * 0.4}s`,
                                        }}
                                        initial={{opacity: 0}}
                                    />
                                ))}
                            </div>
                        ) : (
                            <>
                                <h2
                                    className='font-heading mt-1 mb-4 fs-9 text-white'
                                    style={{letterSpacing: '-1px'}}
                                >
                                    {isUser ? userName : (selectedModel ? selectedModel.name : "Produkacja")}
                                </h2>
                                <div className="mb-0 fs-11 fw-medium text-white">
                                    {
                                        isUser && speakingConversation && isRecording && (!text || text.trim() === "") ? (
                                            <div>
                                                <img
                                                    style={{width: "50px"}}
                                                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/img/micro+-+speak+now.png'
                                                    alt='mów teraz'/>
                                            </div>
                                        ) : (
                                            <>
                                                <MathJaxContext>
                                                    <MathJax key={replacedText}>
                                                        <Markdown>
                                                            {replacedText}
                                                        </Markdown>
                                                    </MathJax>
                                                </MathJaxContext>
                                                {
                                                    imageUrl &&
                                                    <img src={imageUrl} alt='Obrazek przesłany przez użytkownika'
                                                         style={{maxWidth: '70%'}}
                                                         className='img-fluid'/>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};