import Heading from "../../common/header/Heading";
import Vimeo from "@u-wave/react-vimeo";
import React, {useState} from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";

interface VSLChatContentProps {
    isLoggedIn: boolean
}

export default function VSLChatTutorialContent(props: VSLChatContentProps) {
    const {isLoggedIn} = props;
    const [playButtonVisible, setPlayButtonVisible] = useState(true);
    const [overlayVisible, setOverlayVisible] = useState(true);

    const handlePlayClick = () => {
        setPlayButtonVisible(false);
        playVideo();
    }

    const playVideo = () => {
        const player = document.querySelector('iframe');
        if (player) {
            // @ts-ignore
            player.contentWindow.postMessage({method: 'play'}, '*');
        }
        setOverlayVisible(false); // Hide the overlay after play
        setPlayButtonVisible(false);
    }

    return <>
        <Heading customStyles='mb-6 text-center mw-md-5xl mx-auto' title='Jak zrozumieć i rozwiązać każde zadanie z AI?'
                 customTitleStyles='font-heading mb-4 fs-4 text-white'
                 customSubtitleStyles='text-warning fs-9 fw-medium'
                 subtitle='Pospiesz się. Ten poradnik jest dostępny tylko do końca dnia.'/>
        <div className='mw-md-3xl mx-auto mb-8 position-relative'>
            <Vimeo
                className='h-100'
                video="https://vimeo.com/944525461?share=copy"
                responsive={true}
                dnt={true}
            />
            {overlayVisible && (
                <div className="overlay position-absolute top-0 start-0 w-100 h-100"
                     onClick={playVideo}
                     style={{background: 'rgba(0,0,0,0.2)'}}>
                </div>
            )}
            {playButtonVisible &&
                <div
                    className='position-absolute top-50 start-50 translate-middle text-success link-success play-button'
                    onClick={handlePlayClick}
                    style={{zIndex: 1}}
                >
                    <svg
                        width={64}
                        height={64}
                        viewBox='0 0 64 64'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <circle cx={32} cy={32} r={32} fill='currentColor'/>
                        <path
                            d='M40.5 31.13L26.5 23.05C26.348 22.9622 26.1755 22.916 26 22.916C25.8245 22.916 25.652 22.9622 25.5 23.05C25.3474 23.1381 25.2208 23.265 25.133 23.4177C25.0452 23.5705 24.9993 23.7438 25 23.92V40.08C24.9993 40.2562 25.0452 40.4295 25.133 40.5823C25.2208 40.735 25.3474 40.8619 25.5 40.95C25.652 41.0378 25.8245 41.084 26 41.084C26.1755 41.084 26.348 41.0378 26.5 40.95L40.5 32.87C40.6539 32.7828 40.7819 32.6563 40.871 32.5035C40.96 32.3506 41.007 32.1769 41.007 32C41.007 31.8231 40.96 31.6494 40.871 31.4965C40.7819 31.3437 40.6539 31.2172 40.5 31.13ZM27 38.35V25.65L38 32L27 38.35Z'
                            fill='white'
                        />
                    </svg>
                </div>
            }
        </div>
        <div className='text-center mw-md-2xl mx-auto mt-16'>
            <AnimatedLink
                className="mx-auto mw-md-2xl btn btn-xl fw-bold btn-success w-100 text-success-light shadow"
                onClick={() => {
                    localStorage.setItem('redirectUrlAfterRegistration', '/chat')
                }}
                to={isLoggedIn ? '/chat' : '/rejestracja'}>
                Testuję za darmo
            </AnimatedLink>
        </div>
    </>
}
