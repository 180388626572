import {learningCourseStructure} from "../model/user/learning-course/LearningCourse";

export function calculateNumOfDaysFromStart(boughtAtISO: string) {
    const boughtAt = new Date(boughtAtISO);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - boughtAt.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function formatSecondsToHoursAndMinutes(totalWatchTimeInSeconds: number) {
    const totalHours = Math.floor(totalWatchTimeInSeconds / 3600);
    const remainingSecondsAfterHours = totalWatchTimeInSeconds % 3600;
    const totalMinutes = Math.floor(remainingSecondsAfterHours / 60);

    return `${totalHours > 0 ? `${totalHours}h ` : ``}${totalMinutes}m`;
}

export function calculatePercentageOfDoneEpisodes(doneEpisodes: number) {
    const totalEpisodes = learningCourseStructure.reduce((acc, week) => acc + week.episodes.length, 0);
    const percentage = (doneEpisodes / totalEpisodes) * 100;
    return Math.round(percentage);
}