import Heading from "../../../../common/header/Heading";
import FaqList from "../../../../ui/faq/FaqList";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";

export default function ConversationsFAQ() {
    return <>
        <div className='mt-20'>
            <Heading title='Najczęściej zadawane pytania' badgeText='FAQ'
                     subtitle='Dowiedz się więcej i znajdź odpowiedzi na swoje pytania.'/>

            <FaqList items={[
                {
                    question: 'Czy mogę zobaczyć, jak to działa za darmo?',
                    answer: 'Oczywiście. Każdy użytkownik po stworzeniu konta otrzyma 20 tokenów do wykorzystania, dzięki którym odkryje możliwości AI.'
                },
                {
                    question: 'Czym są tokeny?',
                    answer: 'Token jest wirtualną walutą, którą wykorzystuje się przy każdym zapytaniu do AI. ' +
                        'W zależności od wybranego modelu, cena zapytania wahać się będzie od 1 do 5 tokenów. ' +
                        'Cena każdego wywołania sztucznej inteligencji będzie jasno podana.'
                },
                {
                    question: 'Co to są modele?',
                    answer: 'Modelem nazywamy odpowiednio skonfigurowaną sztuczną inteligencję, która ma za zadanie zachowywać się w określony sposób. '
                },
                {
                    question: 'Do czego otrzymuję dostęp kupując tokeny?',
                    answer: 'Otrzymujesz dostęp do wszystkich narzędzi ze sztuczną inteligencję. ' +
                        'Na ten moment, są to chat i konwersacje. ' +
                        'Na chacie zadasz pytania AI, która pomoże Ci w nauce. ' +
                        'W konwersacjach poćwiczysz język języki obce. '
                },
                {
                    question: 'Czy kupując tokeny zobowiązuję się do czegoś?',
                    answer: 'Oczywiście, że nie. Płacisz tylko za to, co wykorzystujesz. Masz miesiąc przerwy? Nic nie szkodzi, Twoje tokeny cały będą na Ciebie czekać.\n ' +
                        'Gdy skończą Ci się tokeny, wybierasz wygodną dla Ciebie metodę płatności i Twoje konto zostaje zasilone nową liczbą tokenów.'
                },
                {
                    question: 'Na ile wystarczą mi tokeny?',
                    answer: 'Wszystko zależy od tego, jak często będziesz korzystać z naszych narzędzi.\n Średnio, 20 minut ' +
                        'konwersacji z podstawowym modelem kosztować Cię będzie około 60 tokenów.'
                }
            ]}/>

            <div className='mt-20 text-center'>
                <Heading title='Dalej masz pytania?'
                         subtitle='Skontaktuj się z nami, chętnie na nie odpowiemy.'/>
                <AnimatedLink className='btn btn-lg btn-success w-100 text-success-light shadow mw-md-md'
                              to='/kontakt'>Kontakt</AnimatedLink>
            </div>

        </div>
    </>
}