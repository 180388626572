import React, {useEffect, useState} from "react";
import FeedbackItem from "./FeedbackItem";
import {Feedback, FeedbackItemsModel} from "../../../model/common/Feedback";
import usePagination from "../../../hooks/usePagination";
import {FeedbackService} from "../../../service/FeedbackService";
import PaginationLoadMore from "../../ui/pagination/PaginationLoadMore";
import FeedbackSummaryModal from "./FeedbackSummaryModal";

interface FeedbackItemsProps {
    feedbacks: FeedbackItemsModel;
    category: string
}

export default function FeedbackItems(props: FeedbackItemsProps) {
    const {feedbacks, category} = props;
    const {
        items,
        setItems,
        loadMore,
        nextPageKey,
        setNextPageKey,
        sentinelRef,
        isAutoLoadEnabled
    } = usePagination<Feedback, any>(FeedbackService.getAllFeedback, feedbacks.items, feedbacks.nextPageKey, true, category);

    useEffect(() => {
        setNextPageKey(feedbacks.nextPageKey)
    }, [feedbacks.nextPageKey]);

    useEffect(() => {
        setItems(feedbacks.items)
    }, [feedbacks.items]);

    const [isSummaryOpen, setIsSummaryOpen] = useState(false);
    const [feedbackSummary, setFeedbackSummary] = useState<Feedback | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function openFeedbackSummary(feedback: Feedback) {
        setFeedbackSummary(feedback);
        setIsSummaryOpen(true);
    }

    function closeFeedbackSummary() {
        setIsSummaryOpen(false);
    }

    async function markFeedbackAsRead(feedbackId: string) {
        setIsSubmitting(true);
        await FeedbackService.markFeedbackAsRead(feedbackId);
        const index = items.findIndex(item => item.id === feedbackId);

        if (index !== -1) {
            const updatedItems = [...items];

            updatedItems[index] = {
                ...updatedItems[index],
                unread: false,
            };

            setItems(updatedItems);
        }
        setIsSubmitting(false);
    }

    return <>
        <div className='mb-20 row g-8'>
            {
                items.map((item) => (
                    <FeedbackItem key={item.id} feedback={item} isSubmitting={isSubmitting}
                                  markFeedbackAsRead={markFeedbackAsRead}
                                  openFeedbackSummary={openFeedbackSummary}/>
                ))
            }
        </div>
        <PaginationLoadMore isAutoLoadEnabled={isAutoLoadEnabled} nextPageKey={nextPageKey} loadMore={loadMore}
                            sentinelRef={sentinelRef}/>
        <FeedbackSummaryModal isOpen={isSummaryOpen} isSubmitting={isSubmitting} feedback={feedbackSummary!!}
                              closeFeedbackSummary={closeFeedbackSummary} markFeedbackAsRead={markFeedbackAsRead}/>
    </>
}
