import Meta from "../../../../components/common/Meta";
import React, {Suspense, useEffect, useState} from "react";
import MainDesign from "../../../MainDesign";
import Heading from "../../../../components/common/header/Heading";
import AnimatedButton from "../../../../components/ui/motion/AnimatedButton";
import SpeakingSettings from "../../../../components/user/ai/speaking/SpeakingSettings";
import {SpeakingService} from "../../../../service/ai/SpeakingService";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import {Await, defer, useLoaderData} from "react-router-dom";
import {LoadThreadsAndInfo} from "../../../../model/user/ai/chat/LoadThreadsAndInfo";
import LoadingPage from "../../../common/LoadingPage";
import ErrorContent from "../../../../components/common/error/ErrorContent";
import {Conversation as ConversationEntity} from "../../../../model/user/ai/Conversation";
import {redirectIfNotLoggedIn} from "../../../../util/AuthUtil";
import {ChatService} from "../../../../service/ai/ChatService";
import {ApiError} from "../../../../service/HttpService";
import SpeakingConversation from "../../../../components/user/ai/speaking/SpeakingConversation";
import ChatTokensWithFeedback from "../../../../components/user/ai/chat/ChatTokensWithFeedback";
import FaqList from "../../../../components/ui/faq/FaqList";

type LoaderData = {
    threadsAndInfo: Promise<LoadThreadsAndInfo>;
};

export default function SpeakingBotPage() {
    const {threadsAndInfo} = useLoaderData() as LoaderData;
    const {isSubmitting} = useSubmitFormAction('/konwersacje');

    const [language, setLanguage] = useState('en');
    const [showSettings, setShowSettings] = useState(localStorage.getItem('showSettingsFirstTime') === 'true');
    const [sendSpeech, setSendSpeech] = useState('manual');
    const [showTranscript, setShowTranscript] = useState(true);

    const [modelId, setModelId] = React.useState<string | null>(null);
    // selected threadId
    const [threadId, setThreadId] = React.useState<string | null>(null);

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [recordingStartTimestamp, setRecordingStartTimestamp] = useState<number>(0);

    const [conversation, setConversation] = useState<ConversationEntity[]>([]);

    const [tokens, setTokens] = useState<number | undefined>();
    const [callCost, setCallCost] = useState(0);
    const [settingsDisabled, setSettingsDisabled] = useState(false);

    useEffect(() => {
    }, [showTranscript]);

    function handleModelChange(newModelId: string) {
        setModelId(newModelId);
    }

    async function loadThreadMessages(threadId: string) {
        if (threadId === "Nowy wątek") {
            setThreadId(null);
            setConversation([]);
            return;
        }
        setThreadId(threadId);
        try {
            const response = await SpeakingService.getThreadMessages(threadId);

            const mappedConversation = response.messages.map(resp => {
                return {
                    content: resp.message,
                    role: resp.messageType === 'User' ? 'user' : 'system'
                }
            })
            setConversation(mappedConversation); // Assuming setConversation updates the messages displayed// by default all messages with the same conversationId, should use the same model
            setModelId(response.modelId);
        } catch (error) {
            console.error("Error loading thread messages:", error);
        }
    }

    function toggleShowSettings() {
        setShowSettings(!showSettings);
        if (localStorage.getItem('showSettingsFirstTime') === 'true') {
            localStorage.setItem('showSettingsFirstTime', 'false');
        }
    }

    return <>
        <Meta title='Konwersacje'
              description='Ćwicz język w bezpiecznym środowisku. Porozmawiasz ze sztuczną inteligencją na każdy temat,
              poćwiczysz wymowę, gramatykę i słuchanie! Z łatwością wszystko skonfigurujesz w kilka sekund.'/>
        <MainDesign containerStyles="container py-20">
            <Heading title="Ćwicz język obcy z AI (beta)"
                     subtitle="Porozmawiasz ze sztuczną inteligencją na każdy temat, poćwiczysz wymowę, gramatykę i
                     słuchanie! Z łatwością wszystko skonfigurujesz w kilka sekund."
                     badgeText='Konwersacje'/>

            <Suspense fallback={<LoadingPage styles='pt-12'/>}>
                <Await resolve={threadsAndInfo} errorElement={<ErrorContent/>}>
                    {(loadedThreadsAndInfo: LoadThreadsAndInfo) => <>
                        <div className='text-center mw-sm mx-auto my-10'>
                            <AnimatedButton
                                className='btn btn-lg btn-warning fs-11 py-3 w-100 px-5 text-success-light shadow'
                                disabled={settingsDisabled}
                                type='button'
                                onClick={toggleShowSettings}>
                                {showSettings ? "Ukryj ustawienia" : "Pokaż ustawienia"}
                            </AnimatedButton>
                        </div>

                        <SpeakingSettings showSettings={showSettings} toggleShowSettings={toggleShowSettings}
                                          loadThreadsAndInfo={loadedThreadsAndInfo}
                                          handleThreadChange={loadThreadMessages}
                                          modelId={modelId ?? loadedThreadsAndInfo.models[0].id}
                                          setModelId={setModelId}
                                          handleModelChange={handleModelChange}
                                          isSubmitting={isSubmitting}
                                          loadedSettings={loadedThreadsAndInfo.speakingSettings}
                                          language={language} setLanguage={setLanguage}
                                          sendSpeech={sendSpeech} setSendSpeech={setSendSpeech}
                                          setShowTranscript={setShowTranscript} showTranscript={showTranscript}
                                          threadId={threadId}/>
                        <div className='mb-0 row g-8 align-items-center mw-md-4xl mx-auto'>
                            <ChatTokensWithFeedback initialTokens={loadedThreadsAndInfo.tokens}
                                                    tokens={tokens}
                                                    promptCost={callCost}
                                                    setTokens={setTokens}
                                                    feedbackSource='CONVERSATIONS'
                                                    hasCompletedCustomFeedbackForm={loadedThreadsAndInfo.hasCompletedCustomFeedbackForm}
                            />
                        </div>

                        {
                            !showSettings && <>
                                <hr className='text-white'/>
                                <SpeakingConversation loadedThreadsAndInfo={loadedThreadsAndInfo} modelId={modelId}
                                                      sendSpeech={sendSpeech} conversation={conversation}
                                                      setConversation={setConversation} showTranscript={showTranscript}
                                                      isRecording={isRecording} setIsRecording={setIsRecording}
                                                      currentThreadId={threadId} setCurrentThreadId={setThreadId}
                                                      language={language}
                                                      setCallCost={setCallCost} setSettingsDisabled={setSettingsDisabled}
                                                      recordingStartTimestamp={recordingStartTimestamp}
                                                      setRecordingStartTimestamp={setRecordingStartTimestamp}
                                />
                            </>
                        }
                    </>}
                </Await>
            </Suspense>
            <Heading title='Najczęściej zadawane pytania' badgeText='FAQ'
                     customStyles='mb-6 text-center mw-md-4xl mx-auto mt-28'
                     subtitle='Poniżej znajdziesz odpowiedzi na najcześciej zadawane pytania.'/>

            <FaqList items={[
                {
                    question: 'Czym są tokeny?',
                    answer: 'Token jest wirtualną walutą, którą wykorzystuje się przy każdym zapytaniu do AI. ' +
                        'W zależności od wybranego modelu, cena zapytania wahać się będzie od 1 do 5 tokenów. Cena każdego wywołania sztucznej inteligencji jest jasno podana.'
                },
                {
                    question: 'W jakim języku mogę rozmawiać z AI?',
                    answer: 'Na ten moment wspierane są następujące języki: angielski, niemiecki, francuski i hiszpański.\n' +
                        'Język konwersacji należy wybrać w ustawieniach.'
                },
                {
                    question: 'Czym różnią się od siebie modele?',
                    answer: 'Modele różnią się sposobem generowania odpowiedzi. W zależności od wybranego modelu, ' +
                        'odpowiedź będzie się różnić. Każdy model jest szczegółowo opisany, dlatego z łatwością ' +
                        'wybierzesz ten, który najlepiej spełni Twoje potrzeby.'
                },
                {
                    question: 'O co chodzi z wątkami?',
                    answer: 'W danym wątku AI ma dostęp do poprzednich wiadomości. Dzięki temu treść poprzednich ' +
                        'zapytań może być wykorzystana do wygenerowania jak najlepszej odpowiedzi na aktualne pytanie.\n' +
                        'Zaleca się tworzenie nowych wątków przy rozpoczynaniu nowej konwersacji.'
                },
                {
                    question: 'Na czym polegają poziomy?',
                    answer: 'Możesz opcjonalnie wybrać poziom swoich umiejętności.\n A1 - bardzo początkujący ... C2 - biegłość dwujęzyczna\n' +
                        'Może być to przydatne w momencie, gdy będziesz chciał skupić się na słownictwie. Wybierając wyższe poziomy, automatycznie ' +
                        'dobierane będą bardziej zaawansowane słowa i zwroty.'
                },
                {
                    question: 'Czym różnią się od siebie lektorzy?',
                    answer: 'Lektorzy różnią się swoim akcentem oraz głosem. Zachęcamy do eksperymentowania z różnymi i znalezienia swojego ulubionego!'
                },
                {
                    question: 'O co chodzi z automatycznym wysyłaniem wypowiedzi?',
                    answer: 'Jeżeli wybierzesz automatyczne wysyłanie wypowiedzi (po 2s/3s/4s), to jeżeli AI nie wykryje ' +
                        'żadnej nowej wiadomości po upływie tego czasu, wypowiedź zostanie wysłana automatycznie. ' +
                        'W przypadku wyboru manualnego wysyłania wypowiedzi, samemu musisz kliknąć przycisk ' +
                        '`Zakończ wypowiedź` gdy uznasz to za stosowne.'
                },
                {
                    question: 'Czy muszę wybierać temat wypowiedzi?',
                    answer: 'Nie jest to konieczne. Jeżeli temat pozostanie pusty, rozmowa przyjmie naturalny tok.'
                },
            ]}/>

        </MainDesign>
    </>
}

export async function loader() {
    const response = await redirectIfNotLoggedIn();
    if (response) {
        return response;
    }

    if (!localStorage.getItem('showSettingsFirstTime')) {
        localStorage.setItem('showSettingsFirstTime', 'true');
    }

    return defer({
        threadsAndInfo: ChatService.getAllUserThreadsAndInfo("speaking")
    });
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const speakingTutorSettings = {
        language: formData.get('language') ? formData.get('language') as string : undefined,
        level: formData.get('level') ? formData.get('level') as string : undefined,
        lector: formData.get('lector') ? formData.get('lector') as string : undefined,
        sendSpeech: formData.get('sendSpeech') ? formData.get('sendSpeech') as string : undefined,
        showTranscript: formData.get('showTranscript') ? formData.get('showTranscript') as string === "yes" : false,
        conversationSubject: formData.get('conversationSubject') ? formData.get('conversationSubject') as string : undefined,
        modelId: formData.get('modelId') ? formData.get('modelId') as string : undefined
    };

    try {
        const response = await SpeakingService.updateSpeakingSettings(speakingTutorSettings);
        return {
            status: 200,
            body: response.message
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}