import AnimatedButton from "../../ui/motion/AnimatedButton";
import React from "react";
import {formatDate} from "../../../util/DateUtil";
import {Diagnostic} from "../../../model/admin/Diagnostic";

interface DiagnosticSummaryModalProps {
    isOpen: boolean;
    isSubmitting: boolean;
    diagnostic: Diagnostic | undefined;
    closeDiagnosticSummary: () => void;
    markDiagnosticAsReviewed: (diagnosticId: string) => void;
}

export default function DiagnosticSummaryModal(props: DiagnosticSummaryModalProps) {
    const {isOpen, isSubmitting, diagnostic, closeDiagnosticSummary, markDiagnosticAsReviewed} = props;

    let prettyError;
    try {
        prettyError = JSON.stringify(JSON.parse(diagnostic?.error), null, 2)
    } catch (error) {
        prettyError = diagnostic?.error ?? "Brak"
    }

    return diagnostic ?
        <>
            <section
                className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${isOpen ? '' : 'd-none'}`}
                style={{zIndex: 50}}
            >
                <div className='d-flex flex-wrap align-items-center h-100'>
                    <div className='container py-20'>
                        <div className='p-10 mw-4xl mx-auto bg-light-dark overflow-hidden rounded shadow'>
                            <div className='mb-8 row g-8 align-items-center'>
                                <div className='col-auto'>
                                    <div className='navbar-close text-secondary-light'
                                         onClick={() => closeDiagnosticSummary()}>
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M6 18L18 6M6 6L18 18'
                                                stroke='currentColor'
                                                strokeWidth={2}
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className='col-11 mb-0'>
                                    <span className='fs-7 fw-bold text-white'>Zobacz podsumowanie</span>
                                    <h6 className='mb-1 text-white'>
                                        {formatDate(diagnostic.createdAt)}
                                    </h6>
                                </div>
                                <div className='col-12 fs-10 mb-0 text-white'>
                                    Diagnostyka odnośnie {diagnostic.source} - {diagnostic.functionName}
                                </div>
                                {
                                    diagnostic.fullEvent &&
                                    <div className='col-12 mb-1 text-white'>
                                        Pełny event:
                                        <pre>
                                            {JSON.stringify(diagnostic.fullEvent, null, 2)}
                                        </pre>
                                    </div>
                                }
                                {
                                    diagnostic.error &&
                                    <div className='col-12 mb-0 mt-0 text-white'>
                                        Błąd:
                                        <pre>
                                             {prettyError}
                                        </pre>

                                    </div>
                                }
                                {
                                    diagnostic.additionalInfo &&
                                    <div className='col-12 mb-1 mt-0 fw-bold text-white'>
                                        Dodatkowe informacje:
                                        <pre className='fw-light'>
                                             {diagnostic.additionalInfo}
                                        </pre>
                                    </div>
                                }
                            </div>
                            <div className='row mt-8'>
                                <div className='col-12 col-md-4'>
                                    <AnimatedButton
                                        className="btn btn-lg btn-dark-light w-100 text-success-light shadow mt-2"
                                        type="button"
                                        onClick={() => closeDiagnosticSummary()}
                                    >
                                        Zamknij
                                    </AnimatedButton>
                                </div>
                                <div className='col-12 col-md-8'>
                                    <AnimatedButton
                                        className='btn btn-lg btn-success w-100 text-success-light shadow mt-2'
                                        type="button"
                                        onClick={() => {
                                            markDiagnosticAsReviewed(diagnostic.id);
                                            closeDiagnosticSummary();
                                        }}
                                        disabled={diagnostic.reviewed || isSubmitting}
                                    >
                                        Oznacz jako przeczytany
                                    </AnimatedButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </> : <></>
}