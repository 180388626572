import GetTranscription from "./GetTranscription";
import Conversation from "../chat/Conversation";
import React, {useEffect, useRef, useState} from "react";
import {LoadThreadsAndInfo} from "../../../../model/user/ai/chat/LoadThreadsAndInfo";
import {Conversation as ConversationEntity} from "../../../../model/user/ai/Conversation";
import {SpeakingService} from "../../../../service/ai/SpeakingService";
import {base64toBlob} from "../../../../util/Util";
import {getModelById, getSelectedSpeakingModelOrDefault} from "../../../../model/common/Model";
import {useToast} from "../../../ui/toast/ToastContext";
import {ApiError} from "../../../../service/HttpService";
import {DiagnosticService} from "../../../../service/DiagnosticService";
import CopyTranscriptButton from "./CopyTranscriptButton";

interface SpeakingConversationProps {
    loadedThreadsAndInfo: LoadThreadsAndInfo
    modelId: string | null
    sendSpeech: string
    language: string
    conversation: ConversationEntity[]
    setConversation: React.Dispatch<React.SetStateAction<ConversationEntity[]>>
    showTranscript: boolean
    isRecording: boolean
    setIsRecording: React.Dispatch<React.SetStateAction<boolean>>
    currentThreadId: string | null
    setCurrentThreadId: React.Dispatch<React.SetStateAction<string | null>>
    setCallCost: React.Dispatch<React.SetStateAction<number>>
    setSettingsDisabled: React.Dispatch<React.SetStateAction<boolean>>
    recordingStartTimestamp: number
    setRecordingStartTimestamp: React.Dispatch<React.SetStateAction<number>>
}

export default function SpeakingConversation(props: SpeakingConversationProps) {
    const {
        loadedThreadsAndInfo,
        modelId,
        sendSpeech,
        language,
        conversation,
        setConversation,
        showTranscript,
        isRecording,
        setIsRecording,
        currentThreadId,
        setCurrentThreadId,
        setCallCost,
        setSettingsDisabled,
        recordingStartTimestamp,
        setRecordingStartTimestamp
    } = props;

    const socket = useRef<WebSocket | null>(null);
    const [isResponsePlaying, setIsResponsePlaying] = useState(false);
    const [isFetchingResponse, setIsFetchingResponse] = useState(false);
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [transcript, setTranscript] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const [tokens, setTokens] = useState(loadedThreadsAndInfo.tokens);
    const {showMessage} = useToast();

    const manual = sendSpeech === 'manual';

    useEffect(() => {
        if (!manual) {
            const sendRequestToBackend = () => {
                endTranscription().then()
            };

            const timeoutId = setTimeout(() => {
                if (transcript !== '') {
                    sendRequestToBackend();
                }
            }, calculateTimeFromSendSpeechString());

            return () => clearTimeout(timeoutId);
        }
    }, [transcript, sendSpeech])

    async function fetchAudio(customMessage?: string) {
        try {
            setSettingsDisabled(true);
            setIsResponsePlaying(true);
            setCallCost(0);
            const oldMessages = [...conversation];
            setConversation((oldConversation: ConversationEntity[]) => [...oldConversation, {
                role: 'system',
                content: 'Typing'
            }]);

            const now = Date.now();
            const durationInMillis = recordingStartTimestamp === 0 ? 0 : now - recordingStartTimestamp;
            setIsFetchingResponse(true);
            const {
                audioBase64,
                message,
                threadId
            } = await SpeakingService.getSpeakingResponse(customMessage ?? transcript, currentThreadId, modelId ?? loadedThreadsAndInfo.models[0].id, durationInMillis);
            setIsFetchingResponse(false);

            setConversation([...oldMessages, {
                role: 'system',
                content: message
            }]);
            const model = getModelById(loadedThreadsAndInfo.models, modelId ?? '') ?? loadedThreadsAndInfo.models[0];
            setCallCost(model.callCost);
            setCurrentThreadId(threadId);
            const audioUrl = URL.createObjectURL(base64toBlob(audioBase64, "audio/mp3"));
            const localAudio = new Audio(audioUrl)
            setAudio(localAudio);
            await localAudio.play();
            localAudio.onended = async function () {
                setTimeout(async () => {
                    setIsResponsePlaying(false);
                    await generateTranscript(model.callCost);
                }, 1)
            }
        } catch (error) {
            const apiError = error as ApiError;
            if (apiError.message) {
                showMessage(apiError.message);
            } else {
                showMessage('Wystąpił błąd. Spróbuj ponownie.');
            }
            await DiagnosticService.addDiagnostic({
                fullEvent: {
                    ...props,
                    transcript: transcript,
                    isLoading: isLoading,
                    isResponsePlaying: isResponsePlaying
                },
                error: JSON.stringify(apiError),
                functionName: "SpeakingConversation - fetchAudio"
            });
            console.error('Error fetching audio:', error);
        }
    }

    function stopResponsePlaying() {
        audio?.pause();
        setIsResponsePlaying(false);
        setSettingsDisabled(false);

        // generate transcript function is not called when someone stops the audio, so we need to handle tokens here (only for display purposes)
        const model = getModelById(loadedThreadsAndInfo.models, modelId ?? '') ?? loadedThreadsAndInfo.models[0];
        setTokens((tokens => tokens! - model.callCost));
    }

    function newThread() {
        setCurrentThreadId(null);
        setConversation([]);
    }

    function calculateTimeFromSendSpeechString() {
        if (sendSpeech === '4s') {
            return 4000;
        } else if (sendSpeech === '3s') {
            return 3000;
        } else if (sendSpeech === '2s') {
            return 2000;
        }
        return 3000;
    }

    function updateUserMessageInConversation(newUserMessage: any) {
        setConversation((oldConversation: any) => {
            const conversationCopy = [...oldConversation];
            if (conversationCopy.length > 0 && conversationCopy[conversationCopy.length - 1].role === 'user') {
                // Replace the last message if it's from the user
                conversationCopy[conversationCopy.length - 1] = {role: 'user', content: newUserMessage};
            } else {
                // Add a new message if the last message is not from the user
                conversationCopy.push({role: 'user', content: newUserMessage});
            }
            return conversationCopy;
        });
    }

    async function generateTranscript(callCost?: number) {
        const model = getModelById(loadedThreadsAndInfo.models, modelId ?? '') ?? loadedThreadsAndInfo.models[0];
        if (!!callCost) {
            setTokens((tokens => tokens! - callCost));
            if (model.callCost > tokens - callCost) {
                setSettingsDisabled(false);
                showMessage("Nie masz wystarczającej ilości tokenów.");
                return;
            }
        } else if (model.callCost > tokens) {
            setSettingsDisabled(false);
            showMessage("Nie masz wystarczającej ilości tokenów.")
            return;
        }

        setIsLoading(true);
        setSettingsDisabled(true);
        try {
            const response = await SpeakingService.generateSpeakingToken();

            const token = response.token;

            navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
                if (!MediaRecorder.isTypeSupported('audio/webm')) {
                    return alert('Ta przeglądarka nie jest wspierana :(');
                }

                const options = {mimeType: 'video/webm'};
                mediaRecorder.current = new MediaRecorder(stream, options);

                socket.current = SpeakingService.establishWebsocketConnection(token, language);

                socket.current.onopen = () => {
                    mediaRecorder.current!!.addEventListener('dataavailable', async (event) => {
                        if (event.data.size > 0 && socket.current && socket.current.readyState === 1) {
                            socket.current.send(event.data);
                        }
                    });
                    setIsLoading(false);
                    setIsRecording(true);
                    setRecordingStartTimestamp(Date.now());
                };

                mediaRecorder.current.start(1100);
                let msg = '';
                updateUserMessageInConversation('');

                socket.current.onmessage = async (message) => {
                    const received = JSON.parse(message.data);
                    if (received.type === 'Results') {
                        const transcript = received.channel.alternatives[0].transcript;
                        if (transcript && received.is_final) {
                            msg += transcript + " ";
                            setTranscript(msg);
                            updateUserMessageInConversation(msg);
                        } else if (transcript) {
                            const tempMsg = msg + transcript;
                            setTranscript(tempMsg);
                            updateUserMessageInConversation(tempMsg);
                        }
                    } else {
                        DiagnosticService.addDiagnostic({
                            fullEvent: received,
                            error: 'none',
                            functionName: "SpeakingConversation - generateTranscript",
                            additionalInfo: 'Received type was not of type "Results"!'
                        });
                    }

                };
                socket.current.onerror = (event: Event) => {
                    if (socket.current) socket.current.close();
                }

                socket.current.onclose = (event: CloseEvent) => {
                    socket.current = null;
                }
            });
        } catch (error) {
            const apiError = error as ApiError;
            if (apiError.message) {
                showMessage(apiError.message);
            } else {
                showMessage('Wystąpił błąd. Spróbuj ponownie.');
            }
            await DiagnosticService.addDiagnostic({
                fullEvent: {
                    ...props,
                    transcript: transcript,
                    isLoading: isLoading,
                    isResponsePlaying: isResponsePlaying
                },
                error: JSON.stringify(apiError),
                functionName: "SpeakingConversation - generateTranscript"
            });
            console.error('Error connecting with websocket', error);
        }
    }

    async function endTranscription(event?: React.MouseEvent<HTMLButtonElement>, cancel?: boolean) {
        if (event) {
            event.preventDefault();
        }
        setIsRecording(false);

        if (mediaRecorder.current && mediaRecorder.current?.state !== 'inactive') {
            mediaRecorder.current.stop();
            mediaRecorder.current.stream.getTracks().filter((i) => i.stop());
            mediaRecorder.current = null;
        }

        if (socket.current) {
            while (socket.current?.readyState === 0) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            socket.current.send(JSON.stringify({"type": "CloseStream"}));

            // If I wanted to wait for SessionInformation message, I'd have to remove those two - but it adds latency.
            socket.current.close();
            socket.current = null;
        }

        if (!cancel && transcript.trim().length > 0) {
            await fetchAudio();
        } else {
            setSettingsDisabled(false);
        }

        setTranscript('')
    }

    async function copyToClip() {
        let conv = '';
        for (let i = 0; i < conversation.length; i++) {
            conv += `${conversation[i].role === 'user' ? loadedThreadsAndInfo.userName : 'AI'}: ${conversation[i].content.trim()}\n\n`;
        }
        await navigator.clipboard.writeText(conv);
        showMessage("Pomyślnie skopiowano.")
    }

    async function generateTranscriptOrGetFirstResponse() {
        if (currentThreadId) {
            await generateTranscript()
        } else {
            await fetchAudio("Hello");
        }
    }

    const getTranscriptionComponent = <GetTranscription
        manual={manual}
        isLoading={isLoading}
        isRecording={isRecording}
        isResponsePlaying={isResponsePlaying}
        isFetchingResponse={isFetchingResponse}
        generateTranscript={() => generateTranscriptOrGetFirstResponse()}
        endTranscription={endTranscription}
        stopResponsePlaying={stopResponsePlaying}
        threadId={currentThreadId}
        newThread={newThread}
    />

    return <>
        <p className='fs-9 text-center fw-medium text-white'>
            Kliknij w poniższy przycisk, aby rozpocząć konwersację
        </p>
        {getTranscriptionComponent}
        {
            isRecording && <div className='text-center mw-sm mx-auto mt-8'>
                <img
                    style={{width: "70px"}}
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/img/micro+-+speak+now.png'
                    alt='mów teraz'/>
            </div>
        }
        {
            showTranscript && <>
                <div className='mb-8 row g-8 align-items-center mw-md-4xl mx-auto'>
                    <p className='fs-10 text-center fw-medium text-white mt-20'>
                        Poniżej zobaczysz transkrypcję w czasie rzeczywistym
                    </p>
                    <Conversation conversation={conversation}
                                  selectedModel={getSelectedSpeakingModelOrDefault(loadedThreadsAndInfo)}
                                  userName={loadedThreadsAndInfo.userName}
                                  speakingConversation={true} isRecording={isRecording}/>
                </div>
                {
                    conversation.length > 2 && getTranscriptionComponent

                }
            </>
        }
        {
            currentThreadId && !isRecording && !isResponsePlaying && !isLoading &&
            <CopyTranscriptButton copyToClip={copyToClip}/>
        }
    </>
}