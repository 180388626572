import {motion} from "framer-motion";
import {useInView} from 'react-intersection-observer';
import {ReactNode} from "react";

interface SlideInWhenVisibleProps {
    children: ReactNode
    initial?: {}
    initialX: number
    delay?: number
    duration?: number
}

export default function SlideInWhenVisible(props: SlideInWhenVisibleProps) {
    const {children, initial, initialX, delay, duration} = props;
    const {ref, inView} = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.5,    // Trigger when 50% of the element is in view
    });

    return (
        <motion.div
            ref={ref}
            initial={initial ? initial : {opacity: 0, x: initialX}}
            animate={{x: inView ? 0 : initialX, opacity: inView ? 1 : 0, y: 0, scale: 1}}
            transition={{duration: duration ? duration : 1, delay: delay}}
        >
            {children}
        </motion.div>
    );
};