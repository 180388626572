import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import RedirectAfterSchedulingIndividualKEDSession
    from "../../../components/user/thank-you/RedirectAfterSchedulingIndividualKEDSession";

export default function RedirectAfterSchedulingIndividualKEDSessionPage() {

    return (
        <>
            <Meta title='Spotkanie zostało umówione'/>
            <MainDesign containerStyles='container py-28'>
                <Heading badgeText='sukces'
                         title='Spotkanie zostało umówione'
                         subtitle='Link do spotkania został wysłany mailem. Prosimy o punktualne przybycie.'/>
                <RedirectAfterSchedulingIndividualKEDSession/>
            </MainDesign>
        </>
    );
}