import {Category} from "../../../components/ui/category/Categories";

export const blogCategories = [
    {
        displayName: 'Wszystkie kategorie',
        value: "all"
    },
    {
        displayName: 'Efektywne działanie',
        value: "EFEKTYWNE_DZIAŁANIE"
    },
    {
        displayName: 'AI',
        value: "AI"
    },
    {
        displayName: 'Zarządzanie czasem',
        value: "ZARZĄDZANIE_CZASEM"
    },
    {
        displayName: 'Mindset',
        value: "MINDSET"
    },
    {
        displayName: 'Zdrowie',
        value: "ZDROWIE"
    },
    {
        displayName: 'Medytacja',
        value: "MEDYTACJA"
    },
] as Category[]