import React from "react";
import Heading from "../../../../common/header/Heading";
import ConversationsModelItem from "./ConversationsModelItem";
import StaggerChildrenWhenVisible from "../../../../ui/motion/StaggerChildrenWhenVisible";

export default function ConversationsModels() {
    return <>
        <Heading badgeText='Oferujemy różne' title='Modele konwersacji z AI'/>
        <div className='row mb-24'>
            <StaggerChildrenWhenVisible>
                <ConversationsModelItem
                    imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-chat/gadajacy+korepetytor.png'
                    title='Gadający Korepetytor' subtitle='Głównym celem modelu jest pomóc użytkownikom pokonać obawę
                przed mówieniem i ewentualnymi błędami, oferując bezpieczne, nieoceniające środowisko do praktykowania
                umiejętności językowych.'/>
                <ConversationsModelItem
                    imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-chat/grammars+talking.png'
                    title="Grammar's Talking" subtitle='Ten model, prowadząc konwersacje skupia się na gramatyce. AI analizuje
            każdą Twoją wypowiedź i podaje odpowiedni, konstruktywny feedback, który pomoże Ci pewnie rozwijać swoje
            umiejętności. Nasz model skonstruowany został tak, by mimo otrzymywania informacji zwrotnej konwersacja nadal
            była płynna i angażująca.'/>
            </StaggerChildrenWhenVisible>
        </div>
    </>
}