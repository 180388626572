import Heading from "../../common/header/Heading";
import React from "react";
import AdvantageItem from "../../user/ai/pricing/AdvantageItem";
import ConfirmModal from "../../ui/ConfirmModal";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import OptInEmailAndName from "../../user/offer/OptInEmailAndName";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {OptInProps} from "../../../util/ExperimentUtil";
import LoadingDots from "../../common/LoadingDots";

export default function EffectiveActionCourseOptInB(props: OptInProps) {
    const {
        handleButtonClick,
        confirmModalOpen,
        setConfirmModalOpen,
        loading,
        isInputValid,
        email,
        setEmail,
        name,
        setName
    } = props;
    const {isSubmitting} = useSubmitFormAction(`/spotkanie`, null, () => {
        setConfirmModalOpen(false);
    });

    return <>
        <p className={`fs-12 fw-medium text-warning text-center`}>
            Nowe, w 100% bezpłatne szkolenie <u>ujawnia</u>...
        </p>
        <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                 title={'Jak Działać Efektywniej i Osiągać Więcej'}
                 customTitleStyles='font-heading mb-4 fs-3 fs-md-10 text-white'
        />
        <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
            <AnimatedButton
                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                onClick={() => handleButtonClick(1, "B")}
            >
                Oglądam szkolenie teraz
            </AnimatedButton>
            {loading &&
                <div className="spinner-container fs-9 text-danger fw-medium">Sprawdzam
                    dostępność <LoadingDots/>
                </div>}
        </div>
        <div className='row container mt-4'>
            <div className='col-md-6 col-12 text-center'>
                <div style={{position: 'relative', width: '100%', paddingTop: '66.67%'}}> {/* 3:2 aspect ratio */}
                    <img
                        data-src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/opt-in-photo.webp'
                        className='img-fluid lazyload' alt='pokazanie kursu efektywnego działania'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>
            <div className='col-md-6 col-12 mt-2'>
                <div className='container'>
                    <Heading customStyles='mb-6 text-center mw-md-4xl mx-auto' title='Nauczysz się:'/>
                    <AdvantageItem
                        title={`Jak wyrwać się z szarej codzienności`}/>
                    <AdvantageItem title='Jak znaleźć czas na wszystko bez zaniedbywania zdrowego trybu życia'/>
                    <AdvantageItem title='Jak wykształcić niezbędny mindset do osiągania doskonałych wyników'/>
                    <AdvantageItem title='Jak stale osiągać więcej niż 99% społeczeństwa'/>
                    <div className='text-center mw-md-2xl mx-auto mt-6 mb-4'>
                        <AnimatedButton
                            className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                            onClick={() => handleButtonClick(2, "B")}
                        >
                            Oglądam szkolenie teraz
                        </AnimatedButton>
                        {loading &&
                            <div className="spinner-container fs-9 text-danger fw-medium">Sprawdzam
                                dostępność <LoadingDots/>
                            </div>}
                    </div>
                    <p className='text-danger fw-medium fs-11'>
                        <span className='fw-bold'>*Uwaga! </span>
                        Szkolenie jest dostępne tylko <span className='fw-bold'>do końca dnia</span>. Kliknij powyższy
                        przycisk, aby obejrzeć je teraz.</p>
                </div>
            </div>
        </div>
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Otrzymaj więcej szczegółów"
                      subtitle={`Na podanego maila wyślemy link do szkolenia. Będziesz mógł/mogła je obejrzeć w dowolnym dla siebie momencie.`}
                      customSubtitleStyles={'mt-0'}
                      buttonType='submit'
                      isSubmitting={isSubmitting}
                      submitDisabled={!isInputValid}
                      children={
                          <>
                              <OptInEmailAndName name={name} setName={setName} email={email} setEmail={setEmail}
                                                 customFooterText='newslettera szkolenia z efektywnego działania'/>
                          </>
                      }
        />
    </>
}
