import Meta from "../../../components/common/Meta";
import Heading from "../../../components/common/header/Heading";
import MainDesign from "../../MainDesign";
import LearningCourseLeftMenu from "../../../components/user/learning-course/content/LearningCourseLeftMenu";
import LearningCourseDashboardDescription
    from "../../../components/user/learning-course/dashboard/LearningCourseDashboardDescription";
import {Await, defer, useLoaderData, useSearchParams} from "react-router-dom";
import LearningCourseEpisodeComponent from "../../../components/user/learning-course/LearningCourseEpisodeComponent";
import {
    CourseEpisode,
    CourseStatistics,
    CourseWeekAndEpisodes,
    learningCourseStructure
} from "../../../model/user/learning-course/LearningCourse";
import {motion} from "framer-motion";
import React, {Suspense} from "react";
import {CourseService} from "../../../service/CourseService";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import {redirectIfNotEffectiveActionCourseSubscriber} from "../../../util/AuthUtil";

type LoaderData = {
    // only on the dashboard page
    statistics: Promise<CourseStatistics & { weekAndEpisodes: CourseWeekAndEpisodes[] }>;

    // only on the episode page
    episodeDashboard: {
        courseWeekAndEpisodes: Promise<CourseWeekAndEpisodes>;
        episode: Promise<CourseEpisode>;
    }
};

interface EpisodeDashboard {
    courseWeekAndEpisodes: CourseWeekAndEpisodes;
    episode: CourseEpisode;
}

export default function LearningCourseDashboardPage() {
    const {statistics, episodeDashboard} = useLoaderData() as LoaderData;

    const [searchParams, setSearchParams] = useSearchParams();
    const week = searchParams.get('w');
    const episode = searchParams.get('e');

    const watchesCourseEpisode = !!week && !!episode;

    function updateWeek(newWeek: string) {
        searchParams.set('w', newWeek);
        searchParams.set('e', '1');
        setSearchParams(searchParams);
    }

    function updateEpisode(newEpisode: string) {
        searchParams.set('e', newEpisode);
        setSearchParams(searchParams);
    }

    return <>
        <Meta title='Kurs Efektywnego Działania - Produkacja'/>
        <MainDesign containerStyles={`container ${watchesCourseEpisode ? 'py-10' : 'py-20'}`}>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={watchesCourseEpisode ? episodeDashboard : statistics}
                       errorElement={<ErrorContent/>}>
                    {(loaded: CourseStatistics & { weekAndEpisodes: CourseWeekAndEpisodes[] } | EpisodeDashboard) => {
                        return <>
                            {
                                !watchesCourseEpisode &&
                                <Heading title={`Witaj ${(loaded as unknown as (CourseStatistics & {
                                    weekAndEpisodes: CourseWeekAndEpisodes[]
                                })[])[0].name}`}
                                         badgeText='Kurs Efektywnego Działania'/>
                            }
                            <div className='row mt-12' key={watchesCourseEpisode + ''}>
                                <div className='col-12 col-md-3'>
                                    <LearningCourseLeftMenu wrapperClasses=''
                                                            watchesCourseEpisode={watchesCourseEpisode}
                                                            lastWatched={(loaded as unknown as CourseStatistics).lastWatched}
                                                            weekAndEpisodes={(loaded as EpisodeDashboard).courseWeekAndEpisodes}
                                                            episode={(loaded as EpisodeDashboard).episode}
                                                            updateWeek={updateWeek}
                                                            updateEpisode={updateEpisode}/>
                                </div>
                                <div className='col-12 col-md-8 mx-auto px-3 px-md-0 pt-8 pt-md-0'>
                                    <motion.div className='p-3 p-md-10 p-sm-none bg-light-dark rounded'
                                                key={JSON.stringify(loaded)}
                                                initial={{opacity: 0, y: 200}}
                                                animate={{opacity: 1, y: 0}}
                                                transition={{delay: watchesCourseEpisode ? 0.3 : 0}}>
                                        {
                                            watchesCourseEpisode &&
                                            <LearningCourseEpisodeComponent week={Number(week)}
                                                                            episodeObj={(loaded as EpisodeDashboard).episode}
                                                                            episodeNumber={Number(episode)}
                                                                            episode={learningCourseStructure[Number(week)].episodes[Number(episode) - 1]}/>
                                        }
                                        {
                                            !watchesCourseEpisode &&
                                            (() => {
                                                const arrayLoaded = loaded as unknown as (CourseStatistics & {
                                                    weekAndEpisodes: CourseWeekAndEpisodes[]
                                                })[];
                                                return (
                                                    <LearningCourseDashboardDescription
                                                        courseWeekAndEpisodes={arrayLoaded.slice(1) as any}
                                                        statistics={arrayLoaded[0]}/>
                                                );
                                            })()
                                        }
                                    </motion.div>
                                </div>
                            </div>
                        </>
                    }
                    }
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

async function loadCourseStatistics(): Promise<CourseStatistics> {
    return await CourseService.getCourseStatistics();
}

async function loadCourseWeekAndEpisodes(weekNum: number, silent: boolean = false): Promise<CourseWeekAndEpisodes> {
    const episodesNum = learningCourseStructure[Number(weekNum)].episodes.length;
    return await CourseService.getCourseWeekAndEpisodes(weekNum, episodesNum, silent);
}

async function loadCourseEpisode(weekNum: number, episodeNum: number): Promise<CourseEpisode> {
    return await CourseService.getCourseEpisode(weekNum, episodeNum);
}

async function loadEpisodeDashboard(weekNum: number, episodeNum: number): Promise<EpisodeDashboard> {
    return {
        // TODO: Remove await in the future if too long to load -> add spinner + suspense
        courseWeekAndEpisodes: await loadCourseWeekAndEpisodes(weekNum),
        episode: await loadCourseEpisode(weekNum, episodeNum)
    }
}

export async function loader({request}: { request: Request }) {
    const response = await redirectIfNotEffectiveActionCourseSubscriber();
    if (response) {
        return response;
    }

    const url = new URL(request.url);
    const week = url.searchParams.get('w');
    const episode = url.searchParams.get('e');
    if (!!week && !!episode) {
        return defer({
            episodeDashboard: loadEpisodeDashboard(Number(week), Number(episode))
        });
    } else {
        let functions = [];
        for (let i = 0; i < learningCourseStructure.length; i++) {
            if (learningCourseStructure[i].episodes.length > 0) {
                functions.push(() => loadCourseWeekAndEpisodes(i, true));
            }
        }
        return defer({
            statistics: Promise.all([loadCourseStatistics(), ...functions.map(f => f())])
        });
    }
}