export const learningCourseStructure = [
    {
        title: "Wstęp",
        episodes: [
            {
                title: "Świetny wybór",
                vimeoUrl: 'https://vimeo.com/994680745',
                description: "Witaj na Kursie Efektywnego Działania!<br/><br/>" +
                    "Nagrałem ten krótki film, aby pogratulować Ci podjęcia doskonałej decyzji i osobiście " +
                    "przywitać Cię w programie. <br/>" +
                    "W tygodniu zerowym przejdziemy sobie przez sprawy organizacyjne i pokażę Ci, jak wynieść 100% z " +
                    "kursu. <br/><br/> " +
                    "Bardzo się cieszę, że tutaj jesteś i życzę Ci powodzenia w stawaniu się najlepszą wersją siebie!" +
                    "<br/> <i>-Mikołaj, Twój główny instruktor</i>"
            },
            {
                title: "Nasza wizja",
                vimeoUrl: 'https://vimeo.com/974237608?share=copy',
                description: "W tym filmie dowiesz się, dlaczego zdecydowaliśmy się stworzyć Kurs Efektywnego Działania." +
                    "<br/> Nasze motto to <i class='d-inline'>\"Działaj efektywniej i osiągaj więcej\"</i>.<br/><br/>" +
                    "Ludziom ogromnie brakuje wiary w siebie, determinacji i narzędzi, by sukcesywnie realizować " +
                    "swoje cele i osiągać sukcesy na każdej płaszczyźnie.<br/> Dlatego powstał <i>Kurs Efektywnego Działania</i>, który " +
                    "jest dopasowany zarówno do uczniów, jak i dorosłych.<br/><br/> Te umiejętności " +
                    "są uniwersalne i przydadzą Ci się w każdym momencie życia. <br/><br/> " +
                    "Zacznij już teraz i podziękuj sobie w przyszłości!",
            },
            {
                title: "Jak wygląda kurs?",
                description: "Kurs będzie się składał z siedmiu tygodni. <br/><br/>" +
                    "Zaczniemy od porządnych podstaw dotyczących <b>efektywnego działania</b>, a następnie przejdziemy przez elementarne zagadnienia dotyczące <b>skutecznego przyswajania wiedzy</b>.<br/><br/>" +
                    "W kolejnej części skupimy się na <b>optymalizacji środowiska pracy</b> oraz zagłębimy się w bardziej zaawansowane aspekty wydajności. <br/>Zoptymalizujemy również <b>aktywność fizyczną</b>, <b>jedzenie</b>, <b>odpoczynek</b> i <b>sen</b>, byś po szóstym tygodniu z łatwością wchodził w <b>stan flow</b> i osiągał więcej w jeden dzień, niż większość osób przez tydzień lub nawet miesiąc!<br/><br/>" +
                    "Zakończymy rozdziałem poświęconym wejściem na wyższy poziom! Zapewni Ci on <b>utrwalenie nawyków</b> i już nigdy nie zboczysz ze zwycięskiej ścieżki! <br/><br/>" +
                    "W tym momencie dostępne są dwa pierwsze tygodnie kursu. <br/> Nowe filmy będą się pojawiały co kilka dni, a w przypadku aktualizacji jakiejś lekcji - zostaniesz o tym poinformowany. <br/><br/>" +
                    "Każda lekcja składa się z wideo, w którym omawiamy dane zagadnienie przeplatając teorię z przykładami. W sekcji z materiałami często dostępne są zadania lub cheat-sheety. <br/><br/> " +
                    "<b class='text-decoration-underline text-danger'>Uwaga</b>: Pierwszy tydzień jest <b>kluczowy</b> i każdy kolejny tydzień na nim bazuje. Poświęc czas na wykonanie wszystkich ćwiczeń i nie spiesz się. Zobaczysz, że czas poświęcony na te zadania zaplusuje w przyszłości.",
            },
            {
                title: "Jak wynieść 100% z kursu?",
                vimeoUrl: 'https://player.vimeo.com/video/971651738',
                description: "W tym filmie porozmawiamy na tematy dotyczące szukania pomocy oraz optymalizacji środowiska pracy.<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Gdzie szukać pomocy?</li>" +
                    "<li>Indywidualne konsultacje</li>" +
                    "<li>Najlepsze praktyki przerabiania materiałów</li>" +
                    "<li>Usuwanie rozpraszaczy</li>" +
                    "</ul>",
                resources: [
                    {
                        title: "Indywidualne konsultacje",
                        link: "https://produkacja.pl/produkt/kurs-efektywnego-dzialania"
                    },
                    {
                        title: "Cotygodniowe spotkania zoom (od października)",
                        link: "https://us05web.zoom.us/j/87095420657?pwd=sSfyhaRG7lamcv4W25l8biwVgnhvZP.1"
                    },
                    {
                        title: "Forum - Discord",
                        link: "https://discord.gg/Fkesj2ue5Y"
                    },
                    {
                        title: "HTML New Tab - kod",
                        link: "https://pastebin.com/FQifj62c"
                    }
                ]
            }
        ]
    },
    {
        title: "Budowa świadomości",
        episodes: [
            {
                title: 'Po co mi świadomość?',
                vimeoUrl: 'https://vimeo.com/994462839',
                description: "W tej lekcji poznasz podstawy pracy ze świadomością. Zrozumiesz, czym jest, dlaczego jest istotna oraz jak wykorzystywać ją do swoich celów.<br/>" +
                    "Na świadomości będziemy bazować w całym kursie, dlatego tak kluczowe jest jej zrozumienie. <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Czym jest świadomość</li>" +
                    "<li>Dlaczego jest istotna w efektywnym działaniu?</li>" +
                    "<li>Jaki wpływ ma otoczenie?</li>" +
                    "</ul>",
            },
            {
                title: 'Idealna wersja siebie',
                vimeoUrl: 'https://vimeo.com/994565553',
                description: "W tej lekcji przejdziemy przez ćwiczenie polegające na stworzeniu idealnej wersji siebie. Zaczniemy od analizy sytuacji obecnej, a następnie stworzymy personę, którą chcesz się stać i do której dążysz. Na podstawie tego ćwiczenia, będziesz mógł/ mogła jasno określić dalsze działania konieczne do stawania się najlepszą wersją siebie",
                resources: [
                    {
                        link: 'https://docs.google.com/document/d/1muaPqx4UlmK9taEOqH4Qxw8pWY-Eo317eyNV_bo-sL8/edit?usp=sharing',
                        title: 'Idealna wersja siebie'
                    }
                ]
            },
            {
                title: 'Wygrywaj każdego dnia!',
                vimeoUrl: 'https://vimeo.com/994674259',
                description: "W tym filmie dowiesz się, jak każdego dnia zwiększać swoją samoświadomość. Przeanalizujemy prowadzenie dziennika. Zyskasz wiedzę, na czym polega, jakie są jego formy oraz jakie korzyści płynną z codziennej samoanalizy w dzienniku. <br/><br/>" +
                    "Dokładnie, krok po kroku, przejdziemy razem przez szablon dziennika. Wytłumaczę Ci wszystkie aspekty, które warto zawrzeć w swoich codziennych wpisach i dlaczego. Na końcu przejdziemy przez arkusz do pomiarów swojej wydajności, który pomoże Ci zebrać dane na temat twoich postępów.<br/><br/>" +
                    "Obiecaj sobie, że codziennie będziesz pisać w dzienniku i wypełniać arkusz do pomiaru wydajności.<br/><b>Za kilka tygodni podziękujesz sobie, że zacząłeś/zaczęłaś już dzisiaj</b>.",
                resources: [
                    {
                        link: 'https://www.evernote.com/shard/s580/sh/b55259c6-c857-9623-8464-1164b91333bb/FPkKFEkaUVw0v4znve1MI-kVeJUbyCMU9wIIJ06gcvM1e_-1tYc1MZWAdA',
                        title: 'Instrukcja wypełniania dziennika'
                    },
                    {
                        link: 'https://www.evernote.com/shard/s580/sh/4f3f2b9d-a3fe-9355-36eb-d9e597eecefd/N8OroWDl1psNJckA2a6UpT6Pbrm0WFa-e5XODXA-ZD0KRc1TlOn5Uh6S7g',
                        title: 'Szablon dziennika'
                    },
                    {
                        link: 'https://docs.google.com/spreadsheets/d/1DR5vhJMKJwQb-eyczKjvLn19ZwZ87cIBH6JSZBfV3tc/edit?usp=sharing',
                        title: 'Pomiar wydajności'
                    },
                    {
                        link: 'https://docs.google.com/document/d/1b4nw4a8wLAehG8wV8EcX00AbV3cqsDwZ4Gye_8fmFog/edit?usp=sharing',
                        title: 'Afirmacje - cheat sheet'
                    }
                ]
            },
            {
                title: 'Wyznaczanie długoterminowych celów',
                vimeoUrl: 'https://player.vimeo.com/video/1000048174',
                description: "W tym filmie porozmawiamy na tematy związane z przekonaniami i wyznaczaniem długoterminowych celów. " +
                    "Przejdziemy przez kilka przykładów i zbudujemy podstawy do planowania krótkoterminowego, które omówimy w kolejnym filmie.<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Jak porzucić ograniczające przekonania</li>" +
                    "<li>Wyznaczenie celów 10-letnich i 5-letnich</li>" +
                    "</ul>" +
                    "<br/> <hr/><br/>Zastanów się, czy Twoja wizja 10-letnia <b>jest zgodna z Twoją WYMARZONĄ wizją </b>przyszłości. <br/> " +
                    "Jeżeli te dwa scenariusze się od siebie różnią, zastanów się dlaczego. <br/>" +
                    "Może nie wierzysz w swoje umiejętności? Może sądzisz, że Tobie i tak się nie uda? <br/><br/>" +
                    "Jeżeli tak jest, nie martw się. W kolejnych filmach porozmawiamy o istocie nastawienia i o tym, jak przekształcić je w mindset zwycięzcy, dzięki któremu UWIERZYSZ w swoje możliwości, co sprawi, że skutecznie będziesz realizować swoje cele i koniec końców je osiągnięsz."
            },
            {
                title: 'Wszystko o planowaniu',
                vimeoUrl: 'https://vimeo.com/962044024?share=copy',
                description: "W tym filmie przejdziemy przez wszystkie tematy związane z planowaniem. Dzięki tym technikom już " +
                    "zawsze będziesz skutecznie realizować swoje cele. <br/> Pamiętaj, aby poświęcić czas na wykonanie ćwiczeń " +
                    "zawartych w filmie i stworzenie własnego planu dominacji.<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Tworzenie rocznego planu dominacji</li>" +
                    "<li>Mistrzowskie planowanie dni i zarządzanie czasem</li>" +
                    "<li>Refleksje na temat minionych wydarzeń</li>" +
                    "</ul>",
                resources: [
                    {
                        link: 'https://docs.google.com/spreadsheets/d/1Mofqi3CJ3Wqm7I7foLlMkJdLfziXFXoD1_HG4-fq6RQ/edit?usp=sharing',
                        title: 'Twój roczny plan dominacji'
                    },
                    {
                        link: 'https://docs.google.com/spreadsheets/d/1GnxxyYwwpZ1-56YhCC1Ek7zLoTMs2hw99yjonX6cpsU/edit?usp=sharing',
                        title: 'Świadomość czasu'
                    }
                ]
            },
            {
                title: 'Growth mindset',
                vimeoUrl: 'https://vimeo.com/979092422?share=copy',
                description: "W tym filmie Marysia przedstawi, jak mindset definiuje nasze podejście do siebie, swojej wartości i umiejętności. Determinuje to, jak podchodzimy do wyzwań i interpretujemy wydarzenia. <br/><br/>" +
                    "Rozpoznanie, jaki mamy mindset, jest kluczowe w procesie rozwoju.<br/><br/>" +
                    "Dowiesz się, jakie są cechy, sposoby myślenia i schematy działania osób reprezentujących growth i fixed mindset, z czego to wynika oraz jak kształować i zmieniać swój mindset. ",
            },
            {
                title: 'Podsumowanie tygodnia',
                description: "Zdaję sobie sprawę, że był to intensywny tydzień i było w nim mnóstwo nowej wiedzy. <br/><br/>" +
                    "Jeżeli jeszcze nie przerobiłeś/aś wszystkich ćwiczeń, zachęcam Cię do ukończenia ich przed przejściem do kolejnego tygodnia. <br/>" +
                    "<u>Bez porządnych fundamentów, żaden dom nie ustoi</u>. To samo tyczy się każdego z nas. Aby przejść do bardziej zaawansowanych rzeczy, musimy mieć solidne podstawy. W przeciwnym razie to nie ma sensu. <br/><br/>" +
                    "W następnym tygodniu zajmiemy się efektywnem przyswajaniem wiedzy, a także przejdziemy przez temat marginalnych zysków i Cykl Kolba, które pomogą Ci stawać się jeszcze lepszym każdego dnia. <br/><br/><br/>" +
                    "P.S. Kliknij proszę w pomarańczowy przycisk 'Prześlij feedback' i napisz nam, co sądzisz o kursie, co Ci się podobało, a co moglibyśmy poprawić. Twoja opinia ma znaczenie. Dziękujemy."
            },

        ]
    },
    {
        title: "Podstawy",
        episodes: [
            {
                title: 'Krzywa zapominania',
                vimeoUrl: 'https://vimeo.com/987658944?share=copy',
                description: "W tym omówimy istotę krzywej zapominania, na której będziemy bazować w kolejnej lekcji."
            },
            {
                title: 'Istota powtórek',
                vimeoUrl: 'https://vimeo.com/987667568?share=copy',
                description: "W tym filmie omówimy znaczenie powtórek. <br/><br/>" +
                    "Przejdziemy przez wiele przykładów, łącząc teorię z praktyką. <br/><br/>" +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Dlaczego powtórki są niezbędne</li>" +
                    "<li>Jak skutecznie powtarzać</li>" +
                    "<li>Jak często powtarzać</li>" +
                    "</ul>",
                resources: [
                    {
                        link: "https://produkacja.pl/chat",
                        title: "Chat Produkacji"
                    }
                ]
            },
            {
                title: 'Marginalne zyski',
                vimeoUrl: 'https://vimeo.com/994723997',
                description: "W tym filmie omówimy istotę marginalnych zysków. <br/><br/>" +
                    "Jest to niezbędne wprowadzenie do kolejnej lekcji, poświęconej Cyklowi Kolba. <br/><br/>" +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Czym są marginalne zyski</li>" +
                    "<li>Dlaczego są one istotne</li>" +
                    "</ul>",
                resources: [
                    {
                        link: "https://jamesclear.com/marginal-gains",
                        title: "Historia Brytyjskich kolarzy"
                    }
                ]
            },
            {
                title: 'Ciągła nauka - Cykl Kolba',
                vimeoUrl: 'https://vimeo.com/994724060',
                description: "W tym filmie omówimy dokładnie, czym jest Cykl Kolba. Przejdziemy przez krótki wstęp, a następnie omówimy Cheat Sheet do tworzenia Cyklu Kolba wraz z praktycznymi przykładami. ",
                resources: [
                    {
                        title: 'Cykl Kolba - Cheat Sheet',
                        link: 'https://docs.google.com/document/d/1bsmkEtrmOgDHhBmo5MYiRo0duydQIP9btpFDDtLJ468/edit?usp=sharing'
                    },
                    {
                        title: 'Przykład cyklów Kolba (blog)',
                        link: 'https://produkacja.pl/blog/2i5Lz6X464cb7STIj8W75WMKfA7'
                    }
                ]
            },
            {
                title: 'Twoja pierwsza mapa myśli',
                description: "W tym filmie przejdziemy przez temat mapy myśli.<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Dlaczego mapa myśli jest istotna</li>" +
                    "<li>Jak tworzyć mapy myśli</li>" +
                    "</ul>" +
                    "Zakończymy przejściem przez kilka przykładów, aby dać Ci jeszcze lepszy ogląd na to, na co zwrócić uwagę.",
                vimeoUrl: 'https://vimeo.com/994724518'
            },
            {
                title: 'Prestudy',
                description: "W tym filmie skupimy się na temacie <i>Prestudy</i>.<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Na czym polega <i>prestudy</i></li>" +
                    "<li>W jaki sposób efektywnie przygotowywać się do zajęć / szkoleń / wykładów</li>" +
                    "</ul>" +
                    "Dodatkowo, pokażę Ci, jak można zastosować AI, aby <i>prestudy</i> było jeszcze bardziej efektywne. ",
                vimeoUrl: 'https://vimeo.com/994924222',
                resources: [
                    {
                        link: "https://produkacja.pl/chat",
                        title: "Chat Produkacji"
                    }
                ]
            },
            {
                title: 'Zbieranie i analiza',
                description: "W tym filmie przejdziemy przez temat <b>zbierania i analizy informacji</b>.<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Na czym polega techinka zbierania i przetwarzania informacji</li>" +
                    "<li>Dlaczego jest ona istotna</li>" +
                    "<li>Jak skutecznie zapamiętać więcej z lekcji / wykładu / szkolenia</li>" +
                    "</ul>" +
                    "Wszystko pokażę na praktyczne praktycznym przykładzie, dzięki czemu od razu z łatwością zastosujesz tę technikę w swoim przypadku. <br/><br/>" +
                    "Na końcu podsumuję, jak <i>prestudy</i> łączy się z techniką zbierania i analizy, aby zapamiętywać więcej podczas zajęć.",
                vimeoUrl: 'https://vimeo.com/994924256'
            },
            {
                title: 'Podsumowanie tygodnia',
                description: 'Gratuluję! Udało Ci się ukończyć drugi tydzień 😎<br/><br/>' +
                    'Wstęp do efektywnego przyswajania wiedzy jest już za Tobą. Do tego tematu wrócimy w tygodniu czwartym, w którym zagłębimy się w bardziej zaawansowane techniki.<br/>' +
                    'Na ten moment, potrzebujesz obyć się z tym, czego się tutaj nauczyłeś/aś. Nie spiesz się z przerabianiem kursu. Pamiętaj, że <b>praktyka > teoria</b>. <br/><br/>' +
                    'W kolejnym tygodniu omówimy temat środowiska, gdzie <b>zoptymalizujemy solidne podstawy</b>. <br/><br/><br/>' +
                    "P.S. Kliknij proszę w pomarańczowy przycisk 'Prześlij feedback' i napisz nam, co sądzisz o tym tygodniu kursu, co Ci się podobało, a co moglibyśmy poprawić. Twoja opinia ma znaczenie. Dziękujemy."
            }
        ]
    },
    {
        title: "Środowisko",
        episodes: [
            {
                title: 'Dlaczego nie mam czasu?',
                vimeoUrl: 'https://vimeo.com/1000048223',
                description: "W tym filmie porozmawiamy na temat <b>braku czasu</b>.<br/>" +
                    "Wiele osób ma poczucie, że doba jest za krótka. Dlatego warto zbudować sobie świadomość, na co tak naprawdę poświęcamy swój czas i jak to wygląda w długoterminowej perspektywie." +
                    "<br/><br/> " +
                    "Omówimy następujące materiały:" +
                    "<ul>" +
                    "<li>Świadomość czasu (arkusz)</li>" +
                    "<li>Mapa mocy (arkusz)</li>" +
                    "</ul>" +
                    "Wszystko od razu zostanie zaprezentowane w praktyce.",
                resources: [
                    {
                        title: 'Świadomość czasu',
                        link: 'https://docs.google.com/spreadsheets/d/1GnxxyYwwpZ1-56YhCC1Ek7zLoTMs2hw99yjonX6cpsU/edit?gid=0#gid=0'
                    },
                    {
                        title: 'Mapa mocy',
                        link: 'https://docs.google.com/spreadsheets/d/1mSDgb347BNyNIQNnAxEy0xE9lQFG5Y-BVMgyQ93TfXs/edit?usp=sharing'
                    }
                ]
            },
            {
                title: 'Chronotypy snu',
                vimeoUrl: 'https://vimeo.com/1000335638',
                description: "W tym filmie porozmawiamy na temat <b>chronotypów snu</b>.<br/>" +
                    "Nieważne, czy już znasz swój chronotyp, czy jest to dla Ciebie całkowicie nowe zagadnienie. Ten film wraz z cheat-sheetami w materiałach wyczerpie ten temat." +
                    "<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Czym są chronotypy snu</li>" +
                    "<li>Wyznaczanie Twojego chronotypu</li>" +
                    "<li>Charakterystyka każdego z chronotypów</li>" +
                    "</ul>",
                resources: [
                    {
                        title: 'Test na chronotyp',
                        link: 'https://produkacja.pl/chronotypy'
                    },
                    {
                        title: 'Delfin - Cheat Sheet',
                        link: 'https://docs.google.com/document/d/1mn_wWiWHqDOwST20XQyaHf7vYk8Xw4LfM1KMdHrTkKM/edit?usp=sharing'
                    },
                    {
                        title: 'Lew - Cheat Sheet',
                        link: 'https://docs.google.com/document/d/1KO_UpB_KPn3IlIoMaAsNTCqgxUMdMRM4AVk0KBEZvAk/edit?usp=sharing'
                    },
                    {
                        title: 'Niedźwiedź - Cheat Sheet',
                        link: 'https://docs.google.com/document/d/1Lc816IT1MfHWOZjX_ed4zD0tFU3xIh97maRz1MQ0OcY/edit?usp=sharing'
                    },
                    {
                        title: 'Wilk - Cheat Sheet',
                        link: 'https://docs.google.com/document/d/1_7l1ElBPG9o4yAz21ImC943YO-9kJ-DOW1XgdiPztmQ/edit?usp=sharing'
                    }
                ]
            },
            {
                title: 'Twoje miejsce pracy',
                vimeoUrl: 'https://vimeo.com/1000804366',
                description: "W tym filmie porozmawiamy na temat <b>Twojego miejsca pracy</b>.<br/><br/>" +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Jak wygląda produktywne miejsce pracy</li>" +
                    "<li>Jak stworzyć produktywne miejsce pracy</li>" +
                    "<li>Na co zwrócić uwagę</li>" +
                    "</ul>" +
                    "Efektywne miejsce pracy zaprezentuję na własnym przykładzie.",
                resources: [
                    {
                        title: 'Artykuł na blogu + linki do badań',
                        link: 'https://produkacja.pl/blog/2ijTb0Bl9NJxykeKDGIphmpGKCI'
                    }
                ]
            },
            {
                title: 'Jesteś sumą 5 osób',
                vimeoUrl: 'https://vimeo.com/1000335858',
                description: "W tym filmie porozmawiamy na temat <b>Twojego otoczenia</b>.<br/>" +
                    "Przyjrzymy się stwierdzeniu <i>\"Jesteś sumą 5 osób, z którymi spędzasz najwięcej czasu\"</i>." +
                    "<br/><br/> " +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Jak inne osoby na Ciebie wpływają </li>" +
                    "<li>Jak świadomie podchodzić do swoich znajomych</li>" +
                    "</ul>",
            },
            {
                title: 'Bądź sobą',
                vimeoUrl: 'https://vimeo.com/1000335944',
                description: "W tym filmie omówimy istotę <b>bycia sobą</b>.<br/><br/>" +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Na czym polega bycie sobą</li>" +
                    "<li>Dlaczego bycie sobą jest istotne</li>" +
                    "<li>Jak być w pełni sobą, nawet w trudnych sytuacjach</li>" +
                    "</ul>",
            },
            {
                title: 'Znaczenie medytacji',
                vimeoUrl: 'https://vimeo.com/1004123874',
                description: "W tym filmie Marysia omawia <b>znaczenie medytacji</b>.<br/><br/>" +
                    "Omówimy następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Czym jest medytacja</li>" +
                    "<li>Jak przygotować się do medytacji</li>" +
                    "<li>Jakie są rodzaje medytacji</li>" +
                    "</ul>",
            },
            {
                title: 'BONUS: Medytacja prowadzona',
                description: "Medytacja zostanie udostępniona wkrótce.",
            },
            {
                title: 'Podsumowanie tygodnia',
                description: 'Gratuluję! Trzeci tydzień już za Tobą. To oznacza, że jesteś już praktycznie w połowie! 🎉<br/><br/>' +
                    'Mamy nadzieję, że widzisz już pozytywne zmiany w swoim życiu. Zdajemy sobie sprawę, że może być Ci ciężko. Wytrwaj jednak jeszcze trochę i zobaczysz, że za kolejne 3-4 tygodnie, podziękujesz sobie! <br/><br/>' +
                    'W kolejnym tygodniu zagłębimy się w bardziej zaawansowane techniki <b>przyswajania wiedzy</b>. Po ich opanowaniu będziesz w stanie uczyć się nawet 3-krotnie szybciej! <br/><br/><br/>' +
                    "P.S. Kliknij proszę w pomarańczowy przycisk 'Prześlij feedback' i napisz nam, co sądzisz o tym tygodniu kursu, co Ci się podobało, a co moglibyśmy poprawić. Twoja opinia ma znaczenie. Dziękujemy."
            }
        ]
    },
    {
        title: "Wystrzał",
        episodes: []
    },
    {
        title: "Optymalizacja",
        episodes: []
    },
    {
        title: "Boost",
        episodes: []
    },
    {
        title: "Następny poziom",
        episodes: []
    },
] as LearningCourseWeek[]

export interface LearningCourseWeek {
    title: string
    episodes: LearningCourseEpisode[]
}

export interface LearningCourseEpisode {
    title: string
    description: string
    vimeoUrl: string
    resources?: Resource[]
}

export interface Resource {
    title: string
    link: string
}

export interface LastWatchedEpisode {
    w: number,
    e: number,
}

export interface CourseStatistics {
    totalWatchTimeInSeconds: number
    boughtAt: string // Date in ISO
    lastWatched: LastWatchedEpisode
    episodesCompleted: number
    name: string
    email: string
    maxIndividualSessionsNum: number,
    individualSessionsScheduledNum: number
    individualSessionUrl: string,
    weeksUnlocked: number
}

export interface CourseWeek {
    week: number
    isUnlocked: boolean
    episodesCompleted: number
}

export interface CourseEpisode {
    week: number
    episode: number
    isUnlocked: boolean
    watchTimeInSeconds: number
    currentWatchTime: number
    completed: boolean
    notes?: string
}

export interface CourseWeekAndEpisodes {
    week: CourseWeek
    episodes: CourseEpisode[]
    weeksUnlocked: number
}

export interface UpdateCourseWatchTimeRequest {
    week: number
    episode: number
    currentWatchTime: number
    increaseWatchTime: number
}

export interface UpdateEpisodeNotes {
    week: number
    episode: number
    notes: string
}

export interface CompleteEpisodeRequest {
    week: number
    episode: number
    completed: boolean
}