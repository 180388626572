import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import React from "react";
import {useLoaderData} from "react-router-dom";
import VSLChatTutorialContent from "../../../components/user/offer/VSLChatTutorialContent";

type VSLChatLoadingData = {
    isLoggedIn: boolean
}

export default function VSLChatTutorialPage() {
    const {isLoggedIn} = useLoaderData() as VSLChatLoadingData;
    return <>
        <Meta title='Poradnik do Chatu Produkacji'/>
        <MainDesign containerStyles='container py-20'>
            <VSLChatTutorialContent isLoggedIn={isLoggedIn}/>
        </MainDesign>
    </>
}