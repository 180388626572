import React from "react";
import Chat from "../../../../components/user/ai/chat/Chat";
import MainDesign from "../../../MainDesign";
import Meta from "../../../../components/common/Meta";

export default function ChatBot() {
    return <>
        <Meta title='Chat'/>
        <MainDesign containerStyles="container py-20">
            <div className='mb-12 text-center mw-md-4xl mx-auto'>
            <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
              chat
            </span>
                <h2
                    className='font-heading mb-4 fs-5 text-white'
                    style={{letterSpacing: '-1px'}}
                >
                    Twój wirtualny asystent
                </h2>
                <p className='mb-0 fs-10 fw-medium text-secondary-light'>
                    Możesz poprosić o pomoc w rozwiązaniu zadania, szczegółowe wyjaśnienie i wiele więcej.
                </p>
            </div>
            <div className='mw-md-4xl mx-auto'>
                <div className='row g-8'>
                    <div className='col-12'>
                        <Chat/>
                    </div>
                </div>
            </div>
        </MainDesign>
    </>
}