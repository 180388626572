import React from "react";
import ExtraordinarySupportParentExplanation from "./ExtraordinarySupportParentExplanation";

export default function EffectiveActionCourseMaturaParentThankYou() {
    return <>
        <div className='mw-md-2xl mx-auto mt-10'>
            <h1 className='text-center fs-8 mt-8'>Poniżej chciałbym przypomnieć Ci, co oznacza
                MISTRZOWSKIE wsparcie.</h1>
            <ExtraordinarySupportParentExplanation/>
        </div>
        <h1 className='text-center fs-7 mt-8'>A to dopiero początek wspaniałej przygody!</h1>
    </>
}