import {motion} from "framer-motion";
import React from "react";

interface ConversationsModelItemProps {
    imageUrl: string
    title: string
    subtitle: string
}

export default function ChatModelItem(props: ConversationsModelItemProps) {
    const {imageUrl, title, subtitle} = props;
    return <>
        <motion.div
            variants={{
                hidden: {opacity: 0, scale: 0.5},
                visible: {opacity: 1, scale: 1}
            }}
            exit={{opacity: 1, scale: 1}}
            transition={{type: 'spring'}}
            className='col-12 col-md-4 mt-5 text-center'>
            <img
                className='img-fluid mb-6'
                style={{maxHeight: 400}}
                src={imageUrl}
                alt='model'
            />
            <h3 className='font-heading mb-4 fs-6 text-white link-white'>
                {title}
            </h3>
            <p className='text-white px-10'>
                {subtitle}
            </p>
        </motion.div>
    </>
}