import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import ProdukacjaCopyrightNote from "../../../components/funnel/ProdukacjaCopyrightNote";
import CalendlyInlineLazy from "../../../components/user/calendly/CalendlyInlineLazy";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {FB_ADS_DIRECT_EXPERIMENT_ID_2} from "../../../util/ExperimentUtil";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";

export default function FBAdsLandingPage() {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        TraceService.addTraceWithExperiment(TraceType.PageLoad, FB_ADS_DIRECT_EXPERIMENT_ID_2, "A", searchParams.get('source'))
    }, []);

    return <>
        <Meta title='Indywidualny Plan Działania'/>
        <MainDesign containerStyles='container pt-20 pb-8' minHeight='100vh'>
            <Heading title='Porozmawiajmy o potrzebach Twojego dziecka.'/>
            <CalendlyInlineLazy
                onEventTypeViewed={() => {
                    TraceService.addTraceWithExperiment(TraceType.CalendlyEventTypeViewed, FB_ADS_DIRECT_EXPERIMENT_ID_2, "A", searchParams.get('source'))
                }}
                onDateAndTimeSelected={() => {
                    TraceService.addTraceWithExperiment(TraceType.CalendlyDateAndTimeSelected, FB_ADS_DIRECT_EXPERIMENT_ID_2, "A", searchParams.get('source'))
                }}
                onEventScheduled={() => {
                    TraceService.addTraceWithExperiment(TraceType.CalendlyEventScheduled, FB_ADS_DIRECT_EXPERIMENT_ID_2, "A", searchParams.get('source'))
                }}
                calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania-dla-twojego-dziecka?hide_gdpr_banner=1'/>
            <ProdukacjaCopyrightNote/>
        </MainDesign>
    </>
}