import React from "react";
import Heading from "../../../../common/header/Heading";
import ChatModelItem from "./ChatModelItem";
import StaggerChildrenWhenVisible from "../../../../ui/motion/StaggerChildrenWhenVisible";

export default function ChatModels() {
    return <>
        <div id='modele'>
            <Heading badgeText='Oferujemy różne' title='Modele chatu'/>
            <div className='row mb-24'>
                <StaggerChildrenWhenVisible>
                    <ChatModelItem
                        imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-chat/korepetytor+kot.png'
                        title='Korepetytor Kot' subtitle='Precyzyjnie wyjaśnia pojęcia, używa sprawdzonych metod efektywnej nauki, zapewnia indywidualnie
                        podejście oraz zwiększa skuteczność nauki.'/>
                    <ChatModelItem
                        imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-chat/baza+wiedzy.png'
                        title='Baza wiedzy' subtitle='Wszystkie informacje jakie tylko potrzebujesz dostępne wygodnie w zasięgu paru kliknięć w
                        jednym. Pozyskuj wiedzę szybko, łatwo i przystępnie'/>
                    <ChatModelItem
                        imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-chat/edytor+tekstow.png'
                        title='Edytor tekstów' subtitle='Tworzenie tekstów w języku angielskim jeszcze nigdy nie było tak przyjemne. Wyjaśnia specyfikę
                        form,
                        podpowiada słownictwo oraz wyrażenia. Natychmiastowo weryfikuje poprawność i podaje sugestie.'/>
                </StaggerChildrenWhenVisible>
            </div>
        </div>
    </>
}