import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import AIProductsSelectOption from "../../../components/user/products/AIProductsSelectOption";

export default function AIProductsPage() {
    return <>
        <Meta title='Produkty AI'/>
        <MainDesign containerStyles='container py-20'>
            <Heading title='Wybierz Chat/Konwersacje' badgeText='produkty ai'
                     subtitle='Wybierz chat lub konwersacje i przejdź dalej.'
                     customSubtitleStyles='text-warning fs-9 fw-medium'/>
            <AIProductsSelectOption/>
        </MainDesign>
    </>
}
