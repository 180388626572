import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {MW_STUDENT_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";
import React, {useEffect} from "react";
import ProdukacjaCopyrightNote from "../../../components/funnel/ProdukacjaCopyrightNote";
import {TraceType} from "../../../model/user/general/Trace";
import {TraceService} from "../../../service/TraceService";
import {useSearchParams} from "react-router-dom";
import ExtraordinarySupportStudent from "../../../components/funnel/direct/ExtraordinarySupportStudent";


export default function ExtraordinarySupportStudentPage() {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        TraceService.addTraceWithExperiment(TraceType.PageLoad, MW_STUDENT_EXPERIMENT_ID_1, 'A', searchParams.get('source'));

        if (searchParams.get('source') === 'qr') {
            TraceService.addTraceWithExperiment(TraceType.QrCodeScan, MW_STUDENT_EXPERIMENT_ID_1, "A", "qr");
        }
    }, [searchParams]);

    return (
        <>
            <Meta
                title="MISTRZOWSKIE Wsparcie"
                description="Mistrzowskie wsparcie dla licelaistów. W pełni wykorzystasz swój potencjał i odważysz się iść własną drogą."
                imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/mistrzowskie-wsparcie-miniaturka.png'
            />
            <MainDesign containerStyles="container pt-10 pt-md-20 pb-10" minHeight='100vh'>
                <ExtraordinarySupportStudent/>
                <ProdukacjaCopyrightNote/>
            </MainDesign>
        </>
    );
}