import {useEffect, useState} from 'react';

interface BubbleNotificationOptions {
    delay?: number;    // Time before showing the notification (in ms)
    duration?: number; // Time the notification stays visible (in ms)
}

export function useBubbleNotification(options: BubbleNotificationOptions = {}) {
    const {delay = 5000, duration = 5000} = options;
    const [showBubble, setShowBubble] = useState(false);

    useEffect(() => {
        const showTimer = setTimeout(() => {
            setShowBubble(true);
        }, delay);

        const hideTimer = setTimeout(() => {
            setShowBubble(false);
        }, delay + duration);

        return () => {
            clearTimeout(showTimer);
            clearTimeout(hideTimer);
        };
    }, [delay, duration]);

    return {showBubble, setShowBubble};
}