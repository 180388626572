import React, {useState} from "react";
import Heading from "../../common/header/Heading";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import CalendlyModalLazy from "../calendly/CalendlyModalLazy";
import {KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";

interface CalendlyContentProps {
    headingTitle: string,
    calendlyUrl: string,
    vslVimeoId: string,
    isMaturaParent: boolean
    hideBadge?: boolean
}

export default function CalendlyContent(props: CalendlyContentProps) {
    const {headingTitle, calendlyUrl, vslVimeoId, isMaturaParent, hideBadge} = props;
    const [open, setOpen] = useState(false);

    return <>
        <Heading customStyles='mb-0 text-center mw-md-4xl mx-auto'
                 customTitleStyles='font-heading mb-4 fs-4 text-white'
                 title={headingTitle}
                 badgeText={hideBadge ? undefined : 'Kurs Efektywnego Działania'}
        />
        <div className='mw-md-5xl mx-auto'>
            <div className='mw-md-3xl mx-auto mb-8 position-relative'>
                <VideoWithPlayButton vimeoVideoId={vslVimeoId} id='vsl-ked'
                                     onPlay={() => TraceService.addTrace(TraceType.PlayVideo, `vsl-ked-all`)}/>
            </div>
            <div className='text-center'>
                <p className='text-white fw-medium fs-10 mw-md-3xl mx-auto'>
                    Na spotkaniu przygotujemy <b>indywidualny plan działania</b> dopasowany
                    do <b>{isMaturaParent ? "potrzeb Twojego dziecka" : "Twoich potrzeb"}</b>.
                    Potrwa ono 30 minut i odbędzie się na Zoomie.
                </p>
                <AnimatedButton className='btn btn-lg btn-success' onClick={() => {
                    setOpen(true);
                    TraceService.addTrace(TraceType.BtnClick, 'btn-vsl-ked -> Umów się na spotkanie');
                    // matura parent has A/B Testing. OptInAll doesn't.
                    if (isMaturaParent) {
                        TraceService.addTraceWithExperiment(TraceType.BtnClick, KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1)
                    }
                }}>
                    Umów się na indywidualną konsultację
                </AnimatedButton>
                {open && (
                    <CalendlyModalLazy open={open} setOpen={setOpen} calendlyUrl={calendlyUrl} onEventScheduled={() => {
                        TraceService.addTraceWithExperiment(TraceType.ScheduledSalesCall, KED_SALES_CALL_MATURA_PARENT_EXPERIMENT_ID_1)
                    }}/>
                )}
            </div>
        </div>
    </>
}
