import {AnimatePresence, motion} from "framer-motion";
import CustomSelect from "../../../ui/form/CustomSelect";
import RadioTwoOptions from "../../../ui/form/RadioTwoOptions";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import React, {useEffect, useState} from "react";
import {Form, useActionData} from "react-router-dom";
import {SpeakingSettings as SpeakingSettingsModel} from "../../../../model/user/ai/speaking/SpeakingSettings";
import ThreadSelection from "../chat/ThreadSelection";
import {LoadThreadsAndInfo} from "../../../../model/user/ai/chat/LoadThreadsAndInfo";
import ModelSelection from "../chat/ModelSelection";
import {getModelById} from "../../../../model/common/Model";
import ModelDescription from "../ModelDescription";

interface SpeakingSettingsProps {
    showSettings: boolean
    toggleShowSettings: () => void;
    loadThreadsAndInfo: LoadThreadsAndInfo;
    handleThreadChange: (item: any) => void;
    modelId: string;
    setModelId: React.Dispatch<React.SetStateAction<string | null>>;
    handleModelChange: (item: string) => void;
    isSubmitting: boolean;
    loadedSettings: SpeakingSettingsModel;
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>
    sendSpeech: string;
    setSendSpeech: React.Dispatch<React.SetStateAction<string>>;
    showTranscript: boolean;
    setShowTranscript: React.Dispatch<React.SetStateAction<boolean>>;
    threadId: string | null;
}

type VoiceIdsByLanguages = {
    [key: string]: {
        [key: string]: string
    }
}

const voiceIdsByLanguage = {
    "en": {
        "Matthew": "Matthew (US)",
        "Emma": "Emma (GB)",
        "Brian": "Brian (GB)",
        "Arthur": "Arthur (GB)",
        "Amy": "Amy (GB)",
        "Joanna": "Joanna (US)",
    },
    "de": {
        "Vicki": "Vicki",
        "Daniel": "Daniel"
    },
    "fr": {
        "Lea": "Léa",
        "Remi": "Rémi"
    },
    "es": {
        "Lucia": "Lucia",
        "Sergio": "Sergio"
    }
} as VoiceIdsByLanguages

export default function SpeakingSettings(props: SpeakingSettingsProps) {
    const {
        showSettings,
        toggleShowSettings,
        loadThreadsAndInfo,
        handleThreadChange,
        modelId,
        setModelId,
        handleModelChange,
        isSubmitting,
        loadedSettings,
        language,
        setLanguage,
        sendSpeech,
        setSendSpeech,
        showTranscript,
        setShowTranscript,
        threadId,
    } = props;

    const actionData: any = useActionData();
    const [level, setLevel] = useState('automatic');
    const [conversationSubject, setConversationSubject] = useState('');

    const [availableLectors, setAvailableLectors] = useState(voiceIdsByLanguage[language as keyof typeof voiceIdsByLanguage]);
    const [lector, setLector] = useState(availableLectors[0]);


    const model = getModelById(loadThreadsAndInfo.models, modelId) ?? loadThreadsAndInfo.models[0];

    useEffect(() => {
        if (actionData && actionData.status === 200) {
            toggleShowSettings();
            return;
        }
        if (!loadedSettings) {
            return;
        }
        if (loadedSettings.language) {
            setLanguage(loadedSettings.language);
            setAvailableLectors(voiceIdsByLanguage[loadedSettings.language as keyof typeof voiceIdsByLanguage]);
        }
        if (loadedSettings.level) {
            setLevel(loadedSettings.level);
        }
        if (loadedSettings.lector) {
            setLector(loadedSettings.lector);
        }
        if (loadedSettings.sendSpeech) {
            setSendSpeech(loadedSettings.sendSpeech);
        }
        if (loadedSettings.showTranscript) {
            setShowTranscript(loadedSettings.showTranscript);
        }
        if (loadedSettings.conversationSubject) {
            setConversationSubject(loadedSettings.conversationSubject);
        }
        if (loadedSettings.modelId) {
            if (getModelById(loadThreadsAndInfo.models, loadedSettings.modelId)) {
                setModelId(loadedSettings.modelId);
            } else {
                setModelId(loadThreadsAndInfo.models[0].id);
            }
        }
    }, [actionData])

    return <AnimatePresence>
        {
            showSettings && <>
                <Form method='POST'>
                    {
                        localStorage.getItem('showSettingsFirstTime') === 'true' && <>
                            <p className='text-center text-white fw-medium fs-10 mw-md-4xl mx-auto'>
                                <span className='text-danger fw-bold fs-9'>Uwaga: </span>
                                Upewnij się, że Twoja przeglądarka ma dostęp do mikrofonu i działa on prawidłowo. Możesz to
                                sprawdzić, testując mikrofon na <a className='link-success text-decoration-underline'
                                                                   href='https://pl.mictests.com/'>tej stronie.</a>
                            </p>
                            <p className='text-center text-white fw-medium fs-10 mw-md-4xl mx-auto'>
                                Po uruchomieniu rozmowy kliknij "Zezwalaj", aby udzielić Produkacji dostępu do mikrofonu.
                            </p>
                            <div className='text-center mw-md-sm mx-auto'>
                                <img alt='dostep do mikrofonu' className='img-fluid'
                                     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/konwersacje/mic-access2.png'/>
                            </div>
                            <p className='text-center text-white fw-medium fs-10 mw-md-4xl mx-auto'>
                                Skonfiguruj ustawienia, aby zacząć rozmawiać.
                            </p>
                            <hr className='text-white'/>
                        </>
                    }
                    <motion.div className='mb-8 row g-8 align-items-center mw-md-4xl mx-auto'
                                initial={{opacity: 0, y: 100}} animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 100}}>

                        <div className='col-12 col-sm-6 col-md-4 mt-8'>
                            <label
                                className='mb-2 text-white fw-light'
                                htmlFor='modalInput2-2'
                            >
                                Wybierz język
                            </label>
                            <CustomSelect value={language} name='language' onChange={(event) => {
                                setLanguage(event.target.value)
                                setAvailableLectors(voiceIdsByLanguage[event.target.value as keyof typeof voiceIdsByLanguage])
                            }}>
                                <option value='en'>Angielski</option>
                                <option value='de'>Niemiecki</option>
                                <option value='es'>Hiszpański</option>
                                <option value='fr'>Francuski</option>
                            </CustomSelect>
                        </div>
                        <div className='col-12 col-sm-6 col-md-4 mt-8'>
                            <label
                                className='mb-2 text-white fw-light'
                                htmlFor='modalInput2-2'
                            >
                                Wybierz poziom
                            </label>
                            <CustomSelect value={level} name='level' onChange={(event) => {
                                setLevel(event.target.value)
                            }}>
                                <option value='automatic'>Wykryj automatycznie</option>
                                <option value='A1'>A1</option>
                                <option value='A2'>A2</option>
                                <option value='B1'>B1</option>
                                <option value='B2'>B2</option>
                                <option value='C1'>C1</option>
                                <option value='C2'>C2</option>
                            </CustomSelect>
                        </div>
                        <div className='col-12 col-sm-6 col-md-4 mt-8'>
                            <label
                                className='mb-2 text-white fw-light'
                                htmlFor='modalInput2-2'
                            >
                                Wybierz lektora
                            </label>
                            <CustomSelect value={lector} name='lector' onChange={(event) => {
                                setLector(event.target.value)
                            }}>
                                {
                                    Object.entries(availableLectors).map(([lector, name]) => (
                                        <option value={lector} key={lector}>{name}</option>
                                    ))
                                }
                            </CustomSelect>
                        </div>
                        <div className='col-12 col-sm-6 col-md-4 mt-8'>
                            <label
                                className='mb-2 text-white fw-light'
                                htmlFor='modalInput2-2'
                            >
                                Wysyłanie wypowiedzi
                            </label>
                            <CustomSelect value={sendSpeech} name='sendSpeech' onChange={(event) => {
                                setSendSpeech(event.target.value)
                            }}>
                                <option value='manual'>Manualnie</option>
                                <option value='2s'>Automatycznie, po 2s ciszy</option>
                                <option value='3s'>Automatycznie, po 3s ciszy</option>
                                <option value='4s'>Automatycznie, po 4s ciszy</option>
                            </CustomSelect>
                        </div>
                        <div className='col-12 col-md-4'>
                            <ModelSelection models={loadThreadsAndInfo.models} selectedModelId={modelId}
                                            handleModelChange={handleModelChange}/>
                        </div>
                        <div className='col-12 col-sm-8 col-md-4 '>
                            <ThreadSelection allThreads={loadThreadsAndInfo}
                                             selectedThread={threadId}
                                             handleThreadChange={handleThreadChange}/>
                        </div>
                        <div className='col-12 col-sm-4 col-md-4 mt-8'>
                            <RadioTwoOptions name='showTranscript' handleRadioChange={(event) => {
                                setShowTranscript(event.target.value === "yes")
                            }} checked={showTranscript} label="Pokazywać transkrypcję?" firstText="Tak" firstValue="yes"
                                             secondText="Nie" secondValue="no"/>
                        </div>
                        <div className='col-12 col-md-8 mt-8'>
                            <label
                                className='mb-1 fw-medium text-white'
                                htmlFor='contactInput10-2'
                            >
                                Temat konwersacji (opcjonalnie)
                            </label>
                            <textarea
                                className='form-control shadow bg-dark'
                                id='contactInput10-2'
                                placeholder='Wprowadź temat...'
                                rows={2}
                                name='conversationSubject'
                                value={conversationSubject}
                                onChange={(event) => {
                                    setConversationSubject(event.target.value)
                                }}
                                style={conversationSubject ? {color: 'white'} : {}}
                            />
                        </div>
                        <ModelDescription model={model}/>

                        <div className='col-12 text-center mw-xs mx-auto my-10'>
                            <AnimatedButton
                                className='btn btn-lg btn-success fs-11 py-3 w-100 px-5 text-success-light shadow'
                                type='submit'
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Trwa zapisywanie..." : "Zapisz ustawienia"}
                            </AnimatedButton>
                        </div>
                    </motion.div>
                </Form>

            </>

        }
    </AnimatePresence>
}