import Meta from "../../../components/common/Meta";
import FunnelMWParentVSL from "../../../components/funnel/after-optin/FunnelMWParentVSL";
import MainDesign from "../../MainDesign";
import ProdukacjaCopyrightNote from "../../../components/funnel/ProdukacjaCopyrightNote";

export default function FunnelMWParentVSLPage() {
    return <>
        <Meta title='Obejrzyj bezpłatne szkolenie'/>

        <MainDesign containerStyles="container py-20" minHeight='100vh'>
            <FunnelMWParentVSL/>
            <ProdukacjaCopyrightNote/>
        </MainDesign>
    </>
}