import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import CustomSelect from "../../../ui/form/CustomSelect";
import {
    CourseEpisode,
    CourseWeekAndEpisodes,
    LastWatchedEpisode,
    learningCourseStructure
} from "../../../../model/user/learning-course/LearningCourse";
import {Link} from "react-router-dom";
import AnimatedLink from "../../../ui/motion/AnimatedLink";

interface LearningCourseLeftMenuProps {
    wrapperClasses: string
    watchesCourseEpisode: boolean
    lastWatched: LastWatchedEpisode
    weekAndEpisodes: CourseWeekAndEpisodes | null
    episode: CourseEpisode | null
    updateWeek: (newWeek: string) => void
    updateEpisode: (newEpisode: string) => void
}

const buttonVariants = {
    hidden: {opacity: 0, y: 20},
    visible: (i: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: i * 0.2
        }
    })
};

export default function LearningCourseLeftMenu(props: LearningCourseLeftMenuProps) {
    const {
        wrapperClasses,
        watchesCourseEpisode,
        lastWatched,
        weekAndEpisodes,
        episode,
        updateWeek,
        updateEpisode,
    } = props;

    const currentWeek = watchesCourseEpisode ? learningCourseStructure[Number(weekAndEpisodes!!.week.week)] : null;

    return <motion.div className={wrapperClasses} key={JSON.stringify(weekAndEpisodes)}>
        <div className="px-2 py-8 rounded text-center mb-8 bg-light-dark">
            <p className="mb-0 text-white fw-medium fs-7">
                {watchesCourseEpisode ? `Tydzień ${weekAndEpisodes?.week.week}:` : 'Długość kursu:'}
            </p>
            <h3 className='text-warning fs-7 px-1'>
                {watchesCourseEpisode ? currentWeek!!.title : '7 tygodni'}
            </h3>
        </div>
        {!watchesCourseEpisode &&
            <AnimatedLink
                className='btn btn-xl btn-warning w-100 text-success-light shadow'
                replaceLinkWithAHref={true}
                to={lastWatched ? `?w=${lastWatched.w}&e=${lastWatched.e}` : '?w=0&e=1'}>
                Przejdź do kursu
            </AnimatedLink>
        }
        {watchesCourseEpisode && (
            <>
                <CustomSelect
                    value={weekAndEpisodes!!.week.week.toString()}
                    customStyles='select-lg'
                    onChange={(event) => {
                        console.log(event.target.value);
                        updateWeek(event.target.value);
                    }}
                >
                    {learningCourseStructure.filter((course) => (course.episodes.length > 0 && learningCourseStructure.indexOf(course) <= weekAndEpisodes!!.weeksUnlocked)).map((weekItem, count) => (
                        <option value={count.toString()} key={weekItem.title}>
                            Tydzień {count}
                        </option>
                    ))}
                </CustomSelect>
                <div className='text-white py-4'>
                    <AnimatePresence>
                        {currentWeek!!.episodes.map((episodeItem, count) => (
                            <motion.div
                                key={episodeItem.title}
                                custom={count}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                variants={buttonVariants}
                            >
                                <Link
                                    to={'?w=' + weekAndEpisodes!!.week.week + '&e=' + (count + 1)}
                                    // replaceLinkWithAHref={true}
                                    // onClick={() => updateEpisode((count + 1).toString())}
                                    className={`btn btn-lg btn-dark-light w-100 rounded-2 mb-3 text-start fs-10 ${count + 1 === episode!!.episode ? 'active fw-bold' : ''}`}
                                >
                                    {count + 1}. {episodeItem.title} {weekAndEpisodes!!.episodes[count] && weekAndEpisodes!!.episodes[count].completed && (
                                    <img className='me-2'
                                         src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/checkbox-green.svg'
                                         alt='checkbox'/>
                                )}
                                </Link>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            </>
        )}
    </motion.div>
}
