import Heading from "../../../components/common/header/Heading";
import MainDesign from "../../MainDesign";
import AddBlogButton from "../../../components/admin/blog/AddBlogButton";
import {BlogArticles} from "../../../model/user/blog/Blog";
import {BlogService} from "../../../service/BlogService";
import {Await, defer, useLoaderData} from "react-router-dom";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import BlogPosts from "../../../components/user/blog/BlogPosts";
import React, {Suspense} from "react";
import Meta from "../../../components/common/Meta";
import {useCategory} from "../../../hooks/useCategory";
import {blogCategories} from "../../../model/common/blog/BlogCategories";
import Categories from "../../../components/ui/category/Categories";

type LoaderData = {
    articles: BlogArticles;
};

export default function AdminBlogPage() {
    const {articles} = useLoaderData() as LoaderData;

    const {selectedCategory, onCategoryChange} = useCategory(blogCategories);

    return <>
        <Meta title="Artykuły na blogu"/>
        <MainDesign containerStyles='py-28 container'>
            <Heading title="Artykuły na blogu"
                     subtitle="Przeglądaj istniejące artykuły, edytuj je, usuwaj, lub dodawaj nowe."
                     badgeText="blog"/>
            <AddBlogButton/>
            <Categories categories={blogCategories} selectedCategory={selectedCategory}
                        onCategoryChange={onCategoryChange}/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={articles} errorElement={<ErrorContent/>}>
                    {(loadedArticles) => <>
                        <BlogPosts isAdmin={true} blogArticles={loadedArticles} category={selectedCategory.value}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadArticles(category: string): Promise<BlogArticles> {
    return await BlogService.getArticles(undefined, category);
}

export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const category = url.searchParams.get('category') ?? 'all';
    return defer({
        articles: loadArticles(category),
    })
}