import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import KedShowcase from "../../../components/user/offer/KedShowcase";
import Heading from "../../../components/common/header/Heading";

export default function KedShowcasePage() {
    return <>
        <Meta title='Kurs Efektywnego Działania - Prezentacja'
              description='Czym jest Kurs Efektywnego Działania? O tym dowiesz się w tym krótkim filmie. Działaj Efektywniej i Osiągaj Więcej.'
              imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-showcase-photo.png'/>
        <MainDesign containerStyles='container py-20'>
            <Heading title='Jak Kurs Efektywnego Działania może Ci pomóc?'/>
            <KedShowcase/>
        </MainDesign>
    </>
}