import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {Exercise, ExercisePage, ExerciseRequest} from "../model/user/exercise/Exercise";
import {constructUrl} from "../util/Util";
import {increaseItemViews} from "../util/ViewsUtil";

export class ExerciseService {

    public static async getExercise(uuid: string, toEdit: boolean): Promise<Exercise> {
        const {uniqueView, repeatedView} = increaseItemViews('exercises', uuid, toEdit);

        return HttpService.sendRequest<Exercise>(
            HttpMethod.GET,
            `/api/exercise/${uuid}?uniqueView=${uniqueView}&repeatedView=${repeatedView}`,
        );
    }

    public static async getExercises(nextPageKey: string | undefined, category: string | null): Promise<ExercisePage> {
        const url = constructUrl('/api/exercise',
            {
                nextPageKey: nextPageKey,
                field: category === 'all' ? null : category,
                level: 'Podstawa',
                subject: 'Matematyka'
            });
        return HttpService.sendRequest<ExercisePage>(
            HttpMethod.GET,
            url,
        );
    }

    public static async getPlannedExercises(): Promise<Exercise[]> {
        return HttpService.sendRequest<Exercise[]>(
            HttpMethod.GET,
            '/api/exercise?planned=true',
        );
    }


    public static addOrEditExercise(requestBody: ExerciseRequest): Promise<string> {
        return HttpService.sendRequest<string>(
            HttpMethod.POST,
            `/api/exercise`,
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static deleteExercise(uuid: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.DELETE,
            `/api/exercise/${uuid}`,
        );
    }
}