import Header from "../ui/header/Header";

export default function AdminHeader() {
    return <Header
        adminPanel={true}
        mainPage={{
            title: 'Dashboard',
            to: '/admin'
        }} topItems={[
        {
            title: 'AI',
            to: 'ai'
        },
        {
            title: 'Blog',
            to: 'blog'
        },
        {
            title: 'Feedback',
            to: 'feedback'
        },
        {
            title: 'Newsletter',
            to: 'newsletter'
        },
        // TODO: In the future, uncomment it.
        // {
        //     title: 'Zadania',
        //     to: 'zadania'
        // },
        {
            title: 'Diagnostyka',
            to: 'diagnostyka'
        },
        {
            title: 'Ścieżki',
            to: 'traces'
        },
        {
            title: 'KED',
            to: 'ked'
        }
    ]}/>
}