import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import React, {useEffect, useState} from "react";
import {ApiError} from "../../../service/HttpService";
import {NewsletterService} from "../../../service/NewsletterService";
import {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import EffectiveActionCourseOptInA from "../../../components/funnel/optin/EffectiveActionCourseOptInA";
import ABTestingComponent from "../../../components/ab-testing/ABTestingComponent";
import EffectiveActionCourseOptInB from "../../../components/funnel/optin/EffectiveActionCourseOptInB";
import {getSourceAndVariationFromRequest, KED_OPT_IN_ALL_EXPERIMENT_ID_1} from "../../../util/ExperimentUtil";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {useSearchParams} from "react-router-dom";
import ProdukacjaCopyrightNote from "../../../components/funnel/ProdukacjaCopyrightNote";

export default function FunnelAllOptInPage() {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [searchParams] = useSearchParams();

    const isInputValid = !!email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) && name.length >= 2;

    function handleButtonClick(buttonNum: number, variation: "A" | "B") {
        setLoading(true);
        TraceService.addTrace(TraceType.BtnClick, `btn ${buttonNum} -> chcę otrzymać szczegóły. Variation ${variation}`);
        TraceService.addTraceWithExperiment(TraceType.BtnClick, KED_OPT_IN_ALL_EXPERIMENT_ID_1, variation, searchParams.get('source'))

        setTimeout(() => {
            setLoading(false);
            setConfirmModalOpen(true);
        }, 2000);
    }

    useEffect(() => {
        TraceService.addTrace(TraceType.PageLoad)
    }, []);
    return (
        <>
            <Meta
                title="Kurs Efektywnego Działania"
                description="Kurs Efektywnego Działania. Działaj efektywniej i osiągaj więcej! Połączenie kursu efektywnej nauki z efektywnym działaniem, które przełoży się na sukces w dorosłym życiu."
            />
            <MainDesign containerStyles="container pt-20 pb-10">
                <ABTestingComponent
                    ComponentA={<EffectiveActionCourseOptInA handleButtonClick={handleButtonClick}
                                                             email={email} name={name} setName={setName}
                                                             setEmail={setEmail}
                                                             confirmModalOpen={confirmModalOpen}
                                                             setConfirmModalOpen={setConfirmModalOpen}
                                                             isInputValid={isInputValid}
                                                             loading={loading}/>}
                    ComponentB={<EffectiveActionCourseOptInB handleButtonClick={handleButtonClick}
                                                             email={email} name={name} setName={setName}
                                                             setEmail={setEmail}
                                                             confirmModalOpen={confirmModalOpen}
                                                             setConfirmModalOpen={setConfirmModalOpen}
                                                             isInputValid={isInputValid}
                                                             loading={loading}/>}
                    experimentId={KED_OPT_IN_ALL_EXPERIMENT_ID_1}
                    noCookies={true}
                />
                <ProdukacjaCopyrightNote/>
            </MainDesign>
        </>
    );
}

export async function action({request}: { request: Request }) {
    const {
        source,
        variation
    } = await getSourceAndVariationFromRequest(request, KED_OPT_IN_ALL_EXPERIMENT_ID_1);
    const formData = await request.formData();
    const optInData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
    };

    TraceService.addTraceWithExperiment(TraceType.RequestSend, KED_OPT_IN_ALL_EXPERIMENT_ID_1);

    //@ts-ignore
    gtag('event', 'conversion', {'send_to': 'AW-16562613681/1xYBCPbY864ZELHb1dk9'});
    try {
        await NewsletterService.subscribeNewsletter({
            name: optInData.name,
            type: NewsletterType.KedOptInAll,
            email: optInData.email,
            source: source,
            variation: variation
        });
        return {
            status: 200,
            body: "Email został wysłany pomyślnie."
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}