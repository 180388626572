export default function RedirectAfterRegistration() {
    return <>
        <div className='mw-md-3xl mx-auto'>
            <p className='text-center fs-10 fw-medium text-white '>
                <span className='text-success fw-bold'>Świetna decyzja!</span> Zobaczysz, że
                nasze rozwiązania
                AI zmienią Twoje podejście do nauki na lepsze!</p>
            <img src='https://produkacja.s3.eu-central-1.amazonaws.com/web/img/after-register.png'
                 alt='Dziękujemy za rejestrację' className='img-fluid'/>
            <p className='text-center fs-11 fw-medium text-secondary-light mt-3'>
                Możesz zamknąć tę stronę.</p>
        </div>
    </>
}