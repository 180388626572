import {AddDiagnosticRequest, DiagnosticItemsModel} from "../model/admin/Diagnostic";
import {constructUrl} from "../util/Util";
import {ContentType, HttpMethod, HttpService} from "./HttpService";

export class DiagnosticService {

    public static async getDiagnostics(pageKey: string | undefined, category: string | null): Promise<DiagnosticItemsModel> {
        let url = constructUrl('/api/diagnostic', {nextPageKey: pageKey, category: category});
        return HttpService.sendRequest<DiagnosticItemsModel>(
            HttpMethod.GET,
            url,
        );
    }

    public static async markDiagnosticAsReviewed(diagnosticId: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/diagnostic/reviewed',
            JSON.stringify({id: diagnosticId}),
            ContentType.JSON
        );
    }

    public static async addDiagnostic(diagnosticRequest: AddDiagnosticRequest): Promise<void> {
        try {
            return HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/diagnostic',
                JSON.stringify(diagnosticRequest),
                ContentType.JSON
            );
        } catch (error) {
            console.log('Error adding diagnostic:', error);
        }
    }
}