import React, {lazy, Suspense} from "react";
import LoadingPage from "../../../pages/common/LoadingPage";

const CalendlyModal = lazy(() => import("./CalendlyModal"));

interface CalendlyModalLazyProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    calendlyUrl: string
    email?: string
    name?: string
    onEventScheduled?: () => void
    onDateAndTimeSelected?: () => void;
    onEventTypeViewed?: () => void;
}

export default function CalendlyModalLazy(props: CalendlyModalLazyProps) {
    const {open, setOpen, calendlyUrl, email, name, onEventTypeViewed, onDateAndTimeSelected, onEventScheduled} = props;
    return <Suspense fallback={<LoadingPage/>}>
        <CalendlyModal
            open={open}
            onClose={() => setOpen(false)}
            url={calendlyUrl + `${email ? `?email=${email}` : ''}${name ? (email ? `&name=${name}` : `?name=${name}`) : ''}`}
            onEventScheduled={onEventScheduled}
            onDateAndTimeSelected={onDateAndTimeSelected}
            onEventTypeViewed={onEventTypeViewed}
        />
    </Suspense>
}