import React from "react";
import Heading from "../../common/header/Heading";

export default function ExtraordinarySupportSuccessfulPurchase() {
    return <div className='mw-md-4xl mx-auto text-center mt-12'>
        <Heading title='Działaj Efektywniej i Osiągaj Więcej.'
                 customTitleStyles='fs-6 fw-bold text-success'
        />
        <p className='fw-medium text-white fs-10 mw-md-2xl mx-auto mt-10'>Wszystkie instrukcje dotyczące wykonania
            testów oraz
            konsultacji zostaną wysłane na podany adres email w ciągu kilku godzin. </p>

        <p className='fw-medium text-white fs-9 mw-md-2xl mx-auto mt-10'>Masz jakiekolwiek pytanie? </p>
        <p className='fw-medium text-white fs-10 mw-md-2xl mx-auto mt-2'><i>+48 603 434 484</i></p>

        {/*<img className='img-fluid mb-10'*/}
        {/*     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wspolne1.jpg'*/}
        {/*     alt='wspólne zdjęcie'/>*/}
    </div>
}