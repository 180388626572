import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import RedirectAfterSchedulingCall from "../../../components/user/thank-you/RedirectAfterSchedulingCall";

export default function RedirectAfterSchedulingCallPage() {

    return (
        <>
            <Meta title='Dziekujemy za zainteresowanie!'/>
            <MainDesign containerStyles='container py-28'>
                <Heading badgeText='sukces'
                         title='Spotkanie zostało umówione'
                         customSubtitleStyles='fs-10 fw-medium text-warning mw-md-3xl mx-auto'
                         subtitle='UWAGA: Proszę Cię o zrobienie tych dwóch rzeczy. Zajmie Ci to maksymalnie minutę. W przeciwnym razie spotkanie zostanie automatycznie anulowane.'/>
                <RedirectAfterSchedulingCall/>
            </MainDesign>
        </>
    );
}