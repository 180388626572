import React from "react";

export default function OldExercisesDescription() {
    return <div className='mt-10 mb-6 text-center mw-md-4xl mx-auto'>
        <h2
            className='font-heading mb-4 fs-5 text-white'
            style={{letterSpacing: '-1px'}}
        >
            Przeszłe zadania
        </h2>
        <p className='mb-0 fs-10 fw-medium text-secondary-light'>
            Znajdziesz tutaj wszystkie opublikowane zadania. Możesz dowolny wybrać dział, lub przeglądać wszystkie.
        </p>
    </div>
}