import AnimatedButton from "../motion/AnimatedButton";

export interface Category {
    displayName: string
    value: string
    apiCategory?: boolean
    navigateUrl: string
}

export interface CategoriesProps {
    categories: Category[],
    selectedCategory: Category,
    onCategoryChange: (category: Category) => void
}

const selectedItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-success link-success bg-success bg-opacity-25 shadow'
const otherItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-secondary link-secondary-light'

export default function Categories(props: CategoriesProps) {
    const {categories, selectedCategory, onCategoryChange} = props;

    return <ul className='mb-8 nav nav-pills'>
        {
            (categories).map((category) => (
                <li key={category.displayName}>
                    <AnimatedButton onClick={() => onCategoryChange(category)}
                                    className={selectedCategory.displayName === category.displayName ? selectedItemStyles : otherItemStyles}>
                        {category.displayName}
                    </AnimatedButton>
                </li>
            ))
        }
    </ul>
}