import React from 'react';
import {motion} from 'framer-motion';

const LoadingDots = () => {
    const dotStyle = {
        width: '8px',
        height: '8px',
        backgroundColor: 'currentColor',
        borderRadius: '50%',
        display: 'inline-block',
        marginLeft: '2px',
        marginRight: '2px',
    };

    return <>
        <motion.div
            style={dotStyle}
            animate={{
                opacity: [0, 1, 0],
            }}
            transition={{
                repeat: Infinity,
                duration: 1,
                ease: 'easeInOut',
            }}
        />
        <motion.div
            style={dotStyle}
            animate={{
                opacity: [0, 1, 0],
            }}
            transition={{
                repeat: Infinity,
                duration: 1,
                ease: 'easeInOut',
                delay: 0.3,
            }}
        />
        <motion.div
            style={dotStyle}
            animate={{
                opacity: [0, 1, 0],
            }}
            transition={{
                repeat: Infinity,
                duration: 1,
                ease: 'easeInOut',
                delay: 0.6,
            }}/>
    </>
};

export default LoadingDots;