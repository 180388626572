import {HttpMethod, HttpService} from "./HttpService";
import {ExperimentMetrics, Metrics} from "../model/admin/Metrics";

export class AdminService {

    public static getMetrics(): Promise<Metrics> {
        return HttpService.sendRequest<Metrics>(
            HttpMethod.GET,
            '/api/metrics'
        );
    }

    public static getExperimentResults(experimentId: string): Promise<ExperimentMetrics[]> {
        return HttpService.sendRequest<ExperimentMetrics[]>(
            HttpMethod.GET,
            `/api/experiment_results?experimentId=${experimentId}`
        );
    }
}