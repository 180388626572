import Meta from "../../../components/common/Meta";
import AdminTracesContent from "../../../components/admin/trace/AdminTracesContent";
import {Await, defer, useLoaderData} from "react-router-dom";
import {TraceService} from "../../../service/TraceService";
import {Traces} from "../../../model/user/general/Trace";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import React, {Suspense} from "react";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";

type LoaderData = {
    traces: Promise<Traces>;
};

export default function AdminTracesPage() {
    const {traces} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Traces'/>
        <MainDesign containerStyles='container pt-20'>
            <Heading title='Ślady użytkowników' badgeText='traces'
                     subtitle='Zobacz, jak użytkownicy korzystają z aplikacji.'/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={traces} errorElement={<ErrorContent/>}>
                    {(loadedTraces) => <>
                        <AdminTracesContent traces={loadedTraces}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadTraces(date: string): Promise<Traces> {
    return await TraceService.getTraces(undefined, date);
}


export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const date = url.searchParams.get('date') ?? new Date().toISOString().split("T")[0];

    return defer({
        traces: loadTraces(date)
    })
}