import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import ABTestingComponent from "../../../components/ab-testing/ABTestingComponent";
import EffectiveActionCourseMaturaParentOptInA
    from "../../../components/funnel/optin/EffectiveActionCourseMaturaParentOptInA";
import EffectiveActionCourseMaturaParentOptInB
    from "../../../components/funnel/optin/EffectiveActionCourseMaturaParentOptInB";
import {KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_3} from "../../../util/ExperimentUtil";
import React from "react";
import ProdukacjaCopyrightNote from "../../../components/funnel/ProdukacjaCopyrightNote";
import usePageTracking from "../../../hooks/usePageTracking";
import useOptInForm from "../../../hooks/useOptInForm";

export default function FunnelMaturaParentOptInPage() {
    usePageTracking();

    const {
        confirmModalOpen,
        setConfirmModalOpen,
        loading,
        email,
        setEmail,
        name,
        setName,
        isInputValid,
        handleButtonClick,
    } = useOptInForm(KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_3);

    return (
        <>
            <Meta
                title="Kurs Efektywnego Działania"
                description="Kurs Efektywnego Działania. Działaj efektywniej i osiągaj więcej! Połączenie kursu efektywnej nauki z efektywnym działaniem, które przełoży się na sukces w dorosłym życiu."
            />
            <MainDesign containerStyles="container pt-10 pt-md-20 pb-10">
                <ABTestingComponent
                    ComponentA={<EffectiveActionCourseMaturaParentOptInA handleButtonClick={handleButtonClick}
                                                                         email={email} name={name} setName={setName}
                                                                         setEmail={setEmail}
                                                                         confirmModalOpen={confirmModalOpen}
                                                                         setConfirmModalOpen={setConfirmModalOpen}
                                                                         isInputValid={isInputValid}
                                                                         loading={loading}/>}
                    ComponentB={<EffectiveActionCourseMaturaParentOptInB handleButtonClick={handleButtonClick}
                                                                         email={email} name={name} setName={setName}
                                                                         setEmail={setEmail}
                                                                         confirmModalOpen={confirmModalOpen}
                                                                         setConfirmModalOpen={setConfirmModalOpen}
                                                                         isInputValid={isInputValid}
                                                                         loading={loading}/>}
                    experimentId={KED_OPT_IN_MATURA_PARENT_EXPERIMENT_ID_3}
                    noCookies={true}
                />
                <ProdukacjaCopyrightNote/>
            </MainDesign>
        </>
    );
}
