import {AnswerType, QuestionType} from '../../../model/user/Chronotypes';
import React, {useState} from "react";
import Question from "./Question";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

const dolphinPreQuiz: QuestionType[] = [
    {
        question: "Najsłabszy dźwięk albo światło nie pozwalają mi zasnąć lub mogą mnie obudzić.",
        options: ["Prawda", "Fałsz"]
    },
    {question: "Jedzenie nie jest moją wielką pasją.", options: ["Prawda", "Fałsz"]},
    {question: "Zazwyczaj budzę się, zanim zadzwoni budzik.", options: ["Prawda", "Fałsz"]},
    {
        question: "Nie potrafię zasnąć w samolocie, nawet z przepaską na oczach i zatyczkami w uszach.",
        options: ["Prawda", "Fałsz"]
    },
    {question: "Często jestem poirytowany ze zmęczenia.", options: ["Prawda", "Fałsz"]},
    {question: "Nadmiernie zamartwiam się drobiazgami.", options: ["Prawda", "Fałsz"]},
    {
        question: "Cierpię na bezsenność, którą zdiagnozował u mnie lekarz lub sam u siebie zdiagnozowałem.",
        options: ["Prawda", "Fałsz"]
    },
    {question: "W szkole martwiłem się ocenami.", options: ["Prawda", "Fałsz"]},
    {
        question: "Nie mogę spać, bo rozmyślam o tym, co wydarzyło się w przeszłości i może wydarzyć się w przyszłości.",
        options: ["Prawda", "Fałsz"]
    },
    {question: "Jestem perfekcjonistą.", options: ["Prawda", "Fałsz"]}
];

const mainQuiz: QuestionType[] = [
    {
        question: "Gdybyś następnego dnia nie miał żadnych zajęć i mógł spać tak długo, jak tylko chcesz, o której byś się obudził?",
        options: ["a. Przed 6.30", "b. Między 6.30 a 8.45", "c. Po 8.45"]
    },
    {
        question: "Jeśli musisz wstać o określonej porze, czy nastawiasz budzik?",
        options: ["a. Nie ma takiej potrzeby.", "b. Tak, budzi mnie budzik bez drzemki albo z jedną drzemką.", "c. Tak, budzi mnie budzik, ale potrzebuję też drugiego budzika oraz licznych drzemek."]
    },
    {
        question: "O której wstajesz w weekendy?",
        options: ["a. O tej samej porze co w tygodniu.", "b. Od 45 do 90 minut później niż w tygodniu.", "c. O 90 minut lub więcej później niż w tygodniu."]
    },
    {
        question: "Jak przeżywasz jet lag?",
        options: ["a. Męczy mnie on niezależnie od tego, co zrobię.", "b. Przystosowuję się w ciągu 48 godzin.", "c. Przystosowuję się szybko, zwłaszcza gdy podróżuję na zachód."]
    },
    {
        question: "Który posiłek jadasz najchętniej?",
        options: ["a. Śniadanie.", "b. Obiad.", "c. Kolacja."]
    },
    {
        question: "Gdybyś miał cofnąć się do czasów liceum i znów zdawać maturę, kiedy wolałbyś rozpocząć egzamin?",
        options: ["a. Wczesnym rankiem.", "b. Koło południa.", "c. Po południu."]
    },
    {
        question: "Gdybyś mógł wybrać dowolną porę dnia na intensywny trening, jaką byś wybrał?",
        options: ["a. Przed 8.00.", "b. Pomiędzy 8.00 a 16.00.", "c. Po 16.00."]
    },
    {
        question: "Kiedy jesteś najbardziej pobudzony?",
        options: ["a. Godzinę, dwie po przebudzeniu.", "b. Od dwóch do czterech godzin po przebudzeniu.", "c. Od czterech do sześciu godzin po przebudzeniu."]
    },
    {
        question: "Gdybyś mógł wybrać własny pięciogodzinny dzień pracy, który blok byś wybrał?",
        options: ["a. Od 4.00 do 9.00.", "b. Od 9.00 do 14.00.", "c. Od 16.00 do 21.00."]
    },
    {
        question: "Uważasz się za:",
        options: ["a. Osobę lewopółkulową. (strategiczne, analityczne myślenie)", "b. Zrównoważonego myśliciela.", "c. Osobę prawopółkulową. (kreatywne myślenie)"]
    },
    {
        question: "Czy masz w zwyczaju drzemać?",
        options: ["a. Nigdy.", "b. Czasami w weekendy.", "c. Jeśli zdrzemnąłbym się w ciągu dnia, nie spałbym całą noc."]
    },
    {
        question: "Gdybyś musiał poświęcić dwie godziny na ciężką pracę fizyczną, na przykład przesuwanie mebli czy rąbanie drewna, jaką porę byś wybrał?",
        options: ["a. Pomiędzy 8.00 a 10.00.", "b. Pomiędzy 11.00 a 13.00.", "c. Pomiędzy 18.00 a 20.00."]
    },
    {
        question: "W kwestii zdrowia — które z poniższych zdań pasuje do ciebie?",
        options: ["a. „Dokonuję zdrowych wyborów niemal zawsze”.", "b. „Czasami dokonuję zdrowych wyborów”.", "c. „Z trudem udaje mi się dokonywać zdrowych wyborów”."]
    },
    {
        question: "Jak chętnie podejmujesz ryzyko?",
        options: ["a. Niechętnie.", "b. Dość chętnie.", "c. Bardzo chętnie."]
    },
    {
        question: "Uważasz, że jesteś:",
        options: ["a. Skupiony na przyszłości.", "b. Pogodzony z przeszłością.", "c. Skupiony na chwili obecnej."]
    },
    {
        question: "Jak scharakteryzowałby siebie jako studenta?",
        options: ["a. Wybitny.", "b. Solidny.", "c. Obibok."]
    },
    {
        question: "Gdy budzisz się rano, jesteś:",
        options: ["a. Całkowicie przytomny.", "b. Oszołomiony, ale nie zdezorientowany.", "c. Półprzytomny, powieki ci opadają."]
    },
    {
        question: "Jak określiłbyś swój apetyt pól godziny po przebudzeniu?",
        options: ["a. Bardzo głodny.", "b. Głodny.", "c. W ogóle nie czuję głodu."]
    },
    {
        question: "Jak często cierpisz z powodu bezsenności?",
        options: ["a. Rzadko.", "b. Czasami.", "c. Bezustannie."]
    },
    {
        question: "Jak oceniłbyś swoje ogólne zadowolenie z życia?",
        options: ["a. Wysokie.", "b. Dobre.", "c. Niskie."]
    }
];

const points: number[][] = [
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [1, 2, 3],
    [0, 2, 4]
];

const checkDolphin = (answers: AnswerType[]) => {
    const dolphinAnswers = answers.slice(0, 10);
    const dolphinScore = dolphinAnswers.filter(answer => answer.answerIndex === 0).length;
    return dolphinScore >= 7;
};

const calculateChronotype = (answers: AnswerType[]) => {
    const totalScore = answers.reduce((sum, answer, index) => {
        return sum + points[index][answer.answerIndex];
    }, 0);

    if (totalScore >= 19 && totalScore <= 32) {
        return {
            type: "Lew",
            summary: "Lwy budzą się wcześnie i są najbardziej produktywne rano. Potrafią utrzymać wysoki poziom energii przez cały dzień. Często są zorganizowane, proaktywne i ambitne, dążąc do osiągnięcia swoich celów z determinacją. Lubią planować dzień z wyprzedzeniem i preferują wczesne godziny poranne na najważniejsze zadania."
        };
    }
    if (totalScore >= 33 && totalScore <= 47) {
        return {
            type: "Niedźwiedź",
            summary: "Niedźwiedzie budzą się i zasypiają zgodnie z cyklem słonecznym. Są najbardziej produktywne w ciągu dnia. Lubią rutynę i są bardzo społeczne, cenią sobie czas spędzony z rodziną i przyjaciółmi. Niedźwiedzie często mają zrównoważone podejście do życia, starając się znaleźć równowagę między pracą a odpoczynkiem."
        };
    }
    if (totalScore >= 48 && totalScore <= 61) {
        return {
            type: "Wilk",
            summary: "Wilki to nocne marki, które są najbardziej produktywne późnym popołudniem i wieczorem. Mają trudności z porannym wstawaniem. Są kreatywne, impulsywne i często działają pod wpływem chwili. Wilki preferują pracę w elastycznych godzinach i mają tendencję do bycia bardziej aktywnymi i twórczymi w nocy."
        };
    }
    return {
        type: "Nieznany",
        summary: "Nie udało się jednoznacznie określić Twojego chronotypu. Możliwe, że masz cechy kilku różnych chronotypów lub Twoje nawyki snu są niestandardowe."
    };
};

export default function Quiz() {
    const [answers, setAnswers] = useState<AnswerType[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [finished, setFinished] = useState(false);
    const [yourChronotype, setYourChronotype] = useState({type: "", summary: ""});

    const handleAnswer = (answerIndex: number) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestionIndex] = {questionIndex: currentQuestionIndex, answerIndex};
        setAnswers(newAnswers);

        if (currentQuestionIndex < dolphinPreQuiz.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentQuestionIndex === dolphinPreQuiz.length - 1) {
            if (checkDolphin(newAnswers)) {
                console.log(answers);
                console.log(getSelectedAnswers())
                TraceService.addTrace(TraceType.SolvedQuiz, `Chronotyp: Delfin`);
                TraceService.addTrace(TraceType.SolvedQuiz, JSON.stringify(getSelectedAnswers()));
                setYourChronotype({
                    type: "Delfin",
                    summary: "Delfiny są lekkimi śpiochami, łatwo się budzą i mają trudności z zasypianiem. Są bardzo czujni i mają wysoką inteligencję. Często zamartwiają się drobiazgami i mają skłonność do bezsenności. Delfiny są perfekcjonistami i często mają trudności z odprężeniem się."
                });
                setFinished(true);
            } else {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            }
        } else if (currentQuestionIndex < dolphinPreQuiz.length + mainQuiz.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            const chronotype = calculateChronotype(newAnswers.slice(dolphinPreQuiz.length));
            setYourChronotype(chronotype);
            setFinished(true);
            TraceService.addTrace(TraceType.SolvedQuiz, `Chronotyp: ${chronotype.type}`);
            TraceService.addTrace(TraceType.SolvedQuiz, JSON.stringify(getSelectedAnswers()));
        }
    };

    const getSelectedAnswers = () => {
        const allQuestions = [...dolphinPreQuiz, ...mainQuiz];
        return answers.map(answer => ({
            question: allQuestions[answer.questionIndex].question,
            selectedAnswer: allQuestions[answer.questionIndex].options[answer.answerIndex]
        }));
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const currentQuestions = currentQuestionIndex < dolphinPreQuiz.length ? dolphinPreQuiz : mainQuiz;
    const questionIndex = currentQuestionIndex < dolphinPreQuiz.length ? currentQuestionIndex : currentQuestionIndex - dolphinPreQuiz.length;

    return (
        <div className='mx-auto mw-md-4xl'>
            <hr className='text-white'/>
            {
                finished && yourChronotype.type !== '' ? <>
                    <div className='mb-6 text-center mw-md-4xl mx-auto mt-10'>
                        <h2
                            className={'font-heading mb-4 fs-7 text-white text-center'}
                            style={{letterSpacing: '-1px'}}
                        >
                            Twój chronotyp to: <span className='text-success'>{yourChronotype.type}</span>
                        </h2>
                        <p className={'fs-10 fw-medium text-secondary-light'}>
                            {yourChronotype.summary}
                        </p>
                        <p className={'fs-10 fw-medium text-secondary-light'}>
                            Umów się na <b>indywidualne konsultacje</b>, aby dokładnie omówić wynik i <b>dostosować Twój
                            plan dnia specjalnie pod Twój chronotyp.</b>
                        </p>
                        <div className="row justify-content-center g-4">
                            <div className="col-auto">
                                <AnimatedLink
                                    className={`btn btn-lg btn-success text-success-light shadow `}
                                    to='/oferta/konsultacje'>
                                    Umów się na indywidualne konsultacje
                                </AnimatedLink>
                            </div>
                            <div className="col-auto">
                                <AnimatedButton
                                    className={`btn btn-lg btn-dark-light text-success-light shadow`}
                                    onClick={() => {
                                        setCurrentQuestionIndex(0);
                                        setAnswers([]);
                                        setYourChronotype({type: "", summary: ""});
                                        setFinished(false);
                                    }}
                                >
                                    Rozwiąż ponownie
                                </AnimatedButton>
                            </div>
                        </div>
                    </div>
                </> : <>
                    {currentQuestionIndex < dolphinPreQuiz.length + mainQuiz.length ? (
                        <>
                            <Question
                                key={questionIndex}
                                question={currentQuestions[questionIndex].question}
                                options={currentQuestions[questionIndex].options}
                                onAnswer={handleAnswer}
                            />
                            <p className='text-secondary-light text-center mt-4'>
                                {`Część ${currentQuestionIndex < dolphinPreQuiz.length ? 'I' : "II"}: ${currentQuestionIndex + 1}/${mainQuiz.length + dolphinPreQuiz.length}`}
                            </p>


                            {currentQuestionIndex > 0 && (
                                <div className="row justify-content-center g-4 mt-5">
                                    <div className="col-auto">
                                        <button className='btn btn-sm text-success-light shadow btn-dark-light'
                                                onClick={handlePrevious}>Wróć do poprzedniego pytania
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <h2>Dziękujemy za udział w quizie!</h2>
                    )}
                </>
            }

        </div>
    );
}