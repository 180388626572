import {Link, useFetcher} from "react-router-dom";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {ApiError} from "../../../service/HttpService";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {motion} from "framer-motion";
import {NewsletterService} from "../../../service/NewsletterService";

interface NewsletterFormProps {
    type: NewsletterType
    cancelAnimation?: boolean
    customSuccessMessage?: string
    customRedirectPath?: string
    withName?: boolean
    buttonName?: string
    buttonSendingName?: string
}

export enum NewsletterType {
    TestEmails = "TestEmails",
    DailyMathExercises = "DailyMathExercises",
    Newsletter = "Newsletter",
    Planner = "Planner",
    LearningCourse = "LearningCourse",
    ChatTutorial = "ChatTutorial",
    KedOptInParent = "KedOptInParent",
    KedOptInStudent = "KedOptInStudent",
    KedOptInAll = "KedOptInAll",
    KedOptInMaturaParent = "KedOptInMaturaParent",
    MWOptInParent = "MWOptInParent",
    KedScheduledSalesCall = "KedScheduledSalesCall",
    KedMaturaParentScheduledSalesCall = "KedMaturaParentScheduledSalesCall",
    MWParentScheduledSalesCall = "MWParentScheduledSalesCall"
}

export default function NewsletterForm(props: NewsletterFormProps) {
    const {
        type,
        cancelAnimation,
        customSuccessMessage,
        customRedirectPath,
        withName,
        buttonName,
        buttonSendingName
    } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const fetcher = useFetcher();
    const {isSubmitting} = useSubmitFormAction(customRedirectPath ?? '/', fetcher);

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return <>
        <motion.div initial={cancelAnimation ? {opacity: 1, x: 0} : {opacity: 0, x: 300}} animate={{opacity: 1, x: 0}}
                    transition={{duration: 0.2, type: 'spring', stiffness: 300}}>
            <fetcher.Form
                method='post'
                action='/newsletter'
            >
                <div className='mb-2 row justify-content-center g-4 g-md-6'>
                    <div className='col'>
                        <div className='row'>
                            {
                                withName && <div className={`form-group col-md-4 ${withName ? 'pt-3' : ''}`}>
                                    <input
                                        className='form-control text-secondary p-3 shadow'
                                        id='newsletterInput1-1'
                                        type='text'
                                        aria-describedby='emailHelp'
                                        placeholder='Wprowadź swoje imię'
                                        name='name'
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                        style={name ? {color: 'black'} : {}}
                                    />
                                </div>
                            }

                            <div className={`form-group ${withName ? 'col-md-8 mt-3' : 'col-md-12'}`}>
                                <input
                                    className='form-control text-secondary p-3 shadow'
                                    id='newsletterInput17-1'
                                    type='text'
                                    aria-describedby='emailHelp'
                                    placeholder='Wprowadź adres email'
                                    name='email'
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    style={email ? {color: 'black'} : {}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-auto ${withName ? 'pt-3' : ''}`}>
                        <AnimatedButton
                            className='btn btn-lg btn-success fs-11 w-100 py-3 px-4 h-100 shadow'
                            disabled={isSubmitting || !isEmailValid || (withName && name.length < 2)}
                        >
                            {isSubmitting ? (buttonSendingName ? buttonSendingName : 'Subskrybowanie...') : (buttonName ? buttonName : 'Subskrybuj')}
                        </AnimatedButton>
                    </div>
                    <input hidden readOnly value={type.toString()} name="type"/>
                    <input hidden readOnly value={customSuccessMessage?.toString()} name="successMessage"/>
                </div>
            </fetcher.Form>
            <p className='mb-0 fs-13 fw-medium text-secondary'>
                <span>Podając adres email zgadzasz się z akceptacją </span>
                <Link className='text-sucess link-success' to='/polityka-prywatnosci'>
                    polityki prywatności
                </Link> i zapisujesz się
                do {type === 'DailyMathExercises' ? 'otrzymywania codziennych zadań z matematyki, z których w każdej chwili możesz się wypisać.' : (type === NewsletterType.Newsletter || NewsletterType.Planner ? 'newslettera, z którego w każdej chwili możesz się wypisać.' : 'listy oczekujących, z której w każdej chwili możesz się wypisać.')}
            </p>
        </motion.div>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const newsletterData = {
        email: formData.get('email') as string,
        type: formData.get('type') as string,
        name: formData.get('name') as string,
        source: localStorage.getItem('firstJoinedFrom') ?? ''
    };

    const successMessage = formData.get('successMessage') as string | null

    try {
        await NewsletterService.subscribeNewsletter(newsletterData)
        return {
            status: 200,
            body: (successMessage && successMessage.trim().length > 0) ? successMessage : 'Potwierdź swój zapis klikając w link otrzymany na maila.'
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}