import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";

export default function CoachingSessionUnsuccessfulPurchase() {
    return <div className='mw-md-4xl mx-auto text-center mt-16'>
        <div className="row justify-content-center g-4">
            <div className='col-12 col-md-auto'>
                <AnimatedLink
                    className='btn btn-lg btn-warning w-100 text-success-light shadow'
                    to='/oferta/konsultacje'
                >
                    Spróbuj ponownie
                </AnimatedLink>
            </div>
            <div className="col-12 col-md-auto">
                <AnimatedLink
                    className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                    to="/kontakt"
                >
                    Skontaktuj się z nami
                </AnimatedLink>
            </div>
        </div>

    </div>
}