import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import RedirectAfterRegistration from "../../../components/user/thank-you/RedirectAfterRegistration";
import Heading from "../../../components/common/header/Heading";

export default function RedirectAfterRegistrationPage() {

    return (
        <>
            <Meta title='Dziekujemy za rejestrację!'/>
            <MainDesign containerStyles='container py-28'>
                <Heading badgeText='Rejestracja udana!'
                         title='Potwierdź swój adres email'
                         subtitle='Kliknij w link otrzymany na maila, aby dokończyć aktywację konta. Powinien  dojść w ciągu 2 minut.'/>
                <RedirectAfterRegistration/>
            </MainDesign>
        </>
    );
}