import {motion} from "framer-motion";
import {offerVariants} from "../../../pages/user/offer/OfferLandingPage";
import ProductPageOption from "../ai/pricing/ProductPageOption";

interface ProductsSelectOptionProps {
    isEffectiveActionCourseSubscriber: boolean
}

export default function ProductsSelectOption(props: ProductsSelectOptionProps) {
    const {isEffectiveActionCourseSubscriber} = props;
    return <>
        <div className='row mw-7xl mx-auto'>
            <motion.div
                custom={0} // Custom prop for delay calculation
                variants={offerVariants}
                initial="hidden"
                animate="visible"
                className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
            >
                <ProductPageOption
                    imageUrl={isEffectiveActionCourseSubscriber ?
                        'https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked.png' :
                        'https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-lock.png'}
                    redirectTo={isEffectiveActionCourseSubscriber ? 'kurs-efektywnego-dzialania' : '/start'}
                    title={isEffectiveActionCourseSubscriber ? 'Kurs Efektywnego Działania' : 'MISTRZOWSKIE Wsparcie'}
                    ctaCustomText={isEffectiveActionCourseSubscriber ? 'Przejdź do kursu' : 'Umów się na spotkanie'}
                    subtitle='Działaj Efektywniej i Osiągaj Więcej!'/>
            </motion.div>
            <motion.div
                custom={1} // Increasing the custom value for sequential delay
                variants={offerVariants}
                initial="hidden"
                animate="visible"
                className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
            >
                <ProductPageOption
                    imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/rozwiazania-ai.png'
                    ctaCustomText='Przejdź dalej'
                    redirectTo='/produkt/ai' title='Rozwiązania AI'
                    subtitle='AI może zastąpić Twojego fizycznego korepetytora oraz lektora językowego.'/>
            </motion.div>
        </div>
    </>
}