import AnimatedLink from "../../ui/motion/AnimatedLink";
import Heading from "../../common/header/Heading";
import SlideInWhenVisible from "../../ui/motion/SlideInWhenVisible";

export default function WhoAreWeSharedPhoto() {
    return <>
        <div className='mw-md-4xl mx-auto text-center '>
            <img className='img-fluid mb-10'
                 src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/wspolne1.jpg'
                 alt='wspólne zdjęcie'/>
            <Heading title='Na co czekasz?' subtitle='“The best way to get something done is to start.”'/>
            <div className="row justify-content-center g-4">
                <div className='col-12 col-md-auto'>
                    <SlideInWhenVisible initial={{opacity: 0, x: 100}} duration={0.2} initialX={100}>
                        <AnimatedLink
                            className='btn btn-lg btn-success w-100 text-success-light shadow'
                            to='/oferta'
                        >
                            Zobacz ofertę
                        </AnimatedLink>
                    </SlideInWhenVisible>
                </div>
                <div className="col-12 col-md-auto">
                    <SlideInWhenVisible initial={{opacity: 0, x: 100}} duration={0.2} delay={0.2}
                                        initialX={100}>
                        <AnimatedLink
                            className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                            to="/rejestracja"
                        >
                            Zarejestruj się
                        </AnimatedLink>
                    </SlideInWhenVisible>
                </div>
            </div>
        </div>
    </>
}