import React from 'react';
import Meta from "../../components/common/Meta";
import MainDesign from "../MainDesign";
import NewsletterForm, {NewsletterType} from "../../components/user/newsletter/NewsletterForm";
import ImageWithDotsAndCircle from "../../components/ui/img/ImageWithDotsAndCircle";

export default function Newsletter() {
    return <>
        <Meta title='Newsletter'
              description='Zapisz się do newslettera i bądź na bieżąco ze wszystkimi aktualnościami!'/>
        <MainDesign containerStyles="py-20 py-md-44">
            <div className='row align-items-center g-16'>
                <div className='col-12 col-md-7'>
                    <div className='p-2 mw-xl-xl mx-xl-auto'>
                        <h1
                            className='font-heading mb-4 fs-5 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Newsletter
                        </h1>
                        <p className='mb-8 fs-8 fw-medium text-secondary-light'>
                            Podaj swój adres email i otrzymuj wiadomość na temat najnowszych aktualizacji.
                        </p>
                        <NewsletterForm type={NewsletterType.Newsletter}/>
                        <h2
                            className='font-heading mb-2 mt-12 fs-6 text-white'
                            style={{letterSpacing: '-1px'}}
                        >
                            Jak to działa?
                        </h2>
                        <p className='mb-4 fs-10 fw-medium text-secondary-light'>
                            Co jakiś czas będziesz otrzymywać maile, w których będą opisywane najnowsze zmiany na
                            platformie.
                        </p>
                        <p className='mb-8 fs-10 fw-medium text-secondary-light'>
                            Strona jest dynamicznie rozwijana i zmiany będą pojawiać się co kilka tygodni.
                        </p>
                    </div>
                </div>
                <div className='col-12 col-md-5'>
                    <ImageWithDotsAndCircle
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/newsletter/happy-student-reading.webp'/>
                </div>
            </div>
        </MainDesign>
    </>
}



