import {formatISODateString} from "../../../util/DateUtil";
import DOMPurify from "dompurify";
import React from "react";
import {BlogWithRandomArticles} from "../../../model/user/blog/Blog";
import Meta from "../../common/Meta";
import BackButton from "../../ui/element/BackButton";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useToast} from "../../ui/toast/ToastContext";
import BlogPostItem from "./BlogPostItem";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import Heading from "../../common/header/Heading";

interface BlogPostProps {
    blogWithRandomArticles: BlogWithRandomArticles;
    showBackButtonToAdminPanel: boolean;
    disableBackLink: boolean;
}

export default function BlogPost(props: BlogPostProps) {
    const {blogWithRandomArticles, showBackButtonToAdminPanel, disableBackLink} = props;
    const {showMessage} = useToast();

    const blog = blogWithRandomArticles.article;
    const randomArticles = blogWithRandomArticles.randomArticles;

    const createMarkup = () => {
        return {__html: DOMPurify.sanitize(blog.content)};
    };

    async function copyToClip() {
        await navigator.clipboard.writeText(window.location.href);
        showMessage("Pomyślnie skopiowano.");
    }

    return <>
        <Meta title={blog.title} description={blog.subtitle} imageUrl={blog.imageUrl}/>
        <BackButton to='/blog' text='Powrót do listy artykułów'
                    onClick={disableBackLink ? (e) => e.preventDefault() : undefined}
                    secondButtonDisplayed={showBackButtonToAdminPanel}
                    secondButtonText="Powrót do panelu admina" secondButtonTo='/admin/blog'/>
        <div className='mb-12 text-center mw-md-2xl mx-auto'>
                <span className='text-success fw-medium'>
                  {blog.author} • {formatISODateString(blog.addedAt)} {
                    showBackButtonToAdminPanel &&
                    <>
                        •{"\u00A0"}
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="20" height="20" viewBox="0 0 24 24">
                            <path
                                fill='currentColor'
                                d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/>
                        </svg>
                        {"\u00A0"}{blog.uniqueViews + blog.repeatedViews}
                    </>
                }

                </span>
            <h1
                className='font-heading mb-4 fs-5 text-white'
                style={{letterSpacing: '-1px'}}
            >
                {blog.title}
            </h1>
            <p className='mb-6 fs-9 fw-medium text-secondary-light'>
                {blog.subtitle}
            </p>
            {
                blog.tags.map((tag, count) => (
                    <span key={tag} className='badge bg-success-dark text-success text-uppercase shadow mx-2'
                          style={{
                              marginRight: "22px",
                              marginLeft: count === 0 ? "20px" : "-10px",
                              marginBottom: "10px"
                          }}>

                            {tag}
                        </span>
                ))
            }
        </div>
        <div className='mw-md-2xl mx-auto text-white fs-10 blog-article'>
            <div dangerouslySetInnerHTML={createMarkup()}/>
        </div>
        <div className='row justify-content-center g-2 mt-16'>
            <h3
                className='text-center font-heading mb-4 fs-7 text-success'
                style={{letterSpacing: '-1px'}}
            >
                Ciekawe? Wyślij znajomym!
            </h3>
            <div className='col-auto me-2'>
                <div className='bg-white rounded'>
                    <AnimatedButton
                        className='btn btn-lg btn-outline-light fs-12 px-4 py-2 text-secondary shadow'
                        onClick={copyToClip}
                    >
                        <svg
                            className='me-1'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M15 15.8333H5C4.33696 15.8333 3.70108 15.5699 3.23224 15.1011C2.76339 14.6323 2.5 13.9964 2.5 13.3333V6.66667C2.5 6.44565 2.41221 6.23369 2.25592 6.07741C2.09964 5.92113 1.88768 5.83333 1.66667 5.83333C1.44566 5.83333 1.23369 5.92113 1.07741 6.07741C0.921133 6.23369 0.833336 6.44565 0.833336 6.66667V13.3333C0.833336 14.4384 1.27232 15.4982 2.05372 16.2796C2.44063 16.6665 2.89996 16.9734 3.40549 17.1828C3.91101 17.3922 4.45283 17.5 5 17.5H15C15.221 17.5 15.433 17.4122 15.5893 17.2559C15.7455 17.0996 15.8333 16.8877 15.8333 16.6667C15.8333 16.4457 15.7455 16.2337 15.5893 16.0774C15.433 15.9211 15.221 15.8333 15 15.8333ZM19.1667 8.28333C19.158 8.20678 19.1412 8.13136 19.1167 8.05833V7.98333C19.0766 7.89765 19.0232 7.81889 18.9583 7.75V7.75L13.9583 2.75C13.8894 2.68518 13.8107 2.63173 13.725 2.59167H13.65L13.3833 2.5H6.66667C6.00363 2.5 5.36774 2.76339 4.8989 3.23223C4.43006 3.70107 4.16667 4.33696 4.16667 5V11.6667C4.16667 12.3297 4.43006 12.9656 4.8989 13.4344C5.36774 13.9033 6.00363 14.1667 6.66667 14.1667H16.6667C17.3297 14.1667 17.9656 13.9033 18.4344 13.4344C18.9033 12.9656 19.1667 12.3297 19.1667 11.6667V8.33333C19.1667 8.33333 19.1667 8.33333 19.1667 8.28333ZM14.1667 5.34167L16.325 7.5H15C14.779 7.5 14.567 7.4122 14.4107 7.25592C14.2545 7.09964 14.1667 6.88768 14.1667 6.66667V5.34167ZM17.5 11.6667C17.5 11.8877 17.4122 12.0996 17.2559 12.2559C17.0996 12.4122 16.8877 12.5 16.6667 12.5H6.66667C6.44565 12.5 6.23369 12.4122 6.07741 12.2559C5.92113 12.0996 5.83334 11.8877 5.83334 11.6667V5C5.83334 4.77899 5.92113 4.56702 6.07741 4.41074C6.23369 4.25446 6.44565 4.16667 6.66667 4.16667H12.5V6.66667C12.5 7.32971 12.7634 7.96559 13.2322 8.43443C13.7011 8.90327 14.337 9.16667 15 9.16667H17.5V11.6667Z'
                                fill='#BBC3CF'
                            />
                        </svg>
                        <span>Skopiuj link</span>
                    </AnimatedButton>
                </div>
            </div>
            <div></div>
            <hr className='text-white mt-6'/>
            <div className='mx-auto mw-md-5xl mt-3'>
                <Heading title='Te artykuły mogą Ci się spodobać'
                         customTitleStyles='font-heading mb-4 fs-6 text-white'/>
                <div className='mb-20 row g-8'>
                    {
                        randomArticles.map((item) => (
                            <BlogPostItem key={item.id} blogItem={item} isAdmin={false}
                                          setArticleToDelete={() => {
                                          }}
                                          setConfirmDeleteModalOpen={() => {
                                          }}/>
                        ))
                    }
                </div>
                <div className="row justify-content-center g-4 pb-8">
                    <div className="col-12 col-md-auto">
                        <AnimatedLink
                            className="btn btn-lg btn-success w-100 text-success-light shadow"
                            onClick={() => {
                                TraceService.addTrace(TraceType.BtnClick, 'btn3 -> zobacz więcej blog')
                            }}
                            to={'/blog'}>
                            Zobacz więcej
                        </AnimatedLink>
                    </div>
                </div>
            </div>
        </div>
    </>
}