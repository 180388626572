interface View {
    uniqueView: boolean
    repeatedView: boolean
}

export function increaseItemViews(localStorageItemName: string, uuid: string, toEdit: boolean): View {
    let uniqueView = false;
    // the same browser visits website after time
    let repeatedView = false;
    if (!toEdit) {
        const rawItem = localStorage.getItem(localStorageItemName);
        if (rawItem) {
            try {
                const items = JSON.parse(rawItem);
                const mapOfItemUuidsAndTimestamps = new Map(Object.entries(items));
                const viewedAt = mapOfItemUuidsAndTimestamps.get(uuid) as number;
                if (!viewedAt) {
                    uniqueView = true;
                    mapOfItemUuidsAndTimestamps.set(uuid, Date.now());
                } else {
                    if (!isNaN(viewedAt) && Date.now() - viewedAt > 1000 * 60 * 5) { // 5 minutes
                        repeatedView = true;
                        mapOfItemUuidsAndTimestamps.set(uuid, Date.now());
                    }
                }
                localStorage.setItem(localStorageItemName, JSON.stringify(Object.fromEntries(mapOfItemUuidsAndTimestamps)));
            } catch (error) {
                console.log(error)
            }
        } else {
            uniqueView = true;
            localStorage.setItem(localStorageItemName, JSON.stringify({
                [uuid]: Date.now()
            }));
        }
    }
    return {uniqueView: uniqueView, repeatedView: repeatedView}
}