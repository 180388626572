import React, {MouseEvent, Suspense, useState} from 'react';
import {ChatService} from "../../../../service/ai/ChatService";
import {Await, defer, useLoaderData} from "react-router-dom";
import ErrorContent from "../../../common/error/ErrorContent";
import {redirectIfNotLoggedIn} from "../../../../util/AuthUtil";
import NewThreadButton from "./NewThreadButton";
import LoadingPage from "../../../../pages/common/LoadingPage";
import {LoadThreadsAndInfo} from "../../../../model/user/ai/chat/LoadThreadsAndInfo";
import {Conversation as ConversationEntity} from "../../../../model/user/ai/Conversation";
import ChatForm from "./ChatForm";
import {getModelById, getSelectedChatModelOrDefault} from "../../../../model/common/Model";
import Heading from "../../../common/header/Heading";
import FaqList from "../../../ui/faq/FaqList";
import {DiagnosticService} from "../../../../service/DiagnosticService";
import VideoWithPlayButton from "../../../ui/video/VideoWithPlayButton";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";

type LoaderData = {
    threadsAndInfo: Promise<LoadThreadsAndInfo>;
};

// Main functional component
export default function Chat() {
    const {threadsAndInfo} = useLoaderData() as LoaderData;

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showEmptyChat, setShowEmptyChat] = useState(true);
    const [conversation, setConversation] = useState<ConversationEntity[]>([]);
    const [message, setMessage] = useState("");
    const [modelId, setModelId] = React.useState<string | null>(null);
    const [threadId, setThreadId] = React.useState<string | null>(null);
    const [promptCost, setPromptCost] = useState(0);

    async function handleModelChange(newModelId: string) {
        setModelId(newModelId);
        try {
            await ChatService.updateSelectedModel(newModelId);
        } catch (error) {
            console.log(error)
        }
    }

    const sendMessage = async (e: MouseEvent, imageUrl?: string) => {
        e.preventDefault();

        // Don't send empty messages
        if (message.length < 1) {
            setErrorMessage("Twoja wiadomość nie może być pusta.");
            return;
        } else {
            setErrorMessage("");
        }

        setIsLoading(true);
        setPromptCost(0);

        // Add the user message to the conversation immediately
        const updatedConversation = [
            ...conversation,
            {content: message, role: "user", imageUrl: imageUrl},
        ];
        setConversation(updatedConversation);

        // Add a system message indicating typing immediately
        const typingMessage = {content: "Typing", role: "system"};
        setConversation([...updatedConversation, typingMessage]);

        // Clear the message & remove empty chat
        setMessage("");
        setShowEmptyChat(false);

        try {
            // Include both user and system messages for display purposes
            const allMessages = [...updatedConversation];

            const loadThreadsAndInfo = await threadsAndInfo

            const currentModelId = modelId ?? getSelectedChatModelOrDefault(loadThreadsAndInfo).id
            let fullMessage = ''

            const {token} = await ChatService.generateStreamingToken();

            await ChatService.askLlm({
                token: token,
                modelId: currentModelId,
                message: message,
                threadId: threadId,
                imageUrl: imageUrl
            }, (chunk) => {
                if (chunk.success && chunk.finished) {
                    setThreadId(chunk.threadId ?? null);
                    setPromptCost(getModelById(loadThreadsAndInfo.models, currentModelId)?.callCost ?? 0);
                    setIsLoading(false);
                } else if (!chunk.success && chunk.finished) {
                    setErrorMessage(chunk.message ?? "Wystąpił błąd. Spróbuj ponownie później.");
                    setIsLoading(false);
                    setConversation(prevState => prevState.slice(0, -1));
                    DiagnosticService.addDiagnostic({
                        fullEvent: {
                            ...loadThreadsAndInfo,
                            currentModelId: currentModelId,
                            conversation: conversation,
                            isLoading: isLoading,
                            token: token,
                            chunk: chunk
                        },
                        error: "!success & finished",
                        functionName: "Chat - sendMessage (1)"
                    });
                } else if (chunk.success && !chunk.finished) {
                    fullMessage = chunk.parts;
                    const updatedMessages = [
                        ...allMessages,
                        {content: fullMessage, role: "system"},
                    ];
                    // Update the conversation with the actual response
                    setConversation(updatedMessages);
                } else {
                    setErrorMessage("Wystąpił błąd. Spróbuj ponownie później.");
                    setIsLoading(false);
                    setConversation(prevState => prevState.slice(0, -1));
                    DiagnosticService.addDiagnostic({
                        fullEvent: {
                            ...loadThreadsAndInfo,
                            currentModelId: currentModelId,
                            conversation: conversation,
                            isLoading: isLoading,
                            token: token,
                            message: message,
                            chunk: chunk
                        },
                        error: "unknown state",
                        functionName: "Chat - sendMessage (2)"
                    });
                }
            });
        } catch (error: any) {
            console.error(error);
            setErrorMessage(error.message);
            setConversation(prevState => prevState.slice(0, -1));
            setIsLoading(false);
            await DiagnosticService.addDiagnostic({
                fullEvent: {
                    conversation: conversation,
                    modelId: modelId,
                    threadId: threadId,
                    isLoading: isLoading,
                    error: error,
                    errorMessage: error.message,
                    threadsAndInfo: await threadsAndInfo
                },
                error: JSON.stringify(error),
                functionName: "Chat - sendMessage (3)"
            });
        }
    };


    const loadThreadMessages = async (threadId: string) => {
        if (threadId === "Nowy wątek") {
            await newThread();
            return;
        }
        setThreadId(threadId);
        try {
            const response = await ChatService.getThreadMessages(threadId);

            const mappedConversation = response.messages.map(resp => {
                return {
                    content: resp.message,
                    role: resp.messageType === 'User' ? 'user' : 'system',
                    imageUrl: resp.imageUrl
                }
            })
            setConversation(mappedConversation); // Assuming setConversation updates the messages displayed
            setShowEmptyChat(false);
            setModelId(response.modelId);
        } catch (error) {
            console.error("Error loading thread messages:", error);
        }
    };

    async function newThread() {
        setThreadId(null);
        setShowEmptyChat(true);
        setConversation([]);
        setErrorMessage("");
    }

    return (
        <>
            <Suspense fallback={<LoadingPage/>}>
                <Await resolve={threadsAndInfo} errorElement={<ErrorContent/>}>
                    {(loadedThreadsAndInfo: LoadThreadsAndInfo) => (
                        <>
                            <div className="d-flex flex-column">
                                {
                                    !showEmptyChat && <>
                                        <NewThreadButton newThread={newThread}/>
                                    </>
                                }

                                {/* Main chat container */}
                                <div className="d-flex flex-column overflow-hidden">
                                    <div style={{overflowX: "hidden", overflowY: "auto"}}>
                                        <Suspense fallback={<LoadingPage/>}>

                                            <ChatForm loadedThreadsAndInfo={loadedThreadsAndInfo} modelId={modelId}
                                                      threadId={threadId} conversation={conversation}
                                                      errorMessage={errorMessage} message={message}
                                                      setMessage={setMessage}
                                                      sendMessage={sendMessage} isLoading={isLoading}
                                                      showEmptyChat={showEmptyChat}
                                                      handleModelChange={handleModelChange}
                                                      loadThreadMessages={loadThreadMessages}
                                                      promptCost={promptCost}
                                            />
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-28 mb-12'>
                                <Heading title='Zobacz, jak to działa' badgeText='Pomoc'
                                         subtitle='Poniżej znajdziesz szczegółowy poradnik, dzięki któremu dokładnie poznasz możliwości sztucznej
                            inteligencji. Zachęcamy do obejrzenia go, aby osiągać jak najlepsze rezultaty.'/>
                                <VideoWithPlayButton vimeoVideoId='944525461' id='chat-tutorial-video' onPlay={() => {
                                    TraceService.addTrace(TraceType.PlayVideo, 'chat-tutorial');
                                }}/>

                                <Heading title='Najczęściej zadawane pytania' badgeText='FAQ'
                                         subtitle='Poniżej znajdziesz odpowiedzi na najcześciej zadawane pytania.'/>

                                <FaqList items={[
                                    {
                                        question: 'Czym są tokeny?',
                                        answer: 'Token jest wirtualną walutą, którą wykorzystuje się przy każdym zapytaniu do AI. ' +
                                            'W zależności od wybranego modelu, cena zapytania wahać się będzie od 1 do 5 tokenów. Cena każdego wywołania sztucznej inteligencji jest jasno podana.'
                                    },
                                    {
                                        question: 'Czym różnią się od siebie modele?',
                                        answer: 'Modele różnią się sposobem generowania odpowiedzi. W zależności od wybranego modelu, ' +
                                            'odpowiedź będzie się różnić. Każdy model jest szczegółowo opisany, dlatego z łatwością ' +
                                            'wybierzesz ten, który najlepiej spełni Twoje potrzeby.'
                                    },
                                    {
                                        question: 'O co chodzi z wątkami?',
                                        answer: 'W danym wątku AI ma dostęp do poprzednich wiadomości. Dzięki temu treść poprzednich ' +
                                            'zapytań może być wykorzystana do wygenerowania jak najlepszej odpowiedzi na aktualne pytanie.\n' +
                                            'Zaleca się tworzenie nowych wątków przy zmianie kontekstu (np. nowe zadanie, nowy temat).'
                                    },
                                    {
                                        question: 'Czym różni się Chat Produkacji od Chatu GPT?',
                                        answer: 'Chat GPT jest zwykłym modelem, który nauczony jest, aby odpowiadać w sposób bardzo ogólny. ' +
                                            'Darmowa wersja GPT 3.5 źle sobie radzi nawet z dosyć prostymi pytaniami i zadaniami. ' +
                                            'W naszym rozwiązaniu oferujemy dokładnie skonfigurowane modele, których zadaniem jest ' +
                                            'szczegółowo odpowiadać na pytania ucznia. Pod spodem wykorzystujemy najnowsze rozwiązania, ' +
                                            'dzięki czemu odpowiedzi są znacznie lepszej jakości.'
                                    },
                                ]}/>
                            </div>
                        </>
                    )}
                </Await>
            </Suspense>
        </>
    );
};

export async function loader() {
    const response = await redirectIfNotLoggedIn();
    if (response) {
        return response;
    }
    return defer({
        threadsAndInfo: ChatService.getAllUserThreadsAndInfo("chat")
    });
}
