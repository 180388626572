import React from "react";

export default function WhoAreWeValuesHeading() {
    return <div className='mw-md-4xl mx-auto text-center mt-20'>
        <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
          Wartości
        </span>
        <h1
            className='mb-4 font-heading fs-5 text-white'
            style={{letterSpacing: '-1px'}}
        >
            Nasze wartości
        </h1>
        <p className='mb-8 fs-8 fw-medium text-warning'>
            Poznaj wartości, które są dla nas najbardziej istotne.
        </p>

    </div>
}