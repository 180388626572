import React from "react";
import {Model} from "../../../model/common/Model";

interface ModelDescriptionProps {
    model: Model
}

export default function ModelDescription(props: ModelDescriptionProps) {
    const {model} = props;
    return <>
        <div className='col-auto'>
            <img
                className='mb-0 team-round-image'
                src={model.imageUrl}
                alt=''
                style={{width: '70px', height: '70px'}}
            />
        </div>
        <div className='col px-0 d-flex flex-column justify-content-center mb-0'>
            <h3 className='mb-1 font-heading fs-7 text-white'>
                {model.name}
            </h3>
        </div>
        <p className='col-12 text-left w-100 text-secondary-light fs-11 mt-2'>
            {model.description}
        </p>
        <p className='col-12 text-left w-100 text-secondary-light fs-11 mt-2'>
            Koszt
            zapytania: {model.callCost === 1 ? "1 token" : (model.callCost < 5 && model.callCost > 1 ? `${model.callCost} tokeny` : `${model.callCost} tokenów`)}
        </p>
    </>
}