import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import CoachingSessionUnsuccessfulPurchase
    from "../../../components/user/coaching-session/CoachingSessionUnsuccessfulPurchase";

export default function CoachingSessionUnsuccessfulPurchasePage() {
    return <>
        <Meta title="Indiwidualne konsultacje - błąd płatności"/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Przykro nam, coś poszło nie tak'
                     subtitle="Płatność nieudana. Jeżeli problem będzie się powtarzał, skontaktuj się z nami."
                     badgeText='Zakup nieudany'/>
            <CoachingSessionUnsuccessfulPurchase/>
        </MainDesign>
    </>
}