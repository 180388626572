import AdvantageItem from "../ai/pricing/AdvantageItem";
import React from "react";
import Heading from "../../common/header/Heading";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";

export default function RedirectAfterSchedulingCall() {
    return <>
        <div className='mw-md-3xl mx-auto'>
            <p className='text-center fs-10 fw-medium text-white '>
                <div className='row g-8 mt-2'>
                    <div className='col-12 col-md-6'>
                        <div className='mw-md-2xl text-start pe-md-8'>
                            <div
                                className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                                style={{width: 48, height: 48}}
                            >
                                1
                            </div>
                            <h3 className='mb-2 font-heading fs-9 text-white'>
                                Obejrzyj poniższy film
                            </h3>
                            <p className='mb-0 text-secondary-light fw-medium'>
                                Trwa on dokładnie 30 sekund. Wyjaśniam w nim, jak przygotować się do spotkania.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='mw-md-sm text-start'>
                            <div
                                className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                                style={{width: 48, height: 48}}
                            >
                                2
                            </div>
                            <h3 className='mb-2 font-heading fs-9 text-white'>
                                Potwierdź rozmowę poprzez odpisanie na email
                            </h3>
                            <p className='mb-0 text-secondary-light fw-medium'>
                                Wystarczy, że napiszesz krótkie 'potwierdzam'.
                            </p>
                        </div>
                    </div>
                </div>
            </p>
            <VideoWithPlayButton vimeoVideoId='981227070' id='thank-you-for-booking'/>
            <Heading customStyles='mb-6 text-left mw-md-4xl mx-auto' title='Co zyskujesz?'/>
            <AdvantageItem
                title={`Indywidualny plan działania dopasowany do Twoich potrzeb.`}/>
            <AdvantageItem title='Lekcje w postaci filmów online udostępniane na bieżąco.'/>
            <AdvantageItem title='Cheat-Sheety i materiały do pracy własnej.'/>
            <AdvantageItem title='5 indywidualnych konsultacji z założycielem i twórcą Produkacji.'/>
            <AdvantageItem title='Cotygodniowe grupowe spotkania online na Zoomie.'/>
            <AdvantageItem title='Pełny dostęp do forum do zadawania nieograniczonej liczby pytań.'/>
            <AdvantageItem title='Gwarancję satysfakcji albo zwrot pieniędzy.'/>
            <p className='text-center fs-11 fw-medium text-secondary-light mt-3'>
                Możesz zamknąć tę stronę.</p>
        </div>
    </>
}