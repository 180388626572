import {Model} from "../../../model/common/Model";
import ModelListItem from "./ModelListItem";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {useSubmit} from "react-router-dom";
import React, {useState} from "react";
import AddOrEditModelModal from "./AddOrEditModelModal";
import ConfirmModal from "../../ui/ConfirmModal";

interface ModelListProps {
    models: Model[]
}

export default function ModelList(props: ModelListProps) {
    const {models} = props;

    const {isSubmitting} = useSubmitFormAction('/admin/ai', null, () => {
        setEditModalOpen(false);
        setConfirmDeleteModalOpen(false);
    });

    const submit = useSubmit();

    const [modelToEdit, setModelToEdit] = useState<Model | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [modelToDelete, setModelToDelete] = useState<Model | null>(null);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

    function submitDeleteForm() {
        submit({
            id: modelToDelete!!.id
        }, {
            method: "DELETE"
        });
    }

    return <>
        <div className='mw-md-5xl mx-auto'>
            <div className='row g-8'>
                {models.map((model) => (
                    <ModelListItem key={model.id} model={model}
                                   setEditModalOpen={setEditModalOpen}
                                   setModelToEdit={setModelToEdit}
                                   setModelToDelete={setModelToDelete}
                                   setConfirmDeleteModalOpen={setConfirmDeleteModalOpen}/>
                ))}
            </div>
        </div>
        <AddOrEditModelModal isOpen={editModalOpen} setOpen={setEditModalOpen} model={modelToEdit!!}/>
        <ConfirmModal isOpen={confirmDeleteModalOpen} setOpen={setConfirmDeleteModalOpen}
                      title={`Czy na pewno chcesz usunąć model '${modelToDelete?.name}'?`}
                      subtitle="Tej czynności nie będzie dało się cofnąć."
                      isSubmitting={isSubmitting}
                      confirmButtonAction={submitDeleteForm}/>
    </>

}