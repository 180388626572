import React from "react";
import CustomSelect from "../../../ui/form/CustomSelect";
import {Model} from "../../../../model/common/Model";

interface EmptyChatProps {
    models: Model[];
    selectedModelId: string;
    handleModelChange: (item: string) => void;
}

export default function ModelSelection(props: EmptyChatProps) {
    const {selectedModelId, models, handleModelChange} = props;
    return <div className='form-group'>
        <label
            className='mb-2 text-white fw-light'
            htmlFor='modalInput2-2'
        >
            Wybierz model
        </label>
        {/*<p className='mb-1 fs-11 fw-medium text-secondary-light'>*/}
        {/*    W zależności od przedmiotu i kategorii pytania, niektóre modele sprawdzą się lepiej od innych.*/}
        {/*</p>*/}
        <CustomSelect name='modelId' value={selectedModelId} onChange={(event) => handleModelChange(event.target.value)}
                      customStyles={'select-md'}>
            {
                models.map(model => (
                    <option value={model.id} key={model.id}>{model.name}</option>
                ))
            }
        </CustomSelect>
    </div>
}