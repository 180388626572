import Heading from "../../common/header/Heading";
import React from "react";
import ConfirmModal from "../../ui/ConfirmModal";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import OptInEmailAndName from "../../user/offer/OptInEmailAndName";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import LoadingDots from "../../common/LoadingDots";
import {OptInProps} from "../../../util/ExperimentUtil";
import TextWithImageAbove from "./TextWithImageAbove";

export default function EffectiveActionCourseMaturaParentOptInB(props: OptInProps) {
    const {
        handleButtonClick,
        confirmModalOpen,
        setConfirmModalOpen,
        loading,
        isInputValid,
        email,
        setEmail,
        name,
        setName
    } = props;
    const {isSubmitting} = useSubmitFormAction(`/spotkanie/dla-maturzysty`, null, () => {
        setConfirmModalOpen(false);
    });

    return <>
        <div className='d-block d-md-none'>
            <p className='text-white text-center fs-13'>KURS EFEKTYWNEGO DZIAŁANIA + <b>BONUSY</b></p>
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'Zapewnij swojemu dziecku'}
                     customTitleStyles='font-heading mb-1 fs-7 text-white'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto mt-0'
                     title={'MISTRZOWSKIE'}
                     customTitleStyles='font-heading mb-1 fs-4 text-warning'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'wsparcie maturalne'}
                     customTitleStyles='font-heading mb-10 fs-6 text-white'
            />
            <p className={'text-warning fs-11 fw-medium text-center'}>
                Już niedługo <b>inni będą Cię pytać</b>, <br/>skąd Twoje dziecko nauczyło się tego wszystkiego?!
            </p>
        </div>
        <div className='d-none d-md-block'>
            <p className='text-white text-center fs-11'>KURS EFEKTYWNEGO DZIAŁANIA + <b>BONUSY</b></p>
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'Zapewnij swojemu dziecku'}
                     customTitleStyles='font-heading mb-1 fs-6 text-white'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto mt-0'
                     title={'MISTRZOWSKIE'}
                     customTitleStyles='font-heading mb-1 fs-3 text-warning'
            />
            <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto'
                     title={'wsparcie maturalne'}
                     customTitleStyles='font-heading mb-10 fs-5 text-white'
            />
            <p className={'text-warning fs-10 fw-medium text-center'}>
                Już niedługo <b>inni będą Cię pytać</b>, <br/>skąd Twoje dziecko nauczyło się tego wszystkiego?!
            </p>
        </div>
        <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
            <AnimatedButton
                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                onClick={() => handleButtonClick(1, "B")}
            >
                DOWIEDZ SIĘ WIĘCEJ
            </AnimatedButton>
            {loading &&
                <div className="spinner-container fs-10 text-warning fw-medium">Sprawdzam
                    dostępność <LoadingDots/>
                </div>}
        </div>
        <div className='mx-auto mw-md-3xl mt-10'>
            <Heading customTitleStyles='font-heading mb-4 fs-8 text-white'
                     customStyles='mb-6 text-center mw-md-4xl mx-auto mt-6 mt-md-0'
                     title='Czy Twoje dziecko miało kiedyś MISTRZOWSKIE wsparcie?'/>
            <TextWithImageAbove title='Mentoring'
                                explanation='zapewniamy nielimitowany kontakt na WhatsAppie i regularne konsultacje.'/>
            <TextWithImageAbove title='Indywidualizm'
                                explanation='personalizujemy materiały na podstawie testów osobowości i wywiadu.'/>
            <TextWithImageAbove title='Sukces'
                                explanation='weryfikujemy postępy i gwarantujemy zrozumienia każdego zagadnienia.'/>
            <TextWithImageAbove title='Transparentność'
                                explanation='regularnie prezentujemy postępy dziecka.'/>
            <TextWithImageAbove title='Różnorodność'
                                explanation='tworzymy zróżnicowane treści edukacyjne (filmy, karty pracy, zbiory wiedzy).'/>
            <TextWithImageAbove title='Zaufanie'
                                explanation='umożliwiamy przetestowanie i sprawdzenie naszych rozwiązań.'/>
            <TextWithImageAbove title='Opinia'
                                explanation='twoje dziecko zyska renomę osoby sukcesu.'/>
            <TextWithImageAbove title='Wsparcie'
                                explanation='zapewniamy pomoc mentora, społeczności kursu lub w razie potrzeby sprawdzonych korepetytorów.'/>
            <TextWithImageAbove title='Systematyczność'
                                explanation='organizujemy regularne konsultacje indywidualne oraz grupowe.'/>
            <TextWithImageAbove title='Kompleksowość'
                                explanation='gwarantujemy pełne wsparcie, jakie może potrzebować Twoje dziecko.'/>
            <TextWithImageAbove title='Innowacja'
                                explanation='stworzyliśmy pierwsze takie rozwiązanie na polskim rynku.'/>
            <TextWithImageAbove title='Edukacja'
                                explanation='to nie tylko szkoła. Przekazujemy wiedzę, która wyróżni dziecko z tłumu na studiach, w pracy czy w biznesie.'/>
        </div>
        <div className={'mb-6 text-left mw-md-2xl mx-auto'}>
            <div className='text-center mw-md-2xl mx-auto mt-6 mb-4'>
                <AnimatedButton
                    className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                    onClick={() => handleButtonClick(2, "B")}
                >
                    DOWIEDZ SIĘ WIĘCEJ
                </AnimatedButton>
                {loading &&
                    <div className="spinner-container fs-10 text-warning fw-medium">Sprawdzam
                        dostępność <LoadingDots/>
                    </div>}
            </div>
            <p className='text-danger fw-medium fs-11 mt-6 text-center'>
                <span className='fw-bold'>Uwaga! </span>
                W sumie możemy przyjąć tylko <span className='fw-bold'>10 osób</span>. Nie czekaj i zarezerwuj
                miejsce już teraz.</p>
        </div>
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Otrzymaj więcej szczegółów"
                      subtitle={'Nie pozwól, aby inni wyprzedzili Twoje dziecko. Zapewnij mu przewagę, dzięki której wyróżni się z tłumu.'}
                      customSubtitleStyles={'mt-0'}
                      customTitleStyles='mb-4'
                      customTextBottom={<div className='col-11 mt-2'>
                <span
                    className='fs-9 text-light'><span className='fw-bold text-warning'>
                        Uwaga!
                    </span> Zostały jeszcze tylko 3 miejsca!</span>
                      </div>}
                      buttonType='submit'
                      isSubmitting={isSubmitting}
                      submitDisabled={!isInputValid}
                      children={
                          <OptInEmailAndName name={name} setName={setName} email={email} setEmail={setEmail}
                                             customFooterText='newslettera kursu efektywnego działania'/>
                      }
        />
    </>
}