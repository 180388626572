import RichContentEditor from "../../common/editor/RichContentEditor";
import React, {ChangeEvent, useState} from "react";
import {Form, useNavigate, useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import ConfirmModal from "../../ui/ConfirmModal";
import {BlogRequest} from "../../../model/user/blog/Blog";
import RadioTwoOptions from "../../ui/form/RadioTwoOptions";
import {handleFileUpload} from "../../../util/FileUploadUtil";
import {useToast} from "../../ui/toast/ToastContext";
import {useHandleDirtyForm} from "../../../hooks/useHandleDirtyForm";

interface CreateOrEditBlogForm {
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    subtitle: string;
    setSubTitle: React.Dispatch<React.SetStateAction<string>>;
    tags: string;
    setTags: React.Dispatch<React.SetStateAction<string>>;
    imgUrl: string;
    setImgUrl: React.Dispatch<React.SetStateAction<string>>;
    isDraft: boolean;
    setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
    content: string
    setContent: React.Dispatch<React.SetStateAction<string>>;
    actionType: "CREATE" | "EDIT";
    id?: string;
}

export default function CreateOrEditBlogForm(props: CreateOrEditBlogForm) {
    const {isSubmitting} = useSubmitFormAction();
    const submit = useSubmit();
    const [_, setIsFormDirty] = useHandleDirtyForm();

    const navigate = useNavigate();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const {
        title,
        setTitle,
        subtitle,
        setSubTitle,
        tags,
        setTags,
        imgUrl,
        setImgUrl,
        isDraft,
        setIsDraft,
        content,
        setContent,
        actionType,
        id
    } = props;

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsDraft(event.target.value === "draft");
    };

    const {showMessage} = useToast();

    async function handleMainImageChange(event: React.ChangeEvent<HTMLInputElement>) {
        await handleFileUpload(event, (file: File) => {
            showMessage("Zdjęcie zostało pomyślnie przesłane.")
        }, setImgUrl, true)
    }


    function isFormValid() {
        return title.trim().length > 0 &&
            tags.trim().length > 0 &&
            content.trim().length > 50 &&
            subtitle.trim().length > 0 &&
            imgUrl.trim().length > 0;
    }

    function publishArticle() {
        const target: BlogRequest = {
            title: title,
            tags: tags,
            content: content,
            subtitle: subtitle,
            requestType: actionType,
            imageUrl: imgUrl,
            isDraft: isDraft
        }
        if (id && actionType === 'EDIT') {
            target['id'] = id
        }

        submit({
            ...target
        }, {
            method: "POST"
        })
    }

    return <>
        <Form className='mw-4xl mx-auto p-12' method='POST'>
        <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
          Blog
        </span>
            <h1
                className='mb-6 font-heading fs-5 text-white'
                style={{letterSpacing: '-1px'}}
            >
                Dodaj nowy post
            </h1>
            <p className='mb-8 fs-9 fw-medium text-secondary-light'>
                Skorzystaj z formularza poniżej, aby dodać nowy post na bloga.
            </p>

            {/*// TODO: Handle adapter:*/}
            {/*https://ckeditor.com/docs/ckeditor5/latest/framework/deep-dive/upload-adapter.html#implementing-a-custom-upload-adapter*/}

            <div className='mb-8 row g-8 align-items-center'>
                <div className='col-12 mt-8'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-1'
                        >
                            Tytuł
                        </label>
                        <input
                            className='input-lg form-control shadow-sm border-0'
                            id='modalInput2-1'
                            type='text'
                            placeholder="Jak efektywnie się uczyć?"
                            aria-describedby='title'
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            style={title ? {color: 'black'} : {}}
                        />
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-2'
                        >
                            Podtytuł
                        </label>
                        <textarea
                            className='input-lg form-control shadow-sm border-0'
                            id='modalInput2-2'
                            placeholder="W tym artykule znajdziesz wiele przydatnych informacji na temat sposobów nauki."
                            aria-describedby='subtitle'
                            value={subtitle}
                            onChange={(event) => setSubTitle(event.target.value)}
                            style={subtitle ? {color: 'black'} : {}}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-6 mt-3'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-4'
                        >
                            Tagi (oddzielone przecinkami)
                        </label>
                        <input
                            className='input-lg form-control shadow-sm border-0'
                            id='modalInput2-4'
                            type='text'
                            placeholder="efektywna nauka,AI"
                            aria-describedby='tags'
                            value={tags}
                            onChange={(event) => setTags(event.target.value)}
                            style={tags ? {color: 'black'} : {}}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-6 mt-3'>
                    <div className='form-group'>
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='modalInput2-1'
                        >
                            Zdjęcie na liście (700x400px)
                        </label>
                        <input
                            className='input-lg form-control shadow-sm border-0'
                            id='modalInput2-1'
                            type='file'
                            placeholder="https://something.jpg"
                            aria-describedby='title'
                            accept=".png,.jpg,.jpeg,.webp,.gif,.svg"
                            onChange={handleMainImageChange}
                            style={imgUrl ? {color: 'black'} : {}}
                        />
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <RadioTwoOptions handleRadioChange={handleRadioChange} checked={isDraft}
                                     label="Zapisać artykuł jako szkic?" firstText="Szkic" firstValue="draft"
                                     secondText="Publikacja" secondValue="ready"/>
                </div>
                <div className='mt-4'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-4'
                    >
                        Edytor treści
                    </label>
                    <RichContentEditor content={content} setContent={setContent} onChange={() => {
                        setIsFormDirty(true)
                    }}/>
                </div>
            </div>
            <div className='row mt-12'>
                <div className='col-12 col-md-4'>
                    <button
                        className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                        type="button"
                        onClick={() => setCancelModalOpen(true)}
                    >
                        Cofnij
                    </button>
                </div>
                <div className='col-12 col-md-8 mt-5 mt-md-0'>
                    <button
                        className='btn btn-lg btn-success w-100 text-success-light shadow'
                        onClick={() => setConfirmModalOpen(true)}
                        type="button"
                        disabled={!isFormValid()}
                    >
                        Potwierdź
                    </button>
                </div>
            </div>
        </Form>
        <ConfirmModal isOpen={cancelModalOpen} setOpen={setCancelModalOpen} title="Czy na pewno chcesz anulować?"
                      subtitle="Wszystkie zmiany zostaną odrzucone."
                      confirmButtonAction={() => navigate('/admin/blog')}/>
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen}
                      title={actionType === "CREATE" ? "Czy na pewno chcesz dodać nowy artykuł?" : "Czy na pewno chcesz zedytować ten artykuł?"}
                      subtitle={actionType === "CREATE" ? "Będzie można go później edytować." : "Zmiany zostaną opublikowane natychmiast."}
                      confirmButtonAction={publishArticle}
                      isSubmitting={isSubmitting}/>
    </>
}