import Meta from "../../components/common/Meta";
import MainDesign from "../MainDesign";
import Quiz from "../../components/user/chronotypes/Quiz";
import Heading from "../../components/common/header/Heading";

export default function SleepChronotypesTestPage() {
    return <>
        <Meta title='Test na chronotypy snu'
              description="Jakim chronotypem jesteś? Jakie jest Twój chronotyp snu? Odpowiedzi na te pytania znajdziesz po rozwiązaniu quizu."/>
        <MainDesign containerStyles='container py-20'>
            <Heading title='Test na chronotypy snu' badgeText='chronotypy snu'
                     subtitle='Rozwiąż krótki test i przekonaj się, jakim chronotypem jesteś. Test składa się z II części.'/>
            <Quiz/>
            <p className='text-secondary-light text-center mt-16 fs-12'>Test został przygotowany na podstawie książki
                "The
                Power of
                When"
                Michaela Breus'a</p>
        </MainDesign>
    </>
}