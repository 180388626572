import {Form} from "react-router-dom";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import AnimatedButton from "../ui/motion/AnimatedButton";
import AnimatedLink from "../ui/motion/AnimatedLink";

export default function ResetPasswordForm() {
    const {isSubmitting} = useSubmitFormAction();
    const [email, setEmail] = useState('');

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return <Form method="POST" className='mw-sm mx-auto'>
        <div className='mb-4 row g-6'>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-1'
                    >
                        Email
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-1'
                        type='email'
                        aria-describedby='emailHelp'
                        placeholder='Podaj adres email'
                        name='email'
                        autoComplete='username'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
            </div>
        </div>
        <div className='mb-6 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    disabled={isSubmitting || !isEmailValid}
                    customScale={1.05}
                >
                    {isSubmitting ? 'Trwa resetowanie...' : 'Zresetuj hasło'}
                </AnimatedButton>
            </div>
        </div>
        <p className='mb-0 fs-13 fw-medium text-white text-center'>
            <span>Pamiętasz hasło? </span>
            <AnimatedLink className='text-success link-success' to='/logowanie'>
                Zaloguj się
            </AnimatedLink>
        </p>
    </Form>
}