import React from "react";

export default function CancelNewsletterText() {
    return <div className='mb-7 text-center mw-md mx-auto'>
        <div
            className='mb-0 d-flex align-items-center justify-content-center mx-auto rounded-3'
            style={{width: 100, height: 100}}
        >
            <img
                className='img-fluid'
                style={{height: 100}}
                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/logo/logo-500-500-raw.png'
                alt=''
            />
        </div>
        <h2 className='font-heading mb-4 fs-7 text-white'>
            Anulowanie subskrypcji
        </h2>
        <p className='mb-0 fs-9 fw-medium text-secondary-light'>
            Przykro nam, że nas opuszczasz. Napisz nam proszę, co moglibyśmy zrobić lepiej.
        </p>
    </div>
}