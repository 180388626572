import {motion} from "framer-motion";

export default function WhoAreWe() {
    return <>
        <div className='mb-20 mt-16' id='potrzeba'>
            <div className='row mb-20 align-items-center'>
                <div className='col-12 col-md-6'>
                <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                  Motywacja
                </span>
                    <h2
                        className='font-heading mb-4 fs-6 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        Co robimy?
                    </h2>
                    <div className='mb-0 fs-9 fw-medium text-secondary-light'>
                        <ul>
                            <li>
                                Pokazujemy, jak działać efektywnie, aby nie potrzebować więcej ani korepetycji, ani
                                wsparcia osób trzecich.
                            </li>
                            <li>
                                Uczymy niezbędnych umiejętności do skutecznej realizacji swoich celów. Świetne studia?
                                Praca marzeń? Własna działalność? Nie ma problemu.
                            </li>
                            <li>
                                Udostępniamy i popularyzujemy wykorzystanie AI do celów edukacyjnych. Pokazujemy, jak
                                używać ją do szybkiego i skutecznego przyswajania wiedzy.
                            </li>
                            <li>
                                Uczymy, jak budować zdrowy mindset, który uświadamia własny potencjał oraz zwraca
                                uwagę na możliwości dzisiejszego świata.
                            </li>
                            <li>
                                Zachęcamy do przekraczania własnych granic. Pomagamy osiągać rzeczy, które w tym
                                momencie mogą wydawać Ci się nieosiągalne.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='row justify-content-center justify-content-md-end'>
                        <div className='col-auto'>
                            <div className='position-relative'>
                                <div
                                    className='overflow-hidden rounded-5'
                                >
                                    <motion.img className='img-fluid'
                                                initial={{opacity: 0, scale: 0.5}}
                                                animate={{opacity: 1, scale: 1}}
                                                style={{maxHeight: '350px'}}
                                                alt='logo'
                                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/o-nas/logo.png'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}