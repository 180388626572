import React, {Suspense} from "react";
import MainDesign from "../../MainDesign";
import ExercisesList from "../../../components/user/exercise/ExercisesList";
import ExercisesDescription from "../../../components/user/exercise/ExercisesDescription";
import {ExerciseService} from "../../../service/ExerciseService";
import {Await, defer, useLoaderData} from "react-router-dom";
import {ExercisePage} from "../../../model/user/exercise/Exercise";
import LoadingPage from "../../common/LoadingPage";
import {isAdmin, redirectIfNotLoggedIn} from "../../../util/AuthUtil";
import Meta from "../../../components/common/Meta";
import ErrorContent from "../../../components/common/error/ErrorContent";
import {exerciseCategories} from "../../../model/common/exercise/ExerciseCategories";
import Categories from "../../../components/ui/category/Categories";
import {useCategory} from "../../../hooks/useCategory";


type LoaderData = {
    exercises: Promise<ExercisePage>;
    isAdmin: boolean
};

export default function Exercises() {
    const {exercises, isAdmin} = useLoaderData() as LoaderData;

    const {selectedCategory, onCategoryChange} = useCategory(exerciseCategories);

    return <>
        <Meta title="Lista zadań"/>
        <MainDesign containerStyles="py-20 container">
            <ExercisesDescription/>
            <div className='mw-4xl mx-auto'>
                <Categories categories={exerciseCategories} selectedCategory={selectedCategory}
                            onCategoryChange={onCategoryChange}/>
            </div>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={exercises} errorElement={<ErrorContent/>}>
                    {(loadedExercises: ExercisePage) => <>
                        <ExercisesList initialExercises={loadedExercises.items} category={selectedCategory.value}
                                       pageKey={loadedExercises.nextPageKey} readonly={true} isAdmin={isAdmin}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadExercises(category: string): Promise<ExercisePage> {
    return await ExerciseService.getExercises(undefined, category);
}

export async function loader({request}: { request: Request }) {
    const redirect = await redirectIfNotLoggedIn();
    if (redirect) {
        return redirect;
    }
    const category = new URL(request.url).searchParams.get('category') ?? 'all';
    return defer({
        isAdmin: await isAdmin(),
        exercises: loadExercises(category)
    })
}