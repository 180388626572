import React from "react";
import {Link} from "react-router-dom";

interface EmailAndNameProps {
    name: string
    email: string
    setName: React.Dispatch<React.SetStateAction<string>>
    setEmail: React.Dispatch<React.SetStateAction<string>>
    customFooterText?: string
    fullWidth?: boolean;
    hideFooter?: boolean;
}

export default function OptInEmailAndName(props: EmailAndNameProps) {
    const {name, setName, email, setEmail, fullWidth, hideFooter} = props;

    return <>
        <div className={fullWidth ? 'col-12' : 'col-12 col-md-5'}>
            <div className='form-group'>
                <label
                    className='mb-2 fw-bold text-white'
                    htmlFor='signUpInput3-1'
                >
                    Imię
                </label>
                <input
                    className='input-lg form-control text-secondary-dark shadow'
                    id='signUpInput2-1'
                    type='text'
                    aria-describedby='nameHelp'
                    placeholder='Wprowadź swoje imię'
                    name='name'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </div>
        </div>
        <div className={fullWidth ? 'col-12 mt-8 mt-md-6' : 'col-12 col-md-7'}>
            <div className='form-group'>
                <label
                    className='mb-2 fw-bold text-white'
                    htmlFor='signUpInput3-2'
                >
                    Email
                </label>
                <input
                    className='input-lg form-control text-secondary-dark shadow'
                    id='signUpInput2-2'
                    type='email'
                    aria-describedby='emailHelp'
                    placeholder='Podaj adres email'
                    name='email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </div>
        </div>
        {
            !hideFooter && <p className='mb-1 fs-14 fw-medium text-secondary-light text-left mt-2'>
                <span>Przechodząc dalej akceptujesz </span>
                <Link className='text-success link-success' to='/polityka-prywatnosci'>
                    politykę prywatności
                </Link>
                <span> i zapisujesz się do {props.customFooterText ? props.customFooterText : 'newslettera'}, z którego w każdej chwili możesz się wypisać. </span>
            </p>
        }

    </>
}