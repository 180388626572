import TextWithImageAbove from "../optin/TextWithImageAbove";
import React from "react";

export default function ExtraordinarySupportParentExplanation() {
    return <>
        <TextWithImageAbove title='Mentoring'
                            explanation='zapewniam nielimitowany kontakt na WhatsAppie i regularne konsultacje.'/>
        <TextWithImageAbove title='Indywidualizm'
                            explanation='personalizuję materiały na podstawie testów osobowości i wywiadu.'/>
        <TextWithImageAbove title='Sukces'
                            explanation='weryfikuję postępy i gwarantuję zrozumienia każdego zagadnienia.'/>
        <TextWithImageAbove title='Transparentność'
                            explanation='regularnie prezentuję postępy dziecka.'/>
        <TextWithImageAbove title='Różnorodność'
                            explanation='zapewniam zróżnicowane treści edukacyjne (filmy, karty pracy, zbiory wiedzy).'/>
        <TextWithImageAbove title='Zaufanie'
                            explanation='umożliwiam przetestowanie i sprawdzenie wartości mojej pomocy.'/>
        <TextWithImageAbove title='Opinia'
                            explanation='twoje dziecko zyska renomę osoby sukcesu.'/>
        <TextWithImageAbove title='Wsparcie'
                            explanation='pomoc mentora, społeczności, lub w razie potrzeby sprawdzonych ekspertów.'/>
        <TextWithImageAbove title='Systematyczność'
                            explanation='cotygodniowe konsultacje i regularny kontakt.'/>
        <TextWithImageAbove title='Kompleksowość'
                            explanation='gwarantuję pełne wsparcie, jakiego tylko mogłoby potrzebować Twoje dziecko.'/>
        <TextWithImageAbove title='Innowacja'
                            explanation='pierwsze takie rozwiązanie na polskim rynku.'/>
        <TextWithImageAbove title='Edukacja'
                            explanation='to nie tylko szkoła. Przekazuję wiedzę, która wyróżni Twoje dziecko z tłumu na studiach, w pracy, czy w biznesie.'/>
    </>
}