import Heading from "../../common/header/Heading";
import React, {useState} from "react";
import AdvantageItem from "../ai/pricing/AdvantageItem";
import ConfirmModal from "../../ui/ConfirmModal";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import OptInEmailAndName from "./OptInEmailAndName";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import TiktokPixel from "tiktok-pixel";

interface VSLChatContentProps {
    isLoggedIn: boolean
}

export default function VSLChatContent(props: VSLChatContentProps) {
    const {isLoggedIn} = props;

    const {isSubmitting} = useSubmitFormAction('/produkt/chat', null, () => {
        setConfirmModalOpen(false);
    });
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const isInputValid = !!email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) && name.length >= 2;

    return <>
        <Heading customStyles='mb-0 text-center mw-md-5xl mx-auto' title='Rozwiąż i zrozum z łatwością każde zadanie!'
                 customTitleStyles='font-heading mb-4 fs-4 text-white'
                 customSubtitleStyles='text-warning fs-9 fw-medium'
                 subtitle='(AI dostosowana pod polską podstawę programową)'/>
        <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
            <AnimatedButton
                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                onClick={() => {
                    localStorage.setItem('redirectUrlAfterRegistration', '/chat')
                    setConfirmModalOpen(true)
                    TraceService.addTrace(TraceType.BtnClick, 'btn-1')
                    TiktokPixel.track("ClickButton", {
                        "contents": [
                            {
                                "content_id": "chat-tutorial-btn-1", // string. ID of the product. Example: "1077218".
                                "content_name": "Tutorial" // string. The name of the page or product. Example: "shirt".
                            }
                        ],
                    }, {debug: true});
                }}
            >
                Oglądam poradnik
            </AnimatedButton>
        </div>
        <div className='row container mt-4'>
            <div className='col-md-6 col-12 text-center'>
                <img
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/chat/gpt-vs-chat-produkacja-compressed.jpg'
                    className='img-fluid' alt='gpt vs chat produkacja'/>
            </div>
            <div className='col-md-6 col-12 mt-2'>
                <div className='container'>
                    <Heading title='Nauczysz się:'/>
                    <AdvantageItem
                        title='Jak wykorzystywać Chat Produkacji do rozwiązania i zrozumienia każdego zadania.'/>
                    <AdvantageItem title='Jak AI może Ci pomóc w pisaniu tekstów.'/>
                    <AdvantageItem title='Jak tworzyć najlepsze zapytania.'/>
                    <AdvantageItem title='Jak wybrać najlepszy model.'/>
                    <p className='text-warning fw-medium fs-10'>
                        <span className='fw-bold'>Uwaga! </span>
                        Ta okazja jest ograniczona czasowo i dziś jest
                        ostatni dzień, aby się zapisać. Tylko dziś możesz otrzymać ten poradnik ZA DARMO.</p>
                    <div className='text-center mw-md-2xl mx-auto mt-6 mb-4'>
                        <AnimatedButton
                            className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow"
                            onClick={() => {
                                localStorage.setItem('redirectUrlAfterRegistration', '/chat')
                                setConfirmModalOpen(true);
                                TraceService.addTrace(TraceType.BtnClick, 'btn-2')
                                TiktokPixel.track("ClickButton", {
                                    "contents": [
                                        {
                                            "content_id": "chat-tutorial-btn-2", // string. ID of the product. Example: "1077218".
                                            "content_name": "Tutorial" // string. The name of the page or product. Example: "shirt".
                                        }
                                    ],
                                }, {debug: true});
                            }}
                        >
                            Oglądam poradnik
                        </AnimatedButton>
                    </div>
                </div>
            </div>
        </div>
        {/*    Modal*/}
        <ConfirmModal isOpen={confirmModalOpen} setOpen={setConfirmModalOpen} title="Odbierz dostęp do poradnika"
                      subtitle='Poradnik zostanie wysłany na podanego poniżej maila.'
                      customSubtitleStyles={'mt-0'}
                      customTextTop={<div className='col-11 mt-2'>
                              <span
                                  className='fs-9 text-light'><span className='fw-bold text-warning'>
                                  Uwaga!
                              </span> Ta okazja kończy się o 24:00!</span>
                      </div>}
                      buttonType='submit'
                      isSubmitting={isSubmitting}
                      submitDisabled={!isInputValid}
                      children={
                          <OptInEmailAndName name={name} setName={setName} email={email} setEmail={setEmail}/>
                      }
        />
    </>
}
