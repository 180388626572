import {useFetcher} from "react-router-dom";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {FeedbackRequest} from "../../../model/common/Feedback";
import {FeedbackService} from "../../../service/FeedbackService";

interface FeedbackModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    feedbackSource: 'CHAT' | 'CONVERSATIONS' | 'CONTACT' | 'EXERCISE' | 'KED_EPISODE'
    additionalInfo?: string
    hideRating?: boolean,
    hideDreamVision?: boolean
}

export default function FeedbackModal(props: FeedbackModalProps) {
    const {isOpen, setOpen, feedbackSource, additionalInfo, hideRating, hideDreamVision} = props

    const fetcher = useFetcher();
    const {isSubmitting} = useSubmitFormAction('whatever', fetcher, () => {
        setGeneralRating('');
        setRecommendRating('');
        setFeedback('');
        setDreamVision('');
        setOpen(false);
    }, false);

    const [generalRating, setGeneralRating] = useState('');
    const [recommendRating, setRecommendRating] = useState('');
    const [feedback, setFeedback] = useState("");
    const [dreamVision, setDreamVision] = useState("");

    const isFormValid = hideRating || (!!generalRating && generalRating !== '' && !!recommendRating && recommendRating !== '')

    function submitForm() {
        fetcher.submit({
            generalRating: hideRating ? '-1' : generalRating,
            recommendRating: hideRating ? '-1' : recommendRating,
            feedback: feedback + (additionalInfo ? `\n\nDodatkowe informacje:\n ${additionalInfo}` : ''),
            dreamVision,
            category: feedbackSource
        }, {
            method: "POST",
            action: '/feedback'
        });
    }

    return <section
        className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${isOpen ? '' : 'd-none'}`}
        style={{zIndex: 50}}
    >
        <div className='d-flex flex-wrap align-items-center h-100 text-secondary-light'>
            <div className='container py-20'>
                <form className='p-10 mw-5xl mx-auto bg-light-dark overflow-hidden rounded shadow'>
                    <div className={`${hideDreamVision ? 'mb-2' : 'mb-6'} row g-8 align-items-center`}>
                        <div className='col-auto'>
                            <div style={{cursor: "pointer"}} className='navbar-close text-secondary-light'
                                 onClick={() => setOpen(false)}>
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M6 18L18 6M6 6L18 18'
                                        stroke='currentColor'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className='col-11'>
                            <span
                                className='fs-7 fw-bold text-white'>Dziękujemy za Twój feedback!</span>
                        </div>
                        {
                            !hideRating && <>
                                <p className='mb-2 fs-10 fw-medium text-secondary-light'>
                                    1 - słabo, źle, zdecydowanie nie; 5 - super, świetnie, zdecydowanie tak
                                </p>

                                <div className='col-12 col-md-6 mt-0'>
                                    <div className='form-group'>
                                        <label
                                            className='mb-2 text-white fw-bold'
                                            htmlFor='modalInput2-2'
                                        >
                                            Jak oceniasz korzystanie
                                            z {feedbackSource === 'CHAT' ? 'Chatu' : (feedbackSource === 'CONVERSATIONS' ? 'Konwersacji' : 'Produkacji')}?
                                        </label>
                                        <div>
                                            {[1, 2, 3, 4, 5].map((number) => (
                                                <div className="form-check form-check-inline" key={number}>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="generalRating"
                                                        id={`generalRating${number}`}
                                                        value={number}
                                                        onChange={(e) => setGeneralRating(e.target.value)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`rating${number}`}>
                                                        {number}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mt-0'>
                                    <div className='form-group'>
                                        <label
                                            className='mb-2 text-white fw-bold'
                                            htmlFor='modalInput2-2'
                                        >
                                            Polecisz znajomym to rozwiązanie?
                                        </label>
                                        <div>
                                            {[1, 2, 3, 4, 5].map((number) => (
                                                <div className="form-check form-check-inline" key={number}>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="recommendRating"
                                                        id={`recommendRating${number}`}
                                                        value={number}
                                                        onChange={(e) => setRecommendRating(e.target.value)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`rating${number}`}>
                                                        {number}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className='col-12'>
                            <div className='form-group'>
                                <label
                                    className='mb-2 text-white fw-bold'
                                    htmlFor='modalInput2-2'
                                >
                                    Napisz nam, co Ci się podoba i co możemy poprawić.
                                </label>
                                <textarea
                                    className='input-lg form-control shadow'
                                    id='modalInput2-7'
                                    aria-describedby='commentHelp'
                                    placeholder='Zacznij pisać...'
                                    rows={3}
                                    value={feedback}
                                    onChange={(event) => setFeedback(event.target.value)}
                                    style={feedback ? {color: 'black'} : {}}
                                ></textarea>
                            </div>
                        </div>
                        {
                            !hideDreamVision && <>
                                <div className='col-12'>
                                    <div className='form-group'>
                                        <label
                                            className='mb-0 text-white fw-bold'
                                            htmlFor='modalInput2-2'
                                        >
                                            Jak wygląda Twoja wymarzona platforma do nauki?
                                        </label>
                                        <p className='mb-1 mt-0 fs-11 fw-medium text-secondary-light'>
                                            Nie ograniczaj się. Napisz nam, czego Twoim zdaniem brakuje, a my postaramy się
                                            to
                                            wprowadzić!
                                        </p>
                                        <textarea
                                            className='input-lg form-control shadow'
                                            id='modalInput2-7'
                                            aria-describedby='commentHelp'
                                            placeholder='Zacznij pisać...'
                                            rows={2}
                                            value={dreamVision}
                                            onChange={(event) => setDreamVision(event.target.value)}
                                            style={dreamVision ? {color: 'black'} : {}}
                                        ></textarea>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <p className={`mb-0 fs-10 fw-medium text-secondary-light`}>
                        Twoja opinia pomaga nam w tworzeniu jak najlepszej platformy. Dziękujemy.
                    </p>
                    <div className='row mt-2'>
                        <div className='col-12 col-md-4 py-2'>
                            <AnimatedButton
                                className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                type='button'
                                onClick={() => setOpen(false)}
                            >
                                Anuluj
                            </AnimatedButton>
                        </div>
                        <div className='col-12 col-md-8 py-2'>
                            <AnimatedButton
                                className='btn btn-lg btn-success w-100 text-success-light shadow'
                                onClick={submitForm}
                                type="submit"
                                disabled={isSubmitting || !isFormValid}
                            >
                                Potwierdź
                            </AnimatedButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const feedbackRequest = {
        generalRating: formData.get('generalRating') as unknown as number,
        recommendRating: formData.get('recommendRating') as unknown as number,
        feedback: formData.get('feedback') as string,
        dreamVision: formData.get('dreamVision') as string,
        category: formData.get('category') as string
    } as FeedbackRequest;
    try {
        await FeedbackService.sendFeedback(feedbackRequest);
        return {
            status: 200,
            body: 'Feedback został wysłany pomyślnie. Dziękujemy.'
        }
    } catch (error) {
        console.log(error)
        return {
            status: 500,
            body: 'Wystąpił błąd. Spróbuj ponownie później.'
        }
    }
}
