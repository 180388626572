import {motion} from "framer-motion";
import {offerVariants} from "../../../pages/user/offer/OfferLandingPage";
import ProductPageOption from "../ai/pricing/ProductPageOption";

export default function AIProductsSelectOption() {
    return <>
        <div className='row mw-7xl mx-auto'>
            <motion.div
                custom={1}
                variants={offerVariants}
                initial="hidden"
                animate="visible"
                className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
            >
                <ProductPageOption
                    imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/chat.png'
                    redirectTo='/chat' title='Chat' subtitle='Zdobywaj wiedzę szybko, wygodnie i łatwo. Nasze modele AI wspierają Cię, na każdym etapie
          nauki, zapewniając niezbędną wiedzę oraz feedback'/>
            </motion.div>
            <motion.div
                custom={1}
                variants={offerVariants}
                initial="hidden"
                animate="visible"
                className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
            >
                <ProductPageOption
                    imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/konwersacje.png'
                    redirectTo='/konwersacje' title='Konwersacje' subtitle='Konwersacje z AI to klucz do pewności siebie w
          każdej rozmowie. Pozwól naszym wyspecjalizowanym modelom wspomóc Cię w treningu językowym. Odkryj, jak łatwo i
          przyjemnie możesz rozwijać swoje umiejętności lingwistyczne!'/>
            </motion.div>
        </div>
    </>
}