import {Form, Link, useLocation} from "react-router-dom";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import AnimatedButton from "../ui/motion/AnimatedButton";
import AnimatedLink from "../ui/motion/AnimatedLink";
import {AuthService} from "../../service/AuthService";


export default function LoginForm() {
    const location = useLocation(); // Use the generic to type the expected shape of the location state
    const redirectUrl = location.state?.from ?? '/'
    const {isSubmitting} = useSubmitFormAction();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isInputValid = isEmailValid && password.length >= 8;

    return <Form method="POST" className='mw-sm mx-auto p-1'>
        <div className='mb-4 row g-6'>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-1'
                    >
                        Email
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-1'
                        type='email'
                        aria-describedby='emailHelp'
                        placeholder='Podaj adres email'
                        name='email'
                        autoComplete='username'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
            </div>
            <div className='col-12'>
                <div className='form-group'>
                    <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput3-2'
                    >
                        Hasło
                    </label>
                    <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-2'
                        type='password'
                        aria-describedby='passwordHelp'
                        placeholder='Wprowadź hasło'
                        name='password'
                        autoComplete='current-password'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
            </div>
        </div>
        <input name='redirectUrl' readOnly hidden value={redirectUrl}/>
        <div className='mb-6 row align-items-center justify-content-between g-2'>
            <div className='col-auto'>
                <div className='form-check'>
                    <input
                        className='form-check-input shadow'
                        id='flexCheckDefault'
                        type='checkbox'
                        defaultValue=''
                    />
                    <label
                        className='form-check-label fs-13 fw-medium text-white'
                        htmlFor='flexCheckDefault'
                    >
                        Zapamiętaj mnie
                    </label>
                </div>
            </div>
            <div className='col-auto'>
                <Link
                    className='fs-13 fw-medium text-success link-success'
                    to='/reset-hasla'
                >
                    Zapomniałeś hasła?
                </Link>
            </div>
        </div>
        <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
            <span>Logując się akceptujesz </span>
            <Link className='text-success link-success' to='/polityka-prywatnosci'>
                politykę prywatności
            </Link>
            <span> oraz </span>
            <Link className='text-success link-success' to='/regulamin'>
                regulamin
            </Link>
            <span>.</span>
        </p>
        <div className='mb-3 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    disabled={isSubmitting || !isInputValid}
                    customScale={1.05}
                >
                    {isSubmitting ? 'Trwa logowanie...' : 'Zaloguj się'}
                </AnimatedButton>
            </div>
        </div>
        <div className='mb-6 row g-4'>
            <div className='col-12'>
                <AnimatedButton
                    className='btn btn-lg btn-outline-light d-flex flex-wrap align-items-center justify-content-center fs-11 py-3 w-100 text-white shadow'
                    onClick={() => AuthService.facebookLogin()}
                    bounceEffect
                    customScale={1.05}
                    disabled={isSubmitting}
                >

                    <img
                        className='me-2'
                        style={{width: "24px"}}
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/social/social-fb.png'
                        alt='logo facebook'
                    />
                    <span>Kontynuuj z Facebookiem</span>
                </AnimatedButton>
            </div>
        </div>
        <p className='mb-0 fs-13 fw-medium text-white text-center'>
            <span>Nie masz jeszcze konta? </span>
            <AnimatedLink className='text-success link-success' to='/rejestracja'>
                Zarejestruj się
            </AnimatedLink>
        </p>
    </Form>
}