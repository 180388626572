import React, {Suspense, useState} from "react";
import MainDesign from "../../MainDesign";
import {Await, defer, Params, useLoaderData} from "react-router-dom";
import {DeferredData} from "@remix-run/router/dist/utils";
import {ExerciseService} from "../../../service/ExerciseService";
import {Exercise, Exercise as ExerciseModel} from "../../../model/user/exercise/Exercise";
import FullExercise from "../../../components/user/exercise/FullExercise";
import LoadingPage from "../../common/LoadingPage";
import {isAdmin, isLoggedIn} from "../../../util/AuthUtil";
import ErrorContent from "../../../components/common/error/ErrorContent";

type ExerciseData = {
    exercise: ExerciseModel;
    isLoggedIn: boolean;
    isAdmin: boolean;
};

export default function ExercisePage() {
    const {exercise, isLoggedIn, isAdmin} = useLoaderData() as ExerciseData;
    const [showSolution, setShowSolution] = useState(false);

    return <>
        <MainDesign containerStyles="py-20 container">
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={exercise} errorElement={<ErrorContent/>}>
                    {(loadedExercise: Exercise) => <>
                        <FullExercise exercise={loadedExercise} showSolution={showSolution}
                                      setShowSolution={setShowSolution} isLoggedIn={isLoggedIn}
                                      showBackButtonToAdminPanel={isAdmin}
                                      backButtonDisabled={false}/>
                    </>}
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

async function loadUserData(uuid: string): Promise<ExerciseModel> {
    return await ExerciseService.getExercise(uuid, false);
}

export async function loader({params}: { params: Params<"uuid"> }): Promise<DeferredData> {
    return defer({
        exercise: loadUserData(params.uuid!!),
        isLoggedIn: await isLoggedIn(),
        isAdmin: await isAdmin()
    });
}