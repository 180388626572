import AdvantageItem from "../ai/pricing/AdvantageItem";
import React from "react";
import Heading from "../../common/header/Heading";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";

export default function RedirectAfterSchedulingCallMaturaParent() {
    return <>
        <div className='mw-md-3xl mx-auto'>
            <p className='text-center fs-10 fw-medium text-white '>
                <div className='row g-8 mt-2'>
                    <div className='col-12 col-md-6'>
                        <div className='mw-md-2xl text-start pe-md-8'>
                            <div
                                className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                                style={{width: 48, height: 48}}
                            >
                                1
                            </div>
                            <h3 className='mb-2 font-heading fs-9 text-white'>
                                Obejrzyj poniższy film
                            </h3>
                            <p className='mb-0 text-secondary-light fw-medium'>
                                Trwa on dokładnie 30 sekund. Wyjaśniam w nim, jak przygotować się do spotkania.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='mw-md-sm text-start'>
                            <div
                                className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                                style={{width: 48, height: 48}}
                            >
                                2
                            </div>
                            <h3 className='mb-2 font-heading fs-9 text-white'>
                                Potwierdź rozmowę poprzez odpisanie na email
                            </h3>
                            <p className='mb-0 text-secondary-light fw-medium'>
                                Wystarczy, że napiszesz krótkie 'potwierdzam'.
                            </p>
                        </div>
                    </div>
                </div>
            </p>
            <VideoWithPlayButton vimeoVideoId='981227070' id='thank-you-for-booking'/>
            <Heading customTitleStyles='font-heading mb-4 fs-6 text-white'
                     customStyles='mb-6 text-center mw-md-4xl mx-auto mt-6 mt-md-0' title='Co Ty zyskujesz?'/>
            <AdvantageItem customStyles='mb-0 fs-9 fw-medium text-white'
                           title={`Gwarancję sukcesu Twojego dziecka. `}/>
            <AdvantageItem customStyles='mb-0 fs-9 fw-medium text-white'
                           title='Spokojną głowę i koniec ze stresem.'/>
            <AdvantageItem customStyles='mb-0 fs-9 fw-medium text-white'
                           title='Oszczędność czasu i pieniędzy.'/>

            <Heading customTitleStyles='font-heading mb-4 fs-6 text-white'
                     customStyles='mb-6 text-center mw-md-4xl mx-auto mt-16'
                     title='Co zyskuje Twoje dziecko?'/>
            <AdvantageItem title='Indywidualny mentoring i regularne zajęcia z ekspertami.'/>
            <AdvantageItem title='Pełny dostęp do Innowacyjnego Kursu Efektywnego Działania.'/>
            <AdvantageItem title='Umiejętności przyszłości, które pozwolą mu zabłysnąć w dzisiejszym świecie.'/>
            <Heading customStyles='mb-6 text-center mw-md-4xl mx-auto mt-8'
                     customTitleStyles='font-heading mb-4 fs-7 text-white' title='A do tego wiele bonusów...'/>
            <AdvantageItem title='Indywidualnie przygotowany plan działania.'/>
            <AdvantageItem title='Stały kontakt z ekspertami na forum dostępnym 24/7.'/>
            <AdvantageItem title='Dostęp do specjalnie dostosowanych narzędzi AI pod maturzystów.'/>
            <AdvantageItem title='Cheat-Sheety i materiały, gotowe do wdrożenia od zaraz.'/>
            <AdvantageItem
                title='Wspierająca społeczność motywująca do działania.'/>
            <AdvantageItem
                title='Wymiana doświadczeń na cotygodniowych grupowych spotkaniach z innymi kursantami.'/>
            <p className='text-center fs-11 fw-medium text-secondary-light mt-3'>
                I wiele, wiele więcej...</p>
        </div>
    </>
}