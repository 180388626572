import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function KedShowcase() {
    const navigate = useNavigate();
    return <>
        <VideoWithPlayButton vimeoVideoId='996243814' id='ked-showcase'/>
        <div className='text-center mw-md mx-auto'>
            <AnimatedButton
                className='btn btn-lg btn-success w-100 text-success-light shadow'
                onClick={() => navigate('/spotkanie/dla-maturzysty?source=ked-showcase')}
            >
                Umów się na spotkanie
            </AnimatedButton>
        </div>
    </>
}