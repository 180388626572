import React, {Suspense, useState} from "react";
import MainDesign from "../../MainDesign";
import ExercisesList from "../../../components/user/exercise/ExercisesList";
import PlannedExercisesDescription from "../../../components/admin/exercise/PlannedExercisesDescription";
import AddExerciseButton from "../../../components/admin/exercise/AddExerciseButton";
import OldExercisesDescription from "../../../components/admin/exercise/OldExercisesDescription";
import ShowOldExercisesButton from "../../../components/admin/exercise/ShowOldExercisesButton";
import {Exercise, ExercisePage} from "../../../model/user/exercise/Exercise";
import {ExerciseService} from "../../../service/ExerciseService";
import {Await, defer, useLoaderData} from "react-router-dom";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import {ApiError} from "../../../service/HttpService";
import Meta from "../../../components/common/Meta";
import {useCategory} from "../../../hooks/useCategory";
import {exerciseCategories} from "../../../model/common/exercise/ExerciseCategories";
import Categories from "../../../components/ui/category/Categories";

type LoaderData = {
    plannedExercises: Promise<Exercise[]>;
    publishedExercises: Promise<ExercisePage>;
};


export default function AdminExercises() {
    const {plannedExercises, publishedExercises} = useLoaderData() as LoaderData;
    const [showOldExercises, setShowOldExercises] = useState(false);

    const {selectedCategory, onCategoryChange} = useCategory(exerciseCategories);

    return <>
        <Meta title="Zadania"/>
        <MainDesign containerStyles="py-20 container" minHeight="95vh">
            <PlannedExercisesDescription/>
            <AddExerciseButton/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={plannedExercises} errorElement={<ErrorContent/>}>
                    {(loadedExercises) => <>
                        <ExercisesList initialExercises={loadedExercises} readonly={false} pageKey={undefined}
                                       category={'all'} isAdmin={true}/>
                    </>}
                </Await>
            </Suspense>
            <OldExercisesDescription/>
            <ShowOldExercisesButton showOldExercises={showOldExercises} setShowOldExercises={setShowOldExercises}/>

            {
                showOldExercises && <>
                    <div className='mw-4xl mx-auto'>
                        <Categories categories={exerciseCategories} selectedCategory={selectedCategory}
                                    onCategoryChange={onCategoryChange}/>
                    </div>
                    <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                        <Await resolve={publishedExercises} errorElement={<ErrorContent/>}>
                            {(loadedExercises: ExercisePage) => <>
                                <ExercisesList initialExercises={loadedExercises.items} readonly={false} isAdmin={true}
                                               category={selectedCategory.value} pageKey={loadedExercises.nextPageKey}/>
                            </>}
                        </Await>
                    </Suspense>
                </>
            }

        </MainDesign>
    </>
}

async function loadExercises(category: string): Promise<ExercisePage> {
    return await ExerciseService.getExercises(undefined, category);
}

async function loadPlannedExercises(): Promise<Exercise[]> {
    return await ExerciseService.getPlannedExercises();
}

export async function loader({request}: { request: Request }) {
    const category = new URL(request.url).searchParams.get('category') ?? 'all';
    return defer({
        plannedExercises: loadPlannedExercises(),
        publishedExercises: loadExercises(category),
    })
}

export async function deleteAction({request}: { request: Request }) {
    const formData = await request.formData();
    const exerciseUuid = formData.get('uuid') as string;
    try {
        await ExerciseService.deleteExercise(exerciseUuid);
        return {
            status: 200,
            body: 'Pomyślnie usunięto.'
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}