import Heading from "../../../../common/header/Heading";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";
import SlideInWhenVisible from "../../../../ui/motion/SlideInWhenVisible";

interface ConversationsHeaderProps {
    isLoggedIn: boolean
}

export default function ConversationsHeader(props: ConversationsHeaderProps) {
    const {isLoggedIn} = props;

    return <>
        <div className="container">
            <div className="pt-4 pb-10 position-relative" style={{zIndex: 1}}>
                <div className="mb-16 text-center mw-md-3xl mx-auto">
                    <Heading badgeText='Oferta konwersacji' title='Konwersacje bez stresu' subtitle='Nasza innowacyjna oferta konwersacji ze sztuczną inteligencją to
                        klucz do pewności siebie w każdej rozmowie. Przestań martwić się popełnianymi błędami czy
                        nieporadnością w tworzeniu zdań - pozwól naszym wyspecjalizowanym modelom wspomóc Cię w treningu
                        językowym.'/>

                    <div className="row justify-content-center g-4 pt-2">
                        <div className="col-12 col-md-auto">
                            <SlideInWhenVisible initialX={-100} duration={0.4}>
                                <AnimatedLink
                                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                                    onClick={() => {
                                        localStorage.setItem('redirectUrlAfterRegistration', '/konwersacje')
                                    }}
                                    to={isLoggedIn ? "/konwersacje" : "/rejestracja"}>Wypróbuj teraz</AnimatedLink>
                            </SlideInWhenVisible>

                        </div>
                        <div className="col-12 col-md-auto">
                            <SlideInWhenVisible initialX={100} duration={0.4} delay={0.4}>
                                <AnimatedLink hashLink={true}
                                              className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                              to="#potrzeba">Czytaj dalej</AnimatedLink>
                            </SlideInWhenVisible>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}