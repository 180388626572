import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import LearningCourseSuccessfulPurchase
    from "../../../components/user/learning-course/purchase/LearningCourseSuccessfulPurchase";

export default function LearningCourseSuccessfulPurchasePage() {
    return <>
        <Meta title="Kurs Efektywnego Działania - Produkacja"/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Gratulujemy - Świetna decyzja!'
                     subtitle="Witamy na kursie efektywnego działania! Jeżeli podany adres email nie był jeszcze
                     zarejestrowany, należy kliknąć w link otrzymany na maila, aby aktywować konto."
                     badgeText='Zakup udany'/>
            <LearningCourseSuccessfulPurchase/>
        </MainDesign>
    </>
}