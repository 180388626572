import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import TransactionList from "../../../components/user/profile/TransactionList";
import Heading from "../../../components/common/header/Heading";
import {redirectIfNotLoggedIn} from "../../../util/AuthUtil";
import {Await, defer, useLoaderData} from "react-router-dom";
import {TransactionService} from "../../../service/TransactionService";
import {Transaction} from "../../../model/user/purchase/Transaction";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import React, {Suspense} from "react";

type LoaderData = {
    transactions: Promise<Transaction[]>
}

export default function TransactionsPage() {
    const {transactions} = useLoaderData() as LoaderData;
    return <>
        <Meta title="Transakcje"/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Historia transakcji' badgeText='Transakcje'
                     subtitle='Poniżej znajdziesz pełną historię swoich transakcji i zweryfikujesz ich status'/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={transactions} errorElement={<ErrorContent/>}>
                    {(loadedTransactions) => <>
                        <TransactionList transactions={loadedTransactions}/>
                    </>}
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

export async function loader() {
    const response = await redirectIfNotLoggedIn();
    if (response) {
        return response;
    }
    return defer({
        transactions: TransactionService.getTransactions()
    });
}