import React from "react";

export default function PossibleUniversities() {
    return <div className='text-center mt-12'>
        <h2 className='mb-6'>Nasi podopieczni dostają się na: </h2>
        <img
            className='px-3 py-1 py-md-0'
            style={{height: '150px'}}
            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/mw/logo-uniwersytety/cambridge.png'
            alt='Cambridge'/>
        <img
            className='px-3 py-1 py-md-0'
            style={{height: '150px'}}
            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/mw/logo-uniwersytety/politechnika-warszawska.png'
            alt='Politechnika Warszawska'/>
        <img
            className='px-3 py-1 py-md-0'
            style={{height: '150px'}}
            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/mw/logo-uniwersytety/uniwersytet-warszawski-medycyna.png'
            alt='Uniwersytet Warszawski - medycyna'/>
        <img
            className='px-3 py-1 py-md-0'
            style={{height: '150px'}}
            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/mw/logo-uniwersytety/uniwersytet-jagiellonski.png'
            alt='Uniwersytet jagielloński'/>
        <img
            className='px-3 py-1 py-md-0'
            style={{height: '150px'}}
            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/mw/logo-uniwersytety/uo.png'
            alt='Uniwersytet Opolski'/>
        <img
            className='px-3 py-1 py-md-0'
            style={{height: '150px'}}
            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/mw/logo-uniwersytety/pg.png'
            alt='PG'/>
    </div>
}