import {motion} from "framer-motion";

interface ConversationsHowItWorksItemProps {
    count: number
    title: string
    subtitle: string
}

export default function HowItWorksItem(props: ConversationsHowItWorksItemProps) {
    const {count, title, subtitle} = props;
    return <motion.div
        variants={{
            hidden: {opacity: 0, scale: 0.5},
            visible: {opacity: 1, scale: 1}
        }}
        exit={{opacity: 1, scale: 1}}
        transition={{type: 'spring'}}
        className='col-12 col-sm-6 col-md-3'
    >
        <div className='mw-md-sm'>
            <div
                className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                style={{width: 48, height: 48}}
            >
                {count}
            </div>
            <h3 className='mb-2 font-heading fs-9 text-white'>
                {title}
            </h3>
            <p className='mb-0 text-secondary-light fw-medium'>
                {subtitle}
            </p>
        </div>
    </motion.div>
}